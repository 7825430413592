@media (min-width: 768px) {
  .no-mac {
    display: none !important;
  }
  .row-card {
    margin-right: -12.5px;
    margin-left: -12.5px;
    width: auto !important;
  }
}

@media (max-width: 768px) {
  .no-phone {
    display: none !important;
  }
  .padding-left-mobile {
    padding-left: 0px !important;
  }
  .padding-right-mobile {
    padding-right: 0px !important;
  }
}

.line-height-bigger {
  line-height: 42px !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: 600 !important;
}

.font-italic {
  font-style: italic !important;
}

.font-size-base {
  font-size: 13px;
}

.font-size-sm {
  font-size: 12px !important;
}

.font-size-small {
  font-size: 10px !important;
}

.font-size-xs {
  font-size: 9px !important;
}

.font-size-lg {
  font-size: 14px;
}

.font-size-h1 {
  font-size: 24px !important;
}

.font-size-big {
  font-size: 28px !important;
}

.font-size-h2 {
  font-size: 21px !important;
}

.font-size-h3 {
  font-size: 18px !important;
}

.font-size-h4 {
  font-size: 16px !important;
}

.font-size-h5 {
  font-size: 14px !important;
}

.font-size-h6 {
  font-size: 14px !important;
}

.font-size-bigger {
  font-size: 42px !important;
}

.font-size-biggest {
  font-size: 80px !important;
}

.text-dark-75 {
  color: var(--color-dark75) !important;
}

.fs-1rem {
  font-size: 1rem !important;
}

.fs-22px {
  font-size: 22px !important;
}

.fs-20px {
  font-size: 20px !important;
}

a.text-hover-primary, .text-hover-primary {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

a.text-hover-primary:hover, .text-hover-primary:hover {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  color: #3699ff !important;
}

a.text-hover-primary:hover i, .text-hover-primary:hover i {
  color: #3699ff !important;
}

a.text-hover-primary:hover .svg-icon svg g [fill], .text-hover-primary:hover .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #3699ff;
}

a.text-hover-primary:hover .svg-icon svg:hover g [fill], .text-hover-primary:hover .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.hoverable:hover a.text-hover-primary, .hoverable:hover .text-hover-primary {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  color: #3699ff !important;
}

.hoverable:hover a.text-hover-primary i, .hoverable:hover .text-hover-primary i {
  color: #3699ff !important;
}

.hoverable:hover a.text-hover-primary .svg-icon svg g [fill], .hoverable:hover .text-hover-primary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #3699ff !important;
}

.hoverable:hover a.text-hover-primary .svg-icon svg:hover g [fill], .hoverable:hover .text-hover-primary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.text-secondary {
  color: #181c32 !important;
}

.text-inverse-secondary {
  color: #3f4254 !important;
}

.text-light-secondary {
  color: #ebedf3 !important;
}

.text-muted {
  color: #b5b5c3 !important;
}

.text-gold {
  color: #ffff35 !important;
}

.text-orange {
  color: #fec33c !important;
}

.text-gray {
  color: #ced4da !important;
}

.bg-orange {
  background-color: #fec33c !important;
}

.avatar-premium {
  border: 2px solid #fec33c !important;
}

.gradient-border-tipster-1st-nav {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-tipster-1st-nav:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  height: 44px;
  width: 44px;
  background: linear-gradient( 60deg, #a23232, #ff368a, #2196f3, #00bcd4, #a0d1f9, #53a4e7, #f4037b, #a01616);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.gradient-border-tipster-profile {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-tipster-profile:after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: 128px;
  width: 128px;
  background: linear-gradient( 60deg, #3269a2, #3699ff, #2196f3, #00bcd4, #a0d1f9, #87c3f3, #03a9f4, #1663a0);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.gradient-border-tipster {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-tipster:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  height: 68px;
  width: 68px;
  background: linear-gradient( 60deg, #3269a2, #3699ff, #2196f3, #00bcd4, #a0d1f9, #87c3f3, #03a9f4, #1663a0);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.gradient-border-tipster-1st {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-tipster-1st:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  height: 68px;
  width: 68px;
  background: linear-gradient( 60deg, #a23232, #ff368a, #2196f3, #00bcd4, #a0d1f9, #53a4e7, #f4037b, #a01616);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

@-webkit-keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.text-userLevel {
  color: #b5b5c3 !important;
  font-size: 13px;
  font-weight: initial;
}

.flex-root {
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex: 1 0 0px;
}

.flex-column-auto {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.flex-column-fluid {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.flex-row-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.flex-row-fluid {
  -webkit-box-flex: 1;
  flex: 1 auto;
  -ms-flex: 1 0 0px;
  min-width: 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .flex-row-fluid {
    min-width: none;
  }
}

.flex-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.flex-space-arround {
  align-items: center;
  justify-content: space-around;
}

@media (min-width: 576px) {
  .flex-sm-root {
    -webkit-box-flex: 1;
    flex: 1;
    -ms-flex: 1 0 0px;
  }
  .flex-sm-column-auto {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
  }
  .flex-sm-column-fluid {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  .flex-sm-row-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .flex-sm-row-fluid {
    -webkit-box-flex: 1;
    flex: 1 auto;
    -ms-flex: 1 0 0px;
    min-width: 0;
  }
}

@media screen and (min-width: 576px) and (-ms-high-contrast: active), (min-width: 576px) and (-ms-high-contrast: none) {
  .flex-sm-row-fluid {
    min-width: none;
  }
}

@media (min-width: 576px) {
  .flex-sm-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .flex-md-root {
    -webkit-box-flex: 1;
    flex: 1;
    -ms-flex: 1 0 0px;
  }
  .flex-md-column-auto {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
  }
  .flex-md-column-fluid {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  .flex-md-row-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .flex-md-row-fluid {
    -webkit-box-flex: 1;
    flex: 1 auto;
    -ms-flex: 1 0 0px;
    min-width: 0;
  }
}

@media screen and (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
  .flex-md-row-fluid {
    min-width: none;
  }
}

@media (min-width: 768px) {
  .flex-md-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .flex-lg-root {
    -webkit-box-flex: 1;
    flex: 1;
    -ms-flex: 1 0 0px;
  }
  .flex-lg-column-auto {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
  }
  .flex-lg-column-fluid {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  .flex-lg-row-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .flex-lg-row-fluid {
    -webkit-box-flex: 1;
    flex: 1 auto;
    -ms-flex: 1 0 0px;
    min-width: 0;
  }
}

@media screen and (min-width: 992px) and (-ms-high-contrast: active), (min-width: 992px) and (-ms-high-contrast: none) {
  .flex-lg-row-fluid {
    min-width: none;
  }
}

@media (min-width: 992px) {
  .flex-lg-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .flex-xl-root {
    -webkit-box-flex: 1;
    flex: 1;
    -ms-flex: 1 0 0px;
  }
  .flex-xl-column-auto {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
  }
  .flex-xl-column-fluid {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  .flex-xl-row-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .flex-xl-row-fluid {
    -webkit-box-flex: 1;
    flex: 1 auto;
    -ms-flex: 1 0 0px;
    min-width: 0;
  }
}

@media screen and (min-width: 1200px) and (-ms-high-contrast: active), (min-width: 1200px) and (-ms-high-contrast: none) {
  .flex-xl-row-fluid {
    min-width: none;
  }
}

@media (min-width: 1200px) {
  .flex-xl-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (min-width: 1400px) {
  .flex-xxl-root {
    -webkit-box-flex: 1;
    flex: 1;
    -ms-flex: 1 0 0px;
  }
  .flex-xxl-column-auto {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
  }
  .flex-xxl-column-fluid {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  .flex-xxl-row-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .flex-xxl-row-fluid {
    -webkit-box-flex: 1;
    flex: 1 auto;
    -ms-flex: 1 0 0px;
    min-width: 0;
  }
}

@media screen and (min-width: 1400px) and (-ms-high-contrast: active), (min-width: 1400px) and (-ms-high-contrast: none) {
  .flex-xxl-row-fluid {
    min-width: none;
  }
}

@media (min-width: 1400px) {
  .flex-xxl-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.offcanvas, .offcanvas-mobile {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.offcanvas .offcanvas-header.offcanvas-header-navs, .offcanvas-mobile .offcanvas-header.offcanvas-header-navs {
  position: relative;
}

.offcanvas .offcanvas-header.offcanvas-header-navs .offcanvas-close, .offcanvas-mobile .offcanvas-header.offcanvas-header-navs .offcanvas-close {
  position: absolute;
  top: 0;
  right: 0;
}

.offcanvas .offcanvas-content .offcanvas-demo, .offcanvas-mobile .offcanvas-content .offcanvas-demo {
  border: 5px solid #ebedf3;
}

.offcanvas .offcanvas-content .offcanvas-demo.offcanvas-demo-active, .offcanvas-mobile .offcanvas-content .offcanvas-demo.offcanvas-demo-active {
  border: 5px solid #3699ff;
}

.offcanvas {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
}

@media (max-width: 991.98px) {
  .offcanvas-mobile {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
  }
}

.offcanvas {
  z-index: 1001;
  position: fixed;
  top: 0;
  bottom: 0;
  -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  left: -395px;
  width: 375px;
}

.offcanvas.offcanvas-on {
  -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  left: 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .offcanvas {
    -webkit-transition: none !important;
    transition: none !important;
  }
}

.offcanvas.offcanvas-right {
  right: -395px;
  left: auto;
}

.offcanvas.offcanvas-right.offcanvas-on {
  -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  right: 0;
  left: auto;
}

.offcanvas-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1000;
  -webkit-animation: animation-offcanvas-fade-in 0.6s ease 1;
  animation: animation-offcanvas-fade-in 0.6s ease 1;
}

@-webkit-keyframes animation-offcanvas-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animation-offcanvas-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.offcanvas {
  background: #ffffff;
  -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
}

.offcanvas-overlay {
  background: rgba(0, 0, 0, 0.1);
}

@media (max-width: 425px) {
  .offcanvas {
    width: 90% !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-mobile {
    z-index: 1001;
    position: fixed;
    top: 0;
    bottom: 0;
    -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    left: -395px;
    width: 375px;
  }
  .offcanvas-mobile.offcanvas-mobile-on {
    -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    left: 0;
  }
}

@media screen and (max-width: 991.98px) and (-ms-high-contrast: active), (max-width: 991.98px) and (-ms-high-contrast: none) {
  .offcanvas-mobile {
    -webkit-transition: none !important;
    transition: none !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-mobile.offcanvas-mobile-right {
    right: -395px;
    left: auto;
  }
  .offcanvas-mobile.offcanvas-mobile-right.offcanvas-mobile-on {
    -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    right: 0;
    left: auto;
  }
  .offcanvas-mobile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 1000;
    -webkit-animation: animation-offcanvas-fade-in 0.6s ease 1;
    animation: animation-offcanvas-fade-in 0.6s ease 1;
  }
  @-webkit-keyframes animation-offcanvas-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes animation-offcanvas-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .offcanvas-mobile {
    background: #ffffff;
    -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
  }
  .offcanvas-mobile-overlay {
    background: rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 425px) {
  .offcanvas-mobile {
    width: 90% !important;
  }
}

.h-auto {
  height: auto !important;
}

.w-auto {
  width: auto !important;
}

.w-fc {
  width: fit-content !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.min-w-80 {
  min-width: 80% !important;
}

.min-h-80 {
  min-height: 80% !important;
}

.h-80 {
  height: 80% !important;
}

.w-80 {
  width: 80% !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.min-h-100 {
  min-height: 100% !important;
}

.max-h-100 {
  max-height: 100% !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.max-w-48 {
  max-width: 12rem;
}

.w-50 {
  width: 50% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-33 {
  width: 33.33% !important;
}

.w-20 {
  width: 20% !important;
}

.h-50 {
  height: 50% !important;
}

.min-w-50 {
  min-width: 50% !important;
}

.min-h-50 {
  min-height: 50% !important;
}

.max-h-50 {
  max-height: 50% !important;
}

.max-w-50 {
  max-width: 50% !important;
}

.w-auto {
  width: auto !important;
}

.h-auto {
  height: auto !important;
}

.min-w-auto {
  min-width: auto !important;
}

.min-h-auto {
  min-height: auto !important;
}

.max-h-auto {
  max-height: auto !important;
}

.max-w-auto {
  max-width: auto !important;
}

.w-1px {
  width: 1px !important;
}

.h-1px {
  height: 1px !important;
}

.min-w-1px {
  min-width: 1px !important;
}

.min-h-1px {
  min-height: 1px !important;
}

.max-h-1px {
  max-height: 1px !important;
}

.max-w-1px {
  max-width: 1px !important;
}

.w-2px {
  width: 2px !important;
}

.h-2px {
  height: 2px !important;
}

.min-w-2px {
  min-width: 2px !important;
}

.min-h-2px {
  min-height: 2px !important;
}

.max-h-2px {
  max-height: 2px !important;
}

.max-w-2px {
  max-width: 2px !important;
}

.w-3px {
  width: 3px !important;
}

.h-3px {
  height: 3px !important;
}

.h-366px {
  height: 366px !important;
}

.min-w-3px {
  min-width: 3px !important;
}

.min-h-3px {
  min-height: 3px !important;
}

.max-h-3px {
  max-height: 3px !important;
}

.max-w-3px {
  max-width: 3px !important;
}

.w-4px {
  width: 4px !important;
}

.h-4px {
  height: 4px !important;
}

.min-w-4px {
  min-width: 4px !important;
}

.min-h-4px {
  min-height: 4px !important;
}

.max-h-4px {
  max-height: 4px !important;
}

.max-w-4px {
  max-width: 4px !important;
}

.w-5px {
  width: 5px !important;
}

.h-5px {
  height: 5px !important;
}

.min-w-5px {
  min-width: 5px !important;
}

.min-h-5px {
  min-height: 5px !important;
}

.max-h-5px {
  max-height: 5px !important;
}

.max-w-5px {
  max-width: 5px !important;
}

.w-10px {
  width: 10px !important;
}

.h-10px {
  height: 10px !important;
}

.min-w-10px {
  min-width: 10px !important;
}

.min-h-10px {
  min-height: 10px !important;
}

.max-h-10px {
  max-height: 10px !important;
}

.max-w-10px {
  max-width: 10px !important;
}

.w-15px {
  width: 15px !important;
}

.h-15px {
  height: 15px !important;
}

.min-w-15px {
  min-width: 15px !important;
}

.min-h-15px {
  min-height: 15px !important;
}

.max-h-15px {
  max-height: 15px !important;
}

.max-w-15px {
  max-width: 15px !important;
}

.w-20px {
  width: 20px !important;
}

.h-20px {
  height: 20px !important;
}

.min-w-20px {
  min-width: 20px !important;
}

.min-h-20px {
  min-height: 20px !important;
}

.max-h-20px {
  max-height: 20px !important;
}

.max-w-20px {
  max-width: 20px !important;
}

.w-22px {
  width: 22px !important;
}

.h-22px {
  height: 22px !important;
}

.w-25px {
  width: 25px !important;
}

.h-25px {
  height: 25px !important;
}

.min-w-25px {
  min-width: 25px !important;
}

.min-h-25px {
  min-height: 25px !important;
}

.max-h-25px {
  max-height: 25px !important;
}

.max-w-25px {
  max-width: 25px !important;
}

.w-30px {
  width: 30px !important;
}

.h-30px {
  height: 30px !important;
}

.min-w-30px {
  min-width: 30px !important;
}

.min-h-30px {
  min-height: 30px !important;
}

.max-h-30px {
  max-height: 30px !important;
}

.max-w-30px {
  max-width: 30px !important;
}

.w-35px {
  width: 35px !important;
}

.h-35px {
  height: 35px !important;
}

.min-w-35px {
  min-width: 35px !important;
}

.min-h-35px {
  min-height: 35px !important;
}

.max-h-35px {
  max-height: 35px !important;
}

.max-w-35px {
  max-width: 35px !important;
}

.w-40px {
  width: 40px !important;
}

.h-40px {
  height: 40px !important;
}

.min-w-40px {
  min-width: 40px !important;
}

.min-h-40px {
  min-height: 40px !important;
}

.max-h-40px {
  max-height: 40px !important;
}

.max-w-40px {
  max-width: 40px !important;
}

.w-45px {
  width: 45px !important;
}

.h-45px {
  height: 45px !important;
}

.min-w-45px {
  min-width: 45px !important;
}

.min-h-45px {
  min-height: 45px !important;
}

.max-h-45px {
  max-height: 45px !important;
}

.max-w-45px {
  max-width: 45px !important;
}

.w-50px {
  width: 50px !important;
}

.h-50px {
  height: 50px !important;
}

.min-w-50px {
  min-width: 50px !important;
}

.min-h-50px {
  min-height: 50px !important;
}

.max-h-50px {
  max-height: 50px !important;
}

.max-w-50px {
  max-width: 50px !important;
}

.w-55px {
  width: 55px !important;
}

.h-55px {
  height: 55px !important;
}

.min-w-55px {
  min-width: 55px !important;
}

.min-h-55px {
  min-height: 55px !important;
}

.max-h-55px {
  max-height: 55px !important;
}

.max-w-55px {
  max-width: 55px !important;
}

.w-60px {
  width: 60px !important;
}

.h-60px {
  height: 60px !important;
}

.min-w-60px {
  min-width: 60px !important;
}

.min-h-60px {
  min-height: 60px !important;
}

.max-h-60px {
  max-height: 60px !important;
}

.max-w-60px {
  max-width: 60px !important;
}

.w-65px {
  width: 65px !important;
}

.h-65px {
  height: 65px !important;
}

.min-w-65px {
  min-width: 65px !important;
}

.min-h-65px {
  min-height: 65px !important;
}

.max-h-65px {
  max-height: 65px !important;
}

.max-w-65px {
  max-width: 65px !important;
}

.w-70px {
  width: 70px !important;
}

.h-70px {
  height: 70px !important;
}

.min-w-70px {
  min-width: 70px !important;
}

.min-h-70px {
  min-height: 70px !important;
}

.max-h-70px {
  max-height: 70px !important;
}

.max-w-70px {
  max-width: 70px !important;
}

.w-75px {
  width: 75px !important;
}

.h-75px {
  height: 75px !important;
}

.min-w-75px {
  min-width: 75px !important;
}

.min-h-75px {
  min-height: 75px !important;
}

.max-h-75px {
  max-height: 75px !important;
}

.max-w-75px {
  max-width: 75px !important;
}

.w-80px {
  width: 80px !important;
}

.h-80px {
  height: 80px !important;
}

.min-w-80px {
  min-width: 80px !important;
}

.min-h-80px {
  min-height: 80px !important;
}

.max-h-80px {
  max-height: 80px !important;
}

.max-w-80px {
  max-width: 80px !important;
}

.w-85px {
  width: 85px !important;
}

.h-85px {
  height: 85px !important;
}

.min-w-85px {
  min-width: 85px !important;
}

.min-h-85px {
  min-height: 85px !important;
}

.max-h-85px {
  max-height: 85px !important;
}

.max-w-85px {
  max-width: 85px !important;
}

.w-90px {
  width: 90px !important;
}

.h-90px {
  height: 90px !important;
}

.min-w-90px {
  min-width: 90px !important;
}

.min-h-90px {
  min-height: 90px !important;
}

.max-h-90px {
  max-height: 90px !important;
}

.max-w-90px {
  max-width: 90px !important;
}

.w-95px {
  width: 95px !important;
}

.h-95px {
  height: 95px !important;
}

.min-w-95px {
  min-width: 95px !important;
}

.min-h-95px {
  min-height: 95px !important;
}

.max-h-95px {
  max-height: 95px !important;
}

.max-w-95px {
  max-width: 95px !important;
}

.w-100px {
  width: 100px !important;
}

.h-100px {
  height: 100px !important;
}

.min-w-100px {
  min-width: 100px !important;
}

.min-h-100px {
  min-height: 100px !important;
}

.max-h-100px {
  max-height: 100px !important;
}

.max-w-100px {
  max-width: 100px !important;
}

.w-105px {
  width: 105px !important;
}

.h-105px {
  height: 105px !important;
}

.min-w-105px {
  min-width: 105px !important;
}

.min-h-105px {
  min-height: 105px !important;
}

.max-h-105px {
  max-height: 105px !important;
}

.max-w-105px {
  max-width: 105px !important;
}

.w-110px {
  width: 110px !important;
}

.h-110px {
  height: 110px !important;
}

.min-w-110px {
  min-width: 110px !important;
}

.min-h-110px {
  min-height: 110px !important;
}

.max-h-110px {
  max-height: 110px !important;
}

.max-w-110px {
  max-width: 110px !important;
}

.w-115px {
  width: 115px !important;
}

.h-115px {
  height: 115px !important;
}

.min-w-115px {
  min-width: 115px !important;
}

.min-h-115px {
  min-height: 115px !important;
}

.max-h-115px {
  max-height: 115px !important;
}

.max-w-115px {
  max-width: 115px !important;
}

.w-120px {
  width: 120px !important;
}

.h-120px {
  height: 120px !important;
}

.min-w-120px {
  min-width: 120px !important;
}

.min-h-120px {
  min-height: 120px !important;
}

.max-h-120px {
  max-height: 120px !important;
}

.max-w-120px {
  max-width: 120px !important;
}

.w-125px {
  width: 125px !important;
}

.h-125px {
  height: 125px !important;
}

.min-w-125px {
  min-width: 125px !important;
}

.min-h-125px {
  min-height: 125px !important;
}

.max-h-125px {
  max-height: 125px !important;
}

.max-w-125px {
  max-width: 125px !important;
}

.w-130px {
  width: 130px !important;
}

.h-130px {
  height: 130px !important;
}

.min-w-130px {
  min-width: 130px !important;
}

.min-h-130px {
  min-height: 130px !important;
}

.max-h-130px {
  max-height: 130px !important;
}

.max-w-130px {
  max-width: 130px !important;
}

.w-135px {
  width: 135px !important;
}

.h-135px {
  height: 135px !important;
}

.min-w-135px {
  min-width: 135px !important;
}

.min-h-135px {
  min-height: 135px !important;
}

.max-h-135px {
  max-height: 135px !important;
}

.max-w-135px {
  max-width: 135px !important;
}

.w-140px {
  width: 140px !important;
}

.h-140px {
  height: 140px !important;
}

.min-w-140px {
  min-width: 140px !important;
}

.min-h-140px {
  min-height: 140px !important;
}

.max-h-140px {
  max-height: 140px !important;
}

.max-w-140px {
  max-width: 140px !important;
}

.w-145px {
  width: 145px !important;
}

.h-145px {
  height: 145px !important;
}

.min-w-145px {
  min-width: 145px !important;
}

.min-h-145px {
  min-height: 145px !important;
}

.max-h-145px {
  max-height: 145px !important;
}

.max-w-145px {
  max-width: 145px !important;
}

.w-150px {
  width: 150px !important;
}

.h-150px {
  height: 150px !important;
}

.min-w-150px {
  min-width: 150px !important;
}

.min-h-150px {
  min-height: 150px !important;
}

.max-h-150px {
  max-height: 150px !important;
}

.max-w-150px {
  max-width: 150px !important;
}

.w-175px {
  width: 175px !important;
}

.h-175px {
  height: 175px !important;
}

.min-w-175px {
  min-width: 175px !important;
}

.min-h-175px {
  min-height: 175px !important;
}

.max-h-175px {
  max-height: 175px !important;
}

.max-w-175px {
  max-width: 175px !important;
}

.w-180px {
  width: 180px !important;
}

.h-180px {
  height: 180px !important;
}

.min-w-180px {
  min-width: 180px !important;
}

.min-h-180px {
  min-height: 180px !important;
}

.max-h-180px {
  max-height: 180px !important;
}

.max-w-180px {
  max-width: 180px !important;
}

.w-50px {
  width: 50px !important;
}

.h-50px {
  height: 50px !important;
}

.w-200px {
  width: 200px !important;
}

.h-200px {
  height: 200px !important;
}

.min-w-190px {
  min-width: 190px !important;
}

.min-w-200px {
  min-width: 200px !important;
}

.min-h-200px {
  min-height: 200px !important;
}

.max-h-200px {
  max-height: 200px !important;
}

.max-w-200px {
  max-width: 200px !important;
}

.w-225px {
  width: 225px !important;
}

.h-225px {
  height: 225px !important;
}

.min-w-225px {
  min-width: 225px !important;
}

.min-w-220px {
  min-width: 220px !important;
}

.min-h-225px {
  min-height: 225px !important;
}

.max-h-225px {
  max-height: 225px !important;
}

.max-w-225px {
  max-width: 225px !important;
}

.w-250px {
  width: 250px !important;
}

.h-250px {
  height: 250px !important;
}

.min-w-250px {
  min-width: 250px !important;
}

.min-h-250px {
  min-height: 250px !important;
}

.max-h-250px {
  max-height: 250px !important;
}

.max-w-250px {
  max-width: 250px !important;
}

.w-255px {
  width: 255px !important;
}

.h-255px {
  height: 255px !important;
}

.min-w-255px {
  min-width: 255px !important;
}

.min-h-255px {
  min-height: 255px !important;
}

.max-h-255px {
  max-height: 255px !important;
}

.max-w-255px {
  max-width: 255px !important;
}

.w-260px {
  width: 260px !important;
}

.h-260px {
  height: 260px !important;
}

.min-w-260px {
  min-width: 260px !important;
}

.min-w-290px {
  min-width: 290px !important;
}

.min-h-260px {
  min-height: 260px !important;
}

.max-h-260px {
  max-height: 260px !important;
}

.max-w-260px {
  max-width: 260px !important;
}

.w-265px {
  width: 265px !important;
}

.h-265px {
  height: 265px !important;
}

.min-w-265px {
  min-width: 265px !important;
}

.min-h-265px {
  min-height: 265px !important;
}

.max-h-265px {
  max-height: 265px !important;
}

.max-w-265px {
  max-width: 265px !important;
}

.w-275px {
  width: 275px !important;
}

.h-275px {
  height: 275px !important;
}

.min-w-275px {
  min-width: 275px !important;
}

.min-h-275px {
  min-height: 275px !important;
}

.max-h-275px {
  max-height: 275px !important;
}

.max-w-275px {
  max-width: 275px !important;
}

.w-295px {
  width: 295px !important;
}

.h-295px {
  height: 295px !important;
}

.min-w-295px {
  min-width: 295px !important;
}

.min-h-295px {
  min-height: 295px !important;
}

.max-h-295px {
  max-height: 295px !important;
}

.max-w-295px {
  max-width: 295px !important;
}

.w-300px {
  width: 300px !important;
}

.h-300px {
  height: 300px !important;
}

.min-w-300px {
  min-width: 300px !important;
}

.min-h-300px {
  min-height: 300px !important;
}

.max-h-300px {
  max-height: 300px !important;
}

.max-w-300px {
  max-width: 300px !important;
}

.w-325px {
  width: 325px !important;
}

.h-325px {
  height: 325px !important;
}

.min-w-325px {
  min-width: 325px !important;
}

.min-h-325px {
  min-height: 325px !important;
}

.max-h-325px {
  max-height: 325px !important;
}

.max-w-325px {
  max-width: 325px !important;
}

.w-350px {
  width: 350px !important;
}

.h-350px {
  height: 350px !important;
}

.min-w-350px {
  min-width: 350px !important;
}

.min-h-350px {
  min-height: 350px !important;
}

.max-h-350px {
  max-height: 350px !important;
}

.max-w-350px {
  max-width: 350px !important;
}

.w-375px {
  width: 375px !important;
}

.h-375px {
  height: 375px !important;
}

.min-w-375px {
  min-width: 375px !important;
}

.min-h-375px {
  min-height: 375px !important;
}

.max-h-375px {
  max-height: 375px !important;
}

.max-w-375px {
  max-width: 375px !important;
}

.w-400px {
  width: 400px !important;
}

.h-400px {
  height: 400px !important;
}

.min-w-400px {
  min-width: 400px !important;
}

.min-h-400px {
  min-height: 400px !important;
}

.max-h-400px {
  max-height: 400px !important;
}

.max-w-400px {
  max-width: 400px !important;
}

.w-425px {
  width: 425px !important;
}

.h-425px {
  height: 425px !important;
}

.min-w-425px {
  min-width: 425px !important;
}

.min-h-425px {
  min-height: 425px !important;
}

.max-h-425px {
  max-height: 425px !important;
}

.max-w-425px {
  max-width: 425px !important;
}

.w-450px {
  width: 450px !important;
}

.h-450px {
  height: 450px !important;
}

.min-w-450px {
  min-width: 450px !important;
}

.min-h-450px {
  min-height: 450px !important;
}

.max-h-450px {
  max-height: 450px !important;
}

.max-w-450px {
  max-width: 450px !important;
}

.w-475px {
  width: 475px !important;
}

.h-475px {
  height: 475px !important;
}

.min-w-475px {
  min-width: 475px !important;
}

.min-h-475px {
  min-height: 475px !important;
}

.max-h-475px {
  max-height: 475px !important;
}

.max-w-475px {
  max-width: 475px !important;
}

.w-500px {
  width: 500px !important;
}

.h-500px {
  height: 500px !important;
}

.min-w-500px {
  min-width: 500px !important;
}

.min-h-500px {
  min-height: 500px !important;
}

.max-h-500px {
  max-height: 500px !important;
}

.max-w-500px {
  max-width: 500px !important;
}

.w-550px {
  width: 550px !important;
}

.h-550px {
  height: 550px !important;
}

.min-w-550px {
  min-width: 550px !important;
}

.min-h-550px {
  min-height: 550px !important;
}

.max-h-550px {
  max-height: 550px !important;
}

.max-w-550px {
  max-width: 550px !important;
}

.w-600px {
  width: 600px !important;
}

.h-600px {
  height: 600px !important;
}

.min-w-600px {
  min-width: 600px !important;
}

.min-h-600px {
  min-height: 600px !important;
}

.max-h-600px {
  max-height: 600px !important;
}

.max-w-600px {
  max-width: 600px !important;
}

.w-650px {
  width: 650px !important;
}

.h-650px {
  height: 650px !important;
}

.min-w-650px {
  min-width: 650px !important;
}

.min-h-650px {
  min-height: 650px !important;
}

.max-h-650px {
  max-height: 650px !important;
}

.max-w-650px {
  max-width: 650px !important;
}

.w-700px {
  width: 700px !important;
}

.h-700px {
  height: 700px !important;
}

.min-w-700px {
  min-width: 700px !important;
}

.min-h-700px {
  min-height: 700px !important;
}

.max-h-700px {
  max-height: 700px !important;
}

.max-w-700px {
  max-width: 700px !important;
}

.w-750px {
  width: 750px !important;
}

.h-750px {
  height: 750px !important;
}

.min-w-750px {
  min-width: 750px !important;
}

.min-h-750px {
  min-height: 750px !important;
}

.max-h-750px {
  max-height: 750px !important;
}

.max-w-750px {
  max-width: 750px !important;
}

.w-800px {
  width: 800px !important;
}

.h-800px {
  height: 800px !important;
}

.min-w-800px {
  min-width: 800px !important;
}

.min-h-800px {
  min-height: 800px !important;
}

.max-h-800px {
  max-height: 800px !important;
}

.max-w-800px {
  max-width: 800px !important;
}

.w-850px {
  width: 850px !important;
}

.h-850px {
  height: 850px !important;
}

.min-w-850px {
  min-width: 850px !important;
}

.min-h-850px {
  min-height: 850px !important;
}

.max-h-850px {
  max-height: 850px !important;
}

.max-w-850px {
  max-width: 850px !important;
}

.w-900px {
  width: 900px !important;
}

.h-900px {
  height: 900px !important;
}

.min-w-900px {
  min-width: 900px !important;
}

.min-h-900px {
  min-height: 900px !important;
}

.max-h-900px {
  max-height: 900px !important;
}

.max-w-900px {
  max-width: 900px !important;
}

.w-950px {
  width: 950px !important;
}

.h-950px {
  height: 950px !important;
}

.min-w-950px {
  min-width: 950px !important;
}

.min-h-950px {
  min-height: 950px !important;
}

.max-h-950px {
  max-height: 950px !important;
}

.max-w-950px {
  max-width: 950px !important;
}

.w-1000px {
  width: 1000px !important;
}

.h-1000px {
  height: 1000px !important;
}

.min-w-1000px {
  min-width: 1000px !important;
}

.min-h-1000px {
  min-height: 1000px !important;
}

.max-h-1000px {
  max-height: 1000px !important;
}

.max-w-1000px {
  max-width: 1000px !important;
}

@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .min-w-sm-100 {
    min-width: 100% !important;
  }
  .min-h-sm-100 {
    min-height: 100% !important;
  }
  .max-h-sm-100 {
    max-height: 100% !important;
  }
  .max-w-sm-100 {
    max-width: 100% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .min-w-sm-50 {
    min-width: 50% !important;
  }
  .min-h-sm-50 {
    min-height: 50% !important;
  }
  .max-h-sm-50 {
    max-height: 50% !important;
  }
  .max-w-sm-50 {
    max-width: 50% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .min-w-sm-auto {
    min-width: auto !important;
  }
  .min-h-sm-auto {
    min-height: auto !important;
  }
  .max-h-sm-auto {
    max-height: auto !important;
  }
  .max-w-sm-auto {
    max-width: auto !important;
  }
  .w-sm-1px {
    width: 1px !important;
  }
  .h-sm-1px {
    height: 1px !important;
  }
  .min-w-sm-1px {
    min-width: 1px !important;
  }
  .min-h-sm-1px {
    min-height: 1px !important;
  }
  .max-h-sm-1px {
    max-height: 1px !important;
  }
  .max-w-sm-1px {
    max-width: 1px !important;
  }
  .w-sm-2px {
    width: 2px !important;
  }
  .h-sm-2px {
    height: 2px !important;
  }
  .min-w-sm-2px {
    min-width: 2px !important;
  }
  .min-h-sm-2px {
    min-height: 2px !important;
  }
  .max-h-sm-2px {
    max-height: 2px !important;
  }
  .max-w-sm-2px {
    max-width: 2px !important;
  }
  .w-sm-3px {
    width: 3px !important;
  }
  .h-sm-3px {
    height: 3px !important;
  }
  .min-w-sm-3px {
    min-width: 3px !important;
  }
  .min-h-sm-3px {
    min-height: 3px !important;
  }
  .max-h-sm-3px {
    max-height: 3px !important;
  }
  .max-w-sm-3px {
    max-width: 3px !important;
  }
  .w-sm-4px {
    width: 4px !important;
  }
  .h-sm-4px {
    height: 4px !important;
  }
  .min-w-sm-4px {
    min-width: 4px !important;
  }
  .min-h-sm-4px {
    min-height: 4px !important;
  }
  .max-h-sm-4px {
    max-height: 4px !important;
  }
  .max-w-sm-4px {
    max-width: 4px !important;
  }
  .w-sm-5px {
    width: 5px !important;
  }
  .h-sm-5px {
    height: 5px !important;
  }
  .min-w-sm-5px {
    min-width: 5px !important;
  }
  .min-h-sm-5px {
    min-height: 5px !important;
  }
  .max-h-sm-5px {
    max-height: 5px !important;
  }
  .max-w-sm-5px {
    max-width: 5px !important;
  }
  .w-sm-10px {
    width: 10px !important;
  }
  .h-sm-10px {
    height: 10px !important;
  }
  .min-w-sm-10px {
    min-width: 10px !important;
  }
  .min-h-sm-10px {
    min-height: 10px !important;
  }
  .max-h-sm-10px {
    max-height: 10px !important;
  }
  .max-w-sm-10px {
    max-width: 10px !important;
  }
  .w-sm-15px {
    width: 15px !important;
  }
  .h-sm-15px {
    height: 15px !important;
  }
  .min-w-sm-15px {
    min-width: 15px !important;
  }
  .min-h-sm-15px {
    min-height: 15px !important;
  }
  .max-h-sm-15px {
    max-height: 15px !important;
  }
  .max-w-sm-15px {
    max-width: 15px !important;
  }
  .w-sm-20px {
    width: 20px !important;
  }
  .h-sm-20px {
    height: 20px !important;
  }
  .min-w-sm-20px {
    min-width: 20px !important;
  }
  .min-h-sm-20px {
    min-height: 20px !important;
  }
  .max-h-sm-20px {
    max-height: 20px !important;
  }
  .max-w-sm-20px {
    max-width: 20px !important;
  }
  .w-sm-25px {
    width: 25px !important;
  }
  .h-sm-25px {
    height: 25px !important;
  }
  .min-w-sm-25px {
    min-width: 25px !important;
  }
  .min-h-sm-25px {
    min-height: 25px !important;
  }
  .max-h-sm-25px {
    max-height: 25px !important;
  }
  .max-w-sm-25px {
    max-width: 25px !important;
  }
  .w-sm-30px {
    width: 30px !important;
  }
  .h-sm-30px {
    height: 30px !important;
  }
  .min-w-sm-30px {
    min-width: 30px !important;
  }
  .min-h-sm-30px {
    min-height: 30px !important;
  }
  .max-h-sm-30px {
    max-height: 30px !important;
  }
  .max-w-sm-30px {
    max-width: 30px !important;
  }
  .w-sm-35px {
    width: 35px !important;
  }
  .h-sm-35px {
    height: 35px !important;
  }
  .min-w-sm-35px {
    min-width: 35px !important;
  }
  .min-h-sm-35px {
    min-height: 35px !important;
  }
  .max-h-sm-35px {
    max-height: 35px !important;
  }
  .max-w-sm-35px {
    max-width: 35px !important;
  }
  .w-sm-40px {
    width: 40px !important;
  }
  .h-sm-40px {
    height: 40px !important;
  }
  .min-w-sm-40px {
    min-width: 40px !important;
  }
  .min-h-sm-40px {
    min-height: 40px !important;
  }
  .max-h-sm-40px {
    max-height: 40px !important;
  }
  .max-w-sm-40px {
    max-width: 40px !important;
  }
  .w-sm-45px {
    width: 45px !important;
  }
  .h-sm-45px {
    height: 45px !important;
  }
  .min-w-sm-45px {
    min-width: 45px !important;
  }
  .min-h-sm-45px {
    min-height: 45px !important;
  }
  .max-h-sm-45px {
    max-height: 45px !important;
  }
  .max-w-sm-45px {
    max-width: 45px !important;
  }
  .w-sm-50px {
    width: 50px !important;
  }
  .h-sm-50px {
    height: 50px !important;
  }
  .min-w-sm-50px {
    min-width: 50px !important;
  }
  .min-h-sm-50px {
    min-height: 50px !important;
  }
  .max-h-sm-50px {
    max-height: 50px !important;
  }
  .max-w-sm-50px {
    max-width: 50px !important;
  }
  .w-sm-55px {
    width: 55px !important;
  }
  .h-sm-55px {
    height: 55px !important;
  }
  .min-w-sm-55px {
    min-width: 55px !important;
  }
  .min-h-sm-55px {
    min-height: 55px !important;
  }
  .max-h-sm-55px {
    max-height: 55px !important;
  }
  .max-w-sm-55px {
    max-width: 55px !important;
  }
  .w-sm-60px {
    width: 60px !important;
  }
  .h-sm-60px {
    height: 60px !important;
  }
  .min-w-sm-60px {
    min-width: 60px !important;
  }
  .min-h-sm-60px {
    min-height: 60px !important;
  }
  .max-h-sm-60px {
    max-height: 60px !important;
  }
  .max-w-sm-60px {
    max-width: 60px !important;
  }
  .w-sm-65px {
    width: 65px !important;
  }
  .h-sm-65px {
    height: 65px !important;
  }
  .min-w-sm-65px {
    min-width: 65px !important;
  }
  .min-h-sm-65px {
    min-height: 65px !important;
  }
  .max-h-sm-65px {
    max-height: 65px !important;
  }
  .max-w-sm-65px {
    max-width: 65px !important;
  }
  .w-sm-70px {
    width: 70px !important;
  }
  .h-sm-70px {
    height: 70px !important;
  }
  .min-w-sm-70px {
    min-width: 70px !important;
  }
  .min-h-sm-70px {
    min-height: 70px !important;
  }
  .max-h-sm-70px {
    max-height: 70px !important;
  }
  .max-w-sm-70px {
    max-width: 70px !important;
  }
  .w-sm-75px {
    width: 75px !important;
  }
  .h-sm-75px {
    height: 75px !important;
  }
  .min-w-sm-75px {
    min-width: 75px !important;
  }
  .min-h-sm-75px {
    min-height: 75px !important;
  }
  .max-h-sm-75px {
    max-height: 75px !important;
  }
  .max-w-sm-75px {
    max-width: 75px !important;
  }
  .w-sm-80px {
    width: 80px !important;
  }
  .h-sm-80px {
    height: 80px !important;
  }
  .min-w-sm-80px {
    min-width: 80px !important;
  }
  .min-h-sm-80px {
    min-height: 80px !important;
  }
  .max-h-sm-80px {
    max-height: 80px !important;
  }
  .max-w-sm-80px {
    max-width: 80px !important;
  }
  .w-sm-85px {
    width: 85px !important;
  }
  .h-sm-85px {
    height: 85px !important;
  }
  .min-w-sm-85px {
    min-width: 85px !important;
  }
  .min-h-sm-85px {
    min-height: 85px !important;
  }
  .max-h-sm-85px {
    max-height: 85px !important;
  }
  .max-w-sm-85px {
    max-width: 85px !important;
  }
  .w-sm-90px {
    width: 90px !important;
  }
  .h-sm-90px {
    height: 90px !important;
  }
  .min-w-sm-90px {
    min-width: 90px !important;
  }
  .min-h-sm-90px {
    min-height: 90px !important;
  }
  .max-h-sm-90px {
    max-height: 90px !important;
  }
  .max-w-sm-90px {
    max-width: 90px !important;
  }
  .w-sm-95px {
    width: 95px !important;
  }
  .h-sm-95px {
    height: 95px !important;
  }
  .min-w-sm-95px {
    min-width: 95px !important;
  }
  .min-h-sm-95px {
    min-height: 95px !important;
  }
  .max-h-sm-95px {
    max-height: 95px !important;
  }
  .max-w-sm-95px {
    max-width: 95px !important;
  }
  .w-sm-100px {
    width: 100px !important;
  }
  .h-sm-100px {
    height: 100px !important;
  }
  .min-w-sm-100px {
    min-width: 100px !important;
  }
  .min-h-sm-100px {
    min-height: 100px !important;
  }
  .max-h-sm-100px {
    max-height: 100px !important;
  }
  .max-w-sm-100px {
    max-width: 100px !important;
  }
  .w-sm-105px {
    width: 105px !important;
  }
  .h-sm-105px {
    height: 105px !important;
  }
  .min-w-sm-105px {
    min-width: 105px !important;
  }
  .min-h-sm-105px {
    min-height: 105px !important;
  }
  .max-h-sm-105px {
    max-height: 105px !important;
  }
  .max-w-sm-105px {
    max-width: 105px !important;
  }
  .w-sm-110px {
    width: 110px !important;
  }
  .h-sm-110px {
    height: 110px !important;
  }
  .min-w-sm-110px {
    min-width: 110px !important;
  }
  .min-h-sm-110px {
    min-height: 110px !important;
  }
  .max-h-sm-110px {
    max-height: 110px !important;
  }
  .max-w-sm-110px {
    max-width: 110px !important;
  }
  .w-sm-115px {
    width: 115px !important;
  }
  .h-sm-115px {
    height: 115px !important;
  }
  .min-w-sm-115px {
    min-width: 115px !important;
  }
  .min-h-sm-115px {
    min-height: 115px !important;
  }
  .max-h-sm-115px {
    max-height: 115px !important;
  }
  .max-w-sm-115px {
    max-width: 115px !important;
  }
  .w-sm-120px {
    width: 120px !important;
  }
  .h-sm-120px {
    height: 120px !important;
  }
  .min-w-sm-120px {
    min-width: 120px !important;
  }
  .min-h-sm-120px {
    min-height: 120px !important;
  }
  .max-h-sm-120px {
    max-height: 120px !important;
  }
  .max-w-sm-120px {
    max-width: 120px !important;
  }
  .w-sm-125px {
    width: 125px !important;
  }
  .h-sm-125px {
    height: 125px !important;
  }
  .min-w-sm-125px {
    min-width: 125px !important;
  }
  .min-h-sm-125px {
    min-height: 125px !important;
  }
  .max-h-sm-125px {
    max-height: 125px !important;
  }
  .max-w-sm-125px {
    max-width: 125px !important;
  }
  .w-sm-130px {
    width: 130px !important;
  }
  .h-sm-130px {
    height: 130px !important;
  }
  .min-w-sm-130px {
    min-width: 130px !important;
  }
  .min-h-sm-130px {
    min-height: 130px !important;
  }
  .max-h-sm-130px {
    max-height: 130px !important;
  }
  .max-w-sm-130px {
    max-width: 130px !important;
  }
  .w-sm-135px {
    width: 135px !important;
  }
  .h-sm-135px {
    height: 135px !important;
  }
  .min-w-sm-135px {
    min-width: 135px !important;
  }
  .min-h-sm-135px {
    min-height: 135px !important;
  }
  .max-h-sm-135px {
    max-height: 135px !important;
  }
  .max-w-sm-135px {
    max-width: 135px !important;
  }
  .w-sm-140px {
    width: 140px !important;
  }
  .h-sm-140px {
    height: 140px !important;
  }
  .min-w-sm-140px {
    min-width: 140px !important;
  }
  .min-h-sm-140px {
    min-height: 140px !important;
  }
  .max-h-sm-140px {
    max-height: 140px !important;
  }
  .max-w-sm-140px {
    max-width: 140px !important;
  }
  .w-sm-145px {
    width: 145px !important;
  }
  .h-sm-145px {
    height: 145px !important;
  }
  .min-w-sm-145px {
    min-width: 145px !important;
  }
  .min-h-sm-145px {
    min-height: 145px !important;
  }
  .max-h-sm-145px {
    max-height: 145px !important;
  }
  .max-w-sm-145px {
    max-width: 145px !important;
  }
  .w-sm-150px {
    width: 150px !important;
  }
  .h-sm-150px {
    height: 150px !important;
  }
  .min-w-sm-150px {
    min-width: 150px !important;
  }
  .min-h-sm-150px {
    min-height: 150px !important;
  }
  .max-h-sm-150px {
    max-height: 150px !important;
  }
  .max-w-sm-150px {
    max-width: 150px !important;
  }
  .w-sm-175px {
    width: 175px !important;
  }
  .h-sm-175px {
    height: 175px !important;
  }
  .min-w-sm-175px {
    min-width: 175px !important;
  }
  .min-h-sm-175px {
    min-height: 175px !important;
  }
  .max-h-sm-175px {
    max-height: 175px !important;
  }
  .max-w-sm-175px {
    max-width: 175px !important;
  }
  .w-sm-180px {
    width: 180px !important;
  }
  .h-sm-180px {
    height: 180px !important;
  }
  .min-w-sm-180px {
    min-width: 180px !important;
  }
  .min-h-sm-180px {
    min-height: 180px !important;
  }
  .max-h-sm-180px {
    max-height: 180px !important;
  }
  .max-w-sm-180px {
    max-width: 180px !important;
  }
  .w-sm-200px {
    width: 200px !important;
  }
  .h-sm-200px {
    height: 200px !important;
  }
  .min-w-sm-200px {
    min-width: 200px !important;
  }
  .min-h-sm-200px {
    min-height: 200px !important;
  }
  .max-h-sm-200px {
    max-height: 200px !important;
  }
  .max-w-sm-200px {
    max-width: 200px !important;
  }
  .w-sm-225px {
    width: 225px !important;
  }
  .h-sm-225px {
    height: 225px !important;
  }
  .min-w-sm-225px {
    min-width: 225px !important;
  }
  .min-h-sm-225px {
    min-height: 225px !important;
  }
  .max-h-sm-225px {
    max-height: 225px !important;
  }
  .max-w-sm-225px {
    max-width: 225px !important;
  }
  .w-sm-250px {
    width: 250px !important;
  }
  .h-sm-250px {
    height: 250px !important;
  }
  .min-w-sm-250px {
    min-width: 250px !important;
  }
  .min-h-sm-250px {
    min-height: 250px !important;
  }
  .max-h-sm-250px {
    max-height: 250px !important;
  }
  .max-w-sm-250px {
    max-width: 250px !important;
  }
  .w-sm-255px {
    width: 255px !important;
  }
  .h-sm-255px {
    height: 255px !important;
  }
  .min-w-sm-255px {
    min-width: 255px !important;
  }
  .min-h-sm-255px {
    min-height: 255px !important;
  }
  .max-h-sm-255px {
    max-height: 255px !important;
  }
  .max-w-sm-255px {
    max-width: 255px !important;
  }
  .w-sm-260px {
    width: 260px !important;
  }
  .h-sm-260px {
    height: 260px !important;
  }
  .min-w-sm-260px {
    min-width: 260px !important;
  }
  .min-h-sm-260px {
    min-height: 260px !important;
  }
  .max-h-sm-260px {
    max-height: 260px !important;
  }
  .max-w-sm-260px {
    max-width: 260px !important;
  }
  .w-sm-265px {
    width: 265px !important;
  }
  .h-sm-265px {
    height: 265px !important;
  }
  .min-w-sm-265px {
    min-width: 265px !important;
  }
  .min-h-sm-265px {
    min-height: 265px !important;
  }
  .max-h-sm-265px {
    max-height: 265px !important;
  }
  .max-w-sm-265px {
    max-width: 265px !important;
  }
  .w-sm-275px {
    width: 275px !important;
  }
  .h-sm-275px {
    height: 275px !important;
  }
  .min-w-sm-275px {
    min-width: 275px !important;
  }
  .min-h-sm-275px {
    min-height: 275px !important;
  }
  .max-h-sm-275px {
    max-height: 275px !important;
  }
  .max-w-sm-275px {
    max-width: 275px !important;
  }
  .w-sm-295px {
    width: 295px !important;
  }
  .h-sm-295px {
    height: 295px !important;
  }
  .min-w-sm-295px {
    min-width: 295px !important;
  }
  .min-h-sm-295px {
    min-height: 295px !important;
  }
  .max-h-sm-295px {
    max-height: 295px !important;
  }
  .max-w-sm-295px {
    max-width: 295px !important;
  }
  .w-sm-300px {
    width: 300px !important;
  }
  .h-sm-300px {
    height: 300px !important;
  }
  .min-w-sm-300px {
    min-width: 300px !important;
  }
  .min-h-sm-300px {
    min-height: 300px !important;
  }
  .max-h-sm-300px {
    max-height: 300px !important;
  }
  .max-w-sm-300px {
    max-width: 300px !important;
  }
  .w-sm-325px {
    width: 325px !important;
  }
  .h-sm-325px {
    height: 325px !important;
  }
  .min-w-sm-325px {
    min-width: 325px !important;
  }
  .min-h-sm-325px {
    min-height: 325px !important;
  }
  .max-h-sm-325px {
    max-height: 325px !important;
  }
  .max-w-sm-325px {
    max-width: 325px !important;
  }
  .w-sm-350px {
    width: 350px !important;
  }
  .h-sm-350px {
    height: 350px !important;
  }
  .min-w-sm-350px {
    min-width: 350px !important;
  }
  .min-h-sm-350px {
    min-height: 350px !important;
  }
  .max-h-sm-350px {
    max-height: 350px !important;
  }
  .max-w-sm-350px {
    max-width: 350px !important;
  }
  .w-sm-375px {
    width: 375px !important;
  }
  .h-sm-375px {
    height: 375px !important;
  }
  .min-w-sm-375px {
    min-width: 375px !important;
  }
  .min-h-sm-375px {
    min-height: 375px !important;
  }
  .max-h-sm-375px {
    max-height: 375px !important;
  }
  .max-w-sm-375px {
    max-width: 375px !important;
  }
  .w-sm-400px {
    width: 400px !important;
  }
  .h-sm-400px {
    height: 400px !important;
  }
  .min-w-sm-400px {
    min-width: 400px !important;
  }
  .min-h-sm-400px {
    min-height: 400px !important;
  }
  .max-h-sm-400px {
    max-height: 400px !important;
  }
  .max-w-sm-400px {
    max-width: 400px !important;
  }
  .w-sm-425px {
    width: 425px !important;
  }
  .h-sm-425px {
    height: 425px !important;
  }
  .min-w-sm-425px {
    min-width: 425px !important;
  }
  .min-h-sm-425px {
    min-height: 425px !important;
  }
  .max-h-sm-425px {
    max-height: 425px !important;
  }
  .max-w-sm-425px {
    max-width: 425px !important;
  }
  .w-sm-450px {
    width: 450px !important;
  }
  .h-sm-450px {
    height: 450px !important;
  }
  .min-w-sm-450px {
    min-width: 450px !important;
  }
  .min-h-sm-450px {
    min-height: 450px !important;
  }
  .max-h-sm-450px {
    max-height: 450px !important;
  }
  .max-w-sm-450px {
    max-width: 450px !important;
  }
  .w-sm-475px {
    width: 475px !important;
  }
  .h-sm-475px {
    height: 475px !important;
  }
  .min-w-sm-475px {
    min-width: 475px !important;
  }
  .min-h-sm-475px {
    min-height: 475px !important;
  }
  .max-h-sm-475px {
    max-height: 475px !important;
  }
  .max-w-sm-475px {
    max-width: 475px !important;
  }
  .w-sm-500px {
    width: 500px !important;
  }
  .h-sm-500px {
    height: 500px !important;
  }
  .min-w-sm-500px {
    min-width: 500px !important;
  }
  .min-h-sm-500px {
    min-height: 500px !important;
  }
  .max-h-sm-500px {
    max-height: 500px !important;
  }
  .max-w-sm-500px {
    max-width: 500px !important;
  }
  .w-sm-550px {
    width: 550px !important;
  }
  .h-sm-550px {
    height: 550px !important;
  }
  .min-w-sm-550px {
    min-width: 550px !important;
  }
  .min-h-sm-550px {
    min-height: 550px !important;
  }
  .max-h-sm-550px {
    max-height: 550px !important;
  }
  .max-w-sm-550px {
    max-width: 550px !important;
  }
  .w-sm-600px {
    width: 600px !important;
  }
  .h-sm-600px {
    height: 600px !important;
  }
  .min-w-sm-600px {
    min-width: 600px !important;
  }
  .min-h-sm-600px {
    min-height: 600px !important;
  }
  .max-h-sm-600px {
    max-height: 600px !important;
  }
  .max-w-sm-600px {
    max-width: 600px !important;
  }
  .w-sm-650px {
    width: 650px !important;
  }
  .h-sm-650px {
    height: 650px !important;
  }
  .min-w-sm-650px {
    min-width: 650px !important;
  }
  .min-h-sm-650px {
    min-height: 650px !important;
  }
  .max-h-sm-650px {
    max-height: 650px !important;
  }
  .max-w-sm-650px {
    max-width: 650px !important;
  }
  .w-sm-700px {
    width: 700px !important;
  }
  .h-sm-700px {
    height: 700px !important;
  }
  .min-w-sm-700px {
    min-width: 700px !important;
  }
  .min-h-sm-700px {
    min-height: 700px !important;
  }
  .max-h-sm-700px {
    max-height: 700px !important;
  }
  .max-w-sm-700px {
    max-width: 700px !important;
  }
  .w-sm-750px {
    width: 750px !important;
  }
  .h-sm-750px {
    height: 750px !important;
  }
  .min-w-sm-750px {
    min-width: 750px !important;
  }
  .min-h-sm-750px {
    min-height: 750px !important;
  }
  .max-h-sm-750px {
    max-height: 750px !important;
  }
  .max-w-sm-750px {
    max-width: 750px !important;
  }
  .w-sm-800px {
    width: 800px !important;
  }
  .h-sm-800px {
    height: 800px !important;
  }
  .min-w-sm-800px {
    min-width: 800px !important;
  }
  .min-h-sm-800px {
    min-height: 800px !important;
  }
  .max-h-sm-800px {
    max-height: 800px !important;
  }
  .max-w-sm-800px {
    max-width: 800px !important;
  }
  .w-sm-850px {
    width: 850px !important;
  }
  .h-sm-850px {
    height: 850px !important;
  }
  .min-w-sm-850px {
    min-width: 850px !important;
  }
  .min-h-sm-850px {
    min-height: 850px !important;
  }
  .max-h-sm-850px {
    max-height: 850px !important;
  }
  .max-w-sm-850px {
    max-width: 850px !important;
  }
  .w-sm-900px {
    width: 900px !important;
  }
  .h-sm-900px {
    height: 900px !important;
  }
  .min-w-sm-900px {
    min-width: 900px !important;
  }
  .min-h-sm-900px {
    min-height: 900px !important;
  }
  .max-h-sm-900px {
    max-height: 900px !important;
  }
  .max-w-sm-900px {
    max-width: 900px !important;
  }
  .w-sm-950px {
    width: 950px !important;
  }
  .h-sm-950px {
    height: 950px !important;
  }
  .min-w-sm-950px {
    min-width: 950px !important;
  }
  .min-h-sm-950px {
    min-height: 950px !important;
  }
  .max-h-sm-950px {
    max-height: 950px !important;
  }
  .max-w-sm-950px {
    max-width: 950px !important;
  }
  .w-sm-1000px {
    width: 1000px !important;
  }
  .h-sm-1000px {
    height: 1000px !important;
  }
  .min-w-sm-1000px {
    min-width: 1000px !important;
  }
  .min-h-sm-1000px {
    min-height: 1000px !important;
  }
  .max-h-sm-1000px {
    max-height: 1000px !important;
  }
  .max-w-sm-1000px {
    max-width: 1000px !important;
  }
}

@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .min-w-md-100 {
    min-width: 100% !important;
  }
  .min-h-md-100 {
    min-height: 100% !important;
  }
  .max-h-md-100 {
    max-height: 100% !important;
  }
  .max-w-md-100 {
    max-width: 100% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .min-w-md-50 {
    min-width: 50% !important;
  }
  .min-h-md-50 {
    min-height: 50% !important;
  }
  .max-h-md-50 {
    max-height: 50% !important;
  }
  .max-w-md-50 {
    max-width: 50% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .min-w-md-auto {
    min-width: auto !important;
  }
  .min-h-md-auto {
    min-height: auto !important;
  }
  .max-h-md-auto {
    max-height: auto !important;
  }
  .max-w-md-auto {
    max-width: auto !important;
  }
  .w-md-1px {
    width: 1px !important;
  }
  .h-md-1px {
    height: 1px !important;
  }
  .min-w-md-1px {
    min-width: 1px !important;
  }
  .min-h-md-1px {
    min-height: 1px !important;
  }
  .max-h-md-1px {
    max-height: 1px !important;
  }
  .max-w-md-1px {
    max-width: 1px !important;
  }
  .w-md-2px {
    width: 2px !important;
  }
  .h-md-2px {
    height: 2px !important;
  }
  .min-w-md-2px {
    min-width: 2px !important;
  }
  .min-h-md-2px {
    min-height: 2px !important;
  }
  .max-h-md-2px {
    max-height: 2px !important;
  }
  .max-w-md-2px {
    max-width: 2px !important;
  }
  .w-md-3px {
    width: 3px !important;
  }
  .h-md-3px {
    height: 3px !important;
  }
  .min-w-md-3px {
    min-width: 3px !important;
  }
  .min-h-md-3px {
    min-height: 3px !important;
  }
  .max-h-md-3px {
    max-height: 3px !important;
  }
  .max-w-md-3px {
    max-width: 3px !important;
  }
  .w-md-4px {
    width: 4px !important;
  }
  .h-md-4px {
    height: 4px !important;
  }
  .min-w-md-4px {
    min-width: 4px !important;
  }
  .min-h-md-4px {
    min-height: 4px !important;
  }
  .max-h-md-4px {
    max-height: 4px !important;
  }
  .max-w-md-4px {
    max-width: 4px !important;
  }
  .w-md-5px {
    width: 5px !important;
  }
  .h-md-5px {
    height: 5px !important;
  }
  .min-w-md-5px {
    min-width: 5px !important;
  }
  .min-h-md-5px {
    min-height: 5px !important;
  }
  .max-h-md-5px {
    max-height: 5px !important;
  }
  .max-w-md-5px {
    max-width: 5px !important;
  }
  .w-md-10px {
    width: 10px !important;
  }
  .h-md-10px {
    height: 10px !important;
  }
  .min-w-md-10px {
    min-width: 10px !important;
  }
  .min-h-md-10px {
    min-height: 10px !important;
  }
  .max-h-md-10px {
    max-height: 10px !important;
  }
  .max-w-md-10px {
    max-width: 10px !important;
  }
  .w-md-15px {
    width: 15px !important;
  }
  .h-md-15px {
    height: 15px !important;
  }
  .min-w-md-15px {
    min-width: 15px !important;
  }
  .min-h-md-15px {
    min-height: 15px !important;
  }
  .max-h-md-15px {
    max-height: 15px !important;
  }
  .max-w-md-15px {
    max-width: 15px !important;
  }
  .w-md-20px {
    width: 20px !important;
  }
  .h-md-20px {
    height: 20px !important;
  }
  .min-w-md-20px {
    min-width: 20px !important;
  }
  .min-h-md-20px {
    min-height: 20px !important;
  }
  .max-h-md-20px {
    max-height: 20px !important;
  }
  .max-w-md-20px {
    max-width: 20px !important;
  }
  .w-md-25px {
    width: 25px !important;
  }
  .h-md-25px {
    height: 25px !important;
  }
  .min-w-md-25px {
    min-width: 25px !important;
  }
  .min-h-md-25px {
    min-height: 25px !important;
  }
  .max-h-md-25px {
    max-height: 25px !important;
  }
  .max-w-md-25px {
    max-width: 25px !important;
  }
  .w-md-30px {
    width: 30px !important;
  }
  .h-md-30px {
    height: 30px !important;
  }
  .min-w-md-30px {
    min-width: 30px !important;
  }
  .min-h-md-30px {
    min-height: 30px !important;
  }
  .max-h-md-30px {
    max-height: 30px !important;
  }
  .max-w-md-30px {
    max-width: 30px !important;
  }
  .w-md-35px {
    width: 35px !important;
  }
  .h-md-35px {
    height: 35px !important;
  }
  .min-w-md-35px {
    min-width: 35px !important;
  }
  .min-h-md-35px {
    min-height: 35px !important;
  }
  .max-h-md-35px {
    max-height: 35px !important;
  }
  .max-w-md-35px {
    max-width: 35px !important;
  }
  .w-md-40px {
    width: 40px !important;
  }
  .h-md-40px {
    height: 40px !important;
  }
  .min-w-md-40px {
    min-width: 40px !important;
  }
  .min-h-md-40px {
    min-height: 40px !important;
  }
  .max-h-md-40px {
    max-height: 40px !important;
  }
  .max-w-md-40px {
    max-width: 40px !important;
  }
  .w-md-45px {
    width: 45px !important;
  }
  .h-md-45px {
    height: 45px !important;
  }
  .min-w-md-45px {
    min-width: 45px !important;
  }
  .min-h-md-45px {
    min-height: 45px !important;
  }
  .max-h-md-45px {
    max-height: 45px !important;
  }
  .max-w-md-45px {
    max-width: 45px !important;
  }
  .w-md-50px {
    width: 50px !important;
  }
  .h-md-50px {
    height: 50px !important;
  }
  .min-w-md-50px {
    min-width: 50px !important;
  }
  .min-h-md-50px {
    min-height: 50px !important;
  }
  .max-h-md-50px {
    max-height: 50px !important;
  }
  .max-w-md-50px {
    max-width: 50px !important;
  }
  .w-md-55px {
    width: 55px !important;
  }
  .h-md-55px {
    height: 55px !important;
  }
  .min-w-md-55px {
    min-width: 55px !important;
  }
  .min-h-md-55px {
    min-height: 55px !important;
  }
  .max-h-md-55px {
    max-height: 55px !important;
  }
  .max-w-md-55px {
    max-width: 55px !important;
  }
  .w-md-60px {
    width: 60px !important;
  }
  .h-md-60px {
    height: 60px !important;
  }
  .min-w-md-60px {
    min-width: 60px !important;
  }
  .min-h-md-60px {
    min-height: 60px !important;
  }
  .max-h-md-60px {
    max-height: 60px !important;
  }
  .max-w-md-60px {
    max-width: 60px !important;
  }
  .w-md-65px {
    width: 65px !important;
  }
  .h-md-65px {
    height: 65px !important;
  }
  .min-w-md-65px {
    min-width: 65px !important;
  }
  .min-h-md-65px {
    min-height: 65px !important;
  }
  .max-h-md-65px {
    max-height: 65px !important;
  }
  .max-w-md-65px {
    max-width: 65px !important;
  }
  .w-md-70px {
    width: 70px !important;
  }
  .h-md-70px {
    height: 70px !important;
  }
  .min-w-md-70px {
    min-width: 70px !important;
  }
  .min-h-md-70px {
    min-height: 70px !important;
  }
  .max-h-md-70px {
    max-height: 70px !important;
  }
  .max-w-md-70px {
    max-width: 70px !important;
  }
  .w-md-75px {
    width: 75px !important;
  }
  .h-md-75px {
    height: 75px !important;
  }
  .min-w-md-75px {
    min-width: 75px !important;
  }
  .min-h-md-75px {
    min-height: 75px !important;
  }
  .max-h-md-75px {
    max-height: 75px !important;
  }
  .max-w-md-75px {
    max-width: 75px !important;
  }
  .w-md-80px {
    width: 80px !important;
  }
  .h-md-80px {
    height: 80px !important;
  }
  .min-w-md-80px {
    min-width: 80px !important;
  }
  .min-h-md-80px {
    min-height: 80px !important;
  }
  .max-h-md-80px {
    max-height: 80px !important;
  }
  .max-w-md-80px {
    max-width: 80px !important;
  }
  .w-md-85px {
    width: 85px !important;
  }
  .h-md-85px {
    height: 85px !important;
  }
  .min-w-md-85px {
    min-width: 85px !important;
  }
  .min-h-md-85px {
    min-height: 85px !important;
  }
  .max-h-md-85px {
    max-height: 85px !important;
  }
  .max-w-md-85px {
    max-width: 85px !important;
  }
  .w-md-90px {
    width: 90px !important;
  }
  .h-md-90px {
    height: 90px !important;
  }
  .min-w-md-90px {
    min-width: 90px !important;
  }
  .min-h-md-90px {
    min-height: 90px !important;
  }
  .max-h-md-90px {
    max-height: 90px !important;
  }
  .max-w-md-90px {
    max-width: 90px !important;
  }
  .w-md-95px {
    width: 95px !important;
  }
  .h-md-95px {
    height: 95px !important;
  }
  .min-w-md-95px {
    min-width: 95px !important;
  }
  .min-h-md-95px {
    min-height: 95px !important;
  }
  .max-h-md-95px {
    max-height: 95px !important;
  }
  .max-w-md-95px {
    max-width: 95px !important;
  }
  .w-md-100px {
    width: 100px !important;
  }
  .h-md-100px {
    height: 100px !important;
  }
  .min-w-md-100px {
    min-width: 100px !important;
  }
  .min-h-md-100px {
    min-height: 100px !important;
  }
  .max-h-md-100px {
    max-height: 100px !important;
  }
  .max-w-md-100px {
    max-width: 100px !important;
  }
  .w-md-105px {
    width: 105px !important;
  }
  .h-md-105px {
    height: 105px !important;
  }
  .min-w-md-105px {
    min-width: 105px !important;
  }
  .min-h-md-105px {
    min-height: 105px !important;
  }
  .max-h-md-105px {
    max-height: 105px !important;
  }
  .max-w-md-105px {
    max-width: 105px !important;
  }
  .w-md-110px {
    width: 110px !important;
  }
  .h-md-110px {
    height: 110px !important;
  }
  .min-w-md-110px {
    min-width: 110px !important;
  }
  .min-h-md-110px {
    min-height: 110px !important;
  }
  .max-h-md-110px {
    max-height: 110px !important;
  }
  .max-w-md-110px {
    max-width: 110px !important;
  }
  .w-md-115px {
    width: 115px !important;
  }
  .h-md-115px {
    height: 115px !important;
  }
  .min-w-md-115px {
    min-width: 115px !important;
  }
  .min-h-md-115px {
    min-height: 115px !important;
  }
  .max-h-md-115px {
    max-height: 115px !important;
  }
  .max-w-md-115px {
    max-width: 115px !important;
  }
  .w-md-120px {
    width: 120px !important;
  }
  .h-md-120px {
    height: 120px !important;
  }
  .min-w-md-120px {
    min-width: 120px !important;
  }
  .min-h-md-120px {
    min-height: 120px !important;
  }
  .max-h-md-120px {
    max-height: 120px !important;
  }
  .max-w-md-120px {
    max-width: 120px !important;
  }
  .w-md-125px {
    width: 125px !important;
  }
  .h-md-125px {
    height: 125px !important;
  }
  .min-w-md-125px {
    min-width: 125px !important;
  }
  .min-h-md-125px {
    min-height: 125px !important;
  }
  .max-h-md-125px {
    max-height: 125px !important;
  }
  .max-w-md-125px {
    max-width: 125px !important;
  }
  .w-md-130px {
    width: 130px !important;
  }
  .h-md-130px {
    height: 130px !important;
  }
  .min-w-md-130px {
    min-width: 130px !important;
  }
  .min-h-md-130px {
    min-height: 130px !important;
  }
  .max-h-md-130px {
    max-height: 130px !important;
  }
  .max-w-md-130px {
    max-width: 130px !important;
  }
  .w-md-135px {
    width: 135px !important;
  }
  .h-md-135px {
    height: 135px !important;
  }
  .min-w-md-135px {
    min-width: 135px !important;
  }
  .min-h-md-135px {
    min-height: 135px !important;
  }
  .max-h-md-135px {
    max-height: 135px !important;
  }
  .max-w-md-135px {
    max-width: 135px !important;
  }
  .w-md-140px {
    width: 140px !important;
  }
  .h-md-140px {
    height: 140px !important;
  }
  .min-w-md-140px {
    min-width: 140px !important;
  }
  .min-h-md-140px {
    min-height: 140px !important;
  }
  .max-h-md-140px {
    max-height: 140px !important;
  }
  .max-w-md-140px {
    max-width: 140px !important;
  }
  .w-md-145px {
    width: 145px !important;
  }
  .h-md-145px {
    height: 145px !important;
  }
  .min-w-md-145px {
    min-width: 145px !important;
  }
  .min-h-md-145px {
    min-height: 145px !important;
  }
  .max-h-md-145px {
    max-height: 145px !important;
  }
  .max-w-md-145px {
    max-width: 145px !important;
  }
  .w-md-150px {
    width: 150px !important;
  }
  .h-md-150px {
    height: 150px !important;
  }
  .min-w-md-150px {
    min-width: 150px !important;
  }
  .min-h-md-150px {
    min-height: 150px !important;
  }
  .max-h-md-150px {
    max-height: 150px !important;
  }
  .max-w-md-150px {
    max-width: 150px !important;
  }
  .w-md-175px {
    width: 175px !important;
  }
  .h-md-175px {
    height: 175px !important;
  }
  .min-w-md-175px {
    min-width: 175px !important;
  }
  .min-h-md-175px {
    min-height: 175px !important;
  }
  .max-h-md-175px {
    max-height: 175px !important;
  }
  .max-w-md-175px {
    max-width: 175px !important;
  }
  .w-md-180px {
    width: 180px !important;
  }
  .h-md-180px {
    height: 180px !important;
  }
  .min-w-md-180px {
    min-width: 180px !important;
  }
  .min-h-md-180px {
    min-height: 180px !important;
  }
  .max-h-md-180px {
    max-height: 180px !important;
  }
  .max-w-md-180px {
    max-width: 180px !important;
  }
  .w-md-200px {
    width: 200px !important;
  }
  .h-md-200px {
    height: 200px !important;
  }
  .min-w-md-200px {
    min-width: 200px !important;
  }
  .min-h-md-200px {
    min-height: 200px !important;
  }
  .max-h-md-200px {
    max-height: 200px !important;
  }
  .max-w-md-200px {
    max-width: 200px !important;
  }
  .w-md-225px {
    width: 225px !important;
  }
  .h-md-225px {
    height: 225px !important;
  }
  .min-w-md-225px {
    min-width: 225px !important;
  }
  .min-h-md-225px {
    min-height: 225px !important;
  }
  .max-h-md-225px {
    max-height: 225px !important;
  }
  .max-w-md-225px {
    max-width: 225px !important;
  }
  .w-md-250px {
    width: 250px !important;
  }
  .h-md-250px {
    height: 250px !important;
  }
  .min-w-md-250px {
    min-width: 250px !important;
  }
  .min-h-md-250px {
    min-height: 250px !important;
  }
  .max-h-md-250px {
    max-height: 250px !important;
  }
  .max-w-md-250px {
    max-width: 250px !important;
  }
  .w-md-255px {
    width: 255px !important;
  }
  .h-md-255px {
    height: 255px !important;
  }
  .min-w-md-255px {
    min-width: 255px !important;
  }
  .min-h-md-255px {
    min-height: 255px !important;
  }
  .max-h-md-255px {
    max-height: 255px !important;
  }
  .max-w-md-255px {
    max-width: 255px !important;
  }
  .w-md-260px {
    width: 260px !important;
  }
  .h-md-260px {
    height: 260px !important;
  }
  .min-w-md-260px {
    min-width: 260px !important;
  }
  .min-h-md-260px {
    min-height: 260px !important;
  }
  .max-h-md-260px {
    max-height: 260px !important;
  }
  .max-w-md-260px {
    max-width: 260px !important;
  }
  .w-md-265px {
    width: 265px !important;
  }
  .h-md-265px {
    height: 265px !important;
  }
  .min-w-md-265px {
    min-width: 265px !important;
  }
  .min-h-md-265px {
    min-height: 265px !important;
  }
  .max-h-md-265px {
    max-height: 265px !important;
  }
  .max-w-md-265px {
    max-width: 265px !important;
  }
  .w-md-275px {
    width: 275px !important;
  }
  .h-md-275px {
    height: 275px !important;
  }
  .min-w-md-275px {
    min-width: 275px !important;
  }
  .min-h-md-275px {
    min-height: 275px !important;
  }
  .max-h-md-275px {
    max-height: 275px !important;
  }
  .max-w-md-275px {
    max-width: 275px !important;
  }
  .w-md-295px {
    width: 295px !important;
  }
  .h-md-295px {
    height: 295px !important;
  }
  .min-w-md-295px {
    min-width: 295px !important;
  }
  .min-h-md-295px {
    min-height: 295px !important;
  }
  .max-h-md-295px {
    max-height: 295px !important;
  }
  .max-w-md-295px {
    max-width: 295px !important;
  }
  .w-md-300px {
    width: 300px !important;
  }
  .h-md-300px {
    height: 300px !important;
  }
  .min-w-md-300px {
    min-width: 300px !important;
  }
  .min-h-md-300px {
    min-height: 300px !important;
  }
  .max-h-md-300px {
    max-height: 300px !important;
  }
  .max-w-md-300px {
    max-width: 300px !important;
  }
  .w-md-325px {
    width: 325px !important;
  }
  .h-md-325px {
    height: 325px !important;
  }
  .min-w-md-325px {
    min-width: 325px !important;
  }
  .min-h-md-325px {
    min-height: 325px !important;
  }
  .max-h-md-325px {
    max-height: 325px !important;
  }
  .max-w-md-325px {
    max-width: 325px !important;
  }
  .w-md-350px {
    width: 350px !important;
  }
  .h-md-350px {
    height: 350px !important;
  }
  .min-w-md-350px {
    min-width: 350px !important;
  }
  .min-h-md-350px {
    min-height: 350px !important;
  }
  .max-h-md-350px {
    max-height: 350px !important;
  }
  .max-w-md-350px {
    max-width: 350px !important;
  }
  .w-md-375px {
    width: 375px !important;
  }
  .h-md-375px {
    height: 375px !important;
  }
  .min-w-md-375px {
    min-width: 375px !important;
  }
  .min-h-md-375px {
    min-height: 375px !important;
  }
  .max-h-md-375px {
    max-height: 375px !important;
  }
  .max-w-md-375px {
    max-width: 375px !important;
  }
  .w-md-400px {
    width: 400px !important;
  }
  .h-md-400px {
    height: 400px !important;
  }
  .min-w-md-400px {
    min-width: 400px !important;
  }
  .min-h-md-400px {
    min-height: 400px !important;
  }
  .max-h-md-400px {
    max-height: 400px !important;
  }
  .max-w-md-400px {
    max-width: 400px !important;
  }
  .w-md-425px {
    width: 425px !important;
  }
  .h-md-425px {
    height: 425px !important;
  }
  .min-w-md-425px {
    min-width: 425px !important;
  }
  .min-h-md-425px {
    min-height: 425px !important;
  }
  .max-h-md-425px {
    max-height: 425px !important;
  }
  .max-w-md-425px {
    max-width: 425px !important;
  }
  .w-md-450px {
    width: 450px !important;
  }
  .h-md-450px {
    height: 450px !important;
  }
  .min-w-md-450px {
    min-width: 450px !important;
  }
  .min-h-md-450px {
    min-height: 450px !important;
  }
  .max-h-md-450px {
    max-height: 450px !important;
  }
  .max-w-md-450px {
    max-width: 450px !important;
  }
  .w-md-475px {
    width: 475px !important;
  }
  .h-md-475px {
    height: 475px !important;
  }
  .min-w-md-475px {
    min-width: 475px !important;
  }
  .min-h-md-475px {
    min-height: 475px !important;
  }
  .max-h-md-475px {
    max-height: 475px !important;
  }
  .max-w-md-475px {
    max-width: 475px !important;
  }
  .w-md-500px {
    width: 500px !important;
  }
  .h-md-500px {
    height: 500px !important;
  }
  .min-w-md-500px {
    min-width: 500px !important;
  }
  .min-h-md-500px {
    min-height: 500px !important;
  }
  .max-h-md-500px {
    max-height: 500px !important;
  }
  .max-w-md-500px {
    max-width: 500px !important;
  }
  .w-md-550px {
    width: 550px !important;
  }
  .h-md-550px {
    height: 550px !important;
  }
  .min-w-md-550px {
    min-width: 550px !important;
  }
  .min-h-md-550px {
    min-height: 550px !important;
  }
  .max-h-md-550px {
    max-height: 550px !important;
  }
  .max-w-md-550px {
    max-width: 550px !important;
  }
  .w-md-600px {
    width: 600px !important;
  }
  .h-md-600px {
    height: 600px !important;
  }
  .min-w-md-600px {
    min-width: 600px !important;
  }
  .min-h-md-600px {
    min-height: 600px !important;
  }
  .max-h-md-600px {
    max-height: 600px !important;
  }
  .max-w-md-600px {
    max-width: 600px !important;
  }
  .w-md-650px {
    width: 650px !important;
  }
  .h-md-650px {
    height: 650px !important;
  }
  .min-w-md-650px {
    min-width: 650px !important;
  }
  .min-h-md-650px {
    min-height: 650px !important;
  }
  .max-h-md-650px {
    max-height: 650px !important;
  }
  .max-w-md-650px {
    max-width: 650px !important;
  }
  .w-md-700px {
    width: 700px !important;
  }
  .h-md-700px {
    height: 700px !important;
  }
  .min-w-md-700px {
    min-width: 700px !important;
  }
  .min-h-md-700px {
    min-height: 700px !important;
  }
  .max-h-md-700px {
    max-height: 700px !important;
  }
  .max-w-md-700px {
    max-width: 700px !important;
  }
  .w-md-750px {
    width: 750px !important;
  }
  .h-md-750px {
    height: 750px !important;
  }
  .min-w-md-750px {
    min-width: 750px !important;
  }
  .min-h-md-750px {
    min-height: 750px !important;
  }
  .max-h-md-750px {
    max-height: 750px !important;
  }
  .max-w-md-750px {
    max-width: 750px !important;
  }
  .w-md-800px {
    width: 800px !important;
  }
  .h-md-800px {
    height: 800px !important;
  }
  .min-w-md-800px {
    min-width: 800px !important;
  }
  .min-h-md-800px {
    min-height: 800px !important;
  }
  .max-h-md-800px {
    max-height: 800px !important;
  }
  .max-w-md-800px {
    max-width: 800px !important;
  }
  .w-md-850px {
    width: 850px !important;
  }
  .h-md-850px {
    height: 850px !important;
  }
  .min-w-md-850px {
    min-width: 850px !important;
  }
  .min-h-md-850px {
    min-height: 850px !important;
  }
  .max-h-md-850px {
    max-height: 850px !important;
  }
  .max-w-md-850px {
    max-width: 850px !important;
  }
  .w-md-900px {
    width: 900px !important;
  }
  .h-md-900px {
    height: 900px !important;
  }
  .min-w-md-900px {
    min-width: 900px !important;
  }
  .min-h-md-900px {
    min-height: 900px !important;
  }
  .max-h-md-900px {
    max-height: 900px !important;
  }
  .max-w-md-900px {
    max-width: 900px !important;
  }
  .w-md-950px {
    width: 950px !important;
  }
  .h-md-950px {
    height: 950px !important;
  }
  .min-w-md-950px {
    min-width: 950px !important;
  }
  .min-h-md-950px {
    min-height: 950px !important;
  }
  .max-h-md-950px {
    max-height: 950px !important;
  }
  .max-w-md-950px {
    max-width: 950px !important;
  }
  .w-md-1000px {
    width: 1000px !important;
  }
  .h-md-1000px {
    height: 1000px !important;
  }
  .min-w-md-1000px {
    min-width: 1000px !important;
  }
  .min-h-md-1000px {
    min-height: 1000px !important;
  }
  .max-h-md-1000px {
    max-height: 1000px !important;
  }
  .max-w-md-1000px {
    max-width: 1000px !important;
  }
}

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .min-w-lg-100 {
    min-width: 100% !important;
  }
  .min-h-lg-100 {
    min-height: 100% !important;
  }
  .max-h-lg-100 {
    max-height: 100% !important;
  }
  .max-w-lg-100 {
    max-width: 100% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .min-w-lg-50 {
    min-width: 50% !important;
  }
  .min-h-lg-50 {
    min-height: 50% !important;
  }
  .max-h-lg-50 {
    max-height: 50% !important;
  }
  .max-w-lg-50 {
    max-width: 50% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .min-w-lg-auto {
    min-width: auto !important;
  }
  .min-h-lg-auto {
    min-height: auto !important;
  }
  .max-h-lg-auto {
    max-height: auto !important;
  }
  .max-w-lg-auto {
    max-width: auto !important;
  }
  .w-lg-1px {
    width: 1px !important;
  }
  .h-lg-1px {
    height: 1px !important;
  }
  .min-w-lg-1px {
    min-width: 1px !important;
  }
  .min-h-lg-1px {
    min-height: 1px !important;
  }
  .max-h-lg-1px {
    max-height: 1px !important;
  }
  .max-w-lg-1px {
    max-width: 1px !important;
  }
  .w-lg-2px {
    width: 2px !important;
  }
  .h-lg-2px {
    height: 2px !important;
  }
  .min-w-lg-2px {
    min-width: 2px !important;
  }
  .min-h-lg-2px {
    min-height: 2px !important;
  }
  .max-h-lg-2px {
    max-height: 2px !important;
  }
  .max-w-lg-2px {
    max-width: 2px !important;
  }
  .w-lg-3px {
    width: 3px !important;
  }
  .h-lg-3px {
    height: 3px !important;
  }
  .min-w-lg-3px {
    min-width: 3px !important;
  }
  .min-h-lg-3px {
    min-height: 3px !important;
  }
  .max-h-lg-3px {
    max-height: 3px !important;
  }
  .max-w-lg-3px {
    max-width: 3px !important;
  }
  .w-lg-4px {
    width: 4px !important;
  }
  .h-lg-4px {
    height: 4px !important;
  }
  .min-w-lg-4px {
    min-width: 4px !important;
  }
  .min-h-lg-4px {
    min-height: 4px !important;
  }
  .max-h-lg-4px {
    max-height: 4px !important;
  }
  .max-w-lg-4px {
    max-width: 4px !important;
  }
  .w-lg-5px {
    width: 5px !important;
  }
  .h-lg-5px {
    height: 5px !important;
  }
  .min-w-lg-5px {
    min-width: 5px !important;
  }
  .min-h-lg-5px {
    min-height: 5px !important;
  }
  .max-h-lg-5px {
    max-height: 5px !important;
  }
  .max-w-lg-5px {
    max-width: 5px !important;
  }
  .w-lg-10px {
    width: 10px !important;
  }
  .h-lg-10px {
    height: 10px !important;
  }
  .min-w-lg-10px {
    min-width: 10px !important;
  }
  .min-h-lg-10px {
    min-height: 10px !important;
  }
  .max-h-lg-10px {
    max-height: 10px !important;
  }
  .max-w-lg-10px {
    max-width: 10px !important;
  }
  .w-lg-15px {
    width: 15px !important;
  }
  .h-lg-15px {
    height: 15px !important;
  }
  .min-w-lg-15px {
    min-width: 15px !important;
  }
  .min-h-lg-15px {
    min-height: 15px !important;
  }
  .max-h-lg-15px {
    max-height: 15px !important;
  }
  .max-w-lg-15px {
    max-width: 15px !important;
  }
  .w-lg-20px {
    width: 20px !important;
  }
  .h-lg-20px {
    height: 20px !important;
  }
  .min-w-lg-20px {
    min-width: 20px !important;
  }
  .min-h-lg-20px {
    min-height: 20px !important;
  }
  .max-h-lg-20px {
    max-height: 20px !important;
  }
  .max-w-lg-20px {
    max-width: 20px !important;
  }
  .w-lg-25px {
    width: 25px !important;
  }
  .h-lg-25px {
    height: 25px !important;
  }
  .min-w-lg-25px {
    min-width: 25px !important;
  }
  .min-h-lg-25px {
    min-height: 25px !important;
  }
  .max-h-lg-25px {
    max-height: 25px !important;
  }
  .max-w-lg-25px {
    max-width: 25px !important;
  }
  .w-lg-30px {
    width: 30px !important;
  }
  .h-lg-30px {
    height: 30px !important;
  }
  .min-w-lg-30px {
    min-width: 30px !important;
  }
  .min-h-lg-30px {
    min-height: 30px !important;
  }
  .max-h-lg-30px {
    max-height: 30px !important;
  }
  .max-w-lg-30px {
    max-width: 30px !important;
  }
  .w-lg-35px {
    width: 35px !important;
  }
  .h-lg-35px {
    height: 35px !important;
  }
  .min-w-lg-35px {
    min-width: 35px !important;
  }
  .min-h-lg-35px {
    min-height: 35px !important;
  }
  .max-h-lg-35px {
    max-height: 35px !important;
  }
  .max-w-lg-35px {
    max-width: 35px !important;
  }
  .w-lg-40px {
    width: 40px !important;
  }
  .h-lg-40px {
    height: 40px !important;
  }
  .min-w-lg-40px {
    min-width: 40px !important;
  }
  .min-h-lg-40px {
    min-height: 40px !important;
  }
  .max-h-lg-40px {
    max-height: 40px !important;
  }
  .max-w-lg-40px {
    max-width: 40px !important;
  }
  .w-lg-45px {
    width: 45px !important;
  }
  .h-lg-45px {
    height: 45px !important;
  }
  .min-w-lg-45px {
    min-width: 45px !important;
  }
  .min-h-lg-45px {
    min-height: 45px !important;
  }
  .max-h-lg-45px {
    max-height: 45px !important;
  }
  .max-w-lg-45px {
    max-width: 45px !important;
  }
  .w-lg-50px {
    width: 50px !important;
  }
  .h-lg-50px {
    height: 50px !important;
  }
  .min-w-lg-50px {
    min-width: 50px !important;
  }
  .min-h-lg-50px {
    min-height: 50px !important;
  }
  .max-h-lg-50px {
    max-height: 50px !important;
  }
  .max-w-lg-50px {
    max-width: 50px !important;
  }
  .w-lg-55px {
    width: 55px !important;
  }
  .h-lg-55px {
    height: 55px !important;
  }
  .min-w-lg-55px {
    min-width: 55px !important;
  }
  .min-h-lg-55px {
    min-height: 55px !important;
  }
  .max-h-lg-55px {
    max-height: 55px !important;
  }
  .max-w-lg-55px {
    max-width: 55px !important;
  }
  .w-lg-60px {
    width: 60px !important;
  }
  .h-lg-60px {
    height: 60px !important;
  }
  .min-w-lg-60px {
    min-width: 60px !important;
  }
  .min-h-lg-60px {
    min-height: 60px !important;
  }
  .max-h-lg-60px {
    max-height: 60px !important;
  }
  .max-w-lg-60px {
    max-width: 60px !important;
  }
  .w-lg-65px {
    width: 65px !important;
  }
  .h-lg-65px {
    height: 65px !important;
  }
  .min-w-lg-65px {
    min-width: 65px !important;
  }
  .min-h-lg-65px {
    min-height: 65px !important;
  }
  .max-h-lg-65px {
    max-height: 65px !important;
  }
  .max-w-lg-65px {
    max-width: 65px !important;
  }
  .w-lg-70px {
    width: 70px !important;
  }
  .h-lg-70px {
    height: 70px !important;
  }
  .min-w-lg-70px {
    min-width: 70px !important;
  }
  .min-h-lg-70px {
    min-height: 70px !important;
  }
  .max-h-lg-70px {
    max-height: 70px !important;
  }
  .max-w-lg-70px {
    max-width: 70px !important;
  }
  .w-lg-75px {
    width: 75px !important;
  }
  .h-lg-75px {
    height: 75px !important;
  }
  .min-w-lg-75px {
    min-width: 75px !important;
  }
  .min-h-lg-75px {
    min-height: 75px !important;
  }
  .max-h-lg-75px {
    max-height: 75px !important;
  }
  .max-w-lg-75px {
    max-width: 75px !important;
  }
  .w-lg-80px {
    width: 80px !important;
  }
  .h-lg-80px {
    height: 80px !important;
  }
  .min-w-lg-80px {
    min-width: 80px !important;
  }
  .min-h-lg-80px {
    min-height: 80px !important;
  }
  .max-h-lg-80px {
    max-height: 80px !important;
  }
  .max-w-lg-80px {
    max-width: 80px !important;
  }
  .w-lg-85px {
    width: 85px !important;
  }
  .h-lg-85px {
    height: 85px !important;
  }
  .min-w-lg-85px {
    min-width: 85px !important;
  }
  .min-h-lg-85px {
    min-height: 85px !important;
  }
  .max-h-lg-85px {
    max-height: 85px !important;
  }
  .max-w-lg-85px {
    max-width: 85px !important;
  }
  .w-lg-90px {
    width: 90px !important;
  }
  .h-lg-90px {
    height: 90px !important;
  }
  .min-w-lg-90px {
    min-width: 90px !important;
  }
  .min-h-lg-90px {
    min-height: 90px !important;
  }
  .max-h-lg-90px {
    max-height: 90px !important;
  }
  .max-w-lg-90px {
    max-width: 90px !important;
  }
  .w-lg-95px {
    width: 95px !important;
  }
  .h-lg-95px {
    height: 95px !important;
  }
  .min-w-lg-95px {
    min-width: 95px !important;
  }
  .min-h-lg-95px {
    min-height: 95px !important;
  }
  .max-h-lg-95px {
    max-height: 95px !important;
  }
  .max-w-lg-95px {
    max-width: 95px !important;
  }
  .w-lg-100px {
    width: 100px !important;
  }
  .h-lg-100px {
    height: 100px !important;
  }
  .min-w-lg-100px {
    min-width: 100px !important;
  }
  .min-h-lg-100px {
    min-height: 100px !important;
  }
  .max-h-lg-100px {
    max-height: 100px !important;
  }
  .max-w-lg-100px {
    max-width: 100px !important;
  }
  .w-lg-105px {
    width: 105px !important;
  }
  .h-lg-105px {
    height: 105px !important;
  }
  .min-w-lg-105px {
    min-width: 105px !important;
  }
  .min-h-lg-105px {
    min-height: 105px !important;
  }
  .max-h-lg-105px {
    max-height: 105px !important;
  }
  .max-w-lg-105px {
    max-width: 105px !important;
  }
  .w-lg-110px {
    width: 110px !important;
  }
  .h-lg-110px {
    height: 110px !important;
  }
  .min-w-lg-110px {
    min-width: 110px !important;
  }
  .min-h-lg-110px {
    min-height: 110px !important;
  }
  .max-h-lg-110px {
    max-height: 110px !important;
  }
  .max-w-lg-110px {
    max-width: 110px !important;
  }
  .w-lg-115px {
    width: 115px !important;
  }
  .h-lg-115px {
    height: 115px !important;
  }
  .min-w-lg-115px {
    min-width: 115px !important;
  }
  .min-h-lg-115px {
    min-height: 115px !important;
  }
  .max-h-lg-115px {
    max-height: 115px !important;
  }
  .max-w-lg-115px {
    max-width: 115px !important;
  }
  .w-lg-120px {
    width: 120px !important;
  }
  .h-lg-120px {
    height: 120px !important;
  }
  .min-w-lg-120px {
    min-width: 120px !important;
  }
  .min-h-lg-120px {
    min-height: 120px !important;
  }
  .max-h-lg-120px {
    max-height: 120px !important;
  }
  .max-w-lg-120px {
    max-width: 120px !important;
  }
  .w-lg-125px {
    width: 125px !important;
  }
  .h-lg-125px {
    height: 125px !important;
  }
  .min-w-lg-125px {
    min-width: 125px !important;
  }
  .min-h-lg-125px {
    min-height: 125px !important;
  }
  .max-h-lg-125px {
    max-height: 125px !important;
  }
  .max-w-lg-125px {
    max-width: 125px !important;
  }
  .w-lg-130px {
    width: 130px !important;
  }
  .h-lg-130px {
    height: 130px !important;
  }
  .min-w-lg-130px {
    min-width: 130px !important;
  }
  .min-h-lg-130px {
    min-height: 130px !important;
  }
  .max-h-lg-130px {
    max-height: 130px !important;
  }
  .max-w-lg-130px {
    max-width: 130px !important;
  }
  .w-lg-135px {
    width: 135px !important;
  }
  .h-lg-135px {
    height: 135px !important;
  }
  .min-w-lg-135px {
    min-width: 135px !important;
  }
  .min-h-lg-135px {
    min-height: 135px !important;
  }
  .max-h-lg-135px {
    max-height: 135px !important;
  }
  .max-w-lg-135px {
    max-width: 135px !important;
  }
  .w-lg-140px {
    width: 140px !important;
  }
  .h-lg-140px {
    height: 140px !important;
  }
  .min-w-lg-140px {
    min-width: 140px !important;
  }
  .min-h-lg-140px {
    min-height: 140px !important;
  }
  .max-h-lg-140px {
    max-height: 140px !important;
  }
  .max-w-lg-140px {
    max-width: 140px !important;
  }
  .w-lg-145px {
    width: 145px !important;
  }
  .h-lg-145px {
    height: 145px !important;
  }
  .min-w-lg-145px {
    min-width: 145px !important;
  }
  .min-h-lg-145px {
    min-height: 145px !important;
  }
  .max-h-lg-145px {
    max-height: 145px !important;
  }
  .max-w-lg-145px {
    max-width: 145px !important;
  }
  .w-lg-150px {
    width: 150px !important;
  }
  .h-lg-150px {
    height: 150px !important;
  }
  .min-w-lg-150px {
    min-width: 150px !important;
  }
  .min-h-lg-150px {
    min-height: 150px !important;
  }
  .max-h-lg-150px {
    max-height: 150px !important;
  }
  .max-w-lg-150px {
    max-width: 150px !important;
  }
  .w-lg-175px {
    width: 175px !important;
  }
  .h-lg-175px {
    height: 175px !important;
  }
  .min-w-lg-175px {
    min-width: 175px !important;
  }
  .min-h-lg-175px {
    min-height: 175px !important;
  }
  .max-h-lg-175px {
    max-height: 175px !important;
  }
  .max-w-lg-175px {
    max-width: 175px !important;
  }
  .w-lg-180px {
    width: 180px !important;
  }
  .h-lg-180px {
    height: 180px !important;
  }
  .min-w-lg-180px {
    min-width: 180px !important;
  }
  .min-h-lg-180px {
    min-height: 180px !important;
  }
  .max-h-lg-180px {
    max-height: 180px !important;
  }
  .max-w-lg-180px {
    max-width: 180px !important;
  }
  .w-lg-200px {
    width: 200px !important;
  }
  .h-lg-200px {
    height: 200px !important;
  }
  .min-w-lg-200px {
    min-width: 200px !important;
  }
  .min-h-lg-200px {
    min-height: 200px !important;
  }
  .max-h-lg-200px {
    max-height: 200px !important;
  }
  .max-w-lg-200px {
    max-width: 200px !important;
  }
  .w-lg-225px {
    width: 225px !important;
  }
  .h-lg-225px {
    height: 225px !important;
  }
  .min-w-lg-225px {
    min-width: 225px !important;
  }
  .min-h-lg-225px {
    min-height: 225px !important;
  }
  .max-h-lg-225px {
    max-height: 225px !important;
  }
  .max-w-lg-225px {
    max-width: 225px !important;
  }
  .w-lg-250px {
    width: 250px !important;
  }
  .h-lg-250px {
    height: 250px !important;
  }
  .min-w-lg-250px {
    min-width: 250px !important;
  }
  .min-h-lg-250px {
    min-height: 250px !important;
  }
  .max-h-lg-250px {
    max-height: 250px !important;
  }
  .max-w-lg-250px {
    max-width: 250px !important;
  }
  .w-lg-255px {
    width: 255px !important;
  }
  .h-lg-255px {
    height: 255px !important;
  }
  .min-w-lg-255px {
    min-width: 255px !important;
  }
  .min-h-lg-255px {
    min-height: 255px !important;
  }
  .max-h-lg-255px {
    max-height: 255px !important;
  }
  .max-w-lg-255px {
    max-width: 255px !important;
  }
  .w-lg-260px {
    width: 260px !important;
  }
  .h-lg-260px {
    height: 260px !important;
  }
  .min-w-lg-260px {
    min-width: 260px !important;
  }
  .min-h-lg-260px {
    min-height: 260px !important;
  }
  .max-h-lg-260px {
    max-height: 260px !important;
  }
  .max-w-lg-260px {
    max-width: 260px !important;
  }
  .w-lg-265px {
    width: 265px !important;
  }
  .h-lg-265px {
    height: 265px !important;
  }
  .min-w-lg-265px {
    min-width: 265px !important;
  }
  .min-h-lg-265px {
    min-height: 265px !important;
  }
  .max-h-lg-265px {
    max-height: 265px !important;
  }
  .max-w-lg-265px {
    max-width: 265px !important;
  }
  .w-lg-275px {
    width: 275px !important;
  }
  .h-lg-275px {
    height: 275px !important;
  }
  .min-w-lg-275px {
    min-width: 275px !important;
  }
  .min-h-lg-275px {
    min-height: 275px !important;
  }
  .max-h-lg-275px {
    max-height: 275px !important;
  }
  .max-w-lg-275px {
    max-width: 275px !important;
  }
  .w-lg-295px {
    width: 295px !important;
  }
  .h-lg-295px {
    height: 295px !important;
  }
  .min-w-lg-295px {
    min-width: 295px !important;
  }
  .min-h-lg-295px {
    min-height: 295px !important;
  }
  .max-h-lg-295px {
    max-height: 295px !important;
  }
  .max-w-lg-295px {
    max-width: 295px !important;
  }
  .w-lg-300px {
    width: 300px !important;
  }
  .h-lg-300px {
    height: 300px !important;
  }
  .min-w-lg-300px {
    min-width: 300px !important;
  }
  .min-h-lg-300px {
    min-height: 300px !important;
  }
  .max-h-lg-300px {
    max-height: 300px !important;
  }
  .max-w-lg-300px {
    max-width: 300px !important;
  }
  .w-lg-325px {
    width: 325px !important;
  }
  .h-lg-325px {
    height: 325px !important;
  }
  .min-w-lg-325px {
    min-width: 325px !important;
  }
  .min-h-lg-325px {
    min-height: 325px !important;
  }
  .max-h-lg-325px {
    max-height: 325px !important;
  }
  .max-w-lg-325px {
    max-width: 325px !important;
  }
  .w-lg-350px {
    width: 350px !important;
  }
  .h-lg-350px {
    height: 350px !important;
  }
  .min-w-lg-350px {
    min-width: 350px !important;
  }
  .min-h-lg-350px {
    min-height: 350px !important;
  }
  .max-h-lg-350px {
    max-height: 350px !important;
  }
  .max-w-lg-350px {
    max-width: 350px !important;
  }
  .w-lg-375px {
    width: 375px !important;
  }
  .h-lg-375px {
    height: 375px !important;
  }
  .min-w-lg-375px {
    min-width: 375px !important;
  }
  .min-h-lg-375px {
    min-height: 375px !important;
  }
  .max-h-lg-375px {
    max-height: 375px !important;
  }
  .max-w-lg-375px {
    max-width: 375px !important;
  }
  .w-lg-400px {
    width: 400px !important;
  }
  .h-lg-400px {
    height: 400px !important;
  }
  .min-w-lg-400px {
    min-width: 400px !important;
  }
  .min-h-lg-400px {
    min-height: 400px !important;
  }
  .max-h-lg-400px {
    max-height: 400px !important;
  }
  .max-w-lg-400px {
    max-width: 400px !important;
  }
  .w-lg-425px {
    width: 425px !important;
  }
  .h-lg-425px {
    height: 425px !important;
  }
  .min-w-lg-425px {
    min-width: 425px !important;
  }
  .min-h-lg-425px {
    min-height: 425px !important;
  }
  .max-h-lg-425px {
    max-height: 425px !important;
  }
  .max-w-lg-425px {
    max-width: 425px !important;
  }
  .w-lg-450px {
    width: 450px !important;
  }
  .h-lg-450px {
    height: 450px !important;
  }
  .min-w-lg-450px {
    min-width: 450px !important;
  }
  .min-h-lg-450px {
    min-height: 450px !important;
  }
  .max-h-lg-450px {
    max-height: 450px !important;
  }
  .max-w-lg-450px {
    max-width: 450px !important;
  }
  .w-lg-475px {
    width: 475px !important;
  }
  .h-lg-475px {
    height: 475px !important;
  }
  .min-w-lg-475px {
    min-width: 475px !important;
  }
  .min-h-lg-475px {
    min-height: 475px !important;
  }
  .max-h-lg-475px {
    max-height: 475px !important;
  }
  .max-w-lg-475px {
    max-width: 475px !important;
  }
  .w-lg-500px {
    width: 500px !important;
  }
  .h-lg-500px {
    height: 500px !important;
  }
  .min-w-lg-500px {
    min-width: 500px !important;
  }
  .min-h-lg-500px {
    min-height: 500px !important;
  }
  .max-h-lg-500px {
    max-height: 500px !important;
  }
  .max-w-lg-500px {
    max-width: 500px !important;
  }
  .w-lg-550px {
    width: 550px !important;
  }
  .h-lg-550px {
    height: 550px !important;
  }
  .min-w-lg-550px {
    min-width: 550px !important;
  }
  .min-h-lg-550px {
    min-height: 550px !important;
  }
  .max-h-lg-550px {
    max-height: 550px !important;
  }
  .max-w-lg-550px {
    max-width: 550px !important;
  }
  .w-lg-600px {
    width: 600px !important;
  }
  .h-lg-600px {
    height: 600px !important;
  }
  .min-w-lg-600px {
    min-width: 600px !important;
  }
  .min-h-lg-600px {
    min-height: 600px !important;
  }
  .max-h-lg-600px {
    max-height: 600px !important;
  }
  .max-w-lg-600px {
    max-width: 600px !important;
  }
  .w-lg-650px {
    width: 650px !important;
  }
  .h-lg-650px {
    height: 650px !important;
  }
  .min-w-lg-650px {
    min-width: 650px !important;
  }
  .min-h-lg-650px {
    min-height: 650px !important;
  }
  .max-h-lg-650px {
    max-height: 650px !important;
  }
  .max-w-lg-650px {
    max-width: 650px !important;
  }
  .w-lg-700px {
    width: 700px !important;
  }
  .h-lg-700px {
    height: 700px !important;
  }
  .min-w-lg-700px {
    min-width: 700px !important;
  }
  .min-h-lg-700px {
    min-height: 700px !important;
  }
  .max-h-lg-700px {
    max-height: 700px !important;
  }
  .max-w-lg-700px {
    max-width: 700px !important;
  }
  .w-lg-750px {
    width: 750px !important;
  }
  .h-lg-750px {
    height: 750px !important;
  }
  .min-w-lg-750px {
    min-width: 750px !important;
  }
  .min-h-lg-750px {
    min-height: 750px !important;
  }
  .max-h-lg-750px {
    max-height: 750px !important;
  }
  .max-w-lg-750px {
    max-width: 750px !important;
  }
  .w-lg-800px {
    width: 800px !important;
  }
  .h-lg-800px {
    height: 800px !important;
  }
  .min-w-lg-800px {
    min-width: 800px !important;
  }
  .min-h-lg-800px {
    min-height: 800px !important;
  }
  .max-h-lg-800px {
    max-height: 800px !important;
  }
  .max-w-lg-800px {
    max-width: 800px !important;
  }
  .w-lg-850px {
    width: 850px !important;
  }
  .h-lg-850px {
    height: 850px !important;
  }
  .min-w-lg-850px {
    min-width: 850px !important;
  }
  .min-h-lg-850px {
    min-height: 850px !important;
  }
  .max-h-lg-850px {
    max-height: 850px !important;
  }
  .max-w-lg-850px {
    max-width: 850px !important;
  }
  .w-lg-900px {
    width: 900px !important;
  }
  .h-lg-900px {
    height: 900px !important;
  }
  .min-w-lg-900px {
    min-width: 900px !important;
  }
  .min-h-lg-900px {
    min-height: 900px !important;
  }
  .max-h-lg-900px {
    max-height: 900px !important;
  }
  .max-w-lg-900px {
    max-width: 900px !important;
  }
  .w-lg-950px {
    width: 950px !important;
  }
  .h-lg-950px {
    height: 950px !important;
  }
  .min-w-lg-950px {
    min-width: 950px !important;
  }
  .min-h-lg-950px {
    min-height: 950px !important;
  }
  .max-h-lg-950px {
    max-height: 950px !important;
  }
  .max-w-lg-950px {
    max-width: 950px !important;
  }
  .w-lg-1000px {
    width: 1000px !important;
  }
  .h-lg-1000px {
    height: 1000px !important;
  }
  .min-w-lg-1000px {
    min-width: 1000px !important;
  }
  .min-h-lg-1000px {
    min-height: 1000px !important;
  }
  .max-h-lg-1000px {
    max-height: 1000px !important;
  }
  .max-w-lg-1000px {
    max-width: 1000px !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .min-w-xl-100 {
    min-width: 100% !important;
  }
  .min-h-xl-100 {
    min-height: 100% !important;
  }
  .max-h-xl-100 {
    max-height: 100% !important;
  }
  .max-w-xl-100 {
    max-width: 100% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .min-w-xl-50 {
    min-width: 50% !important;
  }
  .min-h-xl-50 {
    min-height: 50% !important;
  }
  .max-h-xl-50 {
    max-height: 50% !important;
  }
  .max-w-xl-50 {
    max-width: 50% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .min-w-xl-auto {
    min-width: auto !important;
  }
  .min-h-xl-auto {
    min-height: auto !important;
  }
  .max-h-xl-auto {
    max-height: auto !important;
  }
  .max-w-xl-auto {
    max-width: auto !important;
  }
  .w-xl-1px {
    width: 1px !important;
  }
  .h-xl-1px {
    height: 1px !important;
  }
  .min-w-xl-1px {
    min-width: 1px !important;
  }
  .min-h-xl-1px {
    min-height: 1px !important;
  }
  .max-h-xl-1px {
    max-height: 1px !important;
  }
  .max-w-xl-1px {
    max-width: 1px !important;
  }
  .w-xl-2px {
    width: 2px !important;
  }
  .h-xl-2px {
    height: 2px !important;
  }
  .min-w-xl-2px {
    min-width: 2px !important;
  }
  .min-h-xl-2px {
    min-height: 2px !important;
  }
  .max-h-xl-2px {
    max-height: 2px !important;
  }
  .max-w-xl-2px {
    max-width: 2px !important;
  }
  .w-xl-3px {
    width: 3px !important;
  }
  .h-xl-3px {
    height: 3px !important;
  }
  .min-w-xl-3px {
    min-width: 3px !important;
  }
  .min-h-xl-3px {
    min-height: 3px !important;
  }
  .max-h-xl-3px {
    max-height: 3px !important;
  }
  .max-w-xl-3px {
    max-width: 3px !important;
  }
  .w-xl-4px {
    width: 4px !important;
  }
  .h-xl-4px {
    height: 4px !important;
  }
  .min-w-xl-4px {
    min-width: 4px !important;
  }
  .min-h-xl-4px {
    min-height: 4px !important;
  }
  .max-h-xl-4px {
    max-height: 4px !important;
  }
  .max-w-xl-4px {
    max-width: 4px !important;
  }
  .w-xl-5px {
    width: 5px !important;
  }
  .h-xl-5px {
    height: 5px !important;
  }
  .min-w-xl-5px {
    min-width: 5px !important;
  }
  .min-h-xl-5px {
    min-height: 5px !important;
  }
  .max-h-xl-5px {
    max-height: 5px !important;
  }
  .max-w-xl-5px {
    max-width: 5px !important;
  }
  .w-xl-10px {
    width: 10px !important;
  }
  .h-xl-10px {
    height: 10px !important;
  }
  .min-w-xl-10px {
    min-width: 10px !important;
  }
  .min-h-xl-10px {
    min-height: 10px !important;
  }
  .max-h-xl-10px {
    max-height: 10px !important;
  }
  .max-w-xl-10px {
    max-width: 10px !important;
  }
  .w-xl-15px {
    width: 15px !important;
  }
  .h-xl-15px {
    height: 15px !important;
  }
  .min-w-xl-15px {
    min-width: 15px !important;
  }
  .min-h-xl-15px {
    min-height: 15px !important;
  }
  .max-h-xl-15px {
    max-height: 15px !important;
  }
  .max-w-xl-15px {
    max-width: 15px !important;
  }
  .w-xl-20px {
    width: 20px !important;
  }
  .h-xl-20px {
    height: 20px !important;
  }
  .min-w-xl-20px {
    min-width: 20px !important;
  }
  .min-h-xl-20px {
    min-height: 20px !important;
  }
  .max-h-xl-20px {
    max-height: 20px !important;
  }
  .max-w-xl-20px {
    max-width: 20px !important;
  }
  .w-xl-25px {
    width: 25px !important;
  }
  .h-xl-25px {
    height: 25px !important;
  }
  .min-w-xl-25px {
    min-width: 25px !important;
  }
  .min-h-xl-25px {
    min-height: 25px !important;
  }
  .max-h-xl-25px {
    max-height: 25px !important;
  }
  .max-w-xl-25px {
    max-width: 25px !important;
  }
  .w-xl-30px {
    width: 30px !important;
  }
  .h-xl-30px {
    height: 30px !important;
  }
  .min-w-xl-30px {
    min-width: 30px !important;
  }
  .min-h-xl-30px {
    min-height: 30px !important;
  }
  .max-h-xl-30px {
    max-height: 30px !important;
  }
  .max-w-xl-30px {
    max-width: 30px !important;
  }
  .w-xl-35px {
    width: 35px !important;
  }
  .h-xl-35px {
    height: 35px !important;
  }
  .min-w-xl-35px {
    min-width: 35px !important;
  }
  .min-h-xl-35px {
    min-height: 35px !important;
  }
  .max-h-xl-35px {
    max-height: 35px !important;
  }
  .max-w-xl-35px {
    max-width: 35px !important;
  }
  .w-xl-40px {
    width: 40px !important;
  }
  .h-xl-40px {
    height: 40px !important;
  }
  .min-w-xl-40px {
    min-width: 40px !important;
  }
  .min-h-xl-40px {
    min-height: 40px !important;
  }
  .max-h-xl-40px {
    max-height: 40px !important;
  }
  .max-w-xl-40px {
    max-width: 40px !important;
  }
  .w-xl-45px {
    width: 45px !important;
  }
  .h-xl-45px {
    height: 45px !important;
  }
  .min-w-xl-45px {
    min-width: 45px !important;
  }
  .min-h-xl-45px {
    min-height: 45px !important;
  }
  .max-h-xl-45px {
    max-height: 45px !important;
  }
  .max-w-xl-45px {
    max-width: 45px !important;
  }
  .w-xl-50px {
    width: 50px !important;
  }
  .h-xl-50px {
    height: 50px !important;
  }
  .min-w-xl-50px {
    min-width: 50px !important;
  }
  .min-h-xl-50px {
    min-height: 50px !important;
  }
  .max-h-xl-50px {
    max-height: 50px !important;
  }
  .max-w-xl-50px {
    max-width: 50px !important;
  }
  .w-xl-55px {
    width: 55px !important;
  }
  .h-xl-55px {
    height: 55px !important;
  }
  .min-w-xl-55px {
    min-width: 55px !important;
  }
  .min-h-xl-55px {
    min-height: 55px !important;
  }
  .max-h-xl-55px {
    max-height: 55px !important;
  }
  .max-w-xl-55px {
    max-width: 55px !important;
  }
  .w-xl-60px {
    width: 60px !important;
  }
  .h-xl-60px {
    height: 60px !important;
  }
  .min-w-xl-60px {
    min-width: 60px !important;
  }
  .min-h-xl-60px {
    min-height: 60px !important;
  }
  .max-h-xl-60px {
    max-height: 60px !important;
  }
  .max-w-xl-60px {
    max-width: 60px !important;
  }
  .w-xl-65px {
    width: 65px !important;
  }
  .h-xl-65px {
    height: 65px !important;
  }
  .min-w-xl-65px {
    min-width: 65px !important;
  }
  .min-h-xl-65px {
    min-height: 65px !important;
  }
  .max-h-xl-65px {
    max-height: 65px !important;
  }
  .max-w-xl-65px {
    max-width: 65px !important;
  }
  .w-xl-70px {
    width: 70px !important;
  }
  .h-xl-70px {
    height: 70px !important;
  }
  .min-w-xl-70px {
    min-width: 70px !important;
  }
  .min-h-xl-70px {
    min-height: 70px !important;
  }
  .max-h-xl-70px {
    max-height: 70px !important;
  }
  .max-w-xl-70px {
    max-width: 70px !important;
  }
  .w-xl-75px {
    width: 75px !important;
  }
  .h-xl-75px {
    height: 75px !important;
  }
  .min-w-xl-75px {
    min-width: 75px !important;
  }
  .min-h-xl-75px {
    min-height: 75px !important;
  }
  .max-h-xl-75px {
    max-height: 75px !important;
  }
  .max-w-xl-75px {
    max-width: 75px !important;
  }
  .w-xl-80px {
    width: 80px !important;
  }
  .h-xl-80px {
    height: 80px !important;
  }
  .min-w-xl-80px {
    min-width: 80px !important;
  }
  .min-h-xl-80px {
    min-height: 80px !important;
  }
  .max-h-xl-80px {
    max-height: 80px !important;
  }
  .max-w-xl-80px {
    max-width: 80px !important;
  }
  .w-xl-85px {
    width: 85px !important;
  }
  .h-xl-85px {
    height: 85px !important;
  }
  .min-w-xl-85px {
    min-width: 85px !important;
  }
  .min-h-xl-85px {
    min-height: 85px !important;
  }
  .max-h-xl-85px {
    max-height: 85px !important;
  }
  .max-w-xl-85px {
    max-width: 85px !important;
  }
  .w-xl-90px {
    width: 90px !important;
  }
  .h-xl-90px {
    height: 90px !important;
  }
  .min-w-xl-90px {
    min-width: 90px !important;
  }
  .min-h-xl-90px {
    min-height: 90px !important;
  }
  .max-h-xl-90px {
    max-height: 90px !important;
  }
  .max-w-xl-90px {
    max-width: 90px !important;
  }
  .w-xl-95px {
    width: 95px !important;
  }
  .h-xl-95px {
    height: 95px !important;
  }
  .min-w-xl-95px {
    min-width: 95px !important;
  }
  .min-h-xl-95px {
    min-height: 95px !important;
  }
  .max-h-xl-95px {
    max-height: 95px !important;
  }
  .max-w-xl-95px {
    max-width: 95px !important;
  }
  .w-xl-100px {
    width: 100px !important;
  }
  .h-xl-100px {
    height: 100px !important;
  }
  .min-w-xl-100px {
    min-width: 100px !important;
  }
  .min-h-xl-100px {
    min-height: 100px !important;
  }
  .max-h-xl-100px {
    max-height: 100px !important;
  }
  .max-w-xl-100px {
    max-width: 100px !important;
  }
  .w-xl-105px {
    width: 105px !important;
  }
  .h-xl-105px {
    height: 105px !important;
  }
  .min-w-xl-105px {
    min-width: 105px !important;
  }
  .min-h-xl-105px {
    min-height: 105px !important;
  }
  .max-h-xl-105px {
    max-height: 105px !important;
  }
  .max-w-xl-105px {
    max-width: 105px !important;
  }
  .w-xl-110px {
    width: 110px !important;
  }
  .h-xl-110px {
    height: 110px !important;
  }
  .min-w-xl-110px {
    min-width: 110px !important;
  }
  .min-h-xl-110px {
    min-height: 110px !important;
  }
  .max-h-xl-110px {
    max-height: 110px !important;
  }
  .max-w-xl-110px {
    max-width: 110px !important;
  }
  .w-xl-115px {
    width: 115px !important;
  }
  .h-xl-115px {
    height: 115px !important;
  }
  .min-w-xl-115px {
    min-width: 115px !important;
  }
  .min-h-xl-115px {
    min-height: 115px !important;
  }
  .max-h-xl-115px {
    max-height: 115px !important;
  }
  .max-w-xl-115px {
    max-width: 115px !important;
  }
  .w-xl-120px {
    width: 120px !important;
  }
  .h-xl-120px {
    height: 120px !important;
  }
  .min-w-xl-120px {
    min-width: 120px !important;
  }
  .min-h-xl-120px {
    min-height: 120px !important;
  }
  .max-h-xl-120px {
    max-height: 120px !important;
  }
  .max-w-xl-120px {
    max-width: 120px !important;
  }
  .w-xl-125px {
    width: 125px !important;
  }
  .h-xl-125px {
    height: 125px !important;
  }
  .min-w-xl-125px {
    min-width: 125px !important;
  }
  .min-h-xl-125px {
    min-height: 125px !important;
  }
  .max-h-xl-125px {
    max-height: 125px !important;
  }
  .max-w-xl-125px {
    max-width: 125px !important;
  }
  .w-xl-130px {
    width: 130px !important;
  }
  .h-xl-130px {
    height: 130px !important;
  }
  .min-w-xl-130px {
    min-width: 130px !important;
  }
  .min-h-xl-130px {
    min-height: 130px !important;
  }
  .max-h-xl-130px {
    max-height: 130px !important;
  }
  .max-w-xl-130px {
    max-width: 130px !important;
  }
  .w-xl-135px {
    width: 135px !important;
  }
  .h-xl-135px {
    height: 135px !important;
  }
  .min-w-xl-135px {
    min-width: 135px !important;
  }
  .min-h-xl-135px {
    min-height: 135px !important;
  }
  .max-h-xl-135px {
    max-height: 135px !important;
  }
  .max-w-xl-135px {
    max-width: 135px !important;
  }
  .w-xl-140px {
    width: 140px !important;
  }
  .h-xl-140px {
    height: 140px !important;
  }
  .min-w-xl-140px {
    min-width: 140px !important;
  }
  .min-h-xl-140px {
    min-height: 140px !important;
  }
  .max-h-xl-140px {
    max-height: 140px !important;
  }
  .max-w-xl-140px {
    max-width: 140px !important;
  }
  .w-xl-145px {
    width: 145px !important;
  }
  .h-xl-145px {
    height: 145px !important;
  }
  .min-w-xl-145px {
    min-width: 145px !important;
  }
  .min-h-xl-145px {
    min-height: 145px !important;
  }
  .max-h-xl-145px {
    max-height: 145px !important;
  }
  .max-w-xl-145px {
    max-width: 145px !important;
  }
  .w-xl-150px {
    width: 150px !important;
  }
  .h-xl-150px {
    height: 150px !important;
  }
  .min-w-xl-150px {
    min-width: 150px !important;
  }
  .min-h-xl-150px {
    min-height: 150px !important;
  }
  .max-h-xl-150px {
    max-height: 150px !important;
  }
  .max-w-xl-150px {
    max-width: 150px !important;
  }
  .w-xl-175px {
    width: 175px !important;
  }
  .h-xl-175px {
    height: 175px !important;
  }
  .min-w-xl-175px {
    min-width: 175px !important;
  }
  .min-h-xl-175px {
    min-height: 175px !important;
  }
  .max-h-xl-175px {
    max-height: 175px !important;
  }
  .max-w-xl-175px {
    max-width: 175px !important;
  }
  .w-xl-180px {
    width: 180px !important;
  }
  .h-xl-180px {
    height: 180px !important;
  }
  .min-w-xl-180px {
    min-width: 180px !important;
  }
  .min-h-xl-180px {
    min-height: 180px !important;
  }
  .max-h-xl-180px {
    max-height: 180px !important;
  }
  .max-w-xl-180px {
    max-width: 180px !important;
  }
  .w-xl-200px {
    width: 200px !important;
  }
  .h-xl-200px {
    height: 200px !important;
  }
  .min-w-xl-200px {
    min-width: 200px !important;
  }
  .min-h-xl-200px {
    min-height: 200px !important;
  }
  .max-h-xl-200px {
    max-height: 200px !important;
  }
  .max-w-xl-200px {
    max-width: 200px !important;
  }
  .w-xl-225px {
    width: 225px !important;
  }
  .h-xl-225px {
    height: 225px !important;
  }
  .min-w-xl-225px {
    min-width: 225px !important;
  }
  .min-h-xl-225px {
    min-height: 225px !important;
  }
  .max-h-xl-225px {
    max-height: 225px !important;
  }
  .max-w-xl-225px {
    max-width: 225px !important;
  }
  .w-xl-250px {
    width: 250px !important;
  }
  .h-xl-250px {
    height: 250px !important;
  }
  .min-w-xl-250px {
    min-width: 250px !important;
  }
  .min-h-xl-250px {
    min-height: 250px !important;
  }
  .max-h-xl-250px {
    max-height: 250px !important;
  }
  .max-w-xl-250px {
    max-width: 250px !important;
  }
  .w-xl-255px {
    width: 255px !important;
  }
  .h-xl-255px {
    height: 255px !important;
  }
  .min-w-xl-255px {
    min-width: 255px !important;
  }
  .min-h-xl-255px {
    min-height: 255px !important;
  }
  .max-h-xl-255px {
    max-height: 255px !important;
  }
  .max-w-xl-255px {
    max-width: 255px !important;
  }
  .w-xl-260px {
    width: 260px !important;
  }
  .h-xl-260px {
    height: 260px !important;
  }
  .min-w-xl-260px {
    min-width: 260px !important;
  }
  .min-h-xl-260px {
    min-height: 260px !important;
  }
  .max-h-xl-260px {
    max-height: 260px !important;
  }
  .max-w-xl-260px {
    max-width: 260px !important;
  }
  .w-xl-265px {
    width: 265px !important;
  }
  .h-xl-265px {
    height: 265px !important;
  }
  .min-w-xl-265px {
    min-width: 265px !important;
  }
  .min-h-xl-265px {
    min-height: 265px !important;
  }
  .max-h-xl-265px {
    max-height: 265px !important;
  }
  .max-w-xl-265px {
    max-width: 265px !important;
  }
  .w-xl-275px {
    width: 275px !important;
  }
  .h-xl-275px {
    height: 275px !important;
  }
  .min-w-xl-275px {
    min-width: 275px !important;
  }
  .min-h-xl-275px {
    min-height: 275px !important;
  }
  .max-h-xl-275px {
    max-height: 275px !important;
  }
  .max-w-xl-275px {
    max-width: 275px !important;
  }
  .w-xl-295px {
    width: 295px !important;
  }
  .h-xl-295px {
    height: 295px !important;
  }
  .min-w-xl-295px {
    min-width: 295px !important;
  }
  .min-h-xl-295px {
    min-height: 295px !important;
  }
  .max-h-xl-295px {
    max-height: 295px !important;
  }
  .max-w-xl-295px {
    max-width: 295px !important;
  }
  .w-xl-300px {
    width: 300px !important;
  }
  .h-xl-300px {
    height: 300px !important;
  }
  .min-w-xl-300px {
    min-width: 300px !important;
  }
  .min-h-xl-300px {
    min-height: 300px !important;
  }
  .max-h-xl-300px {
    max-height: 300px !important;
  }
  .max-w-xl-300px {
    max-width: 300px !important;
  }
  .w-xl-325px {
    width: 325px !important;
  }
  .h-xl-325px {
    height: 325px !important;
  }
  .min-w-xl-325px {
    min-width: 325px !important;
  }
  .min-h-xl-325px {
    min-height: 325px !important;
  }
  .max-h-xl-325px {
    max-height: 325px !important;
  }
  .max-w-xl-325px {
    max-width: 325px !important;
  }
  .w-xl-350px {
    width: 350px !important;
  }
  .h-xl-350px {
    height: 350px !important;
  }
  .min-w-xl-350px {
    min-width: 350px !important;
  }
  .min-h-xl-350px {
    min-height: 350px !important;
  }
  .max-h-xl-350px {
    max-height: 350px !important;
  }
  .max-w-xl-350px {
    max-width: 350px !important;
  }
  .w-xl-375px {
    width: 375px !important;
  }
  .h-xl-375px {
    height: 375px !important;
  }
  .min-w-xl-375px {
    min-width: 375px !important;
  }
  .min-h-xl-375px {
    min-height: 375px !important;
  }
  .max-h-xl-375px {
    max-height: 375px !important;
  }
  .max-w-xl-375px {
    max-width: 375px !important;
  }
  .w-xl-400px {
    width: 400px !important;
  }
  .h-xl-400px {
    height: 400px !important;
  }
  .min-w-xl-400px {
    min-width: 400px !important;
  }
  .min-h-xl-400px {
    min-height: 400px !important;
  }
  .max-h-xl-400px {
    max-height: 400px !important;
  }
  .max-w-xl-400px {
    max-width: 400px !important;
  }
  .w-xl-425px {
    width: 425px !important;
  }
  .h-xl-425px {
    height: 425px !important;
  }
  .min-w-xl-425px {
    min-width: 425px !important;
  }
  .min-h-xl-425px {
    min-height: 425px !important;
  }
  .max-h-xl-425px {
    max-height: 425px !important;
  }
  .max-w-xl-425px {
    max-width: 425px !important;
  }
  .w-xl-450px {
    width: 450px !important;
  }
  .h-xl-450px {
    height: 450px !important;
  }
  .min-w-xl-450px {
    min-width: 450px !important;
  }
  .min-h-xl-450px {
    min-height: 450px !important;
  }
  .max-h-xl-450px {
    max-height: 450px !important;
  }
  .max-w-xl-450px {
    max-width: 450px !important;
  }
  .w-xl-475px {
    width: 475px !important;
  }
  .h-xl-475px {
    height: 475px !important;
  }
  .min-w-xl-475px {
    min-width: 475px !important;
  }
  .min-h-xl-475px {
    min-height: 475px !important;
  }
  .max-h-xl-475px {
    max-height: 475px !important;
  }
  .max-w-xl-475px {
    max-width: 475px !important;
  }
  .w-xl-500px {
    width: 500px !important;
  }
  .h-xl-500px {
    height: 500px !important;
  }
  .min-w-xl-500px {
    min-width: 500px !important;
  }
  .min-h-xl-500px {
    min-height: 500px !important;
  }
  .max-h-xl-500px {
    max-height: 500px !important;
  }
  .max-w-xl-500px {
    max-width: 500px !important;
  }
  .w-xl-550px {
    width: 550px !important;
  }
  .h-xl-550px {
    height: 550px !important;
  }
  .min-w-xl-550px {
    min-width: 550px !important;
  }
  .min-h-xl-550px {
    min-height: 550px !important;
  }
  .max-h-xl-550px {
    max-height: 550px !important;
  }
  .max-w-xl-550px {
    max-width: 550px !important;
  }
  .w-xl-600px {
    width: 600px !important;
  }
  .h-xl-600px {
    height: 600px !important;
  }
  .min-w-xl-600px {
    min-width: 600px !important;
  }
  .min-h-xl-600px {
    min-height: 600px !important;
  }
  .max-h-xl-600px {
    max-height: 600px !important;
  }
  .max-w-xl-600px {
    max-width: 600px !important;
  }
  .w-xl-650px {
    width: 650px !important;
  }
  .h-xl-650px {
    height: 650px !important;
  }
  .min-w-xl-650px {
    min-width: 650px !important;
  }
  .min-h-xl-650px {
    min-height: 650px !important;
  }
  .max-h-xl-650px {
    max-height: 650px !important;
  }
  .max-w-xl-650px {
    max-width: 650px !important;
  }
  .w-xl-700px {
    width: 700px !important;
  }
  .h-xl-700px {
    height: 700px !important;
  }
  .min-w-xl-700px {
    min-width: 700px !important;
  }
  .min-h-xl-700px {
    min-height: 700px !important;
  }
  .max-h-xl-700px {
    max-height: 700px !important;
  }
  .max-w-xl-700px {
    max-width: 700px !important;
  }
  .w-xl-750px {
    width: 750px !important;
  }
  .h-xl-750px {
    height: 750px !important;
  }
  .min-w-xl-750px {
    min-width: 750px !important;
  }
  .min-h-xl-750px {
    min-height: 750px !important;
  }
  .max-h-xl-750px {
    max-height: 750px !important;
  }
  .max-w-xl-750px {
    max-width: 750px !important;
  }
  .w-xl-800px {
    width: 800px !important;
  }
  .h-xl-800px {
    height: 800px !important;
  }
  .min-w-xl-800px {
    min-width: 800px !important;
  }
  .min-h-xl-800px {
    min-height: 800px !important;
  }
  .max-h-xl-800px {
    max-height: 800px !important;
  }
  .max-w-xl-800px {
    max-width: 800px !important;
  }
  .w-xl-850px {
    width: 850px !important;
  }
  .h-xl-850px {
    height: 850px !important;
  }
  .min-w-xl-850px {
    min-width: 850px !important;
  }
  .min-h-xl-850px {
    min-height: 850px !important;
  }
  .max-h-xl-850px {
    max-height: 850px !important;
  }
  .max-w-xl-850px {
    max-width: 850px !important;
  }
  .w-xl-900px {
    width: 900px !important;
  }
  .h-xl-900px {
    height: 900px !important;
  }
  .min-w-xl-900px {
    min-width: 900px !important;
  }
  .min-h-xl-900px {
    min-height: 900px !important;
  }
  .max-h-xl-900px {
    max-height: 900px !important;
  }
  .max-w-xl-900px {
    max-width: 900px !important;
  }
  .w-xl-950px {
    width: 950px !important;
  }
  .h-xl-950px {
    height: 950px !important;
  }
  .min-w-xl-950px {
    min-width: 950px !important;
  }
  .min-h-xl-950px {
    min-height: 950px !important;
  }
  .max-h-xl-950px {
    max-height: 950px !important;
  }
  .max-w-xl-950px {
    max-width: 950px !important;
  }
  .w-xl-1000px {
    width: 1000px !important;
  }
  .h-xl-1000px {
    height: 1000px !important;
  }
  .min-w-xl-1000px {
    min-width: 1000px !important;
  }
  .min-h-xl-1000px {
    min-height: 1000px !important;
  }
  .max-h-xl-1000px {
    max-height: 1000px !important;
  }
  .max-w-xl-1000px {
    max-width: 1000px !important;
  }
}

@media (min-width: 1400px) {
  .w-xxl-100 {
    width: 100% !important;
  }
  .h-xxl-100 {
    height: 100% !important;
  }
  .min-w-xxl-100 {
    min-width: 100% !important;
  }
  .min-h-xxl-100 {
    min-height: 100% !important;
  }
  .max-h-xxl-100 {
    max-height: 100% !important;
  }
  .max-w-xxl-100 {
    max-width: 100% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .h-xxl-50 {
    height: 50% !important;
  }
  .min-w-xxl-50 {
    min-width: 50% !important;
  }
  .min-h-xxl-50 {
    min-height: 50% !important;
  }
  .max-h-xxl-50 {
    max-height: 50% !important;
  }
  .max-w-xxl-50 {
    max-width: 50% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .min-w-xxl-auto {
    min-width: auto !important;
  }
  .min-h-xxl-auto {
    min-height: auto !important;
  }
  .max-h-xxl-auto {
    max-height: auto !important;
  }
  .max-w-xxl-auto {
    max-width: auto !important;
  }
  .w-xxl-1px {
    width: 1px !important;
  }
  .h-xxl-1px {
    height: 1px !important;
  }
  .min-w-xxl-1px {
    min-width: 1px !important;
  }
  .min-h-xxl-1px {
    min-height: 1px !important;
  }
  .max-h-xxl-1px {
    max-height: 1px !important;
  }
  .max-w-xxl-1px {
    max-width: 1px !important;
  }
  .w-xxl-2px {
    width: 2px !important;
  }
  .h-xxl-2px {
    height: 2px !important;
  }
  .min-w-xxl-2px {
    min-width: 2px !important;
  }
  .min-h-xxl-2px {
    min-height: 2px !important;
  }
  .max-h-xxl-2px {
    max-height: 2px !important;
  }
  .max-w-xxl-2px {
    max-width: 2px !important;
  }
  .w-xxl-3px {
    width: 3px !important;
  }
  .h-xxl-3px {
    height: 3px !important;
  }
  .min-w-xxl-3px {
    min-width: 3px !important;
  }
  .min-h-xxl-3px {
    min-height: 3px !important;
  }
  .max-h-xxl-3px {
    max-height: 3px !important;
  }
  .max-w-xxl-3px {
    max-width: 3px !important;
  }
  .w-xxl-4px {
    width: 4px !important;
  }
  .h-xxl-4px {
    height: 4px !important;
  }
  .min-w-xxl-4px {
    min-width: 4px !important;
  }
  .min-h-xxl-4px {
    min-height: 4px !important;
  }
  .max-h-xxl-4px {
    max-height: 4px !important;
  }
  .max-w-xxl-4px {
    max-width: 4px !important;
  }
  .w-xxl-5px {
    width: 5px !important;
  }
  .h-xxl-5px {
    height: 5px !important;
  }
  .min-w-xxl-5px {
    min-width: 5px !important;
  }
  .min-h-xxl-5px {
    min-height: 5px !important;
  }
  .max-h-xxl-5px {
    max-height: 5px !important;
  }
  .max-w-xxl-5px {
    max-width: 5px !important;
  }
  .w-xxl-10px {
    width: 10px !important;
  }
  .h-xxl-10px {
    height: 10px !important;
  }
  .min-w-xxl-10px {
    min-width: 10px !important;
  }
  .min-h-xxl-10px {
    min-height: 10px !important;
  }
  .max-h-xxl-10px {
    max-height: 10px !important;
  }
  .max-w-xxl-10px {
    max-width: 10px !important;
  }
  .w-xxl-15px {
    width: 15px !important;
  }
  .h-xxl-15px {
    height: 15px !important;
  }
  .min-w-xxl-15px {
    min-width: 15px !important;
  }
  .min-h-xxl-15px {
    min-height: 15px !important;
  }
  .max-h-xxl-15px {
    max-height: 15px !important;
  }
  .max-w-xxl-15px {
    max-width: 15px !important;
  }
  .w-xxl-20px {
    width: 20px !important;
  }
  .h-xxl-20px {
    height: 20px !important;
  }
  .min-w-xxl-20px {
    min-width: 20px !important;
  }
  .min-h-xxl-20px {
    min-height: 20px !important;
  }
  .max-h-xxl-20px {
    max-height: 20px !important;
  }
  .max-w-xxl-20px {
    max-width: 20px !important;
  }
  .w-xxl-25px {
    width: 25px !important;
  }
  .h-xxl-25px {
    height: 25px !important;
  }
  .min-w-xxl-25px {
    min-width: 25px !important;
  }
  .min-h-xxl-25px {
    min-height: 25px !important;
  }
  .max-h-xxl-25px {
    max-height: 25px !important;
  }
  .max-w-xxl-25px {
    max-width: 25px !important;
  }
  .w-xxl-30px {
    width: 30px !important;
  }
  .h-xxl-30px {
    height: 30px !important;
  }
  .min-w-xxl-30px {
    min-width: 30px !important;
  }
  .min-h-xxl-30px {
    min-height: 30px !important;
  }
  .max-h-xxl-30px {
    max-height: 30px !important;
  }
  .max-w-xxl-30px {
    max-width: 30px !important;
  }
  .w-xxl-35px {
    width: 35px !important;
  }
  .h-xxl-35px {
    height: 35px !important;
  }
  .min-w-xxl-35px {
    min-width: 35px !important;
  }
  .min-h-xxl-35px {
    min-height: 35px !important;
  }
  .max-h-xxl-35px {
    max-height: 35px !important;
  }
  .max-w-xxl-35px {
    max-width: 35px !important;
  }
  .w-xxl-40px {
    width: 40px !important;
  }
  .h-xxl-40px {
    height: 40px !important;
  }
  .min-w-xxl-40px {
    min-width: 40px !important;
  }
  .min-h-xxl-40px {
    min-height: 40px !important;
  }
  .max-h-xxl-40px {
    max-height: 40px !important;
  }
  .max-w-xxl-40px {
    max-width: 40px !important;
  }
  .w-xxl-45px {
    width: 45px !important;
  }
  .h-xxl-45px {
    height: 45px !important;
  }
  .min-w-xxl-45px {
    min-width: 45px !important;
  }
  .min-h-xxl-45px {
    min-height: 45px !important;
  }
  .max-h-xxl-45px {
    max-height: 45px !important;
  }
  .max-w-xxl-45px {
    max-width: 45px !important;
  }
  .w-xxl-50px {
    width: 50px !important;
  }
  .h-xxl-50px {
    height: 50px !important;
  }
  .min-w-xxl-50px {
    min-width: 50px !important;
  }
  .min-h-xxl-50px {
    min-height: 50px !important;
  }
  .max-h-xxl-50px {
    max-height: 50px !important;
  }
  .max-w-xxl-50px {
    max-width: 50px !important;
  }
  .w-xxl-55px {
    width: 55px !important;
  }
  .h-xxl-55px {
    height: 55px !important;
  }
  .min-w-xxl-55px {
    min-width: 55px !important;
  }
  .min-h-xxl-55px {
    min-height: 55px !important;
  }
  .max-h-xxl-55px {
    max-height: 55px !important;
  }
  .max-w-xxl-55px {
    max-width: 55px !important;
  }
  .w-xxl-60px {
    width: 60px !important;
  }
  .h-xxl-60px {
    height: 60px !important;
  }
  .min-w-xxl-60px {
    min-width: 60px !important;
  }
  .min-h-xxl-60px {
    min-height: 60px !important;
  }
  .max-h-xxl-60px {
    max-height: 60px !important;
  }
  .max-w-xxl-60px {
    max-width: 60px !important;
  }
  .w-xxl-65px {
    width: 65px !important;
  }
  .h-xxl-65px {
    height: 65px !important;
  }
  .min-w-xxl-65px {
    min-width: 65px !important;
  }
  .min-h-xxl-65px {
    min-height: 65px !important;
  }
  .max-h-xxl-65px {
    max-height: 65px !important;
  }
  .max-w-xxl-65px {
    max-width: 65px !important;
  }
  .w-xxl-70px {
    width: 70px !important;
  }
  .h-xxl-70px {
    height: 70px !important;
  }
  .min-w-xxl-70px {
    min-width: 70px !important;
  }
  .min-h-xxl-70px {
    min-height: 70px !important;
  }
  .max-h-xxl-70px {
    max-height: 70px !important;
  }
  .max-w-xxl-70px {
    max-width: 70px !important;
  }
  .w-xxl-75px {
    width: 75px !important;
  }
  .h-xxl-75px {
    height: 75px !important;
  }
  .min-w-xxl-75px {
    min-width: 75px !important;
  }
  .min-h-xxl-75px {
    min-height: 75px !important;
  }
  .max-h-xxl-75px {
    max-height: 75px !important;
  }
  .max-w-xxl-75px {
    max-width: 75px !important;
  }
  .w-xxl-80px {
    width: 80px !important;
  }
  .h-xxl-80px {
    height: 80px !important;
  }
  .min-w-xxl-80px {
    min-width: 80px !important;
  }
  .min-h-xxl-80px {
    min-height: 80px !important;
  }
  .max-h-xxl-80px {
    max-height: 80px !important;
  }
  .max-w-xxl-80px {
    max-width: 80px !important;
  }
  .w-xxl-85px {
    width: 85px !important;
  }
  .h-xxl-85px {
    height: 85px !important;
  }
  .min-w-xxl-85px {
    min-width: 85px !important;
  }
  .min-h-xxl-85px {
    min-height: 85px !important;
  }
  .max-h-xxl-85px {
    max-height: 85px !important;
  }
  .max-w-xxl-85px {
    max-width: 85px !important;
  }
  .w-xxl-90px {
    width: 90px !important;
  }
  .h-xxl-90px {
    height: 90px !important;
  }
  .min-w-xxl-90px {
    min-width: 90px !important;
  }
  .min-h-xxl-90px {
    min-height: 90px !important;
  }
  .max-h-xxl-90px {
    max-height: 90px !important;
  }
  .max-w-xxl-90px {
    max-width: 90px !important;
  }
  .w-xxl-95px {
    width: 95px !important;
  }
  .h-xxl-95px {
    height: 95px !important;
  }
  .min-w-xxl-95px {
    min-width: 95px !important;
  }
  .min-h-xxl-95px {
    min-height: 95px !important;
  }
  .max-h-xxl-95px {
    max-height: 95px !important;
  }
  .max-w-xxl-95px {
    max-width: 95px !important;
  }
  .w-xxl-100px {
    width: 100px !important;
  }
  .h-xxl-100px {
    height: 100px !important;
  }
  .min-w-xxl-100px {
    min-width: 100px !important;
  }
  .min-h-xxl-100px {
    min-height: 100px !important;
  }
  .max-h-xxl-100px {
    max-height: 100px !important;
  }
  .max-w-xxl-100px {
    max-width: 100px !important;
  }
  .w-xxl-105px {
    width: 105px !important;
  }
  .h-xxl-105px {
    height: 105px !important;
  }
  .min-w-xxl-105px {
    min-width: 105px !important;
  }
  .min-h-xxl-105px {
    min-height: 105px !important;
  }
  .max-h-xxl-105px {
    max-height: 105px !important;
  }
  .max-w-xxl-105px {
    max-width: 105px !important;
  }
  .w-xxl-110px {
    width: 110px !important;
  }
  .h-xxl-110px {
    height: 110px !important;
  }
  .min-w-xxl-110px {
    min-width: 110px !important;
  }
  .min-h-xxl-110px {
    min-height: 110px !important;
  }
  .max-h-xxl-110px {
    max-height: 110px !important;
  }
  .max-w-xxl-110px {
    max-width: 110px !important;
  }
  .w-xxl-115px {
    width: 115px !important;
  }
  .h-xxl-115px {
    height: 115px !important;
  }
  .min-w-xxl-115px {
    min-width: 115px !important;
  }
  .min-h-xxl-115px {
    min-height: 115px !important;
  }
  .max-h-xxl-115px {
    max-height: 115px !important;
  }
  .max-w-xxl-115px {
    max-width: 115px !important;
  }
  .w-xxl-120px {
    width: 120px !important;
  }
  .h-xxl-120px {
    height: 120px !important;
  }
  .min-w-xxl-120px {
    min-width: 120px !important;
  }
  .min-h-xxl-120px {
    min-height: 120px !important;
  }
  .max-h-xxl-120px {
    max-height: 120px !important;
  }
  .max-w-xxl-120px {
    max-width: 120px !important;
  }
  .w-xxl-125px {
    width: 125px !important;
  }
  .h-xxl-125px {
    height: 125px !important;
  }
  .min-w-xxl-125px {
    min-width: 125px !important;
  }
  .min-h-xxl-125px {
    min-height: 125px !important;
  }
  .max-h-xxl-125px {
    max-height: 125px !important;
  }
  .max-w-xxl-125px {
    max-width: 125px !important;
  }
  .w-xxl-130px {
    width: 130px !important;
  }
  .h-xxl-130px {
    height: 130px !important;
  }
  .min-w-xxl-130px {
    min-width: 130px !important;
  }
  .min-h-xxl-130px {
    min-height: 130px !important;
  }
  .max-h-xxl-130px {
    max-height: 130px !important;
  }
  .max-w-xxl-130px {
    max-width: 130px !important;
  }
  .w-xxl-135px {
    width: 135px !important;
  }
  .h-xxl-135px {
    height: 135px !important;
  }
  .min-w-xxl-135px {
    min-width: 135px !important;
  }
  .min-h-xxl-135px {
    min-height: 135px !important;
  }
  .max-h-xxl-135px {
    max-height: 135px !important;
  }
  .max-w-xxl-135px {
    max-width: 135px !important;
  }
  .w-xxl-140px {
    width: 140px !important;
  }
  .h-xxl-140px {
    height: 140px !important;
  }
  .min-w-xxl-140px {
    min-width: 140px !important;
  }
  .min-h-xxl-140px {
    min-height: 140px !important;
  }
  .max-h-xxl-140px {
    max-height: 140px !important;
  }
  .max-w-xxl-140px {
    max-width: 140px !important;
  }
  .w-xxl-145px {
    width: 145px !important;
  }
  .h-xxl-145px {
    height: 145px !important;
  }
  .min-w-xxl-145px {
    min-width: 145px !important;
  }
  .min-h-xxl-145px {
    min-height: 145px !important;
  }
  .max-h-xxl-145px {
    max-height: 145px !important;
  }
  .max-w-xxl-145px {
    max-width: 145px !important;
  }
  .w-xxl-150px {
    width: 150px !important;
  }
  .h-xxl-150px {
    height: 150px !important;
  }
  .min-w-xxl-150px {
    min-width: 150px !important;
  }
  .min-h-xxl-150px {
    min-height: 150px !important;
  }
  .max-h-xxl-150px {
    max-height: 150px !important;
  }
  .max-w-xxl-150px {
    max-width: 150px !important;
  }
  .w-xxl-175px {
    width: 175px !important;
  }
  .h-xxl-175px {
    height: 175px !important;
  }
  .min-w-xxl-175px {
    min-width: 175px !important;
  }
  .min-h-xxl-175px {
    min-height: 175px !important;
  }
  .max-h-xxl-175px {
    max-height: 175px !important;
  }
  .max-w-xxl-175px {
    max-width: 175px !important;
  }
  .w-xxl-180px {
    width: 180px !important;
  }
  .h-xxl-180px {
    height: 180px !important;
  }
  .min-w-xxl-180px {
    min-width: 180px !important;
  }
  .min-h-xxl-180px {
    min-height: 180px !important;
  }
  .max-h-xxl-180px {
    max-height: 180px !important;
  }
  .max-w-xxl-180px {
    max-width: 180px !important;
  }
  .w-xxl-200px {
    width: 200px !important;
  }
  .h-xxl-200px {
    height: 200px !important;
  }
  .min-w-xxl-200px {
    min-width: 200px !important;
  }
  .min-h-xxl-200px {
    min-height: 200px !important;
  }
  .max-h-xxl-200px {
    max-height: 200px !important;
  }
  .max-w-xxl-200px {
    max-width: 200px !important;
  }
  .w-xxl-225px {
    width: 225px !important;
  }
  .h-xxl-225px {
    height: 225px !important;
  }
  .min-w-xxl-225px {
    min-width: 225px !important;
  }
  .min-h-xxl-225px {
    min-height: 225px !important;
  }
  .max-h-xxl-225px {
    max-height: 225px !important;
  }
  .max-w-xxl-225px {
    max-width: 225px !important;
  }
  .w-xxl-250px {
    width: 250px !important;
  }
  .h-xxl-250px {
    height: 250px !important;
  }
  .min-w-xxl-250px {
    min-width: 250px !important;
  }
  .min-h-xxl-250px {
    min-height: 250px !important;
  }
  .max-h-xxl-250px {
    max-height: 250px !important;
  }
  .max-w-xxl-250px {
    max-width: 250px !important;
  }
  .w-xxl-255px {
    width: 255px !important;
  }
  .h-xxl-255px {
    height: 255px !important;
  }
  .min-w-xxl-255px {
    min-width: 255px !important;
  }
  .min-h-xxl-255px {
    min-height: 255px !important;
  }
  .max-h-xxl-255px {
    max-height: 255px !important;
  }
  .max-w-xxl-255px {
    max-width: 255px !important;
  }
  .w-xxl-260px {
    width: 260px !important;
  }
  .h-xxl-260px {
    height: 260px !important;
  }
  .min-w-xxl-260px {
    min-width: 260px !important;
  }
  .min-h-xxl-260px {
    min-height: 260px !important;
  }
  .max-h-xxl-260px {
    max-height: 260px !important;
  }
  .max-w-xxl-260px {
    max-width: 260px !important;
  }
  .w-xxl-265px {
    width: 265px !important;
  }
  .h-xxl-265px {
    height: 265px !important;
  }
  .min-w-xxl-265px {
    min-width: 265px !important;
  }
  .min-h-xxl-265px {
    min-height: 265px !important;
  }
  .max-h-xxl-265px {
    max-height: 265px !important;
  }
  .max-w-xxl-265px {
    max-width: 265px !important;
  }
  .w-xxl-275px {
    width: 275px !important;
  }
  .h-xxl-275px {
    height: 275px !important;
  }
  .min-w-xxl-275px {
    min-width: 275px !important;
  }
  .min-h-xxl-275px {
    min-height: 275px !important;
  }
  .max-h-xxl-275px {
    max-height: 275px !important;
  }
  .max-w-xxl-275px {
    max-width: 275px !important;
  }
  .w-xxl-295px {
    width: 295px !important;
  }
  .h-xxl-295px {
    height: 295px !important;
  }
  .min-w-xxl-295px {
    min-width: 295px !important;
  }
  .min-h-xxl-295px {
    min-height: 295px !important;
  }
  .max-h-xxl-295px {
    max-height: 295px !important;
  }
  .max-w-xxl-295px {
    max-width: 295px !important;
  }
  .w-xxl-300px {
    width: 300px !important;
  }
  .h-xxl-300px {
    height: 300px !important;
  }
  .min-w-xxl-300px {
    min-width: 300px !important;
  }
  .min-h-xxl-300px {
    min-height: 300px !important;
  }
  .max-h-xxl-300px {
    max-height: 300px !important;
  }
  .max-w-xxl-300px {
    max-width: 300px !important;
  }
  .w-xxl-325px {
    width: 325px !important;
  }
  .h-xxl-325px {
    height: 325px !important;
  }
  .min-w-xxl-325px {
    min-width: 325px !important;
  }
  .min-h-xxl-325px {
    min-height: 325px !important;
  }
  .max-h-xxl-325px {
    max-height: 325px !important;
  }
  .max-w-xxl-325px {
    max-width: 325px !important;
  }
  .w-xxl-350px {
    width: 350px !important;
  }
  .h-xxl-350px {
    height: 350px !important;
  }
  .min-w-xxl-350px {
    min-width: 350px !important;
  }
  .min-h-xxl-350px {
    min-height: 350px !important;
  }
  .max-h-xxl-350px {
    max-height: 350px !important;
  }
  .max-w-xxl-350px {
    max-width: 350px !important;
  }
  .w-xxl-375px {
    width: 375px !important;
  }
  .h-xxl-375px {
    height: 375px !important;
  }
  .min-w-xxl-375px {
    min-width: 375px !important;
  }
  .min-h-xxl-375px {
    min-height: 375px !important;
  }
  .max-h-xxl-375px {
    max-height: 375px !important;
  }
  .max-w-xxl-375px {
    max-width: 375px !important;
  }
  .w-xxl-400px {
    width: 400px !important;
  }
  .h-xxl-400px {
    height: 400px !important;
  }
  .min-w-xxl-400px {
    min-width: 400px !important;
  }
  .min-h-xxl-400px {
    min-height: 400px !important;
  }
  .max-h-xxl-400px {
    max-height: 400px !important;
  }
  .max-w-xxl-400px {
    max-width: 400px !important;
  }
  .w-xxl-425px {
    width: 425px !important;
  }
  .h-xxl-425px {
    height: 425px !important;
  }
  .min-w-xxl-425px {
    min-width: 425px !important;
  }
  .min-h-xxl-425px {
    min-height: 425px !important;
  }
  .max-h-xxl-425px {
    max-height: 425px !important;
  }
  .max-w-xxl-425px {
    max-width: 425px !important;
  }
  .w-xxl-450px {
    width: 450px !important;
  }
  .h-xxl-450px {
    height: 450px !important;
  }
  .min-w-xxl-450px {
    min-width: 450px !important;
  }
  .min-h-xxl-450px {
    min-height: 450px !important;
  }
  .max-h-xxl-450px {
    max-height: 450px !important;
  }
  .max-w-xxl-450px {
    max-width: 450px !important;
  }
  .w-xxl-475px {
    width: 475px !important;
  }
  .h-xxl-475px {
    height: 475px !important;
  }
  .min-w-xxl-475px {
    min-width: 475px !important;
  }
  .min-h-xxl-475px {
    min-height: 475px !important;
  }
  .max-h-xxl-475px {
    max-height: 475px !important;
  }
  .max-w-xxl-475px {
    max-width: 475px !important;
  }
  .w-xxl-500px {
    width: 500px !important;
  }
  .h-xxl-500px {
    height: 500px !important;
  }
  .min-w-xxl-500px {
    min-width: 500px !important;
  }
  .min-h-xxl-500px {
    min-height: 500px !important;
  }
  .max-h-xxl-500px {
    max-height: 500px !important;
  }
  .max-w-xxl-500px {
    max-width: 500px !important;
  }
  .w-xxl-550px {
    width: 550px !important;
  }
  .h-xxl-550px {
    height: 550px !important;
  }
  .min-w-xxl-550px {
    min-width: 550px !important;
  }
  .min-h-xxl-550px {
    min-height: 550px !important;
  }
  .max-h-xxl-550px {
    max-height: 550px !important;
  }
  .max-w-xxl-550px {
    max-width: 550px !important;
  }
  .w-xxl-600px {
    width: 600px !important;
  }
  .h-xxl-600px {
    height: 600px !important;
  }
  .min-w-xxl-600px {
    min-width: 600px !important;
  }
  .min-h-xxl-600px {
    min-height: 600px !important;
  }
  .max-h-xxl-600px {
    max-height: 600px !important;
  }
  .max-w-xxl-600px {
    max-width: 600px !important;
  }
  .w-xxl-650px {
    width: 650px !important;
  }
  .h-xxl-650px {
    height: 650px !important;
  }
  .min-w-xxl-650px {
    min-width: 650px !important;
  }
  .min-h-xxl-650px {
    min-height: 650px !important;
  }
  .max-h-xxl-650px {
    max-height: 650px !important;
  }
  .max-w-xxl-650px {
    max-width: 650px !important;
  }
  .w-xxl-700px {
    width: 700px !important;
  }
  .h-xxl-700px {
    height: 700px !important;
  }
  .min-w-xxl-700px {
    min-width: 700px !important;
  }
  .min-h-xxl-700px {
    min-height: 700px !important;
  }
  .max-h-xxl-700px {
    max-height: 700px !important;
  }
  .max-w-xxl-700px {
    max-width: 700px !important;
  }
  .w-xxl-750px {
    width: 750px !important;
  }
  .h-xxl-750px {
    height: 750px !important;
  }
  .min-w-xxl-750px {
    min-width: 750px !important;
  }
  .min-h-xxl-750px {
    min-height: 750px !important;
  }
  .max-h-xxl-750px {
    max-height: 750px !important;
  }
  .max-w-xxl-750px {
    max-width: 750px !important;
  }
  .w-xxl-800px {
    width: 800px !important;
  }
  .h-xxl-800px {
    height: 800px !important;
  }
  .min-w-xxl-800px {
    min-width: 800px !important;
  }
  .min-h-xxl-800px {
    min-height: 800px !important;
  }
  .max-h-xxl-800px {
    max-height: 800px !important;
  }
  .max-w-xxl-800px {
    max-width: 800px !important;
  }
  .w-xxl-850px {
    width: 850px !important;
  }
  .h-xxl-850px {
    height: 850px !important;
  }
  .min-w-xxl-850px {
    min-width: 850px !important;
  }
  .min-h-xxl-850px {
    min-height: 850px !important;
  }
  .max-h-xxl-850px {
    max-height: 850px !important;
  }
  .max-w-xxl-850px {
    max-width: 850px !important;
  }
  .w-xxl-900px {
    width: 900px !important;
  }
  .h-xxl-900px {
    height: 900px !important;
  }
  .min-w-xxl-900px {
    min-width: 900px !important;
  }
  .min-h-xxl-900px {
    min-height: 900px !important;
  }
  .max-h-xxl-900px {
    max-height: 900px !important;
  }
  .max-w-xxl-900px {
    max-width: 900px !important;
  }
  .w-xxl-950px {
    width: 950px !important;
  }
  .h-xxl-950px {
    height: 950px !important;
  }
  .min-w-xxl-950px {
    min-width: 950px !important;
  }
  .min-h-xxl-950px {
    min-height: 950px !important;
  }
  .max-h-xxl-950px {
    max-height: 950px !important;
  }
  .max-w-xxl-950px {
    max-width: 950px !important;
  }
  .w-xxl-1000px {
    width: 1000px !important;
  }
  .h-xxl-1000px {
    height: 1000px !important;
  }
  .min-w-xxl-1000px {
    min-width: 1000px !important;
  }
  .min-h-xxl-1000px {
    min-height: 1000px !important;
  }
  .max-h-xxl-1000px {
    max-height: 1000px !important;
  }
  .max-w-xxl-1000px {
    max-width: 1000px !important;
  }
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-n-20 {
  margin-top: -20px !important;
}

.mt-n-30 {
  margin-top: -30px !important;
}

.mt-n-45 {
  margin-top: -45px !important;
}

.mt--5 {
  margin-top: -5px !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1, .my-1 {
  margin-top: 0.25rem !important;
}

.mr-1, .mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1, .mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2, .my-2 {
  margin-top: 0.5rem !important;
}

.mr-2, .mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2, .mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.mt-3, .my-3 {
  margin-top: 0.75rem !important;
}

.mr-3, .mx-3 {
  margin-right: 0.75rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3, .mx-3 {
  margin-left: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4, .my-4 {
  margin-top: 1rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.mt-5, .my-5 {
  margin-top: 1.25rem !important;
}

.mr-5, .mx-5 {
  margin-right: 1.25rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 1.25rem !important;
}

.ml-5, .mx-5 {
  margin-left: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.mt-6, .my-6 {
  margin-top: 1.5rem !important;
}

.mr-6, .mx-6 {
  margin-right: 1.5rem !important;
}

.mb-6, .my-6 {
  margin-bottom: 1.5rem !important;
}

.ml-6, .mx-6 {
  margin-left: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.mt-7, .my-7 {
  margin-top: 1.75rem !important;
}

.mr-7, .mx-7 {
  margin-right: 1.75rem !important;
}

.mb-7, .my-7 {
  margin-bottom: 1.75rem !important;
}

.ml-7, .mx-7 {
  margin-left: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.mt-8, .my-8 {
  margin-top: 2rem !important;
}

.mr-8, .mx-8 {
  margin-right: 2rem !important;
}

.mb-8, .my-8 {
  margin-bottom: 2rem !important;
}

.ml-8, .mx-8 {
  margin-left: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.mt-9, .my-9 {
  margin-top: 2.25rem !important;
}

.mr-9, .mx-9 {
  margin-right: 2.25rem !important;
}

.mb-9, .my-9 {
  margin-bottom: 2.25rem !important;
}

.ml-9, .mx-9 {
  margin-left: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.mt-10, .my-10 {
  margin-top: 2.5rem !important;
}

.mr-10, .mx-10 {
  margin-right: 2.5rem !important;
}

.mb-10, .my-10 {
  margin-bottom: 2.5rem !important;
}

.ml-10, .mx-10 {
  margin-left: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.mt-11, .my-11 {
  margin-top: 2.75rem !important;
}

.mr-11, .mx-11 {
  margin-right: 2.75rem !important;
}

.mb-11, .my-11 {
  margin-bottom: 2.75rem !important;
}

.ml-11, .mx-11 {
  margin-left: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.mt-12, .my-12 {
  margin-top: 3rem !important;
}

.mr-12, .mx-12 {
  margin-right: 3rem !important;
}

.mb-12, .my-12 {
  margin-bottom: 3rem !important;
}

.ml-12, .mx-12 {
  margin-left: 3rem !important;
}

.m-13 {
  margin: 3.25rem !important;
}

.mt-13, .my-13 {
  margin-top: 3.25rem !important;
}

.mr-13, .mx-13 {
  margin-right: 3.25rem !important;
}

.mb-13, .my-13 {
  margin-bottom: 3.25rem !important;
}

.ml-13, .mx-13 {
  margin-left: 3.25rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.mt-14, .my-14 {
  margin-top: 3.5rem !important;
}

.mr-14, .mx-14 {
  margin-right: 3.5rem !important;
}

.mb-14, .my-14 {
  margin-bottom: 3.5rem !important;
}

.ml-14, .mx-14 {
  margin-left: 3.5rem !important;
}

.m-15 {
  margin: 3.75rem !important;
}

.mt-15, .my-15 {
  margin-top: 3.75rem !important;
}

.mr-15, .mx-15 {
  margin-right: 3.75rem !important;
}

.mb-15, .my-15 {
  margin-bottom: 3.75rem !important;
}

.ml-15, .mx-15 {
  margin-left: 3.75rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.mt-16, .my-16 {
  margin-top: 4rem !important;
}

.mr-16, .mx-16 {
  margin-right: 4rem !important;
}

.mb-16, .my-16 {
  margin-bottom: 4rem !important;
}

.ml-16, .mx-16 {
  margin-left: 4rem !important;
}

.m-17 {
  margin: 4.25rem !important;
}

.mt-17, .my-17 {
  margin-top: 4.25rem !important;
}

.mr-17, .mx-17 {
  margin-right: 4.25rem !important;
}

.mb-17, .my-17 {
  margin-bottom: 4.25rem !important;
}

.ml-17, .mx-17 {
  margin-left: 4.25rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.mt-18, .my-18 {
  margin-top: 4.5rem !important;
}

.mr-18, .mx-18 {
  margin-right: 4.5rem !important;
}

.mb-18, .my-18 {
  margin-bottom: 4.5rem !important;
}

.ml-18, .mx-18 {
  margin-left: 4.5rem !important;
}

.m-19 {
  margin: 4.75rem !important;
}

.mt-19, .my-19 {
  margin-top: 4.75rem !important;
}

.mr-19, .mx-19 {
  margin-right: 4.75rem !important;
}

.mb-19, .my-19 {
  margin-bottom: 4.75rem !important;
}

.ml-19, .mx-19 {
  margin-left: 4.75rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.mt-20, .my-20 {
  margin-top: 5rem !important;
}

.mr-20, .mx-20 {
  margin-right: 5rem !important;
}

.mb-20, .my-20 {
  margin-bottom: 5rem !important;
}

.ml-20, .mx-20 {
  margin-left: 5rem !important;
}

.m-21 {
  margin: 5.25rem !important;
}

.mt-21, .my-21 {
  margin-top: 5.25rem !important;
}

.mr-21, .mx-21 {
  margin-right: 5.25rem !important;
}

.mb-21, .my-21 {
  margin-bottom: 5.25rem !important;
}

.ml-21, .mx-21 {
  margin-left: 5.25rem !important;
}

.m-22 {
  margin: 5.5rem !important;
}

.mt-22, .my-22 {
  margin-top: 5.5rem !important;
}

.mr-22, .mx-22 {
  margin-right: 5.5rem !important;
}

.mb-22, .my-22 {
  margin-bottom: 5.5rem !important;
}

.ml-22, .mx-22 {
  margin-left: 5.5rem !important;
}

.m-23 {
  margin: 5.75rem !important;
}

.mt-23, .my-23 {
  margin-top: 5.75rem !important;
}

.mr-23, .mx-23 {
  margin-right: 5.75rem !important;
}

.mb-23, .my-23 {
  margin-bottom: 5.75rem !important;
}

.ml-23, .mx-23 {
  margin-left: 5.75rem !important;
}

.m-24 {
  margin: 6rem !important;
}

.mt-24, .my-24 {
  margin-top: 6rem !important;
}

.mr-24, .mx-24 {
  margin-right: 6rem !important;
}

.mb-24, .my-24 {
  margin-bottom: 6rem !important;
}

.ml-24, .mx-24 {
  margin-left: 6rem !important;
}

.m-25 {
  margin: 6.25rem !important;
}

.mt-25, .my-25 {
  margin-top: 6.25rem !important;
}

.mr-25, .mx-25 {
  margin-right: 6.25rem !important;
}

.mb-25, .my-25 {
  margin-bottom: 6.25rem !important;
}

.ml-25, .mx-25 {
  margin-left: 6.25rem !important;
}

.m-26 {
  margin: 6.5rem !important;
}

.mt-26, .my-26 {
  margin-top: 6.5rem !important;
}

.mr-26, .mx-26 {
  margin-right: 6.5rem !important;
}

.mb-26, .my-26 {
  margin-bottom: 6.5rem !important;
}

.ml-26, .mx-26 {
  margin-left: 6.5rem !important;
}

.m-27 {
  margin: 6.75rem !important;
}

.mt-27, .my-27 {
  margin-top: 6.75rem !important;
}

.mr-27, .mx-27 {
  margin-right: 6.75rem !important;
}

.mb-27, .my-27 {
  margin-bottom: 6.75rem !important;
}

.ml-27, .mx-27 {
  margin-left: 6.75rem !important;
}

.m-28 {
  margin: 7rem !important;
}

.mt-28, .my-28 {
  margin-top: 7rem !important;
}

.mr-28, .mx-28 {
  margin-right: 7rem !important;
}

.mb-28, .my-28 {
  margin-bottom: 7rem !important;
}

.ml-28, .mx-28 {
  margin-left: 7rem !important;
}

.m-29 {
  margin: 7.25rem !important;
}

.mt-29, .my-29 {
  margin-top: 7.25rem !important;
}

.mr-29, .mx-29 {
  margin-right: 7.25rem !important;
}

.mb-29, .my-29 {
  margin-bottom: 7.25rem !important;
}

.ml-29, .mx-29 {
  margin-left: 7.25rem !important;
}

.m-30 {
  margin: 7.5rem !important;
}

.mt-30, .my-30 {
  margin-top: 7.5rem !important;
}

.mr-30, .mx-30 {
  margin-right: 7.5rem !important;
}

.mb-30, .my-30 {
  margin-bottom: 7.5rem !important;
}

.ml-30, .mx-30 {
  margin-left: 7.5rem !important;
}

.m-31 {
  margin: 7.75rem !important;
}

.mt-31, .my-31 {
  margin-top: 7.75rem !important;
}

.mr-31, .mx-31 {
  margin-right: 7.75rem !important;
}

.mb-31, .my-31 {
  margin-bottom: 7.75rem !important;
}

.ml-31, .mx-31 {
  margin-left: 7.75rem !important;
}

.m-32 {
  margin: 8rem !important;
}

.mt-32, .my-32 {
  margin-top: 8rem !important;
}

.mr-32, .mx-32 {
  margin-right: 8rem !important;
}

.mb-32, .my-32 {
  margin-bottom: 8rem !important;
}

.ml-32, .mx-32 {
  margin-left: 8rem !important;
}

.m-33 {
  margin: 8.25rem !important;
}

.mt-33, .my-33 {
  margin-top: 8.25rem !important;
}

.mr-33, .mx-33 {
  margin-right: 8.25rem !important;
}

.mb-33, .my-33 {
  margin-bottom: 8.25rem !important;
}

.ml-33, .mx-33 {
  margin-left: 8.25rem !important;
}

.m-34 {
  margin: 8.5rem !important;
}

.mt-34, .my-34 {
  margin-top: 8.5rem !important;
}

.mr-34, .mx-34 {
  margin-right: 8.5rem !important;
}

.mb-34, .my-34 {
  margin-bottom: 8.5rem !important;
}

.ml-34, .mx-34 {
  margin-left: 8.5rem !important;
}

.m-35 {
  margin: 8.75rem !important;
}

.mt-35, .my-35 {
  margin-top: 8.75rem !important;
}

.mr-35, .mx-35 {
  margin-right: 8.75rem !important;
}

.mb-35, .my-35 {
  margin-bottom: 8.75rem !important;
}

.ml-35, .mx-35 {
  margin-left: 8.75rem !important;
}

.m-36 {
  margin: 9rem !important;
}

.mt-36, .my-36 {
  margin-top: 9rem !important;
}

.mr-36, .mx-36 {
  margin-right: 9rem !important;
}

.mb-36, .my-36 {
  margin-bottom: 9rem !important;
}

.ml-36, .mx-36 {
  margin-left: 9rem !important;
}

.m-37 {
  margin: 9.25rem !important;
}

.mt-37, .my-37 {
  margin-top: 9.25rem !important;
}

.mr-37, .mx-37 {
  margin-right: 9.25rem !important;
}

.mb-37, .my-37 {
  margin-bottom: 9.25rem !important;
}

.ml-37, .mx-37 {
  margin-left: 9.25rem !important;
}

.m-48 {
  margin: 9.5rem !important;
}

.mt-48, .my-48 {
  margin-top: 9.5rem !important;
}

.mr-48, .mx-48 {
  margin-right: 9.5rem !important;
}

.mb-48, .my-48 {
  margin-bottom: 9.5rem !important;
}

.ml-48, .mx-48 {
  margin-left: 9.5rem !important;
}

.m-39 {
  margin: 9.75rem !important;
}

.mt-39, .my-39 {
  margin-top: 9.75rem !important;
}

.mr-39, .mx-39 {
  margin-right: 9.75rem !important;
}

.mb-39, .my-39 {
  margin-bottom: 9.75rem !important;
}

.ml-39, .mx-39 {
  margin-left: 9.75rem !important;
}

.m-40 {
  margin: 10rem !important;
}

.mt-40, .my-40 {
  margin-top: 10rem !important;
}

.mr-40, .mx-40 {
  margin-right: 10rem !important;
}

.mb-40, .my-40 {
  margin-bottom: 10rem !important;
}

.ml-40, .mx-40 {
  margin-left: 10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1, .py-1 {
  padding-top: 0.25rem !important;
}

.pr-1, .px-1 {
  padding-right: 0.25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1, .px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2, .py-2 {
  padding-top: 0.5rem !important;
}

.pr-2, .px-2 {
  padding-right: 0.5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2, .px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.pt-3, .py-3 {
  padding-top: 0.75rem !important;
}

.pr-3, .px-3 {
  padding-right: 0.75rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 0.75rem !important;
}

.pl-3, .px-3 {
  padding-left: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4, .py-4 {
  padding-top: 1rem !important;
}

.pr-4, .px-4 {
  padding-right: 1rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1rem !important;
}

.pl-4, .px-4 {
  padding-left: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.pt-5, .py-5 {
  padding-top: 1.25rem !important;
}

.pr-5, .px-5 {
  padding-right: 1.25rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 1.25rem !important;
}

.pl-5, .px-5 {
  padding-left: 1.25rem !important;
}

.p-6 {
  padding: 18px !important;
}

.pt-6, .py-6 {
  padding-top: 18px !important;
}

.pr-6, .px-6 {
  padding-right: 18px !important;
}

.pb-6, .py-6 {
  padding-bottom: 18px !important;
}

.pl-6, .px-6 {
  padding-left: 18px !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.pt-7, .py-7 {
  padding-top: 1.75rem !important;
}

.pr-7, .px-7 {
  padding-right: 1.75rem !important;
}

.pb-7, .py-7 {
  padding-bottom: 1.75rem !important;
}

.pl-7, .px-7 {
  padding-left: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.pt-8, .py-8 {
  padding-top: 2rem !important;
}

.pr-8, .px-8 {
  padding-right: 2rem !important;
}

.pb-8, .py-8 {
  padding-bottom: 2rem !important;
}

.pl-8, .px-8 {
  padding-left: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.pt-9, .py-9 {
  padding-top: 2.25rem !important;
}

.pr-9, .px-9 {
  padding-right: 2.25rem !important;
}

.pb-9, .py-9 {
  padding-bottom: 2.25rem !important;
}

.pl-9, .px-9 {
  padding-left: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.pt-10, .py-10 {
  padding-top: 2.5rem !important;
}

.pr-10, .px-10 {
  padding-right: 2.5rem !important;
}

.pb-10, .py-10 {
  padding-bottom: 2.5rem !important;
}

.pl-10, .px-10 {
  padding-left: 2.5rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.pt-11, .py-11 {
  padding-top: 2.75rem !important;
}

.pr-11, .px-11 {
  padding-right: 2.75rem !important;
}

.pb-11, .py-11 {
  padding-bottom: 2.75rem !important;
}

.pl-11, .px-11 {
  padding-left: 2.75rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.pt-12, .py-12 {
  padding-top: 3rem !important;
}

.pr-12, .px-12 {
  padding-right: 3rem !important;
}

.pb-12, .py-12 {
  padding-bottom: 3rem !important;
}

.pl-12, .px-12 {
  padding-left: 3rem !important;
}

.p-13 {
  padding: 3.25rem !important;
}

.pt-13, .py-13 {
  padding-top: 3.25rem !important;
}

.pr-13, .px-13 {
  padding-right: 3.25rem !important;
}

.pb-13, .py-13 {
  padding-bottom: 3.25rem !important;
}

.pl-13, .px-13 {
  padding-left: 3.25rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.pt-14, .py-14 {
  padding-top: 3.5rem !important;
}

.pr-14, .px-14 {
  padding-right: 3.5rem !important;
}

.pb-14, .py-14 {
  padding-bottom: 3.5rem !important;
}

.pl-14, .px-14 {
  padding-left: 3.5rem !important;
}

.p-15 {
  padding: 3.75rem !important;
}

.pt-15, .py-15 {
  padding-top: 3.75rem !important;
}

.pr-15, .px-15 {
  padding-right: 3.75rem !important;
}

.pb-15, .py-15 {
  padding-bottom: 3.75rem !important;
}

.pl-15, .px-15 {
  padding-left: 3.75rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.pt-16, .py-16 {
  padding-top: 4rem !important;
}

.pr-16, .px-16 {
  padding-right: 4rem !important;
}

.pb-16, .py-16 {
  padding-bottom: 4rem !important;
}

.pl-16, .px-16 {
  padding-left: 4rem !important;
}

.p-17 {
  padding: 4.25rem !important;
}

.pt-17, .py-17 {
  padding-top: 4.25rem !important;
}

.pr-17, .px-17 {
  padding-right: 4.25rem !important;
}

.pb-17, .py-17 {
  padding-bottom: 4.25rem !important;
}

.pl-17, .px-17 {
  padding-left: 4.25rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.pt-18, .py-18 {
  padding-top: 4.5rem !important;
}

.pr-18, .px-18 {
  padding-right: 4.5rem !important;
}

.pb-18, .py-18 {
  padding-bottom: 4.5rem !important;
}

.pl-18, .px-18 {
  padding-left: 4.5rem !important;
}

.p-19 {
  padding: 4.75rem !important;
}

.pt-19, .py-19 {
  padding-top: 4.75rem !important;
}

.pr-19, .px-19 {
  padding-right: 4.75rem !important;
}

.pb-19, .py-19 {
  padding-bottom: 4.75rem !important;
}

.pl-19, .px-19 {
  padding-left: 4.75rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.pt-20, .py-20 {
  padding-top: 5rem !important;
}

.pr-20, .px-20 {
  padding-right: 5rem !important;
}

.pb-20, .py-20 {
  padding-bottom: 5rem !important;
}

.pl-20, .px-20 {
  padding-left: 5rem !important;
}

.p-21 {
  padding: 5.25rem !important;
}

.pt-21, .py-21 {
  padding-top: 5.25rem !important;
}

.pr-21, .px-21 {
  padding-right: 5.25rem !important;
}

.pb-21, .py-21 {
  padding-bottom: 5.25rem !important;
}

.pl-21, .px-21 {
  padding-left: 5.25rem !important;
}

.p-22 {
  padding: 5.5rem !important;
}

.pt-22, .py-22 {
  padding-top: 5.5rem !important;
}

.pr-22, .px-22 {
  padding-right: 5.5rem !important;
}

.pb-22, .py-22 {
  padding-bottom: 5.5rem !important;
}

.pl-22, .px-22 {
  padding-left: 5.5rem !important;
}

.p-23 {
  padding: 5.75rem !important;
}

.pt-23, .py-23 {
  padding-top: 5.75rem !important;
}

.pr-23, .px-23 {
  padding-right: 5.75rem !important;
}

.pb-23, .py-23 {
  padding-bottom: 5.75rem !important;
}

.pl-23, .px-23 {
  padding-left: 5.75rem !important;
}

.p-24 {
  padding: 6rem !important;
}

.pt-24, .py-24 {
  padding-top: 6rem !important;
}

.pr-24, .px-24 {
  padding-right: 6rem !important;
}

.pb-24, .py-24 {
  padding-bottom: 6rem !important;
}

.pl-24, .px-24 {
  padding-left: 6rem !important;
}

.p-25 {
  padding: 6.25rem !important;
}

.pt-25, .py-25 {
  padding-top: 6.25rem !important;
}

.pr-25, .px-25 {
  padding-right: 6.25rem !important;
}

.pb-25, .py-25 {
  padding-bottom: 6.25rem !important;
}

.pl-25, .px-25 {
  padding-left: 6.25rem !important;
}

.p-26 {
  padding: 6.5rem !important;
}

.pt-26, .py-26 {
  padding-top: 6.5rem !important;
}

.pr-26, .px-26 {
  padding-right: 6.5rem !important;
}

.pb-26, .py-26 {
  padding-bottom: 6.5rem !important;
}

.pl-26, .px-26 {
  padding-left: 6.5rem !important;
}

.p-27 {
  padding: 6.75rem !important;
}

.pt-27, .py-27 {
  padding-top: 6.75rem !important;
}

.pr-27, .px-27 {
  padding-right: 6.75rem !important;
}

.pb-27, .py-27 {
  padding-bottom: 6.75rem !important;
}

.pl-27, .px-27 {
  padding-left: 6.75rem !important;
}

.p-28 {
  padding: 7rem !important;
}

.pt-28, .py-28 {
  padding-top: 7rem !important;
}

.pr-28, .px-28 {
  padding-right: 7rem !important;
}

.pb-28, .py-28 {
  padding-bottom: 7rem !important;
}

.pl-28, .px-28 {
  padding-left: 7rem !important;
}

.p-29 {
  padding: 7.25rem !important;
}

.pt-29, .py-29 {
  padding-top: 7.25rem !important;
}

.pr-29, .px-29 {
  padding-right: 7.25rem !important;
}

.pb-29, .py-29 {
  padding-bottom: 7.25rem !important;
}

.pl-29, .px-29 {
  padding-left: 7.25rem !important;
}

.p-30 {
  padding: 7.5rem !important;
}

.pt-30, .py-30 {
  padding-top: 7.5rem !important;
}

.pr-30, .px-30 {
  padding-right: 7.5rem !important;
}

.pb-30, .py-30 {
  padding-bottom: 7.5rem !important;
}

.pl-30, .px-30 {
  padding-left: 7.5rem !important;
}

.p-31 {
  padding: 7.75rem !important;
}

.pt-31, .py-31 {
  padding-top: 7.75rem !important;
}

.pr-31, .px-31 {
  padding-right: 7.75rem !important;
}

.pb-31, .py-31 {
  padding-bottom: 7.75rem !important;
}

.pl-31, .px-31 {
  padding-left: 7.75rem !important;
}

.p-32 {
  padding: 8rem !important;
}

.pt-32, .py-32 {
  padding-top: 8rem !important;
}

.pr-32, .px-32 {
  padding-right: 8rem !important;
}

.pb-32, .py-32 {
  padding-bottom: 8rem !important;
}

.pl-32, .px-32 {
  padding-left: 8rem !important;
}

.p-33 {
  padding: 8.25rem !important;
}

.pt-33, .py-33 {
  padding-top: 8.25rem !important;
}

.pr-33, .px-33 {
  padding-right: 8.25rem !important;
}

.pb-33, .py-33 {
  padding-bottom: 8.25rem !important;
}

.pl-33, .px-33 {
  padding-left: 8.25rem !important;
}

.p-34 {
  padding: 8.5rem !important;
}

.pt-34, .py-34 {
  padding-top: 8.5rem !important;
}

.pr-34, .px-34 {
  padding-right: 8.5rem !important;
}

.pb-34, .py-34 {
  padding-bottom: 8.5rem !important;
}

.pl-34, .px-34 {
  padding-left: 8.5rem !important;
}

.p-35 {
  padding: 8.75rem !important;
}

.pt-35, .py-35 {
  padding-top: 8.75rem !important;
}

.pr-35, .px-35 {
  padding-right: 8.75rem !important;
}

.pb-35, .py-35 {
  padding-bottom: 8.75rem !important;
}

.pl-35, .px-35 {
  padding-left: 8.75rem !important;
}

.p-36 {
  padding: 9rem !important;
}

.pt-36, .py-36 {
  padding-top: 9rem !important;
}

.pr-36, .px-36 {
  padding-right: 9rem !important;
}

.pb-36, .py-36 {
  padding-bottom: 9rem !important;
}

.pl-36, .px-36 {
  padding-left: 9rem !important;
}

.p-37 {
  padding: 9.25rem !important;
}

.pt-37, .py-37 {
  padding-top: 9.25rem !important;
}

.pr-37, .px-37 {
  padding-right: 9.25rem !important;
}

.pb-37, .py-37 {
  padding-bottom: 9.25rem !important;
}

.pl-37, .px-37 {
  padding-left: 9.25rem !important;
}

.p-48 {
  padding: 9.5rem !important;
}

.pt-48, .py-48 {
  padding-top: 9.5rem !important;
}

.pr-48, .px-48 {
  padding-right: 9.5rem !important;
}

.pb-48, .py-48 {
  padding-bottom: 9.5rem !important;
}

.pl-48, .px-48 {
  padding-left: 9.5rem !important;
}

.p-39 {
  padding: 9.75rem !important;
}

.pt-39, .py-39 {
  padding-top: 9.75rem !important;
}

.pr-39, .px-39 {
  padding-right: 9.75rem !important;
}

.pb-39, .py-39 {
  padding-bottom: 9.75rem !important;
}

.pl-39, .px-39 {
  padding-left: 9.75rem !important;
}

.p-40 {
  padding: 10rem !important;
}

.pt-40, .py-40 {
  padding-top: 10rem !important;
}

.pr-40, .px-40 {
  padding-right: 10rem !important;
}

.pb-40, .py-40 {
  padding-bottom: 10rem !important;
}

.pl-40, .px-40 {
  padding-left: 10rem !important;
}

.p-eveniment-card {
  padding: 1rem 1rem 0.4rem 1rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1, .mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1, .mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2, .my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2, .mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2, .mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.mt-n3, .my-n3 {
  margin-top: -0.75rem !important;
}

.mr-n3, .mx-n3 {
  margin-right: -0.75rem !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -0.75rem !important;
}

.ml-n3, .mx-n3 {
  margin-left: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.mt-n4, .my-n4 {
  margin-top: -1rem !important;
}

.mr-n4, .mx-n4 {
  margin-right: -1rem !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -1rem !important;
}

.ml-n4, .mx-n4 {
  margin-left: -1rem !important;
}

.m-n5 {
  margin: -1.25rem !important;
}

.mt-n5, .my-n5 {
  margin-top: -1.25rem !important;
}

.mr-n5, .mx-n5 {
  margin-right: -1.25rem !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -1.25rem !important;
}

.ml-n5, .mx-n5 {
  margin-left: -1.25rem !important;
}

.m-n6 {
  margin: -1.5rem !important;
}

.mt-n6, .my-n6 {
  margin-top: -1.5rem !important;
}

.mr-n6, .mx-n6 {
  margin-right: -1.5rem !important;
}

.mb-n6, .my-n6 {
  margin-bottom: -1.5rem !important;
}

.ml-n6, .mx-n6 {
  margin-left: -1.5rem !important;
}

.m-n7 {
  margin: -1.75rem !important;
}

.mt-n7, .my-n7 {
  margin-top: -1.75rem !important;
}

.mr-n7, .mx-n7 {
  margin-right: -1.75rem !important;
}

.mb-n7, .my-n7 {
  margin-bottom: -1.75rem !important;
}

.ml-n7, .mx-n7 {
  margin-left: -1.75rem !important;
}

.m-n8 {
  margin: -2rem !important;
}

.mt-n8, .my-n8 {
  margin-top: -2rem !important;
}

.mr-n8, .mx-n8 {
  margin-right: -2rem !important;
}

.mb-n8, .my-n8 {
  margin-bottom: -2rem !important;
}

.ml-n8, .mx-n8 {
  margin-left: -2rem !important;
}

.m-n9 {
  margin: -2.25rem !important;
}

.mt-n9, .my-n9 {
  margin-top: -2.25rem !important;
}

.mr-n9, .mx-n9 {
  margin-right: -2.25rem !important;
}

.mb-n9, .my-n9 {
  margin-bottom: -2.25rem !important;
}

.ml-n9, .mx-n9 {
  margin-left: -2.25rem !important;
}

.m-n10 {
  margin: -2.5rem !important;
}

.mt-n10, .my-n10 {
  margin-top: -2.5rem !important;
}

.mr-n10, .mx-n10 {
  margin-right: -2.5rem !important;
}

.mb-n10, .my-n10 {
  margin-bottom: -2.5rem !important;
}

.ml-n10, .mx-n10 {
  margin-left: -2.5rem !important;
}

.m-n11 {
  margin: -2.75rem !important;
}

.mt-n11, .my-n11 {
  margin-top: -2.75rem !important;
}

.mr-n11, .mx-n11 {
  margin-right: -2.75rem !important;
}

.mb-n11, .my-n11 {
  margin-bottom: -2.75rem !important;
}

.ml-n11, .mx-n11 {
  margin-left: -2.75rem !important;
}

.m-n12 {
  margin: -3rem !important;
}

.mt-n12, .my-n12 {
  margin-top: -3rem !important;
}

.mr-n12, .mx-n12 {
  margin-right: -3rem !important;
}

.mb-n12, .my-n12 {
  margin-bottom: -3rem !important;
}

.ml-n12, .mx-n12 {
  margin-left: -3rem !important;
}

.m-n13 {
  margin: -3.25rem !important;
}

.mt-n13, .my-n13 {
  margin-top: -3.25rem !important;
}

.mr-n13, .mx-n13 {
  margin-right: -3.25rem !important;
}

.mb-n13, .my-n13 {
  margin-bottom: -3.25rem !important;
}

.ml-n13, .mx-n13 {
  margin-left: -3.25rem !important;
}

.m-n14 {
  margin: -3.5rem !important;
}

.mt-n14, .my-n14 {
  margin-top: -3.5rem !important;
}

.mr-n14, .mx-n14 {
  margin-right: -3.5rem !important;
}

.mb-n14, .my-n14 {
  margin-bottom: -3.5rem !important;
}

.ml-n14, .mx-n14 {
  margin-left: -3.5rem !important;
}

.m-n15 {
  margin: -3.75rem !important;
}

.mt-n15, .my-n15 {
  margin-top: -3.75rem !important;
}

.mr-n15, .mx-n15 {
  margin-right: -3.75rem !important;
}

.mb-n15, .my-n15 {
  margin-bottom: -3.75rem !important;
}

.ml-n15, .mx-n15 {
  margin-left: -3.75rem !important;
}

.m-n16 {
  margin: -4rem !important;
}

.mt-n16, .my-n16 {
  margin-top: -4rem !important;
}

.mr-n16, .mx-n16 {
  margin-right: -4rem !important;
}

.mb-n16, .my-n16 {
  margin-bottom: -4rem !important;
}

.ml-n16, .mx-n16 {
  margin-left: -4rem !important;
}

.m-n17 {
  margin: -4.25rem !important;
}

.mt-n17, .my-n17 {
  margin-top: -4.25rem !important;
}

.mr-n17, .mx-n17 {
  margin-right: -4.25rem !important;
}

.mb-n17, .my-n17 {
  margin-bottom: -4.25rem !important;
}

.ml-n17, .mx-n17 {
  margin-left: -4.25rem !important;
}

.m-n18 {
  margin: -4.5rem !important;
}

.mt-n18, .my-n18 {
  margin-top: -4.5rem !important;
}

.mr-n18, .mx-n18 {
  margin-right: -4.5rem !important;
}

.mb-n18, .my-n18 {
  margin-bottom: -4.5rem !important;
}

.ml-n18, .mx-n18 {
  margin-left: -4.5rem !important;
}

.m-n19 {
  margin: -4.75rem !important;
}

.mt-n19, .my-n19 {
  margin-top: -4.75rem !important;
}

.mr-n19, .mx-n19 {
  margin-right: -4.75rem !important;
}

.mb-n19, .my-n19 {
  margin-bottom: -4.75rem !important;
}

.ml-n19, .mx-n19 {
  margin-left: -4.75rem !important;
}

.m-n20 {
  margin: -5rem !important;
}

.mt-n20, .my-n20 {
  margin-top: -5rem !important;
}

.mr-n20, .mx-n20 {
  margin-right: -5rem !important;
}

.mb-n20, .my-n20 {
  margin-bottom: -5rem !important;
}

.ml-n20, .mx-n20 {
  margin-left: -5rem !important;
}

.m-n21 {
  margin: -5.25rem !important;
}

.mt-n21, .my-n21 {
  margin-top: -5.25rem !important;
}

.mr-n21, .mx-n21 {
  margin-right: -5.25rem !important;
}

.mb-n21, .my-n21 {
  margin-bottom: -5.25rem !important;
}

.ml-n21, .mx-n21 {
  margin-left: -5.25rem !important;
}

.m-n22 {
  margin: -5.5rem !important;
}

.mt-n22, .my-n22 {
  margin-top: -5.5rem !important;
}

.mr-n22, .mx-n22 {
  margin-right: -5.5rem !important;
}

.mb-n22, .my-n22 {
  margin-bottom: -5.5rem !important;
}

.ml-n22, .mx-n22 {
  margin-left: -5.5rem !important;
}

.m-n23 {
  margin: -5.75rem !important;
}

.mt-n23, .my-n23 {
  margin-top: -5.75rem !important;
}

.mr-n23, .mx-n23 {
  margin-right: -5.75rem !important;
}

.mb-n23, .my-n23 {
  margin-bottom: -5.75rem !important;
}

.ml-n23, .mx-n23 {
  margin-left: -5.75rem !important;
}

.m-n24 {
  margin: -6rem !important;
}

.mt-n24, .my-n24 {
  margin-top: -6rem !important;
}

.mr-n24, .mx-n24 {
  margin-right: -6rem !important;
}

.mb-n24, .my-n24 {
  margin-bottom: -6rem !important;
}

.ml-n24, .mx-n24 {
  margin-left: -6rem !important;
}

.m-n25 {
  margin: -6.25rem !important;
}

.mt-n25, .my-n25 {
  margin-top: -6.25rem !important;
}

.mr-n25, .mx-n25 {
  margin-right: -6.25rem !important;
}

.mb-n25, .my-n25 {
  margin-bottom: -6.25rem !important;
}

.ml-n25, .mx-n25 {
  margin-left: -6.25rem !important;
}

.m-n26 {
  margin: -6.5rem !important;
}

.mt-n26, .my-n26 {
  margin-top: -6.5rem !important;
}

.mr-n26, .mx-n26 {
  margin-right: -6.5rem !important;
}

.mb-n26, .my-n26 {
  margin-bottom: -6.5rem !important;
}

.ml-n26, .mx-n26 {
  margin-left: -6.5rem !important;
}

.m-n27 {
  margin: -6.75rem !important;
}

.mt-n27, .my-n27 {
  margin-top: -6.75rem !important;
}

.mr-n27, .mx-n27 {
  margin-right: -6.75rem !important;
}

.mb-n27, .my-n27 {
  margin-bottom: -6.75rem !important;
}

.ml-n27, .mx-n27 {
  margin-left: -6.75rem !important;
}

.m-n28 {
  margin: -7rem !important;
}

.mt-n28, .my-n28 {
  margin-top: -7rem !important;
}

.mr-n28, .mx-n28 {
  margin-right: -7rem !important;
}

.mb-n28, .my-n28 {
  margin-bottom: -7rem !important;
}

.ml-n28, .mx-n28 {
  margin-left: -7rem !important;
}

.m-n29 {
  margin: -7.25rem !important;
}

.mt-n29, .my-n29 {
  margin-top: -7.25rem !important;
}

.mr-n29, .mx-n29 {
  margin-right: -7.25rem !important;
}

.mb-n29, .my-n29 {
  margin-bottom: -7.25rem !important;
}

.ml-n29, .mx-n29 {
  margin-left: -7.25rem !important;
}

.m-n30 {
  margin: -7.5rem !important;
}

.mt-n30, .my-n30 {
  margin-top: -7.5rem !important;
}

.mr-n30, .mx-n30 {
  margin-right: -7.5rem !important;
}

.mb-n30, .my-n30 {
  margin-bottom: -7.5rem !important;
}

.ml-n30, .mx-n30 {
  margin-left: -7.5rem !important;
}

.m-n31 {
  margin: -7.75rem !important;
}

.mt-n31, .my-n31 {
  margin-top: -7.75rem !important;
}

.mr-n31, .mx-n31 {
  margin-right: -7.75rem !important;
}

.mb-n31, .my-n31 {
  margin-bottom: -7.75rem !important;
}

.ml-n31, .mx-n31 {
  margin-left: -7.75rem !important;
}

.m-n32 {
  margin: -8rem !important;
}

.mt-n32, .my-n32 {
  margin-top: -8rem !important;
}

.mr-n32, .mx-n32 {
  margin-right: -8rem !important;
}

.mb-n32, .my-n32 {
  margin-bottom: -8rem !important;
}

.ml-n32, .mx-n32 {
  margin-left: -8rem !important;
}

.m-n33 {
  margin: -8.25rem !important;
}

.mt-n33, .my-n33 {
  margin-top: -8.25rem !important;
}

.mr-n33, .mx-n33 {
  margin-right: -8.25rem !important;
}

.mb-n33, .my-n33 {
  margin-bottom: -8.25rem !important;
}

.ml-n33, .mx-n33 {
  margin-left: -8.25rem !important;
}

.m-n34 {
  margin: -8.5rem !important;
}

.mt-n34, .my-n34 {
  margin-top: -8.5rem !important;
}

.mr-n34, .mx-n34 {
  margin-right: -8.5rem !important;
}

.mb-n34, .my-n34 {
  margin-bottom: -8.5rem !important;
}

.ml-n34, .mx-n34 {
  margin-left: -8.5rem !important;
}

.m-n35 {
  margin: -8.75rem !important;
}

.mt-n35, .my-n35 {
  margin-top: -8.75rem !important;
}

.mr-n35, .mx-n35 {
  margin-right: -8.75rem !important;
}

.mb-n35, .my-n35 {
  margin-bottom: -8.75rem !important;
}

.ml-n35, .mx-n35 {
  margin-left: -8.75rem !important;
}

.m-n36 {
  margin: -9rem !important;
}

.mt-n36, .my-n36 {
  margin-top: -9rem !important;
}

.mr-n36, .mx-n36 {
  margin-right: -9rem !important;
}

.mb-n36, .my-n36 {
  margin-bottom: -9rem !important;
}

.ml-n36, .mx-n36 {
  margin-left: -9rem !important;
}

.m-n37 {
  margin: -9.25rem !important;
}

.mt-n37, .my-n37 {
  margin-top: -9.25rem !important;
}

.mr-n37, .mx-n37 {
  margin-right: -9.25rem !important;
}

.mb-n37, .my-n37 {
  margin-bottom: -9.25rem !important;
}

.ml-n37, .mx-n37 {
  margin-left: -9.25rem !important;
}

.m-n48 {
  margin: -9.5rem !important;
}

.mt-n48, .my-n48 {
  margin-top: -9.5rem !important;
}

.mr-n48, .mx-n48 {
  margin-right: -9.5rem !important;
}

.mb-n48, .my-n48 {
  margin-bottom: -9.5rem !important;
}

.ml-n48, .mx-n48 {
  margin-left: -9.5rem !important;
}

.m-n39 {
  margin: -9.75rem !important;
}

.mt-n39, .my-n39 {
  margin-top: -9.75rem !important;
}

.mr-n39, .mx-n39 {
  margin-right: -9.75rem !important;
}

.mb-n39, .my-n39 {
  margin-bottom: -9.75rem !important;
}

.ml-n39, .mx-n39 {
  margin-left: -9.75rem !important;
}

.m-n40 {
  margin: -10rem !important;
}

.mt-n40, .my-n40 {
  margin-top: -10rem !important;
}

.mr-n40, .mx-n40 {
  margin-right: -10rem !important;
}

.mb-n40, .my-n40 {
  margin-bottom: -10rem !important;
}

.ml-n40, .mx-n40 {
  margin-left: -10rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1, .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1, .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1, .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1, .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2, .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2, .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2, .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2, .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .mt-sm-3, .my-sm-3 {
    margin-top: 0.75rem !important;
  }
  .mr-sm-3, .mx-sm-3 {
    margin-right: 0.75rem !important;
  }
  .mb-sm-3, .my-sm-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-sm-3, .mx-sm-3 {
    margin-left: 0.75rem !important;
  }
  .m-sm-4 {
    margin: 1rem !important;
  }
  .mt-sm-4, .my-sm-4 {
    margin-top: 1rem !important;
  }
  .mr-sm-4, .mx-sm-4 {
    margin-right: 1rem !important;
  }
  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-4, .mx-sm-4 {
    margin-left: 1rem !important;
  }
  .m-sm-5 {
    margin: 1.25rem !important;
  }
  .mt-sm-5, .my-sm-5 {
    margin-top: 1.25rem !important;
  }
  .mr-sm-5, .mx-sm-5 {
    margin-right: 1.25rem !important;
  }
  .mb-sm-5, .my-sm-5 {
    margin-bottom: 1.25rem !important;
  }
  .ml-sm-5, .mx-sm-5 {
    margin-left: 1.25rem !important;
  }
  .m-sm-6 {
    margin: 1.5rem !important;
  }
  .mt-sm-6, .my-sm-6 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-6, .mx-sm-6 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-6, .my-sm-6 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-6, .mx-sm-6 {
    margin-left: 1.5rem !important;
  }
  .m-sm-7 {
    margin: 1.75rem !important;
  }
  .mt-sm-7, .my-sm-7 {
    margin-top: 1.75rem !important;
  }
  .mr-sm-7, .mx-sm-7 {
    margin-right: 1.75rem !important;
  }
  .mb-sm-7, .my-sm-7 {
    margin-bottom: 1.75rem !important;
  }
  .ml-sm-7, .mx-sm-7 {
    margin-left: 1.75rem !important;
  }
  .m-sm-8 {
    margin: 2rem !important;
  }
  .mt-sm-8, .my-sm-8 {
    margin-top: 2rem !important;
  }
  .mr-sm-8, .mx-sm-8 {
    margin-right: 2rem !important;
  }
  .mb-sm-8, .my-sm-8 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-8, .mx-sm-8 {
    margin-left: 2rem !important;
  }
  .m-sm-9 {
    margin: 2.25rem !important;
  }
  .mt-sm-9, .my-sm-9 {
    margin-top: 2.25rem !important;
  }
  .mr-sm-9, .mx-sm-9 {
    margin-right: 2.25rem !important;
  }
  .mb-sm-9, .my-sm-9 {
    margin-bottom: 2.25rem !important;
  }
  .ml-sm-9, .mx-sm-9 {
    margin-left: 2.25rem !important;
  }
  .m-sm-10 {
    margin: 2.5rem !important;
  }
  .mt-sm-10, .my-sm-10 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-10, .mx-sm-10 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-10, .my-sm-10 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-10, .mx-sm-10 {
    margin-left: 2.5rem !important;
  }
  .m-sm-11 {
    margin: 2.75rem !important;
  }
  .mt-sm-11, .my-sm-11 {
    margin-top: 2.75rem !important;
  }
  .mr-sm-11, .mx-sm-11 {
    margin-right: 2.75rem !important;
  }
  .mb-sm-11, .my-sm-11 {
    margin-bottom: 2.75rem !important;
  }
  .ml-sm-11, .mx-sm-11 {
    margin-left: 2.75rem !important;
  }
  .m-sm-12 {
    margin: 3rem !important;
  }
  .mt-sm-12, .my-sm-12 {
    margin-top: 3rem !important;
  }
  .mr-sm-12, .mx-sm-12 {
    margin-right: 3rem !important;
  }
  .mb-sm-12, .my-sm-12 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-12, .mx-sm-12 {
    margin-left: 3rem !important;
  }
  .m-sm-13 {
    margin: 3.25rem !important;
  }
  .mt-sm-13, .my-sm-13 {
    margin-top: 3.25rem !important;
  }
  .mr-sm-13, .mx-sm-13 {
    margin-right: 3.25rem !important;
  }
  .mb-sm-13, .my-sm-13 {
    margin-bottom: 3.25rem !important;
  }
  .ml-sm-13, .mx-sm-13 {
    margin-left: 3.25rem !important;
  }
  .m-sm-14 {
    margin: 3.5rem !important;
  }
  .mt-sm-14, .my-sm-14 {
    margin-top: 3.5rem !important;
  }
  .mr-sm-14, .mx-sm-14 {
    margin-right: 3.5rem !important;
  }
  .mb-sm-14, .my-sm-14 {
    margin-bottom: 3.5rem !important;
  }
  .ml-sm-14, .mx-sm-14 {
    margin-left: 3.5rem !important;
  }
  .m-sm-15 {
    margin: 3.75rem !important;
  }
  .mt-sm-15, .my-sm-15 {
    margin-top: 3.75rem !important;
  }
  .mr-sm-15, .mx-sm-15 {
    margin-right: 3.75rem !important;
  }
  .mb-sm-15, .my-sm-15 {
    margin-bottom: 3.75rem !important;
  }
  .ml-sm-15, .mx-sm-15 {
    margin-left: 3.75rem !important;
  }
  .m-sm-16 {
    margin: 4rem !important;
  }
  .mt-sm-16, .my-sm-16 {
    margin-top: 4rem !important;
  }
  .mr-sm-16, .mx-sm-16 {
    margin-right: 4rem !important;
  }
  .mb-sm-16, .my-sm-16 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-16, .mx-sm-16 {
    margin-left: 4rem !important;
  }
  .m-sm-17 {
    margin: 4.25rem !important;
  }
  .mt-sm-17, .my-sm-17 {
    margin-top: 4.25rem !important;
  }
  .mr-sm-17, .mx-sm-17 {
    margin-right: 4.25rem !important;
  }
  .mb-sm-17, .my-sm-17 {
    margin-bottom: 4.25rem !important;
  }
  .ml-sm-17, .mx-sm-17 {
    margin-left: 4.25rem !important;
  }
  .m-sm-18 {
    margin: 4.5rem !important;
  }
  .mt-sm-18, .my-sm-18 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-18, .mx-sm-18 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-18, .my-sm-18 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-18, .mx-sm-18 {
    margin-left: 4.5rem !important;
  }
  .m-sm-19 {
    margin: 4.75rem !important;
  }
  .mt-sm-19, .my-sm-19 {
    margin-top: 4.75rem !important;
  }
  .mr-sm-19, .mx-sm-19 {
    margin-right: 4.75rem !important;
  }
  .mb-sm-19, .my-sm-19 {
    margin-bottom: 4.75rem !important;
  }
  .ml-sm-19, .mx-sm-19 {
    margin-left: 4.75rem !important;
  }
  .m-sm-20 {
    margin: 5rem !important;
  }
  .mt-sm-20, .my-sm-20 {
    margin-top: 5rem !important;
  }
  .mr-sm-20, .mx-sm-20 {
    margin-right: 5rem !important;
  }
  .mb-sm-20, .my-sm-20 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-20, .mx-sm-20 {
    margin-left: 5rem !important;
  }
  .m-sm-21 {
    margin: 5.25rem !important;
  }
  .mt-sm-21, .my-sm-21 {
    margin-top: 5.25rem !important;
  }
  .mr-sm-21, .mx-sm-21 {
    margin-right: 5.25rem !important;
  }
  .mb-sm-21, .my-sm-21 {
    margin-bottom: 5.25rem !important;
  }
  .ml-sm-21, .mx-sm-21 {
    margin-left: 5.25rem !important;
  }
  .m-sm-22 {
    margin: 5.5rem !important;
  }
  .mt-sm-22, .my-sm-22 {
    margin-top: 5.5rem !important;
  }
  .mr-sm-22, .mx-sm-22 {
    margin-right: 5.5rem !important;
  }
  .mb-sm-22, .my-sm-22 {
    margin-bottom: 5.5rem !important;
  }
  .ml-sm-22, .mx-sm-22 {
    margin-left: 5.5rem !important;
  }
  .m-sm-23 {
    margin: 5.75rem !important;
  }
  .mt-sm-23, .my-sm-23 {
    margin-top: 5.75rem !important;
  }
  .mr-sm-23, .mx-sm-23 {
    margin-right: 5.75rem !important;
  }
  .mb-sm-23, .my-sm-23 {
    margin-bottom: 5.75rem !important;
  }
  .ml-sm-23, .mx-sm-23 {
    margin-left: 5.75rem !important;
  }
  .m-sm-24 {
    margin: 6rem !important;
  }
  .mt-sm-24, .my-sm-24 {
    margin-top: 6rem !important;
  }
  .mr-sm-24, .mx-sm-24 {
    margin-right: 6rem !important;
  }
  .mb-sm-24, .my-sm-24 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-24, .mx-sm-24 {
    margin-left: 6rem !important;
  }
  .m-sm-25 {
    margin: 6.25rem !important;
  }
  .mt-sm-25, .my-sm-25 {
    margin-top: 6.25rem !important;
  }
  .mr-sm-25, .mx-sm-25 {
    margin-right: 6.25rem !important;
  }
  .mb-sm-25, .my-sm-25 {
    margin-bottom: 6.25rem !important;
  }
  .ml-sm-25, .mx-sm-25 {
    margin-left: 6.25rem !important;
  }
  .m-sm-26 {
    margin: 6.5rem !important;
  }
  .mt-sm-26, .my-sm-26 {
    margin-top: 6.5rem !important;
  }
  .mr-sm-26, .mx-sm-26 {
    margin-right: 6.5rem !important;
  }
  .mb-sm-26, .my-sm-26 {
    margin-bottom: 6.5rem !important;
  }
  .ml-sm-26, .mx-sm-26 {
    margin-left: 6.5rem !important;
  }
  .m-sm-27 {
    margin: 6.75rem !important;
  }
  .mt-sm-27, .my-sm-27 {
    margin-top: 6.75rem !important;
  }
  .mr-sm-27, .mx-sm-27 {
    margin-right: 6.75rem !important;
  }
  .mb-sm-27, .my-sm-27 {
    margin-bottom: 6.75rem !important;
  }
  .ml-sm-27, .mx-sm-27 {
    margin-left: 6.75rem !important;
  }
  .m-sm-28 {
    margin: 7rem !important;
  }
  .mt-sm-28, .my-sm-28 {
    margin-top: 7rem !important;
  }
  .mr-sm-28, .mx-sm-28 {
    margin-right: 7rem !important;
  }
  .mb-sm-28, .my-sm-28 {
    margin-bottom: 7rem !important;
  }
  .ml-sm-28, .mx-sm-28 {
    margin-left: 7rem !important;
  }
  .m-sm-29 {
    margin: 7.25rem !important;
  }
  .mt-sm-29, .my-sm-29 {
    margin-top: 7.25rem !important;
  }
  .mr-sm-29, .mx-sm-29 {
    margin-right: 7.25rem !important;
  }
  .mb-sm-29, .my-sm-29 {
    margin-bottom: 7.25rem !important;
  }
  .ml-sm-29, .mx-sm-29 {
    margin-left: 7.25rem !important;
  }
  .m-sm-30 {
    margin: 7.5rem !important;
  }
  .mt-sm-30, .my-sm-30 {
    margin-top: 7.5rem !important;
  }
  .mr-sm-30, .mx-sm-30 {
    margin-right: 7.5rem !important;
  }
  .mb-sm-30, .my-sm-30 {
    margin-bottom: 7.5rem !important;
  }
  .ml-sm-30, .mx-sm-30 {
    margin-left: 7.5rem !important;
  }
  .m-sm-31 {
    margin: 7.75rem !important;
  }
  .mt-sm-31, .my-sm-31 {
    margin-top: 7.75rem !important;
  }
  .mr-sm-31, .mx-sm-31 {
    margin-right: 7.75rem !important;
  }
  .mb-sm-31, .my-sm-31 {
    margin-bottom: 7.75rem !important;
  }
  .ml-sm-31, .mx-sm-31 {
    margin-left: 7.75rem !important;
  }
  .m-sm-32 {
    margin: 8rem !important;
  }
  .mt-sm-32, .my-sm-32 {
    margin-top: 8rem !important;
  }
  .mr-sm-32, .mx-sm-32 {
    margin-right: 8rem !important;
  }
  .mb-sm-32, .my-sm-32 {
    margin-bottom: 8rem !important;
  }
  .ml-sm-32, .mx-sm-32 {
    margin-left: 8rem !important;
  }
  .m-sm-33 {
    margin: 8.25rem !important;
  }
  .mt-sm-33, .my-sm-33 {
    margin-top: 8.25rem !important;
  }
  .mr-sm-33, .mx-sm-33 {
    margin-right: 8.25rem !important;
  }
  .mb-sm-33, .my-sm-33 {
    margin-bottom: 8.25rem !important;
  }
  .ml-sm-33, .mx-sm-33 {
    margin-left: 8.25rem !important;
  }
  .m-sm-34 {
    margin: 8.5rem !important;
  }
  .mt-sm-34, .my-sm-34 {
    margin-top: 8.5rem !important;
  }
  .mr-sm-34, .mx-sm-34 {
    margin-right: 8.5rem !important;
  }
  .mb-sm-34, .my-sm-34 {
    margin-bottom: 8.5rem !important;
  }
  .ml-sm-34, .mx-sm-34 {
    margin-left: 8.5rem !important;
  }
  .m-sm-35 {
    margin: 8.75rem !important;
  }
  .mt-sm-35, .my-sm-35 {
    margin-top: 8.75rem !important;
  }
  .mr-sm-35, .mx-sm-35 {
    margin-right: 8.75rem !important;
  }
  .mb-sm-35, .my-sm-35 {
    margin-bottom: 8.75rem !important;
  }
  .ml-sm-35, .mx-sm-35 {
    margin-left: 8.75rem !important;
  }
  .m-sm-36 {
    margin: 9rem !important;
  }
  .mt-sm-36, .my-sm-36 {
    margin-top: 9rem !important;
  }
  .mr-sm-36, .mx-sm-36 {
    margin-right: 9rem !important;
  }
  .mb-sm-36, .my-sm-36 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-36, .mx-sm-36 {
    margin-left: 9rem !important;
  }
  .m-sm-37 {
    margin: 9.25rem !important;
  }
  .mt-sm-37, .my-sm-37 {
    margin-top: 9.25rem !important;
  }
  .mr-sm-37, .mx-sm-37 {
    margin-right: 9.25rem !important;
  }
  .mb-sm-37, .my-sm-37 {
    margin-bottom: 9.25rem !important;
  }
  .ml-sm-37, .mx-sm-37 {
    margin-left: 9.25rem !important;
  }
  .m-sm-48 {
    margin: 9.5rem !important;
  }
  .mt-sm-48, .my-sm-48 {
    margin-top: 9.5rem !important;
  }
  .mr-sm-48, .mx-sm-48 {
    margin-right: 9.5rem !important;
  }
  .mb-sm-48, .my-sm-48 {
    margin-bottom: 9.5rem !important;
  }
  .ml-sm-48, .mx-sm-48 {
    margin-left: 9.5rem !important;
  }
  .m-sm-39 {
    margin: 9.75rem !important;
  }
  .mt-sm-39, .my-sm-39 {
    margin-top: 9.75rem !important;
  }
  .mr-sm-39, .mx-sm-39 {
    margin-right: 9.75rem !important;
  }
  .mb-sm-39, .my-sm-39 {
    margin-bottom: 9.75rem !important;
  }
  .ml-sm-39, .mx-sm-39 {
    margin-left: 9.75rem !important;
  }
  .m-sm-40 {
    margin: 10rem !important;
  }
  .mt-sm-40, .my-sm-40 {
    margin-top: 10rem !important;
  }
  .mr-sm-40, .mx-sm-40 {
    margin-right: 10rem !important;
  }
  .mb-sm-40, .my-sm-40 {
    margin-bottom: 10rem !important;
  }
  .ml-sm-40, .mx-sm-40 {
    margin-left: 10rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1, .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1, .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1, .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1, .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2, .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2, .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2, .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2, .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .pt-sm-3, .py-sm-3 {
    padding-top: 0.75rem !important;
  }
  .pr-sm-3, .px-sm-3 {
    padding-right: 0.75rem !important;
  }
  .pb-sm-3, .py-sm-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-sm-3, .px-sm-3 {
    padding-left: 0.75rem !important;
  }
  .p-sm-4 {
    padding: 1rem !important;
  }
  .pt-sm-4, .py-sm-4 {
    padding-top: 1rem !important;
  }
  .pr-sm-4, .px-sm-4 {
    padding-right: 1rem !important;
  }
  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-4, .px-sm-4 {
    padding-left: 1rem !important;
  }
  .p-sm-5 {
    padding: 1.25rem !important;
  }
  .pt-sm-5, .py-sm-5 {
    padding-top: 1.25rem !important;
  }
  .pr-sm-5, .px-sm-5 {
    padding-right: 1.25rem !important;
  }
  .pb-sm-5, .py-sm-5 {
    padding-bottom: 1.25rem !important;
  }
  .pl-sm-5, .px-sm-5 {
    padding-left: 1.25rem !important;
  }
  .p-sm-6 {
    padding: 1.5rem !important;
  }
  .pt-sm-6, .py-sm-6 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-6, .px-sm-6 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-6, .py-sm-6 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-6, .px-sm-6 {
    padding-left: 1.5rem !important;
  }
  .p-sm-7 {
    padding: 1.75rem !important;
  }
  .pt-sm-7, .py-sm-7 {
    padding-top: 1.75rem !important;
  }
  .pr-sm-7, .px-sm-7 {
    padding-right: 1.75rem !important;
  }
  .pb-sm-7, .py-sm-7 {
    padding-bottom: 1.75rem !important;
  }
  .pl-sm-7, .px-sm-7 {
    padding-left: 1.75rem !important;
  }
  .p-sm-8 {
    padding: 2rem !important;
  }
  .pt-sm-8, .py-sm-8 {
    padding-top: 2rem !important;
  }
  .pr-sm-8, .px-sm-8 {
    padding-right: 2rem !important;
  }
  .pb-sm-8, .py-sm-8 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-8, .px-sm-8 {
    padding-left: 2rem !important;
  }
  .p-sm-9 {
    padding: 2.25rem !important;
  }
  .pt-sm-9, .py-sm-9 {
    padding-top: 2.25rem !important;
  }
  .pr-sm-9, .px-sm-9 {
    padding-right: 2.25rem !important;
  }
  .pb-sm-9, .py-sm-9 {
    padding-bottom: 2.25rem !important;
  }
  .pl-sm-9, .px-sm-9 {
    padding-left: 2.25rem !important;
  }
  .p-sm-10 {
    padding: 2.5rem !important;
  }
  .pt-sm-10, .py-sm-10 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-10, .px-sm-10 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-10, .py-sm-10 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-10, .px-sm-10 {
    padding-left: 2.5rem !important;
  }
  .p-sm-11 {
    padding: 2.75rem !important;
  }
  .pt-sm-11, .py-sm-11 {
    padding-top: 2.75rem !important;
  }
  .pr-sm-11, .px-sm-11 {
    padding-right: 2.75rem !important;
  }
  .pb-sm-11, .py-sm-11 {
    padding-bottom: 2.75rem !important;
  }
  .pl-sm-11, .px-sm-11 {
    padding-left: 2.75rem !important;
  }
  .p-sm-12 {
    padding: 3rem !important;
  }
  .pt-sm-12, .py-sm-12 {
    padding-top: 3rem !important;
  }
  .pr-sm-12, .px-sm-12 {
    padding-right: 3rem !important;
  }
  .pb-sm-12, .py-sm-12 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-12, .px-sm-12 {
    padding-left: 3rem !important;
  }
  .p-sm-13 {
    padding: 3.25rem !important;
  }
  .pt-sm-13, .py-sm-13 {
    padding-top: 3.25rem !important;
  }
  .pr-sm-13, .px-sm-13 {
    padding-right: 3.25rem !important;
  }
  .pb-sm-13, .py-sm-13 {
    padding-bottom: 3.25rem !important;
  }
  .pl-sm-13, .px-sm-13 {
    padding-left: 3.25rem !important;
  }
  .p-sm-14 {
    padding: 3.5rem !important;
  }
  .pt-sm-14, .py-sm-14 {
    padding-top: 3.5rem !important;
  }
  .pr-sm-14, .px-sm-14 {
    padding-right: 3.5rem !important;
  }
  .pb-sm-14, .py-sm-14 {
    padding-bottom: 3.5rem !important;
  }
  .pl-sm-14, .px-sm-14 {
    padding-left: 3.5rem !important;
  }
  .p-sm-15 {
    padding: 3.75rem !important;
  }
  .pt-sm-15, .py-sm-15 {
    padding-top: 3.75rem !important;
  }
  .pr-sm-15, .px-sm-15 {
    padding-right: 3.75rem !important;
  }
  .pb-sm-15, .py-sm-15 {
    padding-bottom: 3.75rem !important;
  }
  .pl-sm-15, .px-sm-15 {
    padding-left: 3.75rem !important;
  }
  .p-sm-16 {
    padding: 4rem !important;
  }
  .pt-sm-16, .py-sm-16 {
    padding-top: 4rem !important;
  }
  .pr-sm-16, .px-sm-16 {
    padding-right: 4rem !important;
  }
  .pb-sm-16, .py-sm-16 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-16, .px-sm-16 {
    padding-left: 4rem !important;
  }
  .p-sm-17 {
    padding: 4.25rem !important;
  }
  .pt-sm-17, .py-sm-17 {
    padding-top: 4.25rem !important;
  }
  .pr-sm-17, .px-sm-17 {
    padding-right: 4.25rem !important;
  }
  .pb-sm-17, .py-sm-17 {
    padding-bottom: 4.25rem !important;
  }
  .pl-sm-17, .px-sm-17 {
    padding-left: 4.25rem !important;
  }
  .p-sm-18 {
    padding: 4.5rem !important;
  }
  .pt-sm-18, .py-sm-18 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-18, .px-sm-18 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-18, .py-sm-18 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-18, .px-sm-18 {
    padding-left: 4.5rem !important;
  }
  .p-sm-19 {
    padding: 4.75rem !important;
  }
  .pt-sm-19, .py-sm-19 {
    padding-top: 4.75rem !important;
  }
  .pr-sm-19, .px-sm-19 {
    padding-right: 4.75rem !important;
  }
  .pb-sm-19, .py-sm-19 {
    padding-bottom: 4.75rem !important;
  }
  .pl-sm-19, .px-sm-19 {
    padding-left: 4.75rem !important;
  }
  .p-sm-20 {
    padding: 5rem !important;
  }
  .pt-sm-20, .py-sm-20 {
    padding-top: 5rem !important;
  }
  .pr-sm-20, .px-sm-20 {
    padding-right: 5rem !important;
  }
  .pb-sm-20, .py-sm-20 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-20, .px-sm-20 {
    padding-left: 5rem !important;
  }
  .p-sm-21 {
    padding: 5.25rem !important;
  }
  .pt-sm-21, .py-sm-21 {
    padding-top: 5.25rem !important;
  }
  .pr-sm-21, .px-sm-21 {
    padding-right: 5.25rem !important;
  }
  .pb-sm-21, .py-sm-21 {
    padding-bottom: 5.25rem !important;
  }
  .pl-sm-21, .px-sm-21 {
    padding-left: 5.25rem !important;
  }
  .p-sm-22 {
    padding: 5.5rem !important;
  }
  .pt-sm-22, .py-sm-22 {
    padding-top: 5.5rem !important;
  }
  .pr-sm-22, .px-sm-22 {
    padding-right: 5.5rem !important;
  }
  .pb-sm-22, .py-sm-22 {
    padding-bottom: 5.5rem !important;
  }
  .pl-sm-22, .px-sm-22 {
    padding-left: 5.5rem !important;
  }
  .p-sm-23 {
    padding: 5.75rem !important;
  }
  .pt-sm-23, .py-sm-23 {
    padding-top: 5.75rem !important;
  }
  .pr-sm-23, .px-sm-23 {
    padding-right: 5.75rem !important;
  }
  .pb-sm-23, .py-sm-23 {
    padding-bottom: 5.75rem !important;
  }
  .pl-sm-23, .px-sm-23 {
    padding-left: 5.75rem !important;
  }
  .p-sm-24 {
    padding: 6rem !important;
  }
  .pt-sm-24, .py-sm-24 {
    padding-top: 6rem !important;
  }
  .pr-sm-24, .px-sm-24 {
    padding-right: 6rem !important;
  }
  .pb-sm-24, .py-sm-24 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-24, .px-sm-24 {
    padding-left: 6rem !important;
  }
  .p-sm-25 {
    padding: 6.25rem !important;
  }
  .pt-sm-25, .py-sm-25 {
    padding-top: 6.25rem !important;
  }
  .pr-sm-25, .px-sm-25 {
    padding-right: 6.25rem !important;
  }
  .pb-sm-25, .py-sm-25 {
    padding-bottom: 6.25rem !important;
  }
  .pl-sm-25, .px-sm-25 {
    padding-left: 6.25rem !important;
  }
  .p-sm-26 {
    padding: 6.5rem !important;
  }
  .pt-sm-26, .py-sm-26 {
    padding-top: 6.5rem !important;
  }
  .pr-sm-26, .px-sm-26 {
    padding-right: 6.5rem !important;
  }
  .pb-sm-26, .py-sm-26 {
    padding-bottom: 6.5rem !important;
  }
  .pl-sm-26, .px-sm-26 {
    padding-left: 6.5rem !important;
  }
  .p-sm-27 {
    padding: 6.75rem !important;
  }
  .pt-sm-27, .py-sm-27 {
    padding-top: 6.75rem !important;
  }
  .pr-sm-27, .px-sm-27 {
    padding-right: 6.75rem !important;
  }
  .pb-sm-27, .py-sm-27 {
    padding-bottom: 6.75rem !important;
  }
  .pl-sm-27, .px-sm-27 {
    padding-left: 6.75rem !important;
  }
  .p-sm-28 {
    padding: 7rem !important;
  }
  .pt-sm-28, .py-sm-28 {
    padding-top: 7rem !important;
  }
  .pr-sm-28, .px-sm-28 {
    padding-right: 7rem !important;
  }
  .pb-sm-28, .py-sm-28 {
    padding-bottom: 7rem !important;
  }
  .pl-sm-28, .px-sm-28 {
    padding-left: 7rem !important;
  }
  .p-sm-29 {
    padding: 7.25rem !important;
  }
  .pt-sm-29, .py-sm-29 {
    padding-top: 7.25rem !important;
  }
  .pr-sm-29, .px-sm-29 {
    padding-right: 7.25rem !important;
  }
  .pb-sm-29, .py-sm-29 {
    padding-bottom: 7.25rem !important;
  }
  .pl-sm-29, .px-sm-29 {
    padding-left: 7.25rem !important;
  }
  .p-sm-30 {
    padding: 7.5rem !important;
  }
  .pt-sm-30, .py-sm-30 {
    padding-top: 7.5rem !important;
  }
  .pr-sm-30, .px-sm-30 {
    padding-right: 7.5rem !important;
  }
  .pb-sm-30, .py-sm-30 {
    padding-bottom: 7.5rem !important;
  }
  .pl-sm-30, .px-sm-30 {
    padding-left: 7.5rem !important;
  }
  .p-sm-31 {
    padding: 7.75rem !important;
  }
  .pt-sm-31, .py-sm-31 {
    padding-top: 7.75rem !important;
  }
  .pr-sm-31, .px-sm-31 {
    padding-right: 7.75rem !important;
  }
  .pb-sm-31, .py-sm-31 {
    padding-bottom: 7.75rem !important;
  }
  .pl-sm-31, .px-sm-31 {
    padding-left: 7.75rem !important;
  }
  .p-sm-32 {
    padding: 8rem !important;
  }
  .pt-sm-32, .py-sm-32 {
    padding-top: 8rem !important;
  }
  .pr-sm-32, .px-sm-32 {
    padding-right: 8rem !important;
  }
  .pb-sm-32, .py-sm-32 {
    padding-bottom: 8rem !important;
  }
  .pl-sm-32, .px-sm-32 {
    padding-left: 8rem !important;
  }
  .p-sm-33 {
    padding: 8.25rem !important;
  }
  .pt-sm-33, .py-sm-33 {
    padding-top: 8.25rem !important;
  }
  .pr-sm-33, .px-sm-33 {
    padding-right: 8.25rem !important;
  }
  .pb-sm-33, .py-sm-33 {
    padding-bottom: 8.25rem !important;
  }
  .pl-sm-33, .px-sm-33 {
    padding-left: 8.25rem !important;
  }
  .p-sm-34 {
    padding: 8.5rem !important;
  }
  .pt-sm-34, .py-sm-34 {
    padding-top: 8.5rem !important;
  }
  .pr-sm-34, .px-sm-34 {
    padding-right: 8.5rem !important;
  }
  .pb-sm-34, .py-sm-34 {
    padding-bottom: 8.5rem !important;
  }
  .pl-sm-34, .px-sm-34 {
    padding-left: 8.5rem !important;
  }
  .p-sm-35 {
    padding: 8.75rem !important;
  }
  .pt-sm-35, .py-sm-35 {
    padding-top: 8.75rem !important;
  }
  .pr-sm-35, .px-sm-35 {
    padding-right: 8.75rem !important;
  }
  .pb-sm-35, .py-sm-35 {
    padding-bottom: 8.75rem !important;
  }
  .pl-sm-35, .px-sm-35 {
    padding-left: 8.75rem !important;
  }
  .p-sm-36 {
    padding: 9rem !important;
  }
  .pt-sm-36, .py-sm-36 {
    padding-top: 9rem !important;
  }
  .pr-sm-36, .px-sm-36 {
    padding-right: 9rem !important;
  }
  .pb-sm-36, .py-sm-36 {
    padding-bottom: 9rem !important;
  }
  .pl-sm-36, .px-sm-36 {
    padding-left: 9rem !important;
  }
  .p-sm-37 {
    padding: 9.25rem !important;
  }
  .pt-sm-37, .py-sm-37 {
    padding-top: 9.25rem !important;
  }
  .pr-sm-37, .px-sm-37 {
    padding-right: 9.25rem !important;
  }
  .pb-sm-37, .py-sm-37 {
    padding-bottom: 9.25rem !important;
  }
  .pl-sm-37, .px-sm-37 {
    padding-left: 9.25rem !important;
  }
  .p-sm-48 {
    padding: 9.5rem !important;
  }
  .pt-sm-48, .py-sm-48 {
    padding-top: 9.5rem !important;
  }
  .pr-sm-48, .px-sm-48 {
    padding-right: 9.5rem !important;
  }
  .pb-sm-48, .py-sm-48 {
    padding-bottom: 9.5rem !important;
  }
  .pl-sm-48, .px-sm-48 {
    padding-left: 9.5rem !important;
  }
  .p-sm-39 {
    padding: 9.75rem !important;
  }
  .pt-sm-39, .py-sm-39 {
    padding-top: 9.75rem !important;
  }
  .pr-sm-39, .px-sm-39 {
    padding-right: 9.75rem !important;
  }
  .pb-sm-39, .py-sm-39 {
    padding-bottom: 9.75rem !important;
  }
  .pl-sm-39, .px-sm-39 {
    padding-left: 9.75rem !important;
  }
  .p-sm-40 {
    padding: 10rem !important;
  }
  .pt-sm-40, .py-sm-40 {
    padding-top: 10rem !important;
  }
  .pr-sm-40, .px-sm-40 {
    padding-right: 10rem !important;
  }
  .pb-sm-40, .py-sm-40 {
    padding-bottom: 10rem !important;
  }
  .pl-sm-40, .px-sm-40 {
    padding-left: 10rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1, .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2, .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -0.75rem !important;
  }
  .mt-sm-n3, .my-sm-n3 {
    margin-top: -0.75rem !important;
  }
  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -0.75rem !important;
  }
  .m-sm-n4 {
    margin: -1rem !important;
  }
  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1rem !important;
  }
  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -1rem !important;
  }
  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -1rem !important;
  }
  .m-sm-n5 {
    margin: -1.25rem !important;
  }
  .mt-sm-n5, .my-sm-n5 {
    margin-top: -1.25rem !important;
  }
  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -1.25rem !important;
  }
  .m-sm-n6 {
    margin: -1.5rem !important;
  }
  .mt-sm-n6, .my-sm-n6 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n6, .mx-sm-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n6, .my-sm-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n6, .mx-sm-n6 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n7 {
    margin: -1.75rem !important;
  }
  .mt-sm-n7, .my-sm-n7 {
    margin-top: -1.75rem !important;
  }
  .mr-sm-n7, .mx-sm-n7 {
    margin-right: -1.75rem !important;
  }
  .mb-sm-n7, .my-sm-n7 {
    margin-bottom: -1.75rem !important;
  }
  .ml-sm-n7, .mx-sm-n7 {
    margin-left: -1.75rem !important;
  }
  .m-sm-n8 {
    margin: -2rem !important;
  }
  .mt-sm-n8, .my-sm-n8 {
    margin-top: -2rem !important;
  }
  .mr-sm-n8, .mx-sm-n8 {
    margin-right: -2rem !important;
  }
  .mb-sm-n8, .my-sm-n8 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n8, .mx-sm-n8 {
    margin-left: -2rem !important;
  }
  .m-sm-n9 {
    margin: -2.25rem !important;
  }
  .mt-sm-n9, .my-sm-n9 {
    margin-top: -2.25rem !important;
  }
  .mr-sm-n9, .mx-sm-n9 {
    margin-right: -2.25rem !important;
  }
  .mb-sm-n9, .my-sm-n9 {
    margin-bottom: -2.25rem !important;
  }
  .ml-sm-n9, .mx-sm-n9 {
    margin-left: -2.25rem !important;
  }
  .m-sm-n10 {
    margin: -2.5rem !important;
  }
  .mt-sm-n10, .my-sm-n10 {
    margin-top: -2.5rem !important;
  }
  .mr-sm-n10, .mx-sm-n10 {
    margin-right: -2.5rem !important;
  }
  .mb-sm-n10, .my-sm-n10 {
    margin-bottom: -2.5rem !important;
  }
  .ml-sm-n10, .mx-sm-n10 {
    margin-left: -2.5rem !important;
  }
  .m-sm-n11 {
    margin: -2.75rem !important;
  }
  .mt-sm-n11, .my-sm-n11 {
    margin-top: -2.75rem !important;
  }
  .mr-sm-n11, .mx-sm-n11 {
    margin-right: -2.75rem !important;
  }
  .mb-sm-n11, .my-sm-n11 {
    margin-bottom: -2.75rem !important;
  }
  .ml-sm-n11, .mx-sm-n11 {
    margin-left: -2.75rem !important;
  }
  .m-sm-n12 {
    margin: -3rem !important;
  }
  .mt-sm-n12, .my-sm-n12 {
    margin-top: -3rem !important;
  }
  .mr-sm-n12, .mx-sm-n12 {
    margin-right: -3rem !important;
  }
  .mb-sm-n12, .my-sm-n12 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n12, .mx-sm-n12 {
    margin-left: -3rem !important;
  }
  .m-sm-n13 {
    margin: -3.25rem !important;
  }
  .mt-sm-n13, .my-sm-n13 {
    margin-top: -3.25rem !important;
  }
  .mr-sm-n13, .mx-sm-n13 {
    margin-right: -3.25rem !important;
  }
  .mb-sm-n13, .my-sm-n13 {
    margin-bottom: -3.25rem !important;
  }
  .ml-sm-n13, .mx-sm-n13 {
    margin-left: -3.25rem !important;
  }
  .m-sm-n14 {
    margin: -3.5rem !important;
  }
  .mt-sm-n14, .my-sm-n14 {
    margin-top: -3.5rem !important;
  }
  .mr-sm-n14, .mx-sm-n14 {
    margin-right: -3.5rem !important;
  }
  .mb-sm-n14, .my-sm-n14 {
    margin-bottom: -3.5rem !important;
  }
  .ml-sm-n14, .mx-sm-n14 {
    margin-left: -3.5rem !important;
  }
  .m-sm-n15 {
    margin: -3.75rem !important;
  }
  .mt-sm-n15, .my-sm-n15 {
    margin-top: -3.75rem !important;
  }
  .mr-sm-n15, .mx-sm-n15 {
    margin-right: -3.75rem !important;
  }
  .mb-sm-n15, .my-sm-n15 {
    margin-bottom: -3.75rem !important;
  }
  .ml-sm-n15, .mx-sm-n15 {
    margin-left: -3.75rem !important;
  }
  .m-sm-n16 {
    margin: -4rem !important;
  }
  .mt-sm-n16, .my-sm-n16 {
    margin-top: -4rem !important;
  }
  .mr-sm-n16, .mx-sm-n16 {
    margin-right: -4rem !important;
  }
  .mb-sm-n16, .my-sm-n16 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n16, .mx-sm-n16 {
    margin-left: -4rem !important;
  }
  .m-sm-n17 {
    margin: -4.25rem !important;
  }
  .mt-sm-n17, .my-sm-n17 {
    margin-top: -4.25rem !important;
  }
  .mr-sm-n17, .mx-sm-n17 {
    margin-right: -4.25rem !important;
  }
  .mb-sm-n17, .my-sm-n17 {
    margin-bottom: -4.25rem !important;
  }
  .ml-sm-n17, .mx-sm-n17 {
    margin-left: -4.25rem !important;
  }
  .m-sm-n18 {
    margin: -4.5rem !important;
  }
  .mt-sm-n18, .my-sm-n18 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n18, .mx-sm-n18 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n18, .my-sm-n18 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n18, .mx-sm-n18 {
    margin-left: -4.5rem !important;
  }
  .m-sm-n19 {
    margin: -4.75rem !important;
  }
  .mt-sm-n19, .my-sm-n19 {
    margin-top: -4.75rem !important;
  }
  .mr-sm-n19, .mx-sm-n19 {
    margin-right: -4.75rem !important;
  }
  .mb-sm-n19, .my-sm-n19 {
    margin-bottom: -4.75rem !important;
  }
  .ml-sm-n19, .mx-sm-n19 {
    margin-left: -4.75rem !important;
  }
  .m-sm-n20 {
    margin: -5rem !important;
  }
  .mt-sm-n20, .my-sm-n20 {
    margin-top: -5rem !important;
  }
  .mr-sm-n20, .mx-sm-n20 {
    margin-right: -5rem !important;
  }
  .mb-sm-n20, .my-sm-n20 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n20, .mx-sm-n20 {
    margin-left: -5rem !important;
  }
  .m-sm-n21 {
    margin: -5.25rem !important;
  }
  .mt-sm-n21, .my-sm-n21 {
    margin-top: -5.25rem !important;
  }
  .mr-sm-n21, .mx-sm-n21 {
    margin-right: -5.25rem !important;
  }
  .mb-sm-n21, .my-sm-n21 {
    margin-bottom: -5.25rem !important;
  }
  .ml-sm-n21, .mx-sm-n21 {
    margin-left: -5.25rem !important;
  }
  .m-sm-n22 {
    margin: -5.5rem !important;
  }
  .mt-sm-n22, .my-sm-n22 {
    margin-top: -5.5rem !important;
  }
  .mr-sm-n22, .mx-sm-n22 {
    margin-right: -5.5rem !important;
  }
  .mb-sm-n22, .my-sm-n22 {
    margin-bottom: -5.5rem !important;
  }
  .ml-sm-n22, .mx-sm-n22 {
    margin-left: -5.5rem !important;
  }
  .m-sm-n23 {
    margin: -5.75rem !important;
  }
  .mt-sm-n23, .my-sm-n23 {
    margin-top: -5.75rem !important;
  }
  .mr-sm-n23, .mx-sm-n23 {
    margin-right: -5.75rem !important;
  }
  .mb-sm-n23, .my-sm-n23 {
    margin-bottom: -5.75rem !important;
  }
  .ml-sm-n23, .mx-sm-n23 {
    margin-left: -5.75rem !important;
  }
  .m-sm-n24 {
    margin: -6rem !important;
  }
  .mt-sm-n24, .my-sm-n24 {
    margin-top: -6rem !important;
  }
  .mr-sm-n24, .mx-sm-n24 {
    margin-right: -6rem !important;
  }
  .mb-sm-n24, .my-sm-n24 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n24, .mx-sm-n24 {
    margin-left: -6rem !important;
  }
  .m-sm-n25 {
    margin: -6.25rem !important;
  }
  .mt-sm-n25, .my-sm-n25 {
    margin-top: -6.25rem !important;
  }
  .mr-sm-n25, .mx-sm-n25 {
    margin-right: -6.25rem !important;
  }
  .mb-sm-n25, .my-sm-n25 {
    margin-bottom: -6.25rem !important;
  }
  .ml-sm-n25, .mx-sm-n25 {
    margin-left: -6.25rem !important;
  }
  .m-sm-n26 {
    margin: -6.5rem !important;
  }
  .mt-sm-n26, .my-sm-n26 {
    margin-top: -6.5rem !important;
  }
  .mr-sm-n26, .mx-sm-n26 {
    margin-right: -6.5rem !important;
  }
  .mb-sm-n26, .my-sm-n26 {
    margin-bottom: -6.5rem !important;
  }
  .ml-sm-n26, .mx-sm-n26 {
    margin-left: -6.5rem !important;
  }
  .m-sm-n27 {
    margin: -6.75rem !important;
  }
  .mt-sm-n27, .my-sm-n27 {
    margin-top: -6.75rem !important;
  }
  .mr-sm-n27, .mx-sm-n27 {
    margin-right: -6.75rem !important;
  }
  .mb-sm-n27, .my-sm-n27 {
    margin-bottom: -6.75rem !important;
  }
  .ml-sm-n27, .mx-sm-n27 {
    margin-left: -6.75rem !important;
  }
  .m-sm-n28 {
    margin: -7rem !important;
  }
  .mt-sm-n28, .my-sm-n28 {
    margin-top: -7rem !important;
  }
  .mr-sm-n28, .mx-sm-n28 {
    margin-right: -7rem !important;
  }
  .mb-sm-n28, .my-sm-n28 {
    margin-bottom: -7rem !important;
  }
  .ml-sm-n28, .mx-sm-n28 {
    margin-left: -7rem !important;
  }
  .m-sm-n29 {
    margin: -7.25rem !important;
  }
  .mt-sm-n29, .my-sm-n29 {
    margin-top: -7.25rem !important;
  }
  .mr-sm-n29, .mx-sm-n29 {
    margin-right: -7.25rem !important;
  }
  .mb-sm-n29, .my-sm-n29 {
    margin-bottom: -7.25rem !important;
  }
  .ml-sm-n29, .mx-sm-n29 {
    margin-left: -7.25rem !important;
  }
  .m-sm-n30 {
    margin: -7.5rem !important;
  }
  .mt-sm-n30, .my-sm-n30 {
    margin-top: -7.5rem !important;
  }
  .mr-sm-n30, .mx-sm-n30 {
    margin-right: -7.5rem !important;
  }
  .mb-sm-n30, .my-sm-n30 {
    margin-bottom: -7.5rem !important;
  }
  .ml-sm-n30, .mx-sm-n30 {
    margin-left: -7.5rem !important;
  }
  .m-sm-n31 {
    margin: -7.75rem !important;
  }
  .mt-sm-n31, .my-sm-n31 {
    margin-top: -7.75rem !important;
  }
  .mr-sm-n31, .mx-sm-n31 {
    margin-right: -7.75rem !important;
  }
  .mb-sm-n31, .my-sm-n31 {
    margin-bottom: -7.75rem !important;
  }
  .ml-sm-n31, .mx-sm-n31 {
    margin-left: -7.75rem !important;
  }
  .m-sm-n32 {
    margin: -8rem !important;
  }
  .mt-sm-n32, .my-sm-n32 {
    margin-top: -8rem !important;
  }
  .mr-sm-n32, .mx-sm-n32 {
    margin-right: -8rem !important;
  }
  .mb-sm-n32, .my-sm-n32 {
    margin-bottom: -8rem !important;
  }
  .ml-sm-n32, .mx-sm-n32 {
    margin-left: -8rem !important;
  }
  .m-sm-n33 {
    margin: -8.25rem !important;
  }
  .mt-sm-n33, .my-sm-n33 {
    margin-top: -8.25rem !important;
  }
  .mr-sm-n33, .mx-sm-n33 {
    margin-right: -8.25rem !important;
  }
  .mb-sm-n33, .my-sm-n33 {
    margin-bottom: -8.25rem !important;
  }
  .ml-sm-n33, .mx-sm-n33 {
    margin-left: -8.25rem !important;
  }
  .m-sm-n34 {
    margin: -8.5rem !important;
  }
  .mt-sm-n34, .my-sm-n34 {
    margin-top: -8.5rem !important;
  }
  .mr-sm-n34, .mx-sm-n34 {
    margin-right: -8.5rem !important;
  }
  .mb-sm-n34, .my-sm-n34 {
    margin-bottom: -8.5rem !important;
  }
  .ml-sm-n34, .mx-sm-n34 {
    margin-left: -8.5rem !important;
  }
  .m-sm-n35 {
    margin: -8.75rem !important;
  }
  .mt-sm-n35, .my-sm-n35 {
    margin-top: -8.75rem !important;
  }
  .mr-sm-n35, .mx-sm-n35 {
    margin-right: -8.75rem !important;
  }
  .mb-sm-n35, .my-sm-n35 {
    margin-bottom: -8.75rem !important;
  }
  .ml-sm-n35, .mx-sm-n35 {
    margin-left: -8.75rem !important;
  }
  .m-sm-n36 {
    margin: -9rem !important;
  }
  .mt-sm-n36, .my-sm-n36 {
    margin-top: -9rem !important;
  }
  .mr-sm-n36, .mx-sm-n36 {
    margin-right: -9rem !important;
  }
  .mb-sm-n36, .my-sm-n36 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-n36, .mx-sm-n36 {
    margin-left: -9rem !important;
  }
  .m-sm-n37 {
    margin: -9.25rem !important;
  }
  .mt-sm-n37, .my-sm-n37 {
    margin-top: -9.25rem !important;
  }
  .mr-sm-n37, .mx-sm-n37 {
    margin-right: -9.25rem !important;
  }
  .mb-sm-n37, .my-sm-n37 {
    margin-bottom: -9.25rem !important;
  }
  .ml-sm-n37, .mx-sm-n37 {
    margin-left: -9.25rem !important;
  }
  .m-sm-n48 {
    margin: -9.5rem !important;
  }
  .mt-sm-n48, .my-sm-n48 {
    margin-top: -9.5rem !important;
  }
  .mr-sm-n48, .mx-sm-n48 {
    margin-right: -9.5rem !important;
  }
  .mb-sm-n48, .my-sm-n48 {
    margin-bottom: -9.5rem !important;
  }
  .ml-sm-n48, .mx-sm-n48 {
    margin-left: -9.5rem !important;
  }
  .m-sm-n39 {
    margin: -9.75rem !important;
  }
  .mt-sm-n39, .my-sm-n39 {
    margin-top: -9.75rem !important;
  }
  .mr-sm-n39, .mx-sm-n39 {
    margin-right: -9.75rem !important;
  }
  .mb-sm-n39, .my-sm-n39 {
    margin-bottom: -9.75rem !important;
  }
  .ml-sm-n39, .mx-sm-n39 {
    margin-left: -9.75rem !important;
  }
  .m-sm-n40 {
    margin: -10rem !important;
  }
  .mt-sm-n40, .my-sm-n40 {
    margin-top: -10rem !important;
  }
  .mr-sm-n40, .mx-sm-n40 {
    margin-right: -10rem !important;
  }
  .mb-sm-n40, .my-sm-n40 {
    margin-bottom: -10rem !important;
  }
  .ml-sm-n40, .mx-sm-n40 {
    margin-left: -10rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1, .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1, .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1, .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1, .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2, .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2, .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2, .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2, .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .mt-md-3, .my-md-3 {
    margin-top: 0.75rem !important;
  }
  .mr-md-3, .mx-md-3 {
    margin-right: 0.75rem !important;
  }
  .mb-md-3, .my-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-md-3, .mx-md-3 {
    margin-left: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .mt-md-4, .my-md-4 {
    margin-top: 1rem !important;
  }
  .mr-md-4, .mx-md-4 {
    margin-right: 1rem !important;
  }
  .mb-md-4, .my-md-4 {
    margin-bottom: 1rem !important;
  }
  .ml-md-4, .mx-md-4 {
    margin-left: 1rem !important;
  }
  .m-md-5 {
    margin: 1.25rem !important;
  }
  .mt-md-5, .my-md-5 {
    margin-top: 1.25rem !important;
  }
  .mr-md-5, .mx-md-5 {
    margin-right: 1.25rem !important;
  }
  .mb-md-5, .my-md-5 {
    margin-bottom: 1.25rem !important;
  }
  .ml-md-5, .mx-md-5 {
    margin-left: 1.25rem !important;
  }
  .m-md-6 {
    margin: 1.5rem !important;
  }
  .mt-md-6, .my-md-6 {
    margin-top: 1.5rem !important;
  }
  .mr-md-6, .mx-md-6 {
    margin-right: 1.5rem !important;
  }
  .mb-md-6, .my-md-6 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-6, .mx-md-6 {
    margin-left: 1.5rem !important;
  }
  .m-md-7 {
    margin: 1.75rem !important;
  }
  .mt-md-7, .my-md-7 {
    margin-top: 1.75rem !important;
  }
  .mr-md-7, .mx-md-7 {
    margin-right: 1.75rem !important;
  }
  .mb-md-7, .my-md-7 {
    margin-bottom: 1.75rem !important;
  }
  .ml-md-7, .mx-md-7 {
    margin-left: 1.75rem !important;
  }
  .m-md-8 {
    margin: 2rem !important;
  }
  .mt-md-8, .my-md-8 {
    margin-top: 2rem !important;
  }
  .mr-md-8, .mx-md-8 {
    margin-right: 2rem !important;
  }
  .mb-md-8, .my-md-8 {
    margin-bottom: 2rem !important;
  }
  .ml-md-8, .mx-md-8 {
    margin-left: 2rem !important;
  }
  .m-md-9 {
    margin: 2.25rem !important;
  }
  .mt-md-9, .my-md-9 {
    margin-top: 2.25rem !important;
  }
  .mr-md-9, .mx-md-9 {
    margin-right: 2.25rem !important;
  }
  .mb-md-9, .my-md-9 {
    margin-bottom: 2.25rem !important;
  }
  .ml-md-9, .mx-md-9 {
    margin-left: 2.25rem !important;
  }
  .m-md-10 {
    margin: 2.5rem !important;
  }
  .mt-md-10, .my-md-10 {
    margin-top: 2.5rem !important;
  }
  .mr-md-10, .mx-md-10 {
    margin-right: 2.5rem !important;
  }
  .mb-md-10, .my-md-10 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-10, .mx-md-10 {
    margin-left: 2.5rem !important;
  }
  .m-md-11 {
    margin: 2.75rem !important;
  }
  .mt-md-11, .my-md-11 {
    margin-top: 2.75rem !important;
  }
  .mr-md-11, .mx-md-11 {
    margin-right: 2.75rem !important;
  }
  .mb-md-11, .my-md-11 {
    margin-bottom: 2.75rem !important;
  }
  .ml-md-11, .mx-md-11 {
    margin-left: 2.75rem !important;
  }
  .m-md-12 {
    margin: 3rem !important;
  }
  .mt-md-12, .my-md-12 {
    margin-top: 3rem !important;
  }
  .mr-md-12, .mx-md-12 {
    margin-right: 3rem !important;
  }
  .mb-md-12, .my-md-12 {
    margin-bottom: 3rem !important;
  }
  .ml-md-12, .mx-md-12 {
    margin-left: 3rem !important;
  }
  .m-md-13 {
    margin: 3.25rem !important;
  }
  .mt-md-13, .my-md-13 {
    margin-top: 3.25rem !important;
  }
  .mr-md-13, .mx-md-13 {
    margin-right: 3.25rem !important;
  }
  .mb-md-13, .my-md-13 {
    margin-bottom: 3.25rem !important;
  }
  .ml-md-13, .mx-md-13 {
    margin-left: 3.25rem !important;
  }
  .m-md-14 {
    margin: 3.5rem !important;
  }
  .mt-md-14, .my-md-14 {
    margin-top: 3.5rem !important;
  }
  .mr-md-14, .mx-md-14 {
    margin-right: 3.5rem !important;
  }
  .mb-md-14, .my-md-14 {
    margin-bottom: 3.5rem !important;
  }
  .ml-md-14, .mx-md-14 {
    margin-left: 3.5rem !important;
  }
  .m-md-15 {
    margin: 3.75rem !important;
  }
  .mt-md-15, .my-md-15 {
    margin-top: 3.75rem !important;
  }
  .mr-md-15, .mx-md-15 {
    margin-right: 3.75rem !important;
  }
  .mb-md-15, .my-md-15 {
    margin-bottom: 3.75rem !important;
  }
  .ml-md-15, .mx-md-15 {
    margin-left: 3.75rem !important;
  }
  .m-md-16 {
    margin: 4rem !important;
  }
  .mt-md-16, .my-md-16 {
    margin-top: 4rem !important;
  }
  .mr-md-16, .mx-md-16 {
    margin-right: 4rem !important;
  }
  .mb-md-16, .my-md-16 {
    margin-bottom: 4rem !important;
  }
  .ml-md-16, .mx-md-16 {
    margin-left: 4rem !important;
  }
  .m-md-17 {
    margin: 4.25rem !important;
  }
  .mt-md-17, .my-md-17 {
    margin-top: 4.25rem !important;
  }
  .mr-md-17, .mx-md-17 {
    margin-right: 4.25rem !important;
  }
  .mb-md-17, .my-md-17 {
    margin-bottom: 4.25rem !important;
  }
  .ml-md-17, .mx-md-17 {
    margin-left: 4.25rem !important;
  }
  .m-md-18 {
    margin: 4.5rem !important;
  }
  .mt-md-18, .my-md-18 {
    margin-top: 4.5rem !important;
  }
  .mr-md-18, .mx-md-18 {
    margin-right: 4.5rem !important;
  }
  .mb-md-18, .my-md-18 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-18, .mx-md-18 {
    margin-left: 4.5rem !important;
  }
  .m-md-19 {
    margin: 4.75rem !important;
  }
  .mt-md-19, .my-md-19 {
    margin-top: 4.75rem !important;
  }
  .mr-md-19, .mx-md-19 {
    margin-right: 4.75rem !important;
  }
  .mb-md-19, .my-md-19 {
    margin-bottom: 4.75rem !important;
  }
  .ml-md-19, .mx-md-19 {
    margin-left: 4.75rem !important;
  }
  .m-md-20 {
    margin: 5rem !important;
  }
  .mt-md-20, .my-md-20 {
    margin-top: 5rem !important;
  }
  .mr-md-20, .mx-md-20 {
    margin-right: 5rem !important;
  }
  .mb-md-20, .my-md-20 {
    margin-bottom: 5rem !important;
  }
  .ml-md-20, .mx-md-20 {
    margin-left: 5rem !important;
  }
  .m-md-21 {
    margin: 5.25rem !important;
  }
  .mt-md-21, .my-md-21 {
    margin-top: 5.25rem !important;
  }
  .mr-md-21, .mx-md-21 {
    margin-right: 5.25rem !important;
  }
  .mb-md-21, .my-md-21 {
    margin-bottom: 5.25rem !important;
  }
  .ml-md-21, .mx-md-21 {
    margin-left: 5.25rem !important;
  }
  .m-md-22 {
    margin: 5.5rem !important;
  }
  .mt-md-22, .my-md-22 {
    margin-top: 5.5rem !important;
  }
  .mr-md-22, .mx-md-22 {
    margin-right: 5.5rem !important;
  }
  .mb-md-22, .my-md-22 {
    margin-bottom: 5.5rem !important;
  }
  .ml-md-22, .mx-md-22 {
    margin-left: 5.5rem !important;
  }
  .m-md-23 {
    margin: 5.75rem !important;
  }
  .mt-md-23, .my-md-23 {
    margin-top: 5.75rem !important;
  }
  .mr-md-23, .mx-md-23 {
    margin-right: 5.75rem !important;
  }
  .mb-md-23, .my-md-23 {
    margin-bottom: 5.75rem !important;
  }
  .ml-md-23, .mx-md-23 {
    margin-left: 5.75rem !important;
  }
  .m-md-24 {
    margin: 6rem !important;
  }
  .mt-md-24, .my-md-24 {
    margin-top: 6rem !important;
  }
  .mr-md-24, .mx-md-24 {
    margin-right: 6rem !important;
  }
  .mb-md-24, .my-md-24 {
    margin-bottom: 6rem !important;
  }
  .ml-md-24, .mx-md-24 {
    margin-left: 6rem !important;
  }
  .m-md-25 {
    margin: 6.25rem !important;
  }
  .mt-md-25, .my-md-25 {
    margin-top: 6.25rem !important;
  }
  .mr-md-25, .mx-md-25 {
    margin-right: 6.25rem !important;
  }
  .mb-md-25, .my-md-25 {
    margin-bottom: 6.25rem !important;
  }
  .ml-md-25, .mx-md-25 {
    margin-left: 6.25rem !important;
  }
  .m-md-26 {
    margin: 6.5rem !important;
  }
  .mt-md-26, .my-md-26 {
    margin-top: 6.5rem !important;
  }
  .mr-md-26, .mx-md-26 {
    margin-right: 6.5rem !important;
  }
  .mb-md-26, .my-md-26 {
    margin-bottom: 6.5rem !important;
  }
  .ml-md-26, .mx-md-26 {
    margin-left: 6.5rem !important;
  }
  .m-md-27 {
    margin: 6.75rem !important;
  }
  .mt-md-27, .my-md-27 {
    margin-top: 6.75rem !important;
  }
  .mr-md-27, .mx-md-27 {
    margin-right: 6.75rem !important;
  }
  .mb-md-27, .my-md-27 {
    margin-bottom: 6.75rem !important;
  }
  .ml-md-27, .mx-md-27 {
    margin-left: 6.75rem !important;
  }
  .m-md-28 {
    margin: 7rem !important;
  }
  .mt-md-28, .my-md-28 {
    margin-top: 7rem !important;
  }
  .mr-md-28, .mx-md-28 {
    margin-right: 7rem !important;
  }
  .mb-md-28, .my-md-28 {
    margin-bottom: 7rem !important;
  }
  .ml-md-28, .mx-md-28 {
    margin-left: 7rem !important;
  }
  .m-md-29 {
    margin: 7.25rem !important;
  }
  .mt-md-29, .my-md-29 {
    margin-top: 7.25rem !important;
  }
  .mr-md-29, .mx-md-29 {
    margin-right: 7.25rem !important;
  }
  .mb-md-29, .my-md-29 {
    margin-bottom: 7.25rem !important;
  }
  .ml-md-29, .mx-md-29 {
    margin-left: 7.25rem !important;
  }
  .m-md-30 {
    margin: 7.5rem !important;
  }
  .mt-md-30, .my-md-30 {
    margin-top: 7.5rem !important;
  }
  .mr-md-30, .mx-md-30 {
    margin-right: 7.5rem !important;
  }
  .mb-md-30, .my-md-30 {
    margin-bottom: 7.5rem !important;
  }
  .ml-md-30, .mx-md-30 {
    margin-left: 7.5rem !important;
  }
  .m-md-31 {
    margin: 7.75rem !important;
  }
  .mt-md-31, .my-md-31 {
    margin-top: 7.75rem !important;
  }
  .mr-md-31, .mx-md-31 {
    margin-right: 7.75rem !important;
  }
  .mb-md-31, .my-md-31 {
    margin-bottom: 7.75rem !important;
  }
  .ml-md-31, .mx-md-31 {
    margin-left: 7.75rem !important;
  }
  .m-md-32 {
    margin: 8rem !important;
  }
  .mt-md-32, .my-md-32 {
    margin-top: 8rem !important;
  }
  .mr-md-32, .mx-md-32 {
    margin-right: 8rem !important;
  }
  .mb-md-32, .my-md-32 {
    margin-bottom: 8rem !important;
  }
  .ml-md-32, .mx-md-32 {
    margin-left: 8rem !important;
  }
  .m-md-33 {
    margin: 8.25rem !important;
  }
  .mt-md-33, .my-md-33 {
    margin-top: 8.25rem !important;
  }
  .mr-md-33, .mx-md-33 {
    margin-right: 8.25rem !important;
  }
  .mb-md-33, .my-md-33 {
    margin-bottom: 8.25rem !important;
  }
  .ml-md-33, .mx-md-33 {
    margin-left: 8.25rem !important;
  }
  .m-md-34 {
    margin: 8.5rem !important;
  }
  .mt-md-34, .my-md-34 {
    margin-top: 8.5rem !important;
  }
  .mr-md-34, .mx-md-34 {
    margin-right: 8.5rem !important;
  }
  .mb-md-34, .my-md-34 {
    margin-bottom: 8.5rem !important;
  }
  .ml-md-34, .mx-md-34 {
    margin-left: 8.5rem !important;
  }
  .m-md-35 {
    margin: 8.75rem !important;
  }
  .mt-md-35, .my-md-35 {
    margin-top: 8.75rem !important;
  }
  .mr-md-35, .mx-md-35 {
    margin-right: 8.75rem !important;
  }
  .mb-md-35, .my-md-35 {
    margin-bottom: 8.75rem !important;
  }
  .ml-md-35, .mx-md-35 {
    margin-left: 8.75rem !important;
  }
  .m-md-36 {
    margin: 9rem !important;
  }
  .mt-md-36, .my-md-36 {
    margin-top: 9rem !important;
  }
  .mr-md-36, .mx-md-36 {
    margin-right: 9rem !important;
  }
  .mb-md-36, .my-md-36 {
    margin-bottom: 9rem !important;
  }
  .ml-md-36, .mx-md-36 {
    margin-left: 9rem !important;
  }
  .m-md-37 {
    margin: 9.25rem !important;
  }
  .mt-md-37, .my-md-37 {
    margin-top: 9.25rem !important;
  }
  .mr-md-37, .mx-md-37 {
    margin-right: 9.25rem !important;
  }
  .mb-md-37, .my-md-37 {
    margin-bottom: 9.25rem !important;
  }
  .ml-md-37, .mx-md-37 {
    margin-left: 9.25rem !important;
  }
  .m-md-48 {
    margin: 9.5rem !important;
  }
  .mt-md-48, .my-md-48 {
    margin-top: 9.5rem !important;
  }
  .mr-md-48, .mx-md-48 {
    margin-right: 9.5rem !important;
  }
  .mb-md-48, .my-md-48 {
    margin-bottom: 9.5rem !important;
  }
  .ml-md-48, .mx-md-48 {
    margin-left: 9.5rem !important;
  }
  .m-md-39 {
    margin: 9.75rem !important;
  }
  .mt-md-39, .my-md-39 {
    margin-top: 9.75rem !important;
  }
  .mr-md-39, .mx-md-39 {
    margin-right: 9.75rem !important;
  }
  .mb-md-39, .my-md-39 {
    margin-bottom: 9.75rem !important;
  }
  .ml-md-39, .mx-md-39 {
    margin-left: 9.75rem !important;
  }
  .m-md-40 {
    margin: 10rem !important;
  }
  .mt-md-40, .my-md-40 {
    margin-top: 10rem !important;
  }
  .mr-md-40, .mx-md-40 {
    margin-right: 10rem !important;
  }
  .mb-md-40, .my-md-40 {
    margin-bottom: 10rem !important;
  }
  .ml-md-40, .mx-md-40 {
    margin-left: 10rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1, .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1, .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1, .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1, .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2, .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2, .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2, .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2, .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .pt-md-3, .py-md-3 {
    padding-top: 0.75rem !important;
  }
  .pr-md-3, .px-md-3 {
    padding-right: 0.75rem !important;
  }
  .pb-md-3, .py-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-md-3, .px-md-3 {
    padding-left: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .pt-md-4, .py-md-4 {
    padding-top: 1rem !important;
  }
  .pr-md-4, .px-md-4 {
    padding-right: 1rem !important;
  }
  .pb-md-4, .py-md-4 {
    padding-bottom: 1rem !important;
  }
  .pl-md-4, .px-md-4 {
    padding-left: 1rem !important;
  }
  .p-md-5 {
    padding: 1.25rem !important;
  }
  .pt-md-5, .py-md-5 {
    padding-top: 1.25rem !important;
  }
  .pr-md-5, .px-md-5 {
    padding-right: 1.25rem !important;
  }
  .pb-md-5, .py-md-5 {
    padding-bottom: 1.25rem !important;
  }
  .pl-md-5, .px-md-5 {
    padding-left: 1.25rem !important;
  }
  .p-md-6 {
    padding: 1.5rem !important;
  }
  .pt-md-6, .py-md-6 {
    padding-top: 1.5rem !important;
  }
  .pr-md-6, .px-md-6 {
    padding-right: 1.5rem !important;
  }
  .pb-md-6, .py-md-6 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-6, .px-md-6 {
    padding-left: 1.5rem !important;
  }
  .p-md-7 {
    padding: 1.75rem !important;
  }
  .pt-md-7, .py-md-7 {
    padding-top: 1.75rem !important;
  }
  .pr-md-7, .px-md-7 {
    padding-right: 1.75rem !important;
  }
  .pb-md-7, .py-md-7 {
    padding-bottom: 1.75rem !important;
  }
  .pl-md-7, .px-md-7 {
    padding-left: 1.75rem !important;
  }
  .p-md-8 {
    padding: 2rem !important;
  }
  .pt-md-8, .py-md-8 {
    padding-top: 2rem !important;
  }
  .pr-md-8, .px-md-8 {
    padding-right: 2rem !important;
  }
  .pb-md-8, .py-md-8 {
    padding-bottom: 2rem !important;
  }
  .pl-md-8, .px-md-8 {
    padding-left: 2rem !important;
  }
  .p-md-9 {
    padding: 2.25rem !important;
  }
  .pt-md-9, .py-md-9 {
    padding-top: 2.25rem !important;
  }
  .pr-md-9, .px-md-9 {
    padding-right: 2.25rem !important;
  }
  .pb-md-9, .py-md-9 {
    padding-bottom: 2.25rem !important;
  }
  .pl-md-9, .px-md-9 {
    padding-left: 2.25rem !important;
  }
  .p-md-10 {
    padding: 2.5rem !important;
  }
  .pt-md-10, .py-md-10 {
    padding-top: 2.5rem !important;
  }
  .pr-md-10, .px-md-10 {
    padding-right: 2.5rem !important;
  }
  .pb-md-10, .py-md-10 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-10, .px-md-10 {
    padding-left: 2.5rem !important;
  }
  .p-md-11 {
    padding: 2.75rem !important;
  }
  .pt-md-11, .py-md-11 {
    padding-top: 2.75rem !important;
  }
  .pr-md-11, .px-md-11 {
    padding-right: 2.75rem !important;
  }
  .pb-md-11, .py-md-11 {
    padding-bottom: 2.75rem !important;
  }
  .pl-md-11, .px-md-11 {
    padding-left: 2.75rem !important;
  }
  .p-md-12 {
    padding: 3rem !important;
  }
  .pt-md-12, .py-md-12 {
    padding-top: 3rem !important;
  }
  .pr-md-12, .px-md-12 {
    padding-right: 3rem !important;
  }
  .pb-md-12, .py-md-12 {
    padding-bottom: 3rem !important;
  }
  .pl-md-12, .px-md-12 {
    padding-left: 3rem !important;
  }
  .p-md-13 {
    padding: 3.25rem !important;
  }
  .pt-md-13, .py-md-13 {
    padding-top: 3.25rem !important;
  }
  .pr-md-13, .px-md-13 {
    padding-right: 3.25rem !important;
  }
  .pb-md-13, .py-md-13 {
    padding-bottom: 3.25rem !important;
  }
  .pl-md-13, .px-md-13 {
    padding-left: 3.25rem !important;
  }
  .p-md-14 {
    padding: 3.5rem !important;
  }
  .pt-md-14, .py-md-14 {
    padding-top: 3.5rem !important;
  }
  .pr-md-14, .px-md-14 {
    padding-right: 3.5rem !important;
  }
  .pb-md-14, .py-md-14 {
    padding-bottom: 3.5rem !important;
  }
  .pl-md-14, .px-md-14 {
    padding-left: 3.5rem !important;
  }
  .p-md-15 {
    padding: 3.75rem !important;
  }
  .pt-md-15, .py-md-15 {
    padding-top: 3.75rem !important;
  }
  .pr-md-15, .px-md-15 {
    padding-right: 3.75rem !important;
  }
  .pb-md-15, .py-md-15 {
    padding-bottom: 3.75rem !important;
  }
  .pl-md-15, .px-md-15 {
    padding-left: 3.75rem !important;
  }
  .p-md-16 {
    padding: 4rem !important;
  }
  .pt-md-16, .py-md-16 {
    padding-top: 4rem !important;
  }
  .pr-md-16, .px-md-16 {
    padding-right: 4rem !important;
  }
  .pb-md-16, .py-md-16 {
    padding-bottom: 4rem !important;
  }
  .pl-md-16, .px-md-16 {
    padding-left: 4rem !important;
  }
  .p-md-17 {
    padding: 4.25rem !important;
  }
  .pt-md-17, .py-md-17 {
    padding-top: 4.25rem !important;
  }
  .pr-md-17, .px-md-17 {
    padding-right: 4.25rem !important;
  }
  .pb-md-17, .py-md-17 {
    padding-bottom: 4.25rem !important;
  }
  .pl-md-17, .px-md-17 {
    padding-left: 4.25rem !important;
  }
  .p-md-18 {
    padding: 4.5rem !important;
  }
  .pt-md-18, .py-md-18 {
    padding-top: 4.5rem !important;
  }
  .pr-md-18, .px-md-18 {
    padding-right: 4.5rem !important;
  }
  .pb-md-18, .py-md-18 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-18, .px-md-18 {
    padding-left: 4.5rem !important;
  }
  .p-md-19 {
    padding: 4.75rem !important;
  }
  .pt-md-19, .py-md-19 {
    padding-top: 4.75rem !important;
  }
  .pr-md-19, .px-md-19 {
    padding-right: 4.75rem !important;
  }
  .pb-md-19, .py-md-19 {
    padding-bottom: 4.75rem !important;
  }
  .pl-md-19, .px-md-19 {
    padding-left: 4.75rem !important;
  }
  .p-md-20 {
    padding: 5rem !important;
  }
  .pt-md-20, .py-md-20 {
    padding-top: 5rem !important;
  }
  .pr-md-20, .px-md-20 {
    padding-right: 5rem !important;
  }
  .pb-md-20, .py-md-20 {
    padding-bottom: 5rem !important;
  }
  .pl-md-20, .px-md-20 {
    padding-left: 5rem !important;
  }
  .p-md-21 {
    padding: 5.25rem !important;
  }
  .pt-md-21, .py-md-21 {
    padding-top: 5.25rem !important;
  }
  .pr-md-21, .px-md-21 {
    padding-right: 5.25rem !important;
  }
  .pb-md-21, .py-md-21 {
    padding-bottom: 5.25rem !important;
  }
  .pl-md-21, .px-md-21 {
    padding-left: 5.25rem !important;
  }
  .p-md-22 {
    padding: 5.5rem !important;
  }
  .pt-md-22, .py-md-22 {
    padding-top: 5.5rem !important;
  }
  .pr-md-22, .px-md-22 {
    padding-right: 5.5rem !important;
  }
  .pb-md-22, .py-md-22 {
    padding-bottom: 5.5rem !important;
  }
  .pl-md-22, .px-md-22 {
    padding-left: 5.5rem !important;
  }
  .p-md-23 {
    padding: 5.75rem !important;
  }
  .pt-md-23, .py-md-23 {
    padding-top: 5.75rem !important;
  }
  .pr-md-23, .px-md-23 {
    padding-right: 5.75rem !important;
  }
  .pb-md-23, .py-md-23 {
    padding-bottom: 5.75rem !important;
  }
  .pl-md-23, .px-md-23 {
    padding-left: 5.75rem !important;
  }
  .p-md-24 {
    padding: 6rem !important;
  }
  .pt-md-24, .py-md-24 {
    padding-top: 6rem !important;
  }
  .pr-md-24, .px-md-24 {
    padding-right: 6rem !important;
  }
  .pb-md-24, .py-md-24 {
    padding-bottom: 6rem !important;
  }
  .pl-md-24, .px-md-24 {
    padding-left: 6rem !important;
  }
  .p-md-25 {
    padding: 6.25rem !important;
  }
  .pt-md-25, .py-md-25 {
    padding-top: 6.25rem !important;
  }
  .pr-md-25, .px-md-25 {
    padding-right: 6.25rem !important;
  }
  .pb-md-25, .py-md-25 {
    padding-bottom: 6.25rem !important;
  }
  .pl-md-25, .px-md-25 {
    padding-left: 6.25rem !important;
  }
  .p-md-26 {
    padding: 6.5rem !important;
  }
  .pt-md-26, .py-md-26 {
    padding-top: 6.5rem !important;
  }
  .pr-md-26, .px-md-26 {
    padding-right: 6.5rem !important;
  }
  .pb-md-26, .py-md-26 {
    padding-bottom: 6.5rem !important;
  }
  .pl-md-26, .px-md-26 {
    padding-left: 6.5rem !important;
  }
  .p-md-27 {
    padding: 6.75rem !important;
  }
  .pt-md-27, .py-md-27 {
    padding-top: 6.75rem !important;
  }
  .pr-md-27, .px-md-27 {
    padding-right: 6.75rem !important;
  }
  .pb-md-27, .py-md-27 {
    padding-bottom: 6.75rem !important;
  }
  .pl-md-27, .px-md-27 {
    padding-left: 6.75rem !important;
  }
  .p-md-28 {
    padding: 7rem !important;
  }
  .pt-md-28, .py-md-28 {
    padding-top: 7rem !important;
  }
  .pr-md-28, .px-md-28 {
    padding-right: 7rem !important;
  }
  .pb-md-28, .py-md-28 {
    padding-bottom: 7rem !important;
  }
  .pl-md-28, .px-md-28 {
    padding-left: 7rem !important;
  }
  .p-md-29 {
    padding: 7.25rem !important;
  }
  .pt-md-29, .py-md-29 {
    padding-top: 7.25rem !important;
  }
  .pr-md-29, .px-md-29 {
    padding-right: 7.25rem !important;
  }
  .pb-md-29, .py-md-29 {
    padding-bottom: 7.25rem !important;
  }
  .pl-md-29, .px-md-29 {
    padding-left: 7.25rem !important;
  }
  .p-md-30 {
    padding: 7.5rem !important;
  }
  .pt-md-30, .py-md-30 {
    padding-top: 7.5rem !important;
  }
  .pr-md-30, .px-md-30 {
    padding-right: 7.5rem !important;
  }
  .pb-md-30, .py-md-30 {
    padding-bottom: 7.5rem !important;
  }
  .pl-md-30, .px-md-30 {
    padding-left: 7.5rem !important;
  }
  .p-md-31 {
    padding: 7.75rem !important;
  }
  .pt-md-31, .py-md-31 {
    padding-top: 7.75rem !important;
  }
  .pr-md-31, .px-md-31 {
    padding-right: 7.75rem !important;
  }
  .pb-md-31, .py-md-31 {
    padding-bottom: 7.75rem !important;
  }
  .pl-md-31, .px-md-31 {
    padding-left: 7.75rem !important;
  }
  .p-md-32 {
    padding: 8rem !important;
  }
  .pt-md-32, .py-md-32 {
    padding-top: 8rem !important;
  }
  .pr-md-32, .px-md-32 {
    padding-right: 8rem !important;
  }
  .pb-md-32, .py-md-32 {
    padding-bottom: 8rem !important;
  }
  .pl-md-32, .px-md-32 {
    padding-left: 8rem !important;
  }
  .p-md-33 {
    padding: 8.25rem !important;
  }
  .pt-md-33, .py-md-33 {
    padding-top: 8.25rem !important;
  }
  .pr-md-33, .px-md-33 {
    padding-right: 8.25rem !important;
  }
  .pb-md-33, .py-md-33 {
    padding-bottom: 8.25rem !important;
  }
  .pl-md-33, .px-md-33 {
    padding-left: 8.25rem !important;
  }
  .p-md-34 {
    padding: 8.5rem !important;
  }
  .pt-md-34, .py-md-34 {
    padding-top: 8.5rem !important;
  }
  .pr-md-34, .px-md-34 {
    padding-right: 8.5rem !important;
  }
  .pb-md-34, .py-md-34 {
    padding-bottom: 8.5rem !important;
  }
  .pl-md-34, .px-md-34 {
    padding-left: 8.5rem !important;
  }
  .p-md-35 {
    padding: 8.75rem !important;
  }
  .pt-md-35, .py-md-35 {
    padding-top: 8.75rem !important;
  }
  .pr-md-35, .px-md-35 {
    padding-right: 8.75rem !important;
  }
  .pb-md-35, .py-md-35 {
    padding-bottom: 8.75rem !important;
  }
  .pl-md-35, .px-md-35 {
    padding-left: 8.75rem !important;
  }
  .p-md-36 {
    padding: 9rem !important;
  }
  .pt-md-36, .py-md-36 {
    padding-top: 9rem !important;
  }
  .pr-md-36, .px-md-36 {
    padding-right: 9rem !important;
  }
  .pb-md-36, .py-md-36 {
    padding-bottom: 9rem !important;
  }
  .pl-md-36, .px-md-36 {
    padding-left: 9rem !important;
  }
  .p-md-37 {
    padding: 9.25rem !important;
  }
  .pt-md-37, .py-md-37 {
    padding-top: 9.25rem !important;
  }
  .pr-md-37, .px-md-37 {
    padding-right: 9.25rem !important;
  }
  .pb-md-37, .py-md-37 {
    padding-bottom: 9.25rem !important;
  }
  .pl-md-37, .px-md-37 {
    padding-left: 9.25rem !important;
  }
  .p-md-48 {
    padding: 9.5rem !important;
  }
  .pt-md-48, .py-md-48 {
    padding-top: 9.5rem !important;
  }
  .pr-md-48, .px-md-48 {
    padding-right: 9.5rem !important;
  }
  .pb-md-48, .py-md-48 {
    padding-bottom: 9.5rem !important;
  }
  .pl-md-48, .px-md-48 {
    padding-left: 9.5rem !important;
  }
  .p-md-39 {
    padding: 9.75rem !important;
  }
  .pt-md-39, .py-md-39 {
    padding-top: 9.75rem !important;
  }
  .pr-md-39, .px-md-39 {
    padding-right: 9.75rem !important;
  }
  .pb-md-39, .py-md-39 {
    padding-bottom: 9.75rem !important;
  }
  .pl-md-39, .px-md-39 {
    padding-left: 9.75rem !important;
  }
  .p-md-40 {
    padding: 10rem !important;
  }
  .pt-md-40, .py-md-40 {
    padding-top: 10rem !important;
  }
  .pr-md-40, .px-md-40 {
    padding-right: 10rem !important;
  }
  .pb-md-40, .py-md-40 {
    padding-bottom: 10rem !important;
  }
  .pl-md-40, .px-md-40 {
    padding-left: 10rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1, .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1, .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1, .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1, .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2, .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2, .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2, .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2, .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -0.75rem !important;
  }
  .mt-md-n3, .my-md-n3 {
    margin-top: -0.75rem !important;
  }
  .mr-md-n3, .mx-md-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-md-n3, .my-md-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ml-md-n3, .mx-md-n3 {
    margin-left: -0.75rem !important;
  }
  .m-md-n4 {
    margin: -1rem !important;
  }
  .mt-md-n4, .my-md-n4 {
    margin-top: -1rem !important;
  }
  .mr-md-n4, .mx-md-n4 {
    margin-right: -1rem !important;
  }
  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n4, .mx-md-n4 {
    margin-left: -1rem !important;
  }
  .m-md-n5 {
    margin: -1.25rem !important;
  }
  .mt-md-n5, .my-md-n5 {
    margin-top: -1.25rem !important;
  }
  .mr-md-n5, .mx-md-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-md-n5, .my-md-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ml-md-n5, .mx-md-n5 {
    margin-left: -1.25rem !important;
  }
  .m-md-n6 {
    margin: -1.5rem !important;
  }
  .mt-md-n6, .my-md-n6 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n6, .mx-md-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n6, .my-md-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n6, .mx-md-n6 {
    margin-left: -1.5rem !important;
  }
  .m-md-n7 {
    margin: -1.75rem !important;
  }
  .mt-md-n7, .my-md-n7 {
    margin-top: -1.75rem !important;
  }
  .mr-md-n7, .mx-md-n7 {
    margin-right: -1.75rem !important;
  }
  .mb-md-n7, .my-md-n7 {
    margin-bottom: -1.75rem !important;
  }
  .ml-md-n7, .mx-md-n7 {
    margin-left: -1.75rem !important;
  }
  .m-md-n8 {
    margin: -2rem !important;
  }
  .mt-md-n8, .my-md-n8 {
    margin-top: -2rem !important;
  }
  .mr-md-n8, .mx-md-n8 {
    margin-right: -2rem !important;
  }
  .mb-md-n8, .my-md-n8 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n8, .mx-md-n8 {
    margin-left: -2rem !important;
  }
  .m-md-n9 {
    margin: -2.25rem !important;
  }
  .mt-md-n9, .my-md-n9 {
    margin-top: -2.25rem !important;
  }
  .mr-md-n9, .mx-md-n9 {
    margin-right: -2.25rem !important;
  }
  .mb-md-n9, .my-md-n9 {
    margin-bottom: -2.25rem !important;
  }
  .ml-md-n9, .mx-md-n9 {
    margin-left: -2.25rem !important;
  }
  .m-md-n10 {
    margin: -2.5rem !important;
  }
  .mt-md-n10, .my-md-n10 {
    margin-top: -2.5rem !important;
  }
  .mr-md-n10, .mx-md-n10 {
    margin-right: -2.5rem !important;
  }
  .mb-md-n10, .my-md-n10 {
    margin-bottom: -2.5rem !important;
  }
  .ml-md-n10, .mx-md-n10 {
    margin-left: -2.5rem !important;
  }
  .m-md-n11 {
    margin: -2.75rem !important;
  }
  .mt-md-n11, .my-md-n11 {
    margin-top: -2.75rem !important;
  }
  .mr-md-n11, .mx-md-n11 {
    margin-right: -2.75rem !important;
  }
  .mb-md-n11, .my-md-n11 {
    margin-bottom: -2.75rem !important;
  }
  .ml-md-n11, .mx-md-n11 {
    margin-left: -2.75rem !important;
  }
  .m-md-n12 {
    margin: -3rem !important;
  }
  .mt-md-n12, .my-md-n12 {
    margin-top: -3rem !important;
  }
  .mr-md-n12, .mx-md-n12 {
    margin-right: -3rem !important;
  }
  .mb-md-n12, .my-md-n12 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n12, .mx-md-n12 {
    margin-left: -3rem !important;
  }
  .m-md-n13 {
    margin: -3.25rem !important;
  }
  .mt-md-n13, .my-md-n13 {
    margin-top: -3.25rem !important;
  }
  .mr-md-n13, .mx-md-n13 {
    margin-right: -3.25rem !important;
  }
  .mb-md-n13, .my-md-n13 {
    margin-bottom: -3.25rem !important;
  }
  .ml-md-n13, .mx-md-n13 {
    margin-left: -3.25rem !important;
  }
  .m-md-n14 {
    margin: -3.5rem !important;
  }
  .mt-md-n14, .my-md-n14 {
    margin-top: -3.5rem !important;
  }
  .mr-md-n14, .mx-md-n14 {
    margin-right: -3.5rem !important;
  }
  .mb-md-n14, .my-md-n14 {
    margin-bottom: -3.5rem !important;
  }
  .ml-md-n14, .mx-md-n14 {
    margin-left: -3.5rem !important;
  }
  .m-md-n15 {
    margin: -3.75rem !important;
  }
  .mt-md-n15, .my-md-n15 {
    margin-top: -3.75rem !important;
  }
  .mr-md-n15, .mx-md-n15 {
    margin-right: -3.75rem !important;
  }
  .mb-md-n15, .my-md-n15 {
    margin-bottom: -3.75rem !important;
  }
  .ml-md-n15, .mx-md-n15 {
    margin-left: -3.75rem !important;
  }
  .m-md-n16 {
    margin: -4rem !important;
  }
  .mt-md-n16, .my-md-n16 {
    margin-top: -4rem !important;
  }
  .mr-md-n16, .mx-md-n16 {
    margin-right: -4rem !important;
  }
  .mb-md-n16, .my-md-n16 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n16, .mx-md-n16 {
    margin-left: -4rem !important;
  }
  .m-md-n17 {
    margin: -4.25rem !important;
  }
  .mt-md-n17, .my-md-n17 {
    margin-top: -4.25rem !important;
  }
  .mr-md-n17, .mx-md-n17 {
    margin-right: -4.25rem !important;
  }
  .mb-md-n17, .my-md-n17 {
    margin-bottom: -4.25rem !important;
  }
  .ml-md-n17, .mx-md-n17 {
    margin-left: -4.25rem !important;
  }
  .m-md-n18 {
    margin: -4.5rem !important;
  }
  .mt-md-n18, .my-md-n18 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n18, .mx-md-n18 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n18, .my-md-n18 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n18, .mx-md-n18 {
    margin-left: -4.5rem !important;
  }
  .m-md-n19 {
    margin: -4.75rem !important;
  }
  .mt-md-n19, .my-md-n19 {
    margin-top: -4.75rem !important;
  }
  .mr-md-n19, .mx-md-n19 {
    margin-right: -4.75rem !important;
  }
  .mb-md-n19, .my-md-n19 {
    margin-bottom: -4.75rem !important;
  }
  .ml-md-n19, .mx-md-n19 {
    margin-left: -4.75rem !important;
  }
  .m-md-n20 {
    margin: -5rem !important;
  }
  .mt-md-n20, .my-md-n20 {
    margin-top: -5rem !important;
  }
  .mr-md-n20, .mx-md-n20 {
    margin-right: -5rem !important;
  }
  .mb-md-n20, .my-md-n20 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n20, .mx-md-n20 {
    margin-left: -5rem !important;
  }
  .m-md-n21 {
    margin: -5.25rem !important;
  }
  .mt-md-n21, .my-md-n21 {
    margin-top: -5.25rem !important;
  }
  .mr-md-n21, .mx-md-n21 {
    margin-right: -5.25rem !important;
  }
  .mb-md-n21, .my-md-n21 {
    margin-bottom: -5.25rem !important;
  }
  .ml-md-n21, .mx-md-n21 {
    margin-left: -5.25rem !important;
  }
  .m-md-n22 {
    margin: -5.5rem !important;
  }
  .mt-md-n22, .my-md-n22 {
    margin-top: -5.5rem !important;
  }
  .mr-md-n22, .mx-md-n22 {
    margin-right: -5.5rem !important;
  }
  .mb-md-n22, .my-md-n22 {
    margin-bottom: -5.5rem !important;
  }
  .ml-md-n22, .mx-md-n22 {
    margin-left: -5.5rem !important;
  }
  .m-md-n23 {
    margin: -5.75rem !important;
  }
  .mt-md-n23, .my-md-n23 {
    margin-top: -5.75rem !important;
  }
  .mr-md-n23, .mx-md-n23 {
    margin-right: -5.75rem !important;
  }
  .mb-md-n23, .my-md-n23 {
    margin-bottom: -5.75rem !important;
  }
  .ml-md-n23, .mx-md-n23 {
    margin-left: -5.75rem !important;
  }
  .m-md-n24 {
    margin: -6rem !important;
  }
  .mt-md-n24, .my-md-n24 {
    margin-top: -6rem !important;
  }
  .mr-md-n24, .mx-md-n24 {
    margin-right: -6rem !important;
  }
  .mb-md-n24, .my-md-n24 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n24, .mx-md-n24 {
    margin-left: -6rem !important;
  }
  .m-md-n25 {
    margin: -6.25rem !important;
  }
  .mt-md-n25, .my-md-n25 {
    margin-top: -6.25rem !important;
  }
  .mr-md-n25, .mx-md-n25 {
    margin-right: -6.25rem !important;
  }
  .mb-md-n25, .my-md-n25 {
    margin-bottom: -6.25rem !important;
  }
  .ml-md-n25, .mx-md-n25 {
    margin-left: -6.25rem !important;
  }
  .m-md-n26 {
    margin: -6.5rem !important;
  }
  .mt-md-n26, .my-md-n26 {
    margin-top: -6.5rem !important;
  }
  .mr-md-n26, .mx-md-n26 {
    margin-right: -6.5rem !important;
  }
  .mb-md-n26, .my-md-n26 {
    margin-bottom: -6.5rem !important;
  }
  .ml-md-n26, .mx-md-n26 {
    margin-left: -6.5rem !important;
  }
  .m-md-n27 {
    margin: -6.75rem !important;
  }
  .mt-md-n27, .my-md-n27 {
    margin-top: -6.75rem !important;
  }
  .mr-md-n27, .mx-md-n27 {
    margin-right: -6.75rem !important;
  }
  .mb-md-n27, .my-md-n27 {
    margin-bottom: -6.75rem !important;
  }
  .ml-md-n27, .mx-md-n27 {
    margin-left: -6.75rem !important;
  }
  .m-md-n28 {
    margin: -7rem !important;
  }
  .mt-md-n28, .my-md-n28 {
    margin-top: -7rem !important;
  }
  .mr-md-n28, .mx-md-n28 {
    margin-right: -7rem !important;
  }
  .mb-md-n28, .my-md-n28 {
    margin-bottom: -7rem !important;
  }
  .ml-md-n28, .mx-md-n28 {
    margin-left: -7rem !important;
  }
  .m-md-n29 {
    margin: -7.25rem !important;
  }
  .mt-md-n29, .my-md-n29 {
    margin-top: -7.25rem !important;
  }
  .mr-md-n29, .mx-md-n29 {
    margin-right: -7.25rem !important;
  }
  .mb-md-n29, .my-md-n29 {
    margin-bottom: -7.25rem !important;
  }
  .ml-md-n29, .mx-md-n29 {
    margin-left: -7.25rem !important;
  }
  .m-md-n30 {
    margin: -7.5rem !important;
  }
  .mt-md-n30, .my-md-n30 {
    margin-top: -7.5rem !important;
  }
  .mr-md-n30, .mx-md-n30 {
    margin-right: -7.5rem !important;
  }
  .mb-md-n30, .my-md-n30 {
    margin-bottom: -7.5rem !important;
  }
  .ml-md-n30, .mx-md-n30 {
    margin-left: -7.5rem !important;
  }
  .m-md-n31 {
    margin: -7.75rem !important;
  }
  .mt-md-n31, .my-md-n31 {
    margin-top: -7.75rem !important;
  }
  .mr-md-n31, .mx-md-n31 {
    margin-right: -7.75rem !important;
  }
  .mb-md-n31, .my-md-n31 {
    margin-bottom: -7.75rem !important;
  }
  .ml-md-n31, .mx-md-n31 {
    margin-left: -7.75rem !important;
  }
  .m-md-n32 {
    margin: -8rem !important;
  }
  .mt-md-n32, .my-md-n32 {
    margin-top: -8rem !important;
  }
  .mr-md-n32, .mx-md-n32 {
    margin-right: -8rem !important;
  }
  .mb-md-n32, .my-md-n32 {
    margin-bottom: -8rem !important;
  }
  .ml-md-n32, .mx-md-n32 {
    margin-left: -8rem !important;
  }
  .m-md-n33 {
    margin: -8.25rem !important;
  }
  .mt-md-n33, .my-md-n33 {
    margin-top: -8.25rem !important;
  }
  .mr-md-n33, .mx-md-n33 {
    margin-right: -8.25rem !important;
  }
  .mb-md-n33, .my-md-n33 {
    margin-bottom: -8.25rem !important;
  }
  .ml-md-n33, .mx-md-n33 {
    margin-left: -8.25rem !important;
  }
  .m-md-n34 {
    margin: -8.5rem !important;
  }
  .mt-md-n34, .my-md-n34 {
    margin-top: -8.5rem !important;
  }
  .mr-md-n34, .mx-md-n34 {
    margin-right: -8.5rem !important;
  }
  .mb-md-n34, .my-md-n34 {
    margin-bottom: -8.5rem !important;
  }
  .ml-md-n34, .mx-md-n34 {
    margin-left: -8.5rem !important;
  }
  .m-md-n35 {
    margin: -8.75rem !important;
  }
  .mt-md-n35, .my-md-n35 {
    margin-top: -8.75rem !important;
  }
  .mr-md-n35, .mx-md-n35 {
    margin-right: -8.75rem !important;
  }
  .mb-md-n35, .my-md-n35 {
    margin-bottom: -8.75rem !important;
  }
  .ml-md-n35, .mx-md-n35 {
    margin-left: -8.75rem !important;
  }
  .m-md-n36 {
    margin: -9rem !important;
  }
  .mt-md-n36, .my-md-n36 {
    margin-top: -9rem !important;
  }
  .mr-md-n36, .mx-md-n36 {
    margin-right: -9rem !important;
  }
  .mb-md-n36, .my-md-n36 {
    margin-bottom: -9rem !important;
  }
  .ml-md-n36, .mx-md-n36 {
    margin-left: -9rem !important;
  }
  .m-md-n37 {
    margin: -9.25rem !important;
  }
  .mt-md-n37, .my-md-n37 {
    margin-top: -9.25rem !important;
  }
  .mr-md-n37, .mx-md-n37 {
    margin-right: -9.25rem !important;
  }
  .mb-md-n37, .my-md-n37 {
    margin-bottom: -9.25rem !important;
  }
  .ml-md-n37, .mx-md-n37 {
    margin-left: -9.25rem !important;
  }
  .m-md-n48 {
    margin: -9.5rem !important;
  }
  .mt-md-n48, .my-md-n48 {
    margin-top: -9.5rem !important;
  }
  .mr-md-n48, .mx-md-n48 {
    margin-right: -9.5rem !important;
  }
  .mb-md-n48, .my-md-n48 {
    margin-bottom: -9.5rem !important;
  }
  .ml-md-n48, .mx-md-n48 {
    margin-left: -9.5rem !important;
  }
  .m-md-n39 {
    margin: -9.75rem !important;
  }
  .mt-md-n39, .my-md-n39 {
    margin-top: -9.75rem !important;
  }
  .mr-md-n39, .mx-md-n39 {
    margin-right: -9.75rem !important;
  }
  .mb-md-n39, .my-md-n39 {
    margin-bottom: -9.75rem !important;
  }
  .ml-md-n39, .mx-md-n39 {
    margin-left: -9.75rem !important;
  }
  .m-md-n40 {
    margin: -10rem !important;
  }
  .mt-md-n40, .my-md-n40 {
    margin-top: -10rem !important;
  }
  .mr-md-n40, .mx-md-n40 {
    margin-right: -10rem !important;
  }
  .mb-md-n40, .my-md-n40 {
    margin-bottom: -10rem !important;
  }
  .ml-md-n40, .mx-md-n40 {
    margin-left: -10rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1, .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1, .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1, .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1, .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2, .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2, .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2, .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2, .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .mt-lg-3, .my-lg-3 {
    margin-top: 0.75rem !important;
  }
  .mr-lg-3, .mx-lg-3 {
    margin-right: 0.75rem !important;
  }
  .mb-lg-3, .my-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-lg-3, .mx-lg-3 {
    margin-left: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .mt-lg-4, .my-lg-4 {
    margin-top: 1rem !important;
  }
  .mr-lg-4, .mx-lg-4 {
    margin-right: 1rem !important;
  }
  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-4, .mx-lg-4 {
    margin-left: 1rem !important;
  }
  .m-lg-5 {
    margin: 1.25rem !important;
  }
  .mt-lg-5, .my-lg-5 {
    margin-top: 1.25rem !important;
  }
  .mr-lg-5, .mx-lg-5 {
    margin-right: 1.25rem !important;
  }
  .mb-lg-5, .my-lg-5 {
    margin-bottom: 1.25rem !important;
  }
  .ml-lg-5, .mx-lg-5 {
    margin-left: 1.25rem !important;
  }
  .m-lg-6 {
    margin: 1.5rem !important;
  }
  .mt-lg-6, .my-lg-6 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-6, .mx-lg-6 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-6, .my-lg-6 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-6, .mx-lg-6 {
    margin-left: 1.5rem !important;
  }
  .m-lg-7 {
    margin: 1.75rem !important;
  }
  .mt-lg-7, .my-lg-7 {
    margin-top: 1.75rem !important;
  }
  .mr-lg-7, .mx-lg-7 {
    margin-right: 1.75rem !important;
  }
  .mb-lg-7, .my-lg-7 {
    margin-bottom: 1.75rem !important;
  }
  .ml-lg-7, .mx-lg-7 {
    margin-left: 1.75rem !important;
  }
  .m-lg-8 {
    margin: 2rem !important;
  }
  .mt-lg-8, .my-lg-8 {
    margin-top: 2rem !important;
  }
  .mr-lg-8, .mx-lg-8 {
    margin-right: 2rem !important;
  }
  .mb-lg-8, .my-lg-8 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-8, .mx-lg-8 {
    margin-left: 2rem !important;
  }
  .m-lg-9 {
    margin: 2.25rem !important;
  }
  .mt-lg-9, .my-lg-9 {
    margin-top: 2.25rem !important;
  }
  .mr-lg-9, .mx-lg-9 {
    margin-right: 2.25rem !important;
  }
  .mb-lg-9, .my-lg-9 {
    margin-bottom: 2.25rem !important;
  }
  .ml-lg-9, .mx-lg-9 {
    margin-left: 2.25rem !important;
  }
  .m-lg-10 {
    margin: 2.5rem !important;
  }
  .mt-lg-10, .my-lg-10 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-10, .mx-lg-10 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-10, .my-lg-10 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-10, .mx-lg-10 {
    margin-left: 2.5rem !important;
  }
  .m-lg-11 {
    margin: 2.75rem !important;
  }
  .mt-lg-11, .my-lg-11 {
    margin-top: 2.75rem !important;
  }
  .mr-lg-11, .mx-lg-11 {
    margin-right: 2.75rem !important;
  }
  .mb-lg-11, .my-lg-11 {
    margin-bottom: 2.75rem !important;
  }
  .ml-lg-11, .mx-lg-11 {
    margin-left: 2.75rem !important;
  }
  .m-lg-12 {
    margin: 3rem !important;
  }
  .mt-lg-12, .my-lg-12 {
    margin-top: 3rem !important;
  }
  .mr-lg-12, .mx-lg-12 {
    margin-right: 3rem !important;
  }
  .mb-lg-12, .my-lg-12 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-12, .mx-lg-12 {
    margin-left: 3rem !important;
  }
  .m-lg-13 {
    margin: 3.25rem !important;
  }
  .mt-lg-13, .my-lg-13 {
    margin-top: 3.25rem !important;
  }
  .mr-lg-13, .mx-lg-13 {
    margin-right: 3.25rem !important;
  }
  .mb-lg-13, .my-lg-13 {
    margin-bottom: 3.25rem !important;
  }
  .ml-lg-13, .mx-lg-13 {
    margin-left: 3.25rem !important;
  }
  .m-lg-14 {
    margin: 3.5rem !important;
  }
  .mt-lg-14, .my-lg-14 {
    margin-top: 3.5rem !important;
  }
  .mr-lg-14, .mx-lg-14 {
    margin-right: 3.5rem !important;
  }
  .mb-lg-14, .my-lg-14 {
    margin-bottom: 3.5rem !important;
  }
  .ml-lg-14, .mx-lg-14 {
    margin-left: 3.5rem !important;
  }
  .m-lg-15 {
    margin: 3.75rem !important;
  }
  .mt-lg-15, .my-lg-15 {
    margin-top: 3.75rem !important;
  }
  .mr-lg-15, .mx-lg-15 {
    margin-right: 3.75rem !important;
  }
  .mb-lg-15, .my-lg-15 {
    margin-bottom: 3.75rem !important;
  }
  .ml-lg-15, .mx-lg-15 {
    margin-left: 3.75rem !important;
  }
  .m-lg-16 {
    margin: 4rem !important;
  }
  .mt-lg-16, .my-lg-16 {
    margin-top: 4rem !important;
  }
  .mr-lg-16, .mx-lg-16 {
    margin-right: 4rem !important;
  }
  .mb-lg-16, .my-lg-16 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-16, .mx-lg-16 {
    margin-left: 4rem !important;
  }
  .m-lg-17 {
    margin: 4.25rem !important;
  }
  .mt-lg-17, .my-lg-17 {
    margin-top: 4.25rem !important;
  }
  .mr-lg-17, .mx-lg-17 {
    margin-right: 4.25rem !important;
  }
  .mb-lg-17, .my-lg-17 {
    margin-bottom: 4.25rem !important;
  }
  .ml-lg-17, .mx-lg-17 {
    margin-left: 4.25rem !important;
  }
  .m-lg-18 {
    margin: 4.5rem !important;
  }
  .mt-lg-18, .my-lg-18 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-18, .mx-lg-18 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-18, .my-lg-18 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-18, .mx-lg-18 {
    margin-left: 4.5rem !important;
  }
  .m-lg-19 {
    margin: 4.75rem !important;
  }
  .mt-lg-19, .my-lg-19 {
    margin-top: 4.75rem !important;
  }
  .mr-lg-19, .mx-lg-19 {
    margin-right: 4.75rem !important;
  }
  .mb-lg-19, .my-lg-19 {
    margin-bottom: 4.75rem !important;
  }
  .ml-lg-19, .mx-lg-19 {
    margin-left: 4.75rem !important;
  }
  .m-lg-20 {
    margin: 5rem !important;
  }
  .mt-lg-20, .my-lg-20 {
    margin-top: 5rem !important;
  }
  .mr-lg-20, .mx-lg-20 {
    margin-right: 5rem !important;
  }
  .mb-lg-20, .my-lg-20 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-20, .mx-lg-20 {
    margin-left: 5rem !important;
  }
  .m-lg-21 {
    margin: 5.25rem !important;
  }
  .mt-lg-21, .my-lg-21 {
    margin-top: 5.25rem !important;
  }
  .mr-lg-21, .mx-lg-21 {
    margin-right: 5.25rem !important;
  }
  .mb-lg-21, .my-lg-21 {
    margin-bottom: 5.25rem !important;
  }
  .ml-lg-21, .mx-lg-21 {
    margin-left: 5.25rem !important;
  }
  .m-lg-22 {
    margin: 5.5rem !important;
  }
  .mt-lg-22, .my-lg-22 {
    margin-top: 5.5rem !important;
  }
  .mr-lg-22, .mx-lg-22 {
    margin-right: 5.5rem !important;
  }
  .mb-lg-22, .my-lg-22 {
    margin-bottom: 5.5rem !important;
  }
  .ml-lg-22, .mx-lg-22 {
    margin-left: 5.5rem !important;
  }
  .m-lg-23 {
    margin: 5.75rem !important;
  }
  .mt-lg-23, .my-lg-23 {
    margin-top: 5.75rem !important;
  }
  .mr-lg-23, .mx-lg-23 {
    margin-right: 5.75rem !important;
  }
  .mb-lg-23, .my-lg-23 {
    margin-bottom: 5.75rem !important;
  }
  .ml-lg-23, .mx-lg-23 {
    margin-left: 5.75rem !important;
  }
  .m-lg-24 {
    margin: 6rem !important;
  }
  .mt-lg-24, .my-lg-24 {
    margin-top: 6rem !important;
  }
  .mr-lg-24, .mx-lg-24 {
    margin-right: 6rem !important;
  }
  .mb-lg-24, .my-lg-24 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-24, .mx-lg-24 {
    margin-left: 6rem !important;
  }
  .m-lg-25 {
    margin: 6.25rem !important;
  }
  .mt-lg-25, .my-lg-25 {
    margin-top: 6.25rem !important;
  }
  .mr-lg-25, .mx-lg-25 {
    margin-right: 6.25rem !important;
  }
  .mb-lg-25, .my-lg-25 {
    margin-bottom: 6.25rem !important;
  }
  .ml-lg-25, .mx-lg-25 {
    margin-left: 6.25rem !important;
  }
  .m-lg-26 {
    margin: 6.5rem !important;
  }
  .mt-lg-26, .my-lg-26 {
    margin-top: 6.5rem !important;
  }
  .mr-lg-26, .mx-lg-26 {
    margin-right: 6.5rem !important;
  }
  .mb-lg-26, .my-lg-26 {
    margin-bottom: 6.5rem !important;
  }
  .ml-lg-26, .mx-lg-26 {
    margin-left: 6.5rem !important;
  }
  .m-lg-27 {
    margin: 6.75rem !important;
  }
  .mt-lg-27, .my-lg-27 {
    margin-top: 6.75rem !important;
  }
  .mr-lg-27, .mx-lg-27 {
    margin-right: 6.75rem !important;
  }
  .mb-lg-27, .my-lg-27 {
    margin-bottom: 6.75rem !important;
  }
  .ml-lg-27, .mx-lg-27 {
    margin-left: 6.75rem !important;
  }
  .m-lg-28 {
    margin: 7rem !important;
  }
  .mt-lg-28, .my-lg-28 {
    margin-top: 7rem !important;
  }
  .mr-lg-28, .mx-lg-28 {
    margin-right: 7rem !important;
  }
  .mb-lg-28, .my-lg-28 {
    margin-bottom: 7rem !important;
  }
  .ml-lg-28, .mx-lg-28 {
    margin-left: 7rem !important;
  }
  .m-lg-29 {
    margin: 7.25rem !important;
  }
  .mt-lg-29, .my-lg-29 {
    margin-top: 7.25rem !important;
  }
  .mr-lg-29, .mx-lg-29 {
    margin-right: 7.25rem !important;
  }
  .mb-lg-29, .my-lg-29 {
    margin-bottom: 7.25rem !important;
  }
  .ml-lg-29, .mx-lg-29 {
    margin-left: 7.25rem !important;
  }
  .m-lg-30 {
    margin: 7.5rem !important;
  }
  .mt-lg-30, .my-lg-30 {
    margin-top: 7.5rem !important;
  }
  .mr-lg-30, .mx-lg-30 {
    margin-right: 7.5rem !important;
  }
  .mb-lg-30, .my-lg-30 {
    margin-bottom: 7.5rem !important;
  }
  .ml-lg-30, .mx-lg-30 {
    margin-left: 7.5rem !important;
  }
  .m-lg-31 {
    margin: 7.75rem !important;
  }
  .mt-lg-31, .my-lg-31 {
    margin-top: 7.75rem !important;
  }
  .mr-lg-31, .mx-lg-31 {
    margin-right: 7.75rem !important;
  }
  .mb-lg-31, .my-lg-31 {
    margin-bottom: 7.75rem !important;
  }
  .ml-lg-31, .mx-lg-31 {
    margin-left: 7.75rem !important;
  }
  .m-lg-32 {
    margin: 8rem !important;
  }
  .mt-lg-32, .my-lg-32 {
    margin-top: 8rem !important;
  }
  .mr-lg-32, .mx-lg-32 {
    margin-right: 8rem !important;
  }
  .mb-lg-32, .my-lg-32 {
    margin-bottom: 8rem !important;
  }
  .ml-lg-32, .mx-lg-32 {
    margin-left: 8rem !important;
  }
  .m-lg-33 {
    margin: 8.25rem !important;
  }
  .mt-lg-33, .my-lg-33 {
    margin-top: 8.25rem !important;
  }
  .mr-lg-33, .mx-lg-33 {
    margin-right: 8.25rem !important;
  }
  .mb-lg-33, .my-lg-33 {
    margin-bottom: 8.25rem !important;
  }
  .ml-lg-33, .mx-lg-33 {
    margin-left: 8.25rem !important;
  }
  .m-lg-34 {
    margin: 8.5rem !important;
  }
  .mt-lg-34, .my-lg-34 {
    margin-top: 8.5rem !important;
  }
  .mr-lg-34, .mx-lg-34 {
    margin-right: 8.5rem !important;
  }
  .mb-lg-34, .my-lg-34 {
    margin-bottom: 8.5rem !important;
  }
  .ml-lg-34, .mx-lg-34 {
    margin-left: 8.5rem !important;
  }
  .m-lg-35 {
    margin: 8.75rem !important;
  }
  .mt-lg-35, .my-lg-35 {
    margin-top: 8.75rem !important;
  }
  .mr-lg-35, .mx-lg-35 {
    margin-right: 8.75rem !important;
  }
  .mb-lg-35, .my-lg-35 {
    margin-bottom: 8.75rem !important;
  }
  .ml-lg-35, .mx-lg-35 {
    margin-left: 8.75rem !important;
  }
  .m-lg-36 {
    margin: 9rem !important;
  }
  .mt-lg-36, .my-lg-36 {
    margin-top: 9rem !important;
  }
  .mr-lg-36, .mx-lg-36 {
    margin-right: 9rem !important;
  }
  .mb-lg-36, .my-lg-36 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-36, .mx-lg-36 {
    margin-left: 9rem !important;
  }
  .m-lg-37 {
    margin: 9.25rem !important;
  }
  .mt-lg-37, .my-lg-37 {
    margin-top: 9.25rem !important;
  }
  .mr-lg-37, .mx-lg-37 {
    margin-right: 9.25rem !important;
  }
  .mb-lg-37, .my-lg-37 {
    margin-bottom: 9.25rem !important;
  }
  .ml-lg-37, .mx-lg-37 {
    margin-left: 9.25rem !important;
  }
  .m-lg-48 {
    margin: 9.5rem !important;
  }
  .mt-lg-48, .my-lg-48 {
    margin-top: 9.5rem !important;
  }
  .mr-lg-48, .mx-lg-48 {
    margin-right: 9.5rem !important;
  }
  .mb-lg-48, .my-lg-48 {
    margin-bottom: 9.5rem !important;
  }
  .ml-lg-48, .mx-lg-48 {
    margin-left: 9.5rem !important;
  }
  .m-lg-39 {
    margin: 9.75rem !important;
  }
  .mt-lg-39, .my-lg-39 {
    margin-top: 9.75rem !important;
  }
  .mr-lg-39, .mx-lg-39 {
    margin-right: 9.75rem !important;
  }
  .mb-lg-39, .my-lg-39 {
    margin-bottom: 9.75rem !important;
  }
  .ml-lg-39, .mx-lg-39 {
    margin-left: 9.75rem !important;
  }
  .m-lg-40 {
    margin: 10rem !important;
  }
  .mt-lg-40, .my-lg-40 {
    margin-top: 10rem !important;
  }
  .mr-lg-40, .mx-lg-40 {
    margin-right: 10rem !important;
  }
  .mb-lg-40, .my-lg-40 {
    margin-bottom: 10rem !important;
  }
  .ml-lg-40, .mx-lg-40 {
    margin-left: 10rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1, .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1, .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1, .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1, .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2, .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2, .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2, .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2, .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .pt-lg-3, .py-lg-3 {
    padding-top: 0.75rem !important;
  }
  .pr-lg-3, .px-lg-3 {
    padding-right: 0.75rem !important;
  }
  .pb-lg-3, .py-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-lg-3, .px-lg-3 {
    padding-left: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .pt-lg-4, .py-lg-4 {
    padding-top: 1rem !important;
  }
  .pr-lg-4, .px-lg-4 {
    padding-right: 1rem !important;
  }
  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-4, .px-lg-4 {
    padding-left: 1rem !important;
  }
  .p-lg-5 {
    padding: 1.25rem !important;
  }
  .pt-lg-5, .py-lg-5 {
    padding-top: 1.25rem !important;
  }
  .pr-lg-5, .px-lg-5 {
    padding-right: 1.25rem !important;
  }
  .pb-lg-5, .py-lg-5 {
    padding-bottom: 1.25rem !important;
  }
  .pl-lg-5, .px-lg-5 {
    padding-left: 1.25rem !important;
  }
  .p-lg-6 {
    padding: 1.5rem !important;
  }
  .pt-lg-6, .py-lg-6 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-6, .px-lg-6 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-6, .py-lg-6 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-6, .px-lg-6 {
    padding-left: 1.5rem !important;
  }
  .p-lg-7 {
    padding: 1.75rem !important;
  }
  .pt-lg-7, .py-lg-7 {
    padding-top: 1.75rem !important;
  }
  .pr-lg-7, .px-lg-7 {
    padding-right: 1.75rem !important;
  }
  .pb-lg-7, .py-lg-7 {
    padding-bottom: 1.75rem !important;
  }
  .pl-lg-7, .px-lg-7 {
    padding-left: 1.75rem !important;
  }
  .p-lg-8 {
    padding: 2rem !important;
  }
  .pt-lg-8, .py-lg-8 {
    padding-top: 2rem !important;
  }
  .pr-lg-8, .px-lg-8 {
    padding-right: 2rem !important;
  }
  .pb-lg-8, .py-lg-8 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-8, .px-lg-8 {
    padding-left: 2rem !important;
  }
  .p-lg-9 {
    padding: 2.25rem !important;
  }
  .pt-lg-9, .py-lg-9 {
    padding-top: 2.25rem !important;
  }
  .pr-lg-9, .px-lg-9 {
    padding-right: 2.25rem !important;
  }
  .pb-lg-9, .py-lg-9 {
    padding-bottom: 2.25rem !important;
  }
  .pl-lg-9, .px-lg-9 {
    padding-left: 2.25rem !important;
  }
  .p-lg-10 {
    padding: 2.5rem !important;
  }
  .pt-lg-10, .py-lg-10 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-10, .px-lg-10 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-10, .py-lg-10 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-10, .px-lg-10 {
    padding-left: 2.5rem !important;
  }
  .p-lg-11 {
    padding: 2.75rem !important;
  }
  .pt-lg-11, .py-lg-11 {
    padding-top: 2.75rem !important;
  }
  .pr-lg-11, .px-lg-11 {
    padding-right: 2.75rem !important;
  }
  .pb-lg-11, .py-lg-11 {
    padding-bottom: 2.75rem !important;
  }
  .pl-lg-11, .px-lg-11 {
    padding-left: 2.75rem !important;
  }
  .p-lg-12 {
    padding: 3rem !important;
  }
  .pt-lg-12, .py-lg-12 {
    padding-top: 3rem !important;
  }
  .pr-lg-12, .px-lg-12 {
    padding-right: 3rem !important;
  }
  .pb-lg-12, .py-lg-12 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-12, .px-lg-12 {
    padding-left: 3rem !important;
  }
  .p-lg-13 {
    padding: 3.25rem !important;
  }
  .pt-lg-13, .py-lg-13 {
    padding-top: 3.25rem !important;
  }
  .pr-lg-13, .px-lg-13 {
    padding-right: 3.25rem !important;
  }
  .pb-lg-13, .py-lg-13 {
    padding-bottom: 3.25rem !important;
  }
  .pl-lg-13, .px-lg-13 {
    padding-left: 3.25rem !important;
  }
  .p-lg-14 {
    padding: 3.5rem !important;
  }
  .pt-lg-14, .py-lg-14 {
    padding-top: 3.5rem !important;
  }
  .pr-lg-14, .px-lg-14 {
    padding-right: 3.5rem !important;
  }
  .pb-lg-14, .py-lg-14 {
    padding-bottom: 3.5rem !important;
  }
  .pl-lg-14, .px-lg-14 {
    padding-left: 3.5rem !important;
  }
  .p-lg-15 {
    padding: 3.75rem !important;
  }
  .pt-lg-15, .py-lg-15 {
    padding-top: 3.75rem !important;
  }
  .pr-lg-15, .px-lg-15 {
    padding-right: 3.75rem !important;
  }
  .pb-lg-15, .py-lg-15 {
    padding-bottom: 3.75rem !important;
  }
  .pl-lg-15, .px-lg-15 {
    padding-left: 3.75rem !important;
  }
  .p-lg-16 {
    padding: 4rem !important;
  }
  .pt-lg-16, .py-lg-16 {
    padding-top: 4rem !important;
  }
  .pr-lg-16, .px-lg-16 {
    padding-right: 4rem !important;
  }
  .pb-lg-16, .py-lg-16 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-16, .px-lg-16 {
    padding-left: 4rem !important;
  }
  .p-lg-17 {
    padding: 4.25rem !important;
  }
  .pt-lg-17, .py-lg-17 {
    padding-top: 4.25rem !important;
  }
  .pr-lg-17, .px-lg-17 {
    padding-right: 4.25rem !important;
  }
  .pb-lg-17, .py-lg-17 {
    padding-bottom: 4.25rem !important;
  }
  .pl-lg-17, .px-lg-17 {
    padding-left: 4.25rem !important;
  }
  .p-lg-18 {
    padding: 4.5rem !important;
  }
  .pt-lg-18, .py-lg-18 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-18, .px-lg-18 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-18, .py-lg-18 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-18, .px-lg-18 {
    padding-left: 4.5rem !important;
  }
  .p-lg-19 {
    padding: 4.75rem !important;
  }
  .pt-lg-19, .py-lg-19 {
    padding-top: 4.75rem !important;
  }
  .pr-lg-19, .px-lg-19 {
    padding-right: 4.75rem !important;
  }
  .pb-lg-19, .py-lg-19 {
    padding-bottom: 4.75rem !important;
  }
  .pl-lg-19, .px-lg-19 {
    padding-left: 4.75rem !important;
  }
  .p-lg-20 {
    padding: 5rem !important;
  }
  .pt-lg-20, .py-lg-20 {
    padding-top: 5rem !important;
  }
  .pr-lg-20, .px-lg-20 {
    padding-right: 5rem !important;
  }
  .pb-lg-20, .py-lg-20 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-20, .px-lg-20 {
    padding-left: 5rem !important;
  }
  .p-lg-21 {
    padding: 5.25rem !important;
  }
  .pt-lg-21, .py-lg-21 {
    padding-top: 5.25rem !important;
  }
  .pr-lg-21, .px-lg-21 {
    padding-right: 5.25rem !important;
  }
  .pb-lg-21, .py-lg-21 {
    padding-bottom: 5.25rem !important;
  }
  .pl-lg-21, .px-lg-21 {
    padding-left: 5.25rem !important;
  }
  .p-lg-22 {
    padding: 5.5rem !important;
  }
  .pt-lg-22, .py-lg-22 {
    padding-top: 5.5rem !important;
  }
  .pr-lg-22, .px-lg-22 {
    padding-right: 5.5rem !important;
  }
  .pb-lg-22, .py-lg-22 {
    padding-bottom: 5.5rem !important;
  }
  .pl-lg-22, .px-lg-22 {
    padding-left: 5.5rem !important;
  }
  .p-lg-23 {
    padding: 5.75rem !important;
  }
  .pt-lg-23, .py-lg-23 {
    padding-top: 5.75rem !important;
  }
  .pr-lg-23, .px-lg-23 {
    padding-right: 5.75rem !important;
  }
  .pb-lg-23, .py-lg-23 {
    padding-bottom: 5.75rem !important;
  }
  .pl-lg-23, .px-lg-23 {
    padding-left: 5.75rem !important;
  }
  .p-lg-24 {
    padding: 6rem !important;
  }
  .pt-lg-24, .py-lg-24 {
    padding-top: 6rem !important;
  }
  .pr-lg-24, .px-lg-24 {
    padding-right: 6rem !important;
  }
  .pb-lg-24, .py-lg-24 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-24, .px-lg-24 {
    padding-left: 6rem !important;
  }
  .p-lg-25 {
    padding: 6.25rem !important;
  }
  .pt-lg-25, .py-lg-25 {
    padding-top: 6.25rem !important;
  }
  .pr-lg-25, .px-lg-25 {
    padding-right: 6.25rem !important;
  }
  .pb-lg-25, .py-lg-25 {
    padding-bottom: 6.25rem !important;
  }
  .pl-lg-25, .px-lg-25 {
    padding-left: 6.25rem !important;
  }
  .p-lg-26 {
    padding: 6.5rem !important;
  }
  .pt-lg-26, .py-lg-26 {
    padding-top: 6.5rem !important;
  }
  .pr-lg-26, .px-lg-26 {
    padding-right: 6.5rem !important;
  }
  .pb-lg-26, .py-lg-26 {
    padding-bottom: 6.5rem !important;
  }
  .pl-lg-26, .px-lg-26 {
    padding-left: 6.5rem !important;
  }
  .p-lg-27 {
    padding: 6.75rem !important;
  }
  .pt-lg-27, .py-lg-27 {
    padding-top: 6.75rem !important;
  }
  .pr-lg-27, .px-lg-27 {
    padding-right: 6.75rem !important;
  }
  .pb-lg-27, .py-lg-27 {
    padding-bottom: 6.75rem !important;
  }
  .pl-lg-27, .px-lg-27 {
    padding-left: 6.75rem !important;
  }
  .p-lg-28 {
    padding: 7rem !important;
  }
  .pt-lg-28, .py-lg-28 {
    padding-top: 7rem !important;
  }
  .pr-lg-28, .px-lg-28 {
    padding-right: 7rem !important;
  }
  .pb-lg-28, .py-lg-28 {
    padding-bottom: 7rem !important;
  }
  .pl-lg-28, .px-lg-28 {
    padding-left: 7rem !important;
  }
  .p-lg-29 {
    padding: 7.25rem !important;
  }
  .pt-lg-29, .py-lg-29 {
    padding-top: 7.25rem !important;
  }
  .pr-lg-29, .px-lg-29 {
    padding-right: 7.25rem !important;
  }
  .pb-lg-29, .py-lg-29 {
    padding-bottom: 7.25rem !important;
  }
  .pl-lg-29, .px-lg-29 {
    padding-left: 7.25rem !important;
  }
  .p-lg-30 {
    padding: 7.5rem !important;
  }
  .pt-lg-30, .py-lg-30 {
    padding-top: 7.5rem !important;
  }
  .pr-lg-30, .px-lg-30 {
    padding-right: 7.5rem !important;
  }
  .pb-lg-30, .py-lg-30 {
    padding-bottom: 7.5rem !important;
  }
  .pl-lg-30, .px-lg-30 {
    padding-left: 7.5rem !important;
  }
  .p-lg-31 {
    padding: 7.75rem !important;
  }
  .pt-lg-31, .py-lg-31 {
    padding-top: 7.75rem !important;
  }
  .pr-lg-31, .px-lg-31 {
    padding-right: 7.75rem !important;
  }
  .pb-lg-31, .py-lg-31 {
    padding-bottom: 7.75rem !important;
  }
  .pl-lg-31, .px-lg-31 {
    padding-left: 7.75rem !important;
  }
  .p-lg-32 {
    padding: 8rem !important;
  }
  .pt-lg-32, .py-lg-32 {
    padding-top: 8rem !important;
  }
  .pr-lg-32, .px-lg-32 {
    padding-right: 8rem !important;
  }
  .pb-lg-32, .py-lg-32 {
    padding-bottom: 8rem !important;
  }
  .pl-lg-32, .px-lg-32 {
    padding-left: 8rem !important;
  }
  .p-lg-33 {
    padding: 8.25rem !important;
  }
  .pt-lg-33, .py-lg-33 {
    padding-top: 8.25rem !important;
  }
  .pr-lg-33, .px-lg-33 {
    padding-right: 8.25rem !important;
  }
  .pb-lg-33, .py-lg-33 {
    padding-bottom: 8.25rem !important;
  }
  .pl-lg-33, .px-lg-33 {
    padding-left: 8.25rem !important;
  }
  .p-lg-34 {
    padding: 8.5rem !important;
  }
  .pt-lg-34, .py-lg-34 {
    padding-top: 8.5rem !important;
  }
  .pr-lg-34, .px-lg-34 {
    padding-right: 8.5rem !important;
  }
  .pb-lg-34, .py-lg-34 {
    padding-bottom: 8.5rem !important;
  }
  .pl-lg-34, .px-lg-34 {
    padding-left: 8.5rem !important;
  }
  .p-lg-35 {
    padding: 8.75rem !important;
  }
  .pt-lg-35, .py-lg-35 {
    padding-top: 8.75rem !important;
  }
  .pr-lg-35, .px-lg-35 {
    padding-right: 8.75rem !important;
  }
  .pb-lg-35, .py-lg-35 {
    padding-bottom: 8.75rem !important;
  }
  .pl-lg-35, .px-lg-35 {
    padding-left: 8.75rem !important;
  }
  .p-lg-36 {
    padding: 9rem !important;
  }
  .pt-lg-36, .py-lg-36 {
    padding-top: 9rem !important;
  }
  .pr-lg-36, .px-lg-36 {
    padding-right: 9rem !important;
  }
  .pb-lg-36, .py-lg-36 {
    padding-bottom: 9rem !important;
  }
  .pl-lg-36, .px-lg-36 {
    padding-left: 9rem !important;
  }
  .p-lg-37 {
    padding: 9.25rem !important;
  }
  .pt-lg-37, .py-lg-37 {
    padding-top: 9.25rem !important;
  }
  .pr-lg-37, .px-lg-37 {
    padding-right: 9.25rem !important;
  }
  .pb-lg-37, .py-lg-37 {
    padding-bottom: 9.25rem !important;
  }
  .pl-lg-37, .px-lg-37 {
    padding-left: 9.25rem !important;
  }
  .p-lg-48 {
    padding: 9.5rem !important;
  }
  .pt-lg-48, .py-lg-48 {
    padding-top: 9.5rem !important;
  }
  .pr-lg-48, .px-lg-48 {
    padding-right: 9.5rem !important;
  }
  .pb-lg-48, .py-lg-48 {
    padding-bottom: 9.5rem !important;
  }
  .pl-lg-48, .px-lg-48 {
    padding-left: 9.5rem !important;
  }
  .p-lg-39 {
    padding: 9.75rem !important;
  }
  .pt-lg-39, .py-lg-39 {
    padding-top: 9.75rem !important;
  }
  .pr-lg-39, .px-lg-39 {
    padding-right: 9.75rem !important;
  }
  .pb-lg-39, .py-lg-39 {
    padding-bottom: 9.75rem !important;
  }
  .pl-lg-39, .px-lg-39 {
    padding-left: 9.75rem !important;
  }
  .p-lg-40 {
    padding: 10rem !important;
  }
  .pt-lg-40, .py-lg-40 {
    padding-top: 10rem !important;
  }
  .pr-lg-40, .px-lg-40 {
    padding-right: 10rem !important;
  }
  .pb-lg-40, .py-lg-40 {
    padding-bottom: 10rem !important;
  }
  .pl-lg-40, .px-lg-40 {
    padding-left: 10rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1, .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2, .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -0.75rem !important;
  }
  .mt-lg-n3, .my-lg-n3 {
    margin-top: -0.75rem !important;
  }
  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -0.75rem !important;
  }
  .m-lg-n4 {
    margin: -1rem !important;
  }
  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1rem !important;
  }
  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -1rem !important;
  }
  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -1rem !important;
  }
  .m-lg-n5 {
    margin: -1.25rem !important;
  }
  .mt-lg-n5, .my-lg-n5 {
    margin-top: -1.25rem !important;
  }
  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -1.25rem !important;
  }
  .m-lg-n6 {
    margin: -1.5rem !important;
  }
  .mt-lg-n6, .my-lg-n6 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n6, .mx-lg-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n6, .my-lg-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n6, .mx-lg-n6 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n7 {
    margin: -1.75rem !important;
  }
  .mt-lg-n7, .my-lg-n7 {
    margin-top: -1.75rem !important;
  }
  .mr-lg-n7, .mx-lg-n7 {
    margin-right: -1.75rem !important;
  }
  .mb-lg-n7, .my-lg-n7 {
    margin-bottom: -1.75rem !important;
  }
  .ml-lg-n7, .mx-lg-n7 {
    margin-left: -1.75rem !important;
  }
  .m-lg-n8 {
    margin: -2rem !important;
  }
  .mt-lg-n8, .my-lg-n8 {
    margin-top: -2rem !important;
  }
  .mr-lg-n8, .mx-lg-n8 {
    margin-right: -2rem !important;
  }
  .mb-lg-n8, .my-lg-n8 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n8, .mx-lg-n8 {
    margin-left: -2rem !important;
  }
  .m-lg-n9 {
    margin: -2.25rem !important;
  }
  .mt-lg-n9, .my-lg-n9 {
    margin-top: -2.25rem !important;
  }
  .mr-lg-n9, .mx-lg-n9 {
    margin-right: -2.25rem !important;
  }
  .mb-lg-n9, .my-lg-n9 {
    margin-bottom: -2.25rem !important;
  }
  .ml-lg-n9, .mx-lg-n9 {
    margin-left: -2.25rem !important;
  }
  .m-lg-n10 {
    margin: -2.5rem !important;
  }
  .mt-lg-n10, .my-lg-n10 {
    margin-top: -2.5rem !important;
  }
  .mr-lg-n10, .mx-lg-n10 {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n10, .my-lg-n10 {
    margin-bottom: -2.5rem !important;
  }
  .ml-lg-n10, .mx-lg-n10 {
    margin-left: -2.5rem !important;
  }
  .m-lg-n11 {
    margin: -2.75rem !important;
  }
  .mt-lg-n11, .my-lg-n11 {
    margin-top: -2.75rem !important;
  }
  .mr-lg-n11, .mx-lg-n11 {
    margin-right: -2.75rem !important;
  }
  .mb-lg-n11, .my-lg-n11 {
    margin-bottom: -2.75rem !important;
  }
  .ml-lg-n11, .mx-lg-n11 {
    margin-left: -2.75rem !important;
  }
  .m-lg-n12 {
    margin: -3rem !important;
  }
  .mt-lg-n12, .my-lg-n12 {
    margin-top: -3rem !important;
  }
  .mr-lg-n12, .mx-lg-n12 {
    margin-right: -3rem !important;
  }
  .mb-lg-n12, .my-lg-n12 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n12, .mx-lg-n12 {
    margin-left: -3rem !important;
  }
  .m-lg-n13 {
    margin: -3.25rem !important;
  }
  .mt-lg-n13, .my-lg-n13 {
    margin-top: -3.25rem !important;
  }
  .mr-lg-n13, .mx-lg-n13 {
    margin-right: -3.25rem !important;
  }
  .mb-lg-n13, .my-lg-n13 {
    margin-bottom: -3.25rem !important;
  }
  .ml-lg-n13, .mx-lg-n13 {
    margin-left: -3.25rem !important;
  }
  .m-lg-n14 {
    margin: -3.5rem !important;
  }
  .mt-lg-n14, .my-lg-n14 {
    margin-top: -3.5rem !important;
  }
  .mr-lg-n14, .mx-lg-n14 {
    margin-right: -3.5rem !important;
  }
  .mb-lg-n14, .my-lg-n14 {
    margin-bottom: -3.5rem !important;
  }
  .ml-lg-n14, .mx-lg-n14 {
    margin-left: -3.5rem !important;
  }
  .m-lg-n15 {
    margin: -3.75rem !important;
  }
  .mt-lg-n15, .my-lg-n15 {
    margin-top: -3.75rem !important;
  }
  .mr-lg-n15, .mx-lg-n15 {
    margin-right: -3.75rem !important;
  }
  .mb-lg-n15, .my-lg-n15 {
    margin-bottom: -3.75rem !important;
  }
  .ml-lg-n15, .mx-lg-n15 {
    margin-left: -3.75rem !important;
  }
  .m-lg-n16 {
    margin: -4rem !important;
  }
  .mt-lg-n16, .my-lg-n16 {
    margin-top: -4rem !important;
  }
  .mr-lg-n16, .mx-lg-n16 {
    margin-right: -4rem !important;
  }
  .mb-lg-n16, .my-lg-n16 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n16, .mx-lg-n16 {
    margin-left: -4rem !important;
  }
  .m-lg-n17 {
    margin: -4.25rem !important;
  }
  .mt-lg-n17, .my-lg-n17 {
    margin-top: -4.25rem !important;
  }
  .mr-lg-n17, .mx-lg-n17 {
    margin-right: -4.25rem !important;
  }
  .mb-lg-n17, .my-lg-n17 {
    margin-bottom: -4.25rem !important;
  }
  .ml-lg-n17, .mx-lg-n17 {
    margin-left: -4.25rem !important;
  }
  .m-lg-n18 {
    margin: -4.5rem !important;
  }
  .mt-lg-n18, .my-lg-n18 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n18, .mx-lg-n18 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n18, .my-lg-n18 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n18, .mx-lg-n18 {
    margin-left: -4.5rem !important;
  }
  .m-lg-n19 {
    margin: -4.75rem !important;
  }
  .mt-lg-n19, .my-lg-n19 {
    margin-top: -4.75rem !important;
  }
  .mr-lg-n19, .mx-lg-n19 {
    margin-right: -4.75rem !important;
  }
  .mb-lg-n19, .my-lg-n19 {
    margin-bottom: -4.75rem !important;
  }
  .ml-lg-n19, .mx-lg-n19 {
    margin-left: -4.75rem !important;
  }
  .m-lg-n20 {
    margin: -5rem !important;
  }
  .mt-lg-n20, .my-lg-n20 {
    margin-top: -5rem !important;
  }
  .mr-lg-n20, .mx-lg-n20 {
    margin-right: -5rem !important;
  }
  .mb-lg-n20, .my-lg-n20 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n20, .mx-lg-n20 {
    margin-left: -5rem !important;
  }
  .m-lg-n21 {
    margin: -5.25rem !important;
  }
  .mt-lg-n21, .my-lg-n21 {
    margin-top: -5.25rem !important;
  }
  .mr-lg-n21, .mx-lg-n21 {
    margin-right: -5.25rem !important;
  }
  .mb-lg-n21, .my-lg-n21 {
    margin-bottom: -5.25rem !important;
  }
  .ml-lg-n21, .mx-lg-n21 {
    margin-left: -5.25rem !important;
  }
  .m-lg-n22 {
    margin: -5.5rem !important;
  }
  .mt-lg-n22, .my-lg-n22 {
    margin-top: -5.5rem !important;
  }
  .mr-lg-n22, .mx-lg-n22 {
    margin-right: -5.5rem !important;
  }
  .mb-lg-n22, .my-lg-n22 {
    margin-bottom: -5.5rem !important;
  }
  .ml-lg-n22, .mx-lg-n22 {
    margin-left: -5.5rem !important;
  }
  .m-lg-n23 {
    margin: -5.75rem !important;
  }
  .mt-lg-n23, .my-lg-n23 {
    margin-top: -5.75rem !important;
  }
  .mr-lg-n23, .mx-lg-n23 {
    margin-right: -5.75rem !important;
  }
  .mb-lg-n23, .my-lg-n23 {
    margin-bottom: -5.75rem !important;
  }
  .ml-lg-n23, .mx-lg-n23 {
    margin-left: -5.75rem !important;
  }
  .m-lg-n24 {
    margin: -6rem !important;
  }
  .mt-lg-n24, .my-lg-n24 {
    margin-top: -6rem !important;
  }
  .mr-lg-n24, .mx-lg-n24 {
    margin-right: -6rem !important;
  }
  .mb-lg-n24, .my-lg-n24 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n24, .mx-lg-n24 {
    margin-left: -6rem !important;
  }
  .m-lg-n25 {
    margin: -6.25rem !important;
  }
  .mt-lg-n25, .my-lg-n25 {
    margin-top: -6.25rem !important;
  }
  .mr-lg-n25, .mx-lg-n25 {
    margin-right: -6.25rem !important;
  }
  .mb-lg-n25, .my-lg-n25 {
    margin-bottom: -6.25rem !important;
  }
  .ml-lg-n25, .mx-lg-n25 {
    margin-left: -6.25rem !important;
  }
  .m-lg-n26 {
    margin: -6.5rem !important;
  }
  .mt-lg-n26, .my-lg-n26 {
    margin-top: -6.5rem !important;
  }
  .mr-lg-n26, .mx-lg-n26 {
    margin-right: -6.5rem !important;
  }
  .mb-lg-n26, .my-lg-n26 {
    margin-bottom: -6.5rem !important;
  }
  .ml-lg-n26, .mx-lg-n26 {
    margin-left: -6.5rem !important;
  }
  .m-lg-n27 {
    margin: -6.75rem !important;
  }
  .mt-lg-n27, .my-lg-n27 {
    margin-top: -6.75rem !important;
  }
  .mr-lg-n27, .mx-lg-n27 {
    margin-right: -6.75rem !important;
  }
  .mb-lg-n27, .my-lg-n27 {
    margin-bottom: -6.75rem !important;
  }
  .ml-lg-n27, .mx-lg-n27 {
    margin-left: -6.75rem !important;
  }
  .m-lg-n28 {
    margin: -7rem !important;
  }
  .mt-lg-n28, .my-lg-n28 {
    margin-top: -7rem !important;
  }
  .mr-lg-n28, .mx-lg-n28 {
    margin-right: -7rem !important;
  }
  .mb-lg-n28, .my-lg-n28 {
    margin-bottom: -7rem !important;
  }
  .ml-lg-n28, .mx-lg-n28 {
    margin-left: -7rem !important;
  }
  .m-lg-n29 {
    margin: -7.25rem !important;
  }
  .mt-lg-n29, .my-lg-n29 {
    margin-top: -7.25rem !important;
  }
  .mr-lg-n29, .mx-lg-n29 {
    margin-right: -7.25rem !important;
  }
  .mb-lg-n29, .my-lg-n29 {
    margin-bottom: -7.25rem !important;
  }
  .ml-lg-n29, .mx-lg-n29 {
    margin-left: -7.25rem !important;
  }
  .m-lg-n30 {
    margin: -7.5rem !important;
  }
  .mt-lg-n30, .my-lg-n30 {
    margin-top: -7.5rem !important;
  }
  .mr-lg-n30, .mx-lg-n30 {
    margin-right: -7.5rem !important;
  }
  .mb-lg-n30, .my-lg-n30 {
    margin-bottom: -7.5rem !important;
  }
  .ml-lg-n30, .mx-lg-n30 {
    margin-left: -7.5rem !important;
  }
  .m-lg-n31 {
    margin: -7.75rem !important;
  }
  .mt-lg-n31, .my-lg-n31 {
    margin-top: -7.75rem !important;
  }
  .mr-lg-n31, .mx-lg-n31 {
    margin-right: -7.75rem !important;
  }
  .mb-lg-n31, .my-lg-n31 {
    margin-bottom: -7.75rem !important;
  }
  .ml-lg-n31, .mx-lg-n31 {
    margin-left: -7.75rem !important;
  }
  .m-lg-n32 {
    margin: -8rem !important;
  }
  .mt-lg-n32, .my-lg-n32 {
    margin-top: -8rem !important;
  }
  .mr-lg-n32, .mx-lg-n32 {
    margin-right: -8rem !important;
  }
  .mb-lg-n32, .my-lg-n32 {
    margin-bottom: -8rem !important;
  }
  .ml-lg-n32, .mx-lg-n32 {
    margin-left: -8rem !important;
  }
  .m-lg-n33 {
    margin: -8.25rem !important;
  }
  .mt-lg-n33, .my-lg-n33 {
    margin-top: -8.25rem !important;
  }
  .mr-lg-n33, .mx-lg-n33 {
    margin-right: -8.25rem !important;
  }
  .mb-lg-n33, .my-lg-n33 {
    margin-bottom: -8.25rem !important;
  }
  .ml-lg-n33, .mx-lg-n33 {
    margin-left: -8.25rem !important;
  }
  .m-lg-n34 {
    margin: -8.5rem !important;
  }
  .mt-lg-n34, .my-lg-n34 {
    margin-top: -8.5rem !important;
  }
  .mr-lg-n34, .mx-lg-n34 {
    margin-right: -8.5rem !important;
  }
  .mb-lg-n34, .my-lg-n34 {
    margin-bottom: -8.5rem !important;
  }
  .ml-lg-n34, .mx-lg-n34 {
    margin-left: -8.5rem !important;
  }
  .m-lg-n35 {
    margin: -8.75rem !important;
  }
  .mt-lg-n35, .my-lg-n35 {
    margin-top: -8.75rem !important;
  }
  .mr-lg-n35, .mx-lg-n35 {
    margin-right: -8.75rem !important;
  }
  .mb-lg-n35, .my-lg-n35 {
    margin-bottom: -8.75rem !important;
  }
  .ml-lg-n35, .mx-lg-n35 {
    margin-left: -8.75rem !important;
  }
  .m-lg-n36 {
    margin: -9rem !important;
  }
  .mt-lg-n36, .my-lg-n36 {
    margin-top: -9rem !important;
  }
  .mr-lg-n36, .mx-lg-n36 {
    margin-right: -9rem !important;
  }
  .mb-lg-n36, .my-lg-n36 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-n36, .mx-lg-n36 {
    margin-left: -9rem !important;
  }
  .m-lg-n37 {
    margin: -9.25rem !important;
  }
  .mt-lg-n37, .my-lg-n37 {
    margin-top: -9.25rem !important;
  }
  .mr-lg-n37, .mx-lg-n37 {
    margin-right: -9.25rem !important;
  }
  .mb-lg-n37, .my-lg-n37 {
    margin-bottom: -9.25rem !important;
  }
  .ml-lg-n37, .mx-lg-n37 {
    margin-left: -9.25rem !important;
  }
  .m-lg-n48 {
    margin: -9.5rem !important;
  }
  .mt-lg-n48, .my-lg-n48 {
    margin-top: -9.5rem !important;
  }
  .mr-lg-n48, .mx-lg-n48 {
    margin-right: -9.5rem !important;
  }
  .mb-lg-n48, .my-lg-n48 {
    margin-bottom: -9.5rem !important;
  }
  .ml-lg-n48, .mx-lg-n48 {
    margin-left: -9.5rem !important;
  }
  .m-lg-n39 {
    margin: -9.75rem !important;
  }
  .mt-lg-n39, .my-lg-n39 {
    margin-top: -9.75rem !important;
  }
  .mr-lg-n39, .mx-lg-n39 {
    margin-right: -9.75rem !important;
  }
  .mb-lg-n39, .my-lg-n39 {
    margin-bottom: -9.75rem !important;
  }
  .ml-lg-n39, .mx-lg-n39 {
    margin-left: -9.75rem !important;
  }
  .m-lg-n40 {
    margin: -10rem !important;
  }
  .mt-lg-n40, .my-lg-n40 {
    margin-top: -10rem !important;
  }
  .mr-lg-n40, .mx-lg-n40 {
    margin-right: -10rem !important;
  }
  .mb-lg-n40, .my-lg-n40 {
    margin-bottom: -10rem !important;
  }
  .ml-lg-n40, .mx-lg-n40 {
    margin-left: -10rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1, .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1, .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1, .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1, .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2, .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2, .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2, .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2, .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .mt-xl-3, .my-xl-3 {
    margin-top: 0.75rem !important;
  }
  .mr-xl-3, .mx-xl-3 {
    margin-right: 0.75rem !important;
  }
  .mb-xl-3, .my-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xl-3, .mx-xl-3 {
    margin-left: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .mt-xl-4, .my-xl-4 {
    margin-top: 1rem !important;
  }
  .mr-xl-4, .mx-xl-4 {
    margin-right: 1rem !important;
  }
  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-4, .mx-xl-4 {
    margin-left: 1rem !important;
  }
  .m-xl-5 {
    margin: 1.25rem !important;
  }
  .mt-xl-5, .my-xl-5 {
    margin-top: 1.25rem !important;
  }
  .mr-xl-5, .mx-xl-5 {
    margin-right: 1.25rem !important;
  }
  .mb-xl-5, .my-xl-5 {
    margin-bottom: 1.25rem !important;
  }
  .ml-xl-5, .mx-xl-5 {
    margin-left: 1.25rem !important;
  }
  .m-xl-6 {
    margin: 1.5rem !important;
  }
  .mt-xl-6, .my-xl-6 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-6, .mx-xl-6 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-6, .my-xl-6 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-6, .mx-xl-6 {
    margin-left: 1.5rem !important;
  }
  .m-xl-7 {
    margin: 1.75rem !important;
  }
  .mt-xl-7, .my-xl-7 {
    margin-top: 1.75rem !important;
  }
  .mr-xl-7, .mx-xl-7 {
    margin-right: 1.75rem !important;
  }
  .mb-xl-7, .my-xl-7 {
    margin-bottom: 1.75rem !important;
  }
  .ml-xl-7, .mx-xl-7 {
    margin-left: 1.75rem !important;
  }
  .m-xl-8 {
    margin: 2rem !important;
  }
  .mt-xl-8, .my-xl-8 {
    margin-top: 2rem !important;
  }
  .mr-xl-8, .mx-xl-8 {
    margin-right: 2rem !important;
  }
  .mb-xl-8, .my-xl-8 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-8, .mx-xl-8 {
    margin-left: 2rem !important;
  }
  .m-xl-9 {
    margin: 2.25rem !important;
  }
  .mt-xl-9, .my-xl-9 {
    margin-top: 2.25rem !important;
  }
  .mr-xl-9, .mx-xl-9 {
    margin-right: 2.25rem !important;
  }
  .mb-xl-9, .my-xl-9 {
    margin-bottom: 2.25rem !important;
  }
  .ml-xl-9, .mx-xl-9 {
    margin-left: 2.25rem !important;
  }
  .m-xl-10 {
    margin: 2.5rem !important;
  }
  .mt-xl-10, .my-xl-10 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-10, .mx-xl-10 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-10, .my-xl-10 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-10, .mx-xl-10 {
    margin-left: 2.5rem !important;
  }
  .m-xl-11 {
    margin: 2.75rem !important;
  }
  .mt-xl-11, .my-xl-11 {
    margin-top: 2.75rem !important;
  }
  .mr-xl-11, .mx-xl-11 {
    margin-right: 2.75rem !important;
  }
  .mb-xl-11, .my-xl-11 {
    margin-bottom: 2.75rem !important;
  }
  .ml-xl-11, .mx-xl-11 {
    margin-left: 2.75rem !important;
  }
  .m-xl-12 {
    margin: 3rem !important;
  }
  .mt-xl-12, .my-xl-12 {
    margin-top: 3rem !important;
  }
  .mr-xl-12, .mx-xl-12 {
    margin-right: 3rem !important;
  }
  .mb-xl-12, .my-xl-12 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-12, .mx-xl-12 {
    margin-left: 3rem !important;
  }
  .m-xl-13 {
    margin: 3.25rem !important;
  }
  .mt-xl-13, .my-xl-13 {
    margin-top: 3.25rem !important;
  }
  .mr-xl-13, .mx-xl-13 {
    margin-right: 3.25rem !important;
  }
  .mb-xl-13, .my-xl-13 {
    margin-bottom: 3.25rem !important;
  }
  .ml-xl-13, .mx-xl-13 {
    margin-left: 3.25rem !important;
  }
  .m-xl-14 {
    margin: 3.5rem !important;
  }
  .mt-xl-14, .my-xl-14 {
    margin-top: 3.5rem !important;
  }
  .mr-xl-14, .mx-xl-14 {
    margin-right: 3.5rem !important;
  }
  .mb-xl-14, .my-xl-14 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xl-14, .mx-xl-14 {
    margin-left: 3.5rem !important;
  }
  .m-xl-15 {
    margin: 3.75rem !important;
  }
  .mt-xl-15, .my-xl-15 {
    margin-top: 3.75rem !important;
  }
  .mr-xl-15, .mx-xl-15 {
    margin-right: 3.75rem !important;
  }
  .mb-xl-15, .my-xl-15 {
    margin-bottom: 3.75rem !important;
  }
  .ml-xl-15, .mx-xl-15 {
    margin-left: 3.75rem !important;
  }
  .m-xl-16 {
    margin: 4rem !important;
  }
  .mt-xl-16, .my-xl-16 {
    margin-top: 4rem !important;
  }
  .mr-xl-16, .mx-xl-16 {
    margin-right: 4rem !important;
  }
  .mb-xl-16, .my-xl-16 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-16, .mx-xl-16 {
    margin-left: 4rem !important;
  }
  .m-xl-17 {
    margin: 4.25rem !important;
  }
  .mt-xl-17, .my-xl-17 {
    margin-top: 4.25rem !important;
  }
  .mr-xl-17, .mx-xl-17 {
    margin-right: 4.25rem !important;
  }
  .mb-xl-17, .my-xl-17 {
    margin-bottom: 4.25rem !important;
  }
  .ml-xl-17, .mx-xl-17 {
    margin-left: 4.25rem !important;
  }
  .m-xl-18 {
    margin: 4.5rem !important;
  }
  .mt-xl-18, .my-xl-18 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-18, .mx-xl-18 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-18, .my-xl-18 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-18, .mx-xl-18 {
    margin-left: 4.5rem !important;
  }
  .m-xl-19 {
    margin: 4.75rem !important;
  }
  .mt-xl-19, .my-xl-19 {
    margin-top: 4.75rem !important;
  }
  .mr-xl-19, .mx-xl-19 {
    margin-right: 4.75rem !important;
  }
  .mb-xl-19, .my-xl-19 {
    margin-bottom: 4.75rem !important;
  }
  .ml-xl-19, .mx-xl-19 {
    margin-left: 4.75rem !important;
  }
  .m-xl-20 {
    margin: 5rem !important;
  }
  .mt-xl-20, .my-xl-20 {
    margin-top: 5rem !important;
  }
  .mr-xl-20, .mx-xl-20 {
    margin-right: 5rem !important;
  }
  .mb-xl-20, .my-xl-20 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-20, .mx-xl-20 {
    margin-left: 5rem !important;
  }
  .m-xl-21 {
    margin: 5.25rem !important;
  }
  .mt-xl-21, .my-xl-21 {
    margin-top: 5.25rem !important;
  }
  .mr-xl-21, .mx-xl-21 {
    margin-right: 5.25rem !important;
  }
  .mb-xl-21, .my-xl-21 {
    margin-bottom: 5.25rem !important;
  }
  .ml-xl-21, .mx-xl-21 {
    margin-left: 5.25rem !important;
  }
  .m-xl-22 {
    margin: 5.5rem !important;
  }
  .mt-xl-22, .my-xl-22 {
    margin-top: 5.5rem !important;
  }
  .mr-xl-22, .mx-xl-22 {
    margin-right: 5.5rem !important;
  }
  .mb-xl-22, .my-xl-22 {
    margin-bottom: 5.5rem !important;
  }
  .ml-xl-22, .mx-xl-22 {
    margin-left: 5.5rem !important;
  }
  .m-xl-23 {
    margin: 5.75rem !important;
  }
  .mt-xl-23, .my-xl-23 {
    margin-top: 5.75rem !important;
  }
  .mr-xl-23, .mx-xl-23 {
    margin-right: 5.75rem !important;
  }
  .mb-xl-23, .my-xl-23 {
    margin-bottom: 5.75rem !important;
  }
  .ml-xl-23, .mx-xl-23 {
    margin-left: 5.75rem !important;
  }
  .m-xl-24 {
    margin: 6rem !important;
  }
  .mt-xl-24, .my-xl-24 {
    margin-top: 6rem !important;
  }
  .mr-xl-24, .mx-xl-24 {
    margin-right: 6rem !important;
  }
  .mb-xl-24, .my-xl-24 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-24, .mx-xl-24 {
    margin-left: 6rem !important;
  }
  .m-xl-25 {
    margin: 6.25rem !important;
  }
  .mt-xl-25, .my-xl-25 {
    margin-top: 6.25rem !important;
  }
  .mr-xl-25, .mx-xl-25 {
    margin-right: 6.25rem !important;
  }
  .mb-xl-25, .my-xl-25 {
    margin-bottom: 6.25rem !important;
  }
  .ml-xl-25, .mx-xl-25 {
    margin-left: 6.25rem !important;
  }
  .m-xl-26 {
    margin: 6.5rem !important;
  }
  .mt-xl-26, .my-xl-26 {
    margin-top: 6.5rem !important;
  }
  .mr-xl-26, .mx-xl-26 {
    margin-right: 6.5rem !important;
  }
  .mb-xl-26, .my-xl-26 {
    margin-bottom: 6.5rem !important;
  }
  .ml-xl-26, .mx-xl-26 {
    margin-left: 6.5rem !important;
  }
  .m-xl-27 {
    margin: 6.75rem !important;
  }
  .mt-xl-27, .my-xl-27 {
    margin-top: 6.75rem !important;
  }
  .mr-xl-27, .mx-xl-27 {
    margin-right: 6.75rem !important;
  }
  .mb-xl-27, .my-xl-27 {
    margin-bottom: 6.75rem !important;
  }
  .ml-xl-27, .mx-xl-27 {
    margin-left: 6.75rem !important;
  }
  .m-xl-28 {
    margin: 7rem !important;
  }
  .mt-xl-28, .my-xl-28 {
    margin-top: 7rem !important;
  }
  .mr-xl-28, .mx-xl-28 {
    margin-right: 7rem !important;
  }
  .mb-xl-28, .my-xl-28 {
    margin-bottom: 7rem !important;
  }
  .ml-xl-28, .mx-xl-28 {
    margin-left: 7rem !important;
  }
  .m-xl-29 {
    margin: 7.25rem !important;
  }
  .mt-xl-29, .my-xl-29 {
    margin-top: 7.25rem !important;
  }
  .mr-xl-29, .mx-xl-29 {
    margin-right: 7.25rem !important;
  }
  .mb-xl-29, .my-xl-29 {
    margin-bottom: 7.25rem !important;
  }
  .ml-xl-29, .mx-xl-29 {
    margin-left: 7.25rem !important;
  }
  .m-xl-30 {
    margin: 7.5rem !important;
  }
  .mt-xl-30, .my-xl-30 {
    margin-top: 7.5rem !important;
  }
  .mr-xl-30, .mx-xl-30 {
    margin-right: 7.5rem !important;
  }
  .mb-xl-30, .my-xl-30 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xl-30, .mx-xl-30 {
    margin-left: 7.5rem !important;
  }
  .m-xl-31 {
    margin: 7.75rem !important;
  }
  .mt-xl-31, .my-xl-31 {
    margin-top: 7.75rem !important;
  }
  .mr-xl-31, .mx-xl-31 {
    margin-right: 7.75rem !important;
  }
  .mb-xl-31, .my-xl-31 {
    margin-bottom: 7.75rem !important;
  }
  .ml-xl-31, .mx-xl-31 {
    margin-left: 7.75rem !important;
  }
  .m-xl-32 {
    margin: 8rem !important;
  }
  .mt-xl-32, .my-xl-32 {
    margin-top: 8rem !important;
  }
  .mr-xl-32, .mx-xl-32 {
    margin-right: 8rem !important;
  }
  .mb-xl-32, .my-xl-32 {
    margin-bottom: 8rem !important;
  }
  .ml-xl-32, .mx-xl-32 {
    margin-left: 8rem !important;
  }
  .m-xl-33 {
    margin: 8.25rem !important;
  }
  .mt-xl-33, .my-xl-33 {
    margin-top: 8.25rem !important;
  }
  .mr-xl-33, .mx-xl-33 {
    margin-right: 8.25rem !important;
  }
  .mb-xl-33, .my-xl-33 {
    margin-bottom: 8.25rem !important;
  }
  .ml-xl-33, .mx-xl-33 {
    margin-left: 8.25rem !important;
  }
  .m-xl-34 {
    margin: 8.5rem !important;
  }
  .mt-xl-34, .my-xl-34 {
    margin-top: 8.5rem !important;
  }
  .mr-xl-34, .mx-xl-34 {
    margin-right: 8.5rem !important;
  }
  .mb-xl-34, .my-xl-34 {
    margin-bottom: 8.5rem !important;
  }
  .ml-xl-34, .mx-xl-34 {
    margin-left: 8.5rem !important;
  }
  .m-xl-35 {
    margin: 8.75rem !important;
  }
  .mt-xl-35, .my-xl-35 {
    margin-top: 8.75rem !important;
  }
  .mr-xl-35, .mx-xl-35 {
    margin-right: 8.75rem !important;
  }
  .mb-xl-35, .my-xl-35 {
    margin-bottom: 8.75rem !important;
  }
  .ml-xl-35, .mx-xl-35 {
    margin-left: 8.75rem !important;
  }
  .m-xl-36 {
    margin: 9rem !important;
  }
  .mt-xl-36, .my-xl-36 {
    margin-top: 9rem !important;
  }
  .mr-xl-36, .mx-xl-36 {
    margin-right: 9rem !important;
  }
  .mb-xl-36, .my-xl-36 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-36, .mx-xl-36 {
    margin-left: 9rem !important;
  }
  .m-xl-37 {
    margin: 9.25rem !important;
  }
  .mt-xl-37, .my-xl-37 {
    margin-top: 9.25rem !important;
  }
  .mr-xl-37, .mx-xl-37 {
    margin-right: 9.25rem !important;
  }
  .mb-xl-37, .my-xl-37 {
    margin-bottom: 9.25rem !important;
  }
  .ml-xl-37, .mx-xl-37 {
    margin-left: 9.25rem !important;
  }
  .m-xl-48 {
    margin: 9.5rem !important;
  }
  .mt-xl-48, .my-xl-48 {
    margin-top: 9.5rem !important;
  }
  .mr-xl-48, .mx-xl-48 {
    margin-right: 9.5rem !important;
  }
  .mb-xl-48, .my-xl-48 {
    margin-bottom: 9.5rem !important;
  }
  .ml-xl-48, .mx-xl-48 {
    margin-left: 9.5rem !important;
  }
  .m-xl-39 {
    margin: 9.75rem !important;
  }
  .mt-xl-39, .my-xl-39 {
    margin-top: 9.75rem !important;
  }
  .mr-xl-39, .mx-xl-39 {
    margin-right: 9.75rem !important;
  }
  .mb-xl-39, .my-xl-39 {
    margin-bottom: 9.75rem !important;
  }
  .ml-xl-39, .mx-xl-39 {
    margin-left: 9.75rem !important;
  }
  .m-xl-40 {
    margin: 10rem !important;
  }
  .mt-xl-40, .my-xl-40 {
    margin-top: 10rem !important;
  }
  .mr-xl-40, .mx-xl-40 {
    margin-right: 10rem !important;
  }
  .mb-xl-40, .my-xl-40 {
    margin-bottom: 10rem !important;
  }
  .ml-xl-40, .mx-xl-40 {
    margin-left: 10rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1, .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1, .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1, .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1, .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2, .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2, .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2, .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2, .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .pt-xl-3, .py-xl-3 {
    padding-top: 0.75rem !important;
  }
  .pr-xl-3, .px-xl-3 {
    padding-right: 0.75rem !important;
  }
  .pb-xl-3, .py-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xl-3, .px-xl-3 {
    padding-left: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .pt-xl-4, .py-xl-4 {
    padding-top: 1rem !important;
  }
  .pr-xl-4, .px-xl-4 {
    padding-right: 1rem !important;
  }
  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-4, .px-xl-4 {
    padding-left: 1rem !important;
  }
  .p-xl-5 {
    padding: 1.25rem !important;
  }
  .pt-xl-5, .py-xl-5 {
    padding-top: 1.25rem !important;
  }
  .pr-xl-5, .px-xl-5 {
    padding-right: 1.25rem !important;
  }
  .pb-xl-5, .py-xl-5 {
    padding-bottom: 1.25rem !important;
  }
  .pl-xl-5, .px-xl-5 {
    padding-left: 1.25rem !important;
  }
  .p-xl-6 {
    padding: 1.5rem !important;
  }
  .pt-xl-6, .py-xl-6 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-6, .px-xl-6 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-6, .py-xl-6 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-6, .px-xl-6 {
    padding-left: 1.5rem !important;
  }
  .p-xl-7 {
    padding: 1.75rem !important;
  }
  .pt-xl-7, .py-xl-7 {
    padding-top: 1.75rem !important;
  }
  .pr-xl-7, .px-xl-7 {
    padding-right: 1.75rem !important;
  }
  .pb-xl-7, .py-xl-7 {
    padding-bottom: 1.75rem !important;
  }
  .pl-xl-7, .px-xl-7 {
    padding-left: 1.75rem !important;
  }
  .p-xl-8 {
    padding: 2rem !important;
  }
  .pt-xl-8, .py-xl-8 {
    padding-top: 2rem !important;
  }
  .pr-xl-8, .px-xl-8 {
    padding-right: 2rem !important;
  }
  .pb-xl-8, .py-xl-8 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-8, .px-xl-8 {
    padding-left: 2rem !important;
  }
  .p-xl-9 {
    padding: 2.25rem !important;
  }
  .pt-xl-9, .py-xl-9 {
    padding-top: 2.25rem !important;
  }
  .pr-xl-9, .px-xl-9 {
    padding-right: 2.25rem !important;
  }
  .pb-xl-9, .py-xl-9 {
    padding-bottom: 2.25rem !important;
  }
  .pl-xl-9, .px-xl-9 {
    padding-left: 2.25rem !important;
  }
  .p-xl-10 {
    padding: 2.5rem !important;
  }
  .pt-xl-10, .py-xl-10 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-10, .px-xl-10 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-10, .py-xl-10 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-10, .px-xl-10 {
    padding-left: 2.5rem !important;
  }
  .p-xl-11 {
    padding: 2.75rem !important;
  }
  .pt-xl-11, .py-xl-11 {
    padding-top: 2.75rem !important;
  }
  .pr-xl-11, .px-xl-11 {
    padding-right: 2.75rem !important;
  }
  .pb-xl-11, .py-xl-11 {
    padding-bottom: 2.75rem !important;
  }
  .pl-xl-11, .px-xl-11 {
    padding-left: 2.75rem !important;
  }
  .p-xl-12 {
    padding: 3rem !important;
  }
  .pt-xl-12, .py-xl-12 {
    padding-top: 3rem !important;
  }
  .pr-xl-12, .px-xl-12 {
    padding-right: 3rem !important;
  }
  .pb-xl-12, .py-xl-12 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-12, .px-xl-12 {
    padding-left: 3rem !important;
  }
  .p-xl-13 {
    padding: 3.25rem !important;
  }
  .pt-xl-13, .py-xl-13 {
    padding-top: 3.25rem !important;
  }
  .pr-xl-13, .px-xl-13 {
    padding-right: 3.25rem !important;
  }
  .pb-xl-13, .py-xl-13 {
    padding-bottom: 3.25rem !important;
  }
  .pl-xl-13, .px-xl-13 {
    padding-left: 3.25rem !important;
  }
  .p-xl-14 {
    padding: 3.5rem !important;
  }
  .pt-xl-14, .py-xl-14 {
    padding-top: 3.5rem !important;
  }
  .pr-xl-14, .px-xl-14 {
    padding-right: 3.5rem !important;
  }
  .pb-xl-14, .py-xl-14 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xl-14, .px-xl-14 {
    padding-left: 3.5rem !important;
  }
  .p-xl-15 {
    padding: 3.75rem !important;
  }
  .pt-xl-15, .py-xl-15 {
    padding-top: 3.75rem !important;
  }
  .pr-xl-15, .px-xl-15 {
    padding-right: 3.75rem !important;
  }
  .pb-xl-15, .py-xl-15 {
    padding-bottom: 3.75rem !important;
  }
  .pl-xl-15, .px-xl-15 {
    padding-left: 3.75rem !important;
  }
  .p-xl-16 {
    padding: 4rem !important;
  }
  .pt-xl-16, .py-xl-16 {
    padding-top: 4rem !important;
  }
  .pr-xl-16, .px-xl-16 {
    padding-right: 4rem !important;
  }
  .pb-xl-16, .py-xl-16 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-16, .px-xl-16 {
    padding-left: 4rem !important;
  }
  .p-xl-17 {
    padding: 4.25rem !important;
  }
  .pt-xl-17, .py-xl-17 {
    padding-top: 4.25rem !important;
  }
  .pr-xl-17, .px-xl-17 {
    padding-right: 4.25rem !important;
  }
  .pb-xl-17, .py-xl-17 {
    padding-bottom: 4.25rem !important;
  }
  .pl-xl-17, .px-xl-17 {
    padding-left: 4.25rem !important;
  }
  .p-xl-18 {
    padding: 4.5rem !important;
  }
  .pt-xl-18, .py-xl-18 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-18, .px-xl-18 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-18, .py-xl-18 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-18, .px-xl-18 {
    padding-left: 4.5rem !important;
  }
  .p-xl-19 {
    padding: 4.75rem !important;
  }
  .pt-xl-19, .py-xl-19 {
    padding-top: 4.75rem !important;
  }
  .pr-xl-19, .px-xl-19 {
    padding-right: 4.75rem !important;
  }
  .pb-xl-19, .py-xl-19 {
    padding-bottom: 4.75rem !important;
  }
  .pl-xl-19, .px-xl-19 {
    padding-left: 4.75rem !important;
  }
  .p-xl-20 {
    padding: 5rem !important;
  }
  .pt-xl-20, .py-xl-20 {
    padding-top: 5rem !important;
  }
  .pr-xl-20, .px-xl-20 {
    padding-right: 5rem !important;
  }
  .pb-xl-20, .py-xl-20 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-20, .px-xl-20 {
    padding-left: 5rem !important;
  }
  .p-xl-21 {
    padding: 5.25rem !important;
  }
  .pt-xl-21, .py-xl-21 {
    padding-top: 5.25rem !important;
  }
  .pr-xl-21, .px-xl-21 {
    padding-right: 5.25rem !important;
  }
  .pb-xl-21, .py-xl-21 {
    padding-bottom: 5.25rem !important;
  }
  .pl-xl-21, .px-xl-21 {
    padding-left: 5.25rem !important;
  }
  .p-xl-22 {
    padding: 5.5rem !important;
  }
  .pt-xl-22, .py-xl-22 {
    padding-top: 5.5rem !important;
  }
  .pr-xl-22, .px-xl-22 {
    padding-right: 5.5rem !important;
  }
  .pb-xl-22, .py-xl-22 {
    padding-bottom: 5.5rem !important;
  }
  .pl-xl-22, .px-xl-22 {
    padding-left: 5.5rem !important;
  }
  .p-xl-23 {
    padding: 5.75rem !important;
  }
  .pt-xl-23, .py-xl-23 {
    padding-top: 5.75rem !important;
  }
  .pr-xl-23, .px-xl-23 {
    padding-right: 5.75rem !important;
  }
  .pb-xl-23, .py-xl-23 {
    padding-bottom: 5.75rem !important;
  }
  .pl-xl-23, .px-xl-23 {
    padding-left: 5.75rem !important;
  }
  .p-xl-24 {
    padding: 6rem !important;
  }
  .pt-xl-24, .py-xl-24 {
    padding-top: 6rem !important;
  }
  .pr-xl-24, .px-xl-24 {
    padding-right: 6rem !important;
  }
  .pb-xl-24, .py-xl-24 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-24, .px-xl-24 {
    padding-left: 6rem !important;
  }
  .p-xl-25 {
    padding: 6.25rem !important;
  }
  .pt-xl-25, .py-xl-25 {
    padding-top: 6.25rem !important;
  }
  .pr-xl-25, .px-xl-25 {
    padding-right: 6.25rem !important;
  }
  .pb-xl-25, .py-xl-25 {
    padding-bottom: 6.25rem !important;
  }
  .pl-xl-25, .px-xl-25 {
    padding-left: 6.25rem !important;
  }
  .p-xl-26 {
    padding: 6.5rem !important;
  }
  .pt-xl-26, .py-xl-26 {
    padding-top: 6.5rem !important;
  }
  .pr-xl-26, .px-xl-26 {
    padding-right: 6.5rem !important;
  }
  .pb-xl-26, .py-xl-26 {
    padding-bottom: 6.5rem !important;
  }
  .pl-xl-26, .px-xl-26 {
    padding-left: 6.5rem !important;
  }
  .p-xl-27 {
    padding: 6.75rem !important;
  }
  .pt-xl-27, .py-xl-27 {
    padding-top: 6.75rem !important;
  }
  .pr-xl-27, .px-xl-27 {
    padding-right: 6.75rem !important;
  }
  .pb-xl-27, .py-xl-27 {
    padding-bottom: 6.75rem !important;
  }
  .pl-xl-27, .px-xl-27 {
    padding-left: 6.75rem !important;
  }
  .p-xl-28 {
    padding: 7rem !important;
  }
  .pt-xl-28, .py-xl-28 {
    padding-top: 7rem !important;
  }
  .pr-xl-28, .px-xl-28 {
    padding-right: 7rem !important;
  }
  .pb-xl-28, .py-xl-28 {
    padding-bottom: 7rem !important;
  }
  .pl-xl-28, .px-xl-28 {
    padding-left: 7rem !important;
  }
  .p-xl-29 {
    padding: 7.25rem !important;
  }
  .pt-xl-29, .py-xl-29 {
    padding-top: 7.25rem !important;
  }
  .pr-xl-29, .px-xl-29 {
    padding-right: 7.25rem !important;
  }
  .pb-xl-29, .py-xl-29 {
    padding-bottom: 7.25rem !important;
  }
  .pl-xl-29, .px-xl-29 {
    padding-left: 7.25rem !important;
  }
  .p-xl-30 {
    padding: 7.5rem !important;
  }
  .pt-xl-30, .py-xl-30 {
    padding-top: 7.5rem !important;
  }
  .pr-xl-30, .px-xl-30 {
    padding-right: 7.5rem !important;
  }
  .pb-xl-30, .py-xl-30 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xl-30, .px-xl-30 {
    padding-left: 7.5rem !important;
  }
  .p-xl-31 {
    padding: 7.75rem !important;
  }
  .pt-xl-31, .py-xl-31 {
    padding-top: 7.75rem !important;
  }
  .pr-xl-31, .px-xl-31 {
    padding-right: 7.75rem !important;
  }
  .pb-xl-31, .py-xl-31 {
    padding-bottom: 7.75rem !important;
  }
  .pl-xl-31, .px-xl-31 {
    padding-left: 7.75rem !important;
  }
  .p-xl-32 {
    padding: 8rem !important;
  }
  .pt-xl-32, .py-xl-32 {
    padding-top: 8rem !important;
  }
  .pr-xl-32, .px-xl-32 {
    padding-right: 8rem !important;
  }
  .pb-xl-32, .py-xl-32 {
    padding-bottom: 8rem !important;
  }
  .pl-xl-32, .px-xl-32 {
    padding-left: 8rem !important;
  }
  .p-xl-33 {
    padding: 8.25rem !important;
  }
  .pt-xl-33, .py-xl-33 {
    padding-top: 8.25rem !important;
  }
  .pr-xl-33, .px-xl-33 {
    padding-right: 8.25rem !important;
  }
  .pb-xl-33, .py-xl-33 {
    padding-bottom: 8.25rem !important;
  }
  .pl-xl-33, .px-xl-33 {
    padding-left: 8.25rem !important;
  }
  .p-xl-34 {
    padding: 8.5rem !important;
  }
  .pt-xl-34, .py-xl-34 {
    padding-top: 8.5rem !important;
  }
  .pr-xl-34, .px-xl-34 {
    padding-right: 8.5rem !important;
  }
  .pb-xl-34, .py-xl-34 {
    padding-bottom: 8.5rem !important;
  }
  .pl-xl-34, .px-xl-34 {
    padding-left: 8.5rem !important;
  }
  .p-xl-35 {
    padding: 8.75rem !important;
  }
  .pt-xl-35, .py-xl-35 {
    padding-top: 8.75rem !important;
  }
  .pr-xl-35, .px-xl-35 {
    padding-right: 8.75rem !important;
  }
  .pb-xl-35, .py-xl-35 {
    padding-bottom: 8.75rem !important;
  }
  .pl-xl-35, .px-xl-35 {
    padding-left: 8.75rem !important;
  }
  .p-xl-36 {
    padding: 9rem !important;
  }
  .pt-xl-36, .py-xl-36 {
    padding-top: 9rem !important;
  }
  .pr-xl-36, .px-xl-36 {
    padding-right: 9rem !important;
  }
  .pb-xl-36, .py-xl-36 {
    padding-bottom: 9rem !important;
  }
  .pl-xl-36, .px-xl-36 {
    padding-left: 9rem !important;
  }
  .p-xl-37 {
    padding: 9.25rem !important;
  }
  .pt-xl-37, .py-xl-37 {
    padding-top: 9.25rem !important;
  }
  .pr-xl-37, .px-xl-37 {
    padding-right: 9.25rem !important;
  }
  .pb-xl-37, .py-xl-37 {
    padding-bottom: 9.25rem !important;
  }
  .pl-xl-37, .px-xl-37 {
    padding-left: 9.25rem !important;
  }
  .p-xl-48 {
    padding: 9.5rem !important;
  }
  .pt-xl-48, .py-xl-48 {
    padding-top: 9.5rem !important;
  }
  .pr-xl-48, .px-xl-48 {
    padding-right: 9.5rem !important;
  }
  .pb-xl-48, .py-xl-48 {
    padding-bottom: 9.5rem !important;
  }
  .pl-xl-48, .px-xl-48 {
    padding-left: 9.5rem !important;
  }
  .p-xl-39 {
    padding: 9.75rem !important;
  }
  .pt-xl-39, .py-xl-39 {
    padding-top: 9.75rem !important;
  }
  .pr-xl-39, .px-xl-39 {
    padding-right: 9.75rem !important;
  }
  .pb-xl-39, .py-xl-39 {
    padding-bottom: 9.75rem !important;
  }
  .pl-xl-39, .px-xl-39 {
    padding-left: 9.75rem !important;
  }
  .p-xl-40 {
    padding: 10rem !important;
  }
  .pt-xl-40, .py-xl-40 {
    padding-top: 10rem !important;
  }
  .pr-xl-40, .px-xl-40 {
    padding-right: 10rem !important;
  }
  .pb-xl-40, .py-xl-40 {
    padding-bottom: 10rem !important;
  }
  .pl-xl-40, .px-xl-40 {
    padding-left: 10rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1, .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2, .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -0.75rem !important;
  }
  .mt-xl-n3, .my-xl-n3 {
    margin-top: -0.75rem !important;
  }
  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -0.75rem !important;
  }
  .m-xl-n4 {
    margin: -1rem !important;
  }
  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1rem !important;
  }
  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -1rem !important;
  }
  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -1rem !important;
  }
  .m-xl-n5 {
    margin: -1.25rem !important;
  }
  .mt-xl-n5, .my-xl-n5 {
    margin-top: -1.25rem !important;
  }
  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -1.25rem !important;
  }
  .m-xl-n6 {
    margin: -1.5rem !important;
  }
  .mt-xl-n6, .my-xl-n6 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n6, .mx-xl-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n6, .my-xl-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n6, .mx-xl-n6 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n7 {
    margin: -1.75rem !important;
  }
  .mt-xl-n7, .my-xl-n7 {
    margin-top: -1.75rem !important;
  }
  .mr-xl-n7, .mx-xl-n7 {
    margin-right: -1.75rem !important;
  }
  .mb-xl-n7, .my-xl-n7 {
    margin-bottom: -1.75rem !important;
  }
  .ml-xl-n7, .mx-xl-n7 {
    margin-left: -1.75rem !important;
  }
  .m-xl-n8 {
    margin: -2rem !important;
  }
  .mt-xl-n8, .my-xl-n8 {
    margin-top: -2rem !important;
  }
  .mr-xl-n8, .mx-xl-n8 {
    margin-right: -2rem !important;
  }
  .mb-xl-n8, .my-xl-n8 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n8, .mx-xl-n8 {
    margin-left: -2rem !important;
  }
  .m-xl-n9 {
    margin: -2.25rem !important;
  }
  .mt-xl-n9, .my-xl-n9 {
    margin-top: -2.25rem !important;
  }
  .mr-xl-n9, .mx-xl-n9 {
    margin-right: -2.25rem !important;
  }
  .mb-xl-n9, .my-xl-n9 {
    margin-bottom: -2.25rem !important;
  }
  .ml-xl-n9, .mx-xl-n9 {
    margin-left: -2.25rem !important;
  }
  .m-xl-n10 {
    margin: -2.5rem !important;
  }
  .mt-xl-n10, .my-xl-n10 {
    margin-top: -2.5rem !important;
  }
  .mr-xl-n10, .mx-xl-n10 {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n10, .my-xl-n10 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xl-n10, .mx-xl-n10 {
    margin-left: -2.5rem !important;
  }
  .m-xl-n11 {
    margin: -2.75rem !important;
  }
  .mt-xl-n11, .my-xl-n11 {
    margin-top: -2.75rem !important;
  }
  .mr-xl-n11, .mx-xl-n11 {
    margin-right: -2.75rem !important;
  }
  .mb-xl-n11, .my-xl-n11 {
    margin-bottom: -2.75rem !important;
  }
  .ml-xl-n11, .mx-xl-n11 {
    margin-left: -2.75rem !important;
  }
  .m-xl-n12 {
    margin: -3rem !important;
  }
  .mt-xl-n12, .my-xl-n12 {
    margin-top: -3rem !important;
  }
  .mr-xl-n12, .mx-xl-n12 {
    margin-right: -3rem !important;
  }
  .mb-xl-n12, .my-xl-n12 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n12, .mx-xl-n12 {
    margin-left: -3rem !important;
  }
  .m-xl-n13 {
    margin: -3.25rem !important;
  }
  .mt-xl-n13, .my-xl-n13 {
    margin-top: -3.25rem !important;
  }
  .mr-xl-n13, .mx-xl-n13 {
    margin-right: -3.25rem !important;
  }
  .mb-xl-n13, .my-xl-n13 {
    margin-bottom: -3.25rem !important;
  }
  .ml-xl-n13, .mx-xl-n13 {
    margin-left: -3.25rem !important;
  }
  .m-xl-n14 {
    margin: -3.5rem !important;
  }
  .mt-xl-n14, .my-xl-n14 {
    margin-top: -3.5rem !important;
  }
  .mr-xl-n14, .mx-xl-n14 {
    margin-right: -3.5rem !important;
  }
  .mb-xl-n14, .my-xl-n14 {
    margin-bottom: -3.5rem !important;
  }
  .ml-xl-n14, .mx-xl-n14 {
    margin-left: -3.5rem !important;
  }
  .m-xl-n15 {
    margin: -3.75rem !important;
  }
  .mt-xl-n15, .my-xl-n15 {
    margin-top: -3.75rem !important;
  }
  .mr-xl-n15, .mx-xl-n15 {
    margin-right: -3.75rem !important;
  }
  .mb-xl-n15, .my-xl-n15 {
    margin-bottom: -3.75rem !important;
  }
  .ml-xl-n15, .mx-xl-n15 {
    margin-left: -3.75rem !important;
  }
  .m-xl-n16 {
    margin: -4rem !important;
  }
  .mt-xl-n16, .my-xl-n16 {
    margin-top: -4rem !important;
  }
  .mr-xl-n16, .mx-xl-n16 {
    margin-right: -4rem !important;
  }
  .mb-xl-n16, .my-xl-n16 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n16, .mx-xl-n16 {
    margin-left: -4rem !important;
  }
  .m-xl-n17 {
    margin: -4.25rem !important;
  }
  .mt-xl-n17, .my-xl-n17 {
    margin-top: -4.25rem !important;
  }
  .mr-xl-n17, .mx-xl-n17 {
    margin-right: -4.25rem !important;
  }
  .mb-xl-n17, .my-xl-n17 {
    margin-bottom: -4.25rem !important;
  }
  .ml-xl-n17, .mx-xl-n17 {
    margin-left: -4.25rem !important;
  }
  .m-xl-n18 {
    margin: -4.5rem !important;
  }
  .mt-xl-n18, .my-xl-n18 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n18, .mx-xl-n18 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n18, .my-xl-n18 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n18, .mx-xl-n18 {
    margin-left: -4.5rem !important;
  }
  .m-xl-n19 {
    margin: -4.75rem !important;
  }
  .mt-xl-n19, .my-xl-n19 {
    margin-top: -4.75rem !important;
  }
  .mr-xl-n19, .mx-xl-n19 {
    margin-right: -4.75rem !important;
  }
  .mb-xl-n19, .my-xl-n19 {
    margin-bottom: -4.75rem !important;
  }
  .ml-xl-n19, .mx-xl-n19 {
    margin-left: -4.75rem !important;
  }
  .m-xl-n20 {
    margin: -5rem !important;
  }
  .mt-xl-n20, .my-xl-n20 {
    margin-top: -5rem !important;
  }
  .mr-xl-n20, .mx-xl-n20 {
    margin-right: -5rem !important;
  }
  .mb-xl-n20, .my-xl-n20 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n20, .mx-xl-n20 {
    margin-left: -5rem !important;
  }
  .m-xl-n21 {
    margin: -5.25rem !important;
  }
  .mt-xl-n21, .my-xl-n21 {
    margin-top: -5.25rem !important;
  }
  .mr-xl-n21, .mx-xl-n21 {
    margin-right: -5.25rem !important;
  }
  .mb-xl-n21, .my-xl-n21 {
    margin-bottom: -5.25rem !important;
  }
  .ml-xl-n21, .mx-xl-n21 {
    margin-left: -5.25rem !important;
  }
  .m-xl-n22 {
    margin: -5.5rem !important;
  }
  .mt-xl-n22, .my-xl-n22 {
    margin-top: -5.5rem !important;
  }
  .mr-xl-n22, .mx-xl-n22 {
    margin-right: -5.5rem !important;
  }
  .mb-xl-n22, .my-xl-n22 {
    margin-bottom: -5.5rem !important;
  }
  .ml-xl-n22, .mx-xl-n22 {
    margin-left: -5.5rem !important;
  }
  .m-xl-n23 {
    margin: -5.75rem !important;
  }
  .mt-xl-n23, .my-xl-n23 {
    margin-top: -5.75rem !important;
  }
  .mr-xl-n23, .mx-xl-n23 {
    margin-right: -5.75rem !important;
  }
  .mb-xl-n23, .my-xl-n23 {
    margin-bottom: -5.75rem !important;
  }
  .ml-xl-n23, .mx-xl-n23 {
    margin-left: -5.75rem !important;
  }
  .m-xl-n24 {
    margin: -6rem !important;
  }
  .mt-xl-n24, .my-xl-n24 {
    margin-top: -6rem !important;
  }
  .mr-xl-n24, .mx-xl-n24 {
    margin-right: -6rem !important;
  }
  .mb-xl-n24, .my-xl-n24 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n24, .mx-xl-n24 {
    margin-left: -6rem !important;
  }
  .m-xl-n25 {
    margin: -6.25rem !important;
  }
  .mt-xl-n25, .my-xl-n25 {
    margin-top: -6.25rem !important;
  }
  .mr-xl-n25, .mx-xl-n25 {
    margin-right: -6.25rem !important;
  }
  .mb-xl-n25, .my-xl-n25 {
    margin-bottom: -6.25rem !important;
  }
  .ml-xl-n25, .mx-xl-n25 {
    margin-left: -6.25rem !important;
  }
  .m-xl-n26 {
    margin: -6.5rem !important;
  }
  .mt-xl-n26, .my-xl-n26 {
    margin-top: -6.5rem !important;
  }
  .mr-xl-n26, .mx-xl-n26 {
    margin-right: -6.5rem !important;
  }
  .mb-xl-n26, .my-xl-n26 {
    margin-bottom: -6.5rem !important;
  }
  .ml-xl-n26, .mx-xl-n26 {
    margin-left: -6.5rem !important;
  }
  .m-xl-n27 {
    margin: -6.75rem !important;
  }
  .mt-xl-n27, .my-xl-n27 {
    margin-top: -6.75rem !important;
  }
  .mr-xl-n27, .mx-xl-n27 {
    margin-right: -6.75rem !important;
  }
  .mb-xl-n27, .my-xl-n27 {
    margin-bottom: -6.75rem !important;
  }
  .ml-xl-n27, .mx-xl-n27 {
    margin-left: -6.75rem !important;
  }
  .m-xl-n28 {
    margin: -7rem !important;
  }
  .mt-xl-n28, .my-xl-n28 {
    margin-top: -7rem !important;
  }
  .mr-xl-n28, .mx-xl-n28 {
    margin-right: -7rem !important;
  }
  .mb-xl-n28, .my-xl-n28 {
    margin-bottom: -7rem !important;
  }
  .ml-xl-n28, .mx-xl-n28 {
    margin-left: -7rem !important;
  }
  .m-xl-n29 {
    margin: -7.25rem !important;
  }
  .mt-xl-n29, .my-xl-n29 {
    margin-top: -7.25rem !important;
  }
  .mr-xl-n29, .mx-xl-n29 {
    margin-right: -7.25rem !important;
  }
  .mb-xl-n29, .my-xl-n29 {
    margin-bottom: -7.25rem !important;
  }
  .ml-xl-n29, .mx-xl-n29 {
    margin-left: -7.25rem !important;
  }
  .m-xl-n30 {
    margin: -7.5rem !important;
  }
  .mt-xl-n30, .my-xl-n30 {
    margin-top: -7.5rem !important;
  }
  .mr-xl-n30, .mx-xl-n30 {
    margin-right: -7.5rem !important;
  }
  .mb-xl-n30, .my-xl-n30 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xl-n30, .mx-xl-n30 {
    margin-left: -7.5rem !important;
  }
  .m-xl-n31 {
    margin: -7.75rem !important;
  }
  .mt-xl-n31, .my-xl-n31 {
    margin-top: -7.75rem !important;
  }
  .mr-xl-n31, .mx-xl-n31 {
    margin-right: -7.75rem !important;
  }
  .mb-xl-n31, .my-xl-n31 {
    margin-bottom: -7.75rem !important;
  }
  .ml-xl-n31, .mx-xl-n31 {
    margin-left: -7.75rem !important;
  }
  .m-xl-n32 {
    margin: -8rem !important;
  }
  .mt-xl-n32, .my-xl-n32 {
    margin-top: -8rem !important;
  }
  .mr-xl-n32, .mx-xl-n32 {
    margin-right: -8rem !important;
  }
  .mb-xl-n32, .my-xl-n32 {
    margin-bottom: -8rem !important;
  }
  .ml-xl-n32, .mx-xl-n32 {
    margin-left: -8rem !important;
  }
  .m-xl-n33 {
    margin: -8.25rem !important;
  }
  .mt-xl-n33, .my-xl-n33 {
    margin-top: -8.25rem !important;
  }
  .mr-xl-n33, .mx-xl-n33 {
    margin-right: -8.25rem !important;
  }
  .mb-xl-n33, .my-xl-n33 {
    margin-bottom: -8.25rem !important;
  }
  .ml-xl-n33, .mx-xl-n33 {
    margin-left: -8.25rem !important;
  }
  .m-xl-n34 {
    margin: -8.5rem !important;
  }
  .mt-xl-n34, .my-xl-n34 {
    margin-top: -8.5rem !important;
  }
  .mr-xl-n34, .mx-xl-n34 {
    margin-right: -8.5rem !important;
  }
  .mb-xl-n34, .my-xl-n34 {
    margin-bottom: -8.5rem !important;
  }
  .ml-xl-n34, .mx-xl-n34 {
    margin-left: -8.5rem !important;
  }
  .m-xl-n35 {
    margin: -8.75rem !important;
  }
  .mt-xl-n35, .my-xl-n35 {
    margin-top: -8.75rem !important;
  }
  .mr-xl-n35, .mx-xl-n35 {
    margin-right: -8.75rem !important;
  }
  .mb-xl-n35, .my-xl-n35 {
    margin-bottom: -8.75rem !important;
  }
  .ml-xl-n35, .mx-xl-n35 {
    margin-left: -8.75rem !important;
  }
  .m-xl-n36 {
    margin: -9rem !important;
  }
  .mt-xl-n36, .my-xl-n36 {
    margin-top: -9rem !important;
  }
  .mr-xl-n36, .mx-xl-n36 {
    margin-right: -9rem !important;
  }
  .mb-xl-n36, .my-xl-n36 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-n36, .mx-xl-n36 {
    margin-left: -9rem !important;
  }
  .m-xl-n37 {
    margin: -9.25rem !important;
  }
  .mt-xl-n37, .my-xl-n37 {
    margin-top: -9.25rem !important;
  }
  .mr-xl-n37, .mx-xl-n37 {
    margin-right: -9.25rem !important;
  }
  .mb-xl-n37, .my-xl-n37 {
    margin-bottom: -9.25rem !important;
  }
  .ml-xl-n37, .mx-xl-n37 {
    margin-left: -9.25rem !important;
  }
  .m-xl-n48 {
    margin: -9.5rem !important;
  }
  .mt-xl-n48, .my-xl-n48 {
    margin-top: -9.5rem !important;
  }
  .mr-xl-n48, .mx-xl-n48 {
    margin-right: -9.5rem !important;
  }
  .mb-xl-n48, .my-xl-n48 {
    margin-bottom: -9.5rem !important;
  }
  .ml-xl-n48, .mx-xl-n48 {
    margin-left: -9.5rem !important;
  }
  .m-xl-n39 {
    margin: -9.75rem !important;
  }
  .mt-xl-n39, .my-xl-n39 {
    margin-top: -9.75rem !important;
  }
  .mr-xl-n39, .mx-xl-n39 {
    margin-right: -9.75rem !important;
  }
  .mb-xl-n39, .my-xl-n39 {
    margin-bottom: -9.75rem !important;
  }
  .ml-xl-n39, .mx-xl-n39 {
    margin-left: -9.75rem !important;
  }
  .m-xl-n40 {
    margin: -10rem !important;
  }
  .mt-xl-n40, .my-xl-n40 {
    margin-top: -10rem !important;
  }
  .mr-xl-n40, .mx-xl-n40 {
    margin-right: -10rem !important;
  }
  .mb-xl-n40, .my-xl-n40 {
    margin-bottom: -10rem !important;
  }
  .ml-xl-n40, .mx-xl-n40 {
    margin-left: -10rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0, .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0, .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0, .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0, .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1, .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1, .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1, .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1, .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2, .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2, .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2, .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2, .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 0.75rem !important;
  }
  .mt-xxl-3, .my-xxl-3 {
    margin-top: 0.75rem !important;
  }
  .mr-xxl-3, .mx-xxl-3 {
    margin-right: 0.75rem !important;
  }
  .mb-xxl-3, .my-xxl-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xxl-3, .mx-xxl-3 {
    margin-left: 0.75rem !important;
  }
  .m-xxl-4 {
    margin: 1rem !important;
  }
  .mt-xxl-4, .my-xxl-4 {
    margin-top: 1rem !important;
  }
  .mr-xxl-4, .mx-xxl-4 {
    margin-right: 1rem !important;
  }
  .mb-xxl-4, .my-xxl-4 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-4, .mx-xxl-4 {
    margin-left: 1rem !important;
  }
  .m-xxl-5 {
    margin: 1.25rem !important;
  }
  .mt-xxl-5, .my-xxl-5 {
    margin-top: 1.25rem !important;
  }
  .mr-xxl-5, .mx-xxl-5 {
    margin-right: 1.25rem !important;
  }
  .mb-xxl-5, .my-xxl-5 {
    margin-bottom: 1.25rem !important;
  }
  .ml-xxl-5, .mx-xxl-5 {
    margin-left: 1.25rem !important;
  }
  .m-xxl-6 {
    margin: 1.5rem !important;
  }
  .mt-xxl-6, .my-xxl-6 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-6, .mx-xxl-6 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-6, .my-xxl-6 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-6, .mx-xxl-6 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-7 {
    margin: 1.75rem !important;
  }
  .mt-xxl-7, .my-xxl-7 {
    margin-top: 1.75rem !important;
  }
  .mr-xxl-7, .mx-xxl-7 {
    margin-right: 1.75rem !important;
  }
  .mb-xxl-7, .my-xxl-7 {
    margin-bottom: 1.75rem !important;
  }
  .ml-xxl-7, .mx-xxl-7 {
    margin-left: 1.75rem !important;
  }
  .m-xxl-8 {
    margin: 2rem !important;
  }
  .mt-xxl-8, .my-xxl-8 {
    margin-top: 2rem !important;
  }
  .mr-xxl-8, .mx-xxl-8 {
    margin-right: 2rem !important;
  }
  .mb-xxl-8, .my-xxl-8 {
    margin-bottom: 2rem !important;
  }
  .ml-xxl-8, .mx-xxl-8 {
    margin-left: 2rem !important;
  }
  .m-xxl-9 {
    margin: 2.25rem !important;
  }
  .mt-xxl-9, .my-xxl-9 {
    margin-top: 2.25rem !important;
  }
  .mr-xxl-9, .mx-xxl-9 {
    margin-right: 2.25rem !important;
  }
  .mb-xxl-9, .my-xxl-9 {
    margin-bottom: 2.25rem !important;
  }
  .ml-xxl-9, .mx-xxl-9 {
    margin-left: 2.25rem !important;
  }
  .m-xxl-10 {
    margin: 2.5rem !important;
  }
  .mt-xxl-10, .my-xxl-10 {
    margin-top: 2.5rem !important;
  }
  .mr-xxl-10, .mx-xxl-10 {
    margin-right: 2.5rem !important;
  }
  .mb-xxl-10, .my-xxl-10 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xxl-10, .mx-xxl-10 {
    margin-left: 2.5rem !important;
  }
  .m-xxl-11 {
    margin: 2.75rem !important;
  }
  .mt-xxl-11, .my-xxl-11 {
    margin-top: 2.75rem !important;
  }
  .mr-xxl-11, .mx-xxl-11 {
    margin-right: 2.75rem !important;
  }
  .mb-xxl-11, .my-xxl-11 {
    margin-bottom: 2.75rem !important;
  }
  .ml-xxl-11, .mx-xxl-11 {
    margin-left: 2.75rem !important;
  }
  .m-xxl-12 {
    margin: 3rem !important;
  }
  .mt-xxl-12, .my-xxl-12 {
    margin-top: 3rem !important;
  }
  .mr-xxl-12, .mx-xxl-12 {
    margin-right: 3rem !important;
  }
  .mb-xxl-12, .my-xxl-12 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-12, .mx-xxl-12 {
    margin-left: 3rem !important;
  }
  .m-xxl-13 {
    margin: 3.25rem !important;
  }
  .mt-xxl-13, .my-xxl-13 {
    margin-top: 3.25rem !important;
  }
  .mr-xxl-13, .mx-xxl-13 {
    margin-right: 3.25rem !important;
  }
  .mb-xxl-13, .my-xxl-13 {
    margin-bottom: 3.25rem !important;
  }
  .ml-xxl-13, .mx-xxl-13 {
    margin-left: 3.25rem !important;
  }
  .m-xxl-14 {
    margin: 3.5rem !important;
  }
  .mt-xxl-14, .my-xxl-14 {
    margin-top: 3.5rem !important;
  }
  .mr-xxl-14, .mx-xxl-14 {
    margin-right: 3.5rem !important;
  }
  .mb-xxl-14, .my-xxl-14 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xxl-14, .mx-xxl-14 {
    margin-left: 3.5rem !important;
  }
  .m-xxl-15 {
    margin: 3.75rem !important;
  }
  .mt-xxl-15, .my-xxl-15 {
    margin-top: 3.75rem !important;
  }
  .mr-xxl-15, .mx-xxl-15 {
    margin-right: 3.75rem !important;
  }
  .mb-xxl-15, .my-xxl-15 {
    margin-bottom: 3.75rem !important;
  }
  .ml-xxl-15, .mx-xxl-15 {
    margin-left: 3.75rem !important;
  }
  .m-xxl-16 {
    margin: 4rem !important;
  }
  .mt-xxl-16, .my-xxl-16 {
    margin-top: 4rem !important;
  }
  .mr-xxl-16, .mx-xxl-16 {
    margin-right: 4rem !important;
  }
  .mb-xxl-16, .my-xxl-16 {
    margin-bottom: 4rem !important;
  }
  .ml-xxl-16, .mx-xxl-16 {
    margin-left: 4rem !important;
  }
  .m-xxl-17 {
    margin: 4.25rem !important;
  }
  .mt-xxl-17, .my-xxl-17 {
    margin-top: 4.25rem !important;
  }
  .mr-xxl-17, .mx-xxl-17 {
    margin-right: 4.25rem !important;
  }
  .mb-xxl-17, .my-xxl-17 {
    margin-bottom: 4.25rem !important;
  }
  .ml-xxl-17, .mx-xxl-17 {
    margin-left: 4.25rem !important;
  }
  .m-xxl-18 {
    margin: 4.5rem !important;
  }
  .mt-xxl-18, .my-xxl-18 {
    margin-top: 4.5rem !important;
  }
  .mr-xxl-18, .mx-xxl-18 {
    margin-right: 4.5rem !important;
  }
  .mb-xxl-18, .my-xxl-18 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xxl-18, .mx-xxl-18 {
    margin-left: 4.5rem !important;
  }
  .m-xxl-19 {
    margin: 4.75rem !important;
  }
  .mt-xxl-19, .my-xxl-19 {
    margin-top: 4.75rem !important;
  }
  .mr-xxl-19, .mx-xxl-19 {
    margin-right: 4.75rem !important;
  }
  .mb-xxl-19, .my-xxl-19 {
    margin-bottom: 4.75rem !important;
  }
  .ml-xxl-19, .mx-xxl-19 {
    margin-left: 4.75rem !important;
  }
  .m-xxl-20 {
    margin: 5rem !important;
  }
  .mt-xxl-20, .my-xxl-20 {
    margin-top: 5rem !important;
  }
  .mr-xxl-20, .mx-xxl-20 {
    margin-right: 5rem !important;
  }
  .mb-xxl-20, .my-xxl-20 {
    margin-bottom: 5rem !important;
  }
  .ml-xxl-20, .mx-xxl-20 {
    margin-left: 5rem !important;
  }
  .m-xxl-21 {
    margin: 5.25rem !important;
  }
  .mt-xxl-21, .my-xxl-21 {
    margin-top: 5.25rem !important;
  }
  .mr-xxl-21, .mx-xxl-21 {
    margin-right: 5.25rem !important;
  }
  .mb-xxl-21, .my-xxl-21 {
    margin-bottom: 5.25rem !important;
  }
  .ml-xxl-21, .mx-xxl-21 {
    margin-left: 5.25rem !important;
  }
  .m-xxl-22 {
    margin: 5.5rem !important;
  }
  .mt-xxl-22, .my-xxl-22 {
    margin-top: 5.5rem !important;
  }
  .mr-xxl-22, .mx-xxl-22 {
    margin-right: 5.5rem !important;
  }
  .mb-xxl-22, .my-xxl-22 {
    margin-bottom: 5.5rem !important;
  }
  .ml-xxl-22, .mx-xxl-22 {
    margin-left: 5.5rem !important;
  }
  .m-xxl-23 {
    margin: 5.75rem !important;
  }
  .mt-xxl-23, .my-xxl-23 {
    margin-top: 5.75rem !important;
  }
  .mr-xxl-23, .mx-xxl-23 {
    margin-right: 5.75rem !important;
  }
  .mb-xxl-23, .my-xxl-23 {
    margin-bottom: 5.75rem !important;
  }
  .ml-xxl-23, .mx-xxl-23 {
    margin-left: 5.75rem !important;
  }
  .m-xxl-24 {
    margin: 6rem !important;
  }
  .mt-xxl-24, .my-xxl-24 {
    margin-top: 6rem !important;
  }
  .mr-xxl-24, .mx-xxl-24 {
    margin-right: 6rem !important;
  }
  .mb-xxl-24, .my-xxl-24 {
    margin-bottom: 6rem !important;
  }
  .ml-xxl-24, .mx-xxl-24 {
    margin-left: 6rem !important;
  }
  .m-xxl-25 {
    margin: 6.25rem !important;
  }
  .mt-xxl-25, .my-xxl-25 {
    margin-top: 6.25rem !important;
  }
  .mr-xxl-25, .mx-xxl-25 {
    margin-right: 6.25rem !important;
  }
  .mb-xxl-25, .my-xxl-25 {
    margin-bottom: 6.25rem !important;
  }
  .ml-xxl-25, .mx-xxl-25 {
    margin-left: 6.25rem !important;
  }
  .m-xxl-26 {
    margin: 6.5rem !important;
  }
  .mt-xxl-26, .my-xxl-26 {
    margin-top: 6.5rem !important;
  }
  .mr-xxl-26, .mx-xxl-26 {
    margin-right: 6.5rem !important;
  }
  .mb-xxl-26, .my-xxl-26 {
    margin-bottom: 6.5rem !important;
  }
  .ml-xxl-26, .mx-xxl-26 {
    margin-left: 6.5rem !important;
  }
  .m-xxl-27 {
    margin: 6.75rem !important;
  }
  .mt-xxl-27, .my-xxl-27 {
    margin-top: 6.75rem !important;
  }
  .mr-xxl-27, .mx-xxl-27 {
    margin-right: 6.75rem !important;
  }
  .mb-xxl-27, .my-xxl-27 {
    margin-bottom: 6.75rem !important;
  }
  .ml-xxl-27, .mx-xxl-27 {
    margin-left: 6.75rem !important;
  }
  .m-xxl-28 {
    margin: 7rem !important;
  }
  .mt-xxl-28, .my-xxl-28 {
    margin-top: 7rem !important;
  }
  .mr-xxl-28, .mx-xxl-28 {
    margin-right: 7rem !important;
  }
  .mb-xxl-28, .my-xxl-28 {
    margin-bottom: 7rem !important;
  }
  .ml-xxl-28, .mx-xxl-28 {
    margin-left: 7rem !important;
  }
  .m-xxl-29 {
    margin: 7.25rem !important;
  }
  .mt-xxl-29, .my-xxl-29 {
    margin-top: 7.25rem !important;
  }
  .mr-xxl-29, .mx-xxl-29 {
    margin-right: 7.25rem !important;
  }
  .mb-xxl-29, .my-xxl-29 {
    margin-bottom: 7.25rem !important;
  }
  .ml-xxl-29, .mx-xxl-29 {
    margin-left: 7.25rem !important;
  }
  .m-xxl-30 {
    margin: 7.5rem !important;
  }
  .mt-xxl-30, .my-xxl-30 {
    margin-top: 7.5rem !important;
  }
  .mr-xxl-30, .mx-xxl-30 {
    margin-right: 7.5rem !important;
  }
  .mb-xxl-30, .my-xxl-30 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xxl-30, .mx-xxl-30 {
    margin-left: 7.5rem !important;
  }
  .m-xxl-31 {
    margin: 7.75rem !important;
  }
  .mt-xxl-31, .my-xxl-31 {
    margin-top: 7.75rem !important;
  }
  .mr-xxl-31, .mx-xxl-31 {
    margin-right: 7.75rem !important;
  }
  .mb-xxl-31, .my-xxl-31 {
    margin-bottom: 7.75rem !important;
  }
  .ml-xxl-31, .mx-xxl-31 {
    margin-left: 7.75rem !important;
  }
  .m-xxl-32 {
    margin: 8rem !important;
  }
  .mt-xxl-32, .my-xxl-32 {
    margin-top: 8rem !important;
  }
  .mr-xxl-32, .mx-xxl-32 {
    margin-right: 8rem !important;
  }
  .mb-xxl-32, .my-xxl-32 {
    margin-bottom: 8rem !important;
  }
  .ml-xxl-32, .mx-xxl-32 {
    margin-left: 8rem !important;
  }
  .m-xxl-33 {
    margin: 8.25rem !important;
  }
  .mt-xxl-33, .my-xxl-33 {
    margin-top: 8.25rem !important;
  }
  .mr-xxl-33, .mx-xxl-33 {
    margin-right: 8.25rem !important;
  }
  .mb-xxl-33, .my-xxl-33 {
    margin-bottom: 8.25rem !important;
  }
  .ml-xxl-33, .mx-xxl-33 {
    margin-left: 8.25rem !important;
  }
  .m-xxl-34 {
    margin: 8.5rem !important;
  }
  .mt-xxl-34, .my-xxl-34 {
    margin-top: 8.5rem !important;
  }
  .mr-xxl-34, .mx-xxl-34 {
    margin-right: 8.5rem !important;
  }
  .mb-xxl-34, .my-xxl-34 {
    margin-bottom: 8.5rem !important;
  }
  .ml-xxl-34, .mx-xxl-34 {
    margin-left: 8.5rem !important;
  }
  .m-xxl-35 {
    margin: 8.75rem !important;
  }
  .mt-xxl-35, .my-xxl-35 {
    margin-top: 8.75rem !important;
  }
  .mr-xxl-35, .mx-xxl-35 {
    margin-right: 8.75rem !important;
  }
  .mb-xxl-35, .my-xxl-35 {
    margin-bottom: 8.75rem !important;
  }
  .ml-xxl-35, .mx-xxl-35 {
    margin-left: 8.75rem !important;
  }
  .m-xxl-36 {
    margin: 9rem !important;
  }
  .mt-xxl-36, .my-xxl-36 {
    margin-top: 9rem !important;
  }
  .mr-xxl-36, .mx-xxl-36 {
    margin-right: 9rem !important;
  }
  .mb-xxl-36, .my-xxl-36 {
    margin-bottom: 9rem !important;
  }
  .ml-xxl-36, .mx-xxl-36 {
    margin-left: 9rem !important;
  }
  .m-xxl-37 {
    margin: 9.25rem !important;
  }
  .mt-xxl-37, .my-xxl-37 {
    margin-top: 9.25rem !important;
  }
  .mr-xxl-37, .mx-xxl-37 {
    margin-right: 9.25rem !important;
  }
  .mb-xxl-37, .my-xxl-37 {
    margin-bottom: 9.25rem !important;
  }
  .ml-xxl-37, .mx-xxl-37 {
    margin-left: 9.25rem !important;
  }
  .m-xxl-48 {
    margin: 9.5rem !important;
  }
  .mt-xxl-48, .my-xxl-48 {
    margin-top: 9.5rem !important;
  }
  .mr-xxl-48, .mx-xxl-48 {
    margin-right: 9.5rem !important;
  }
  .mb-xxl-48, .my-xxl-48 {
    margin-bottom: 9.5rem !important;
  }
  .ml-xxl-48, .mx-xxl-48 {
    margin-left: 9.5rem !important;
  }
  .m-xxl-39 {
    margin: 9.75rem !important;
  }
  .mt-xxl-39, .my-xxl-39 {
    margin-top: 9.75rem !important;
  }
  .mr-xxl-39, .mx-xxl-39 {
    margin-right: 9.75rem !important;
  }
  .mb-xxl-39, .my-xxl-39 {
    margin-bottom: 9.75rem !important;
  }
  .ml-xxl-39, .mx-xxl-39 {
    margin-left: 9.75rem !important;
  }
  .m-xxl-40 {
    margin: 10rem !important;
  }
  .mt-xxl-40, .my-xxl-40 {
    margin-top: 10rem !important;
  }
  .mr-xxl-40, .mx-xxl-40 {
    margin-right: 10rem !important;
  }
  .mb-xxl-40, .my-xxl-40 {
    margin-bottom: 10rem !important;
  }
  .ml-xxl-40, .mx-xxl-40 {
    margin-left: 10rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0, .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0, .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0, .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0, .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1, .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1, .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1, .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1, .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2, .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2, .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2, .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2, .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 0.75rem !important;
  }
  .pt-xxl-3, .py-xxl-3 {
    padding-top: 0.75rem !important;
  }
  .pr-xxl-3, .px-xxl-3 {
    padding-right: 0.75rem !important;
  }
  .pb-xxl-3, .py-xxl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xxl-3, .px-xxl-3 {
    padding-left: 0.75rem !important;
  }
  .p-xxl-4 {
    padding: 1rem !important;
  }
  .pt-xxl-4, .py-xxl-4 {
    padding-top: 1rem !important;
  }
  .pr-xxl-4, .px-xxl-4 {
    padding-right: 1rem !important;
  }
  .pb-xxl-4, .py-xxl-4 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-4, .px-xxl-4 {
    padding-left: 1rem !important;
  }
  .p-xxl-5 {
    padding: 1.25rem !important;
  }
  .pt-xxl-5, .py-xxl-5 {
    padding-top: 1.25rem !important;
  }
  .pr-xxl-5, .px-xxl-5 {
    padding-right: 1.25rem !important;
  }
  .pb-xxl-5, .py-xxl-5 {
    padding-bottom: 1.25rem !important;
  }
  .pl-xxl-5, .px-xxl-5 {
    padding-left: 1.25rem !important;
  }
  .p-xxl-6 {
    padding: 1.5rem !important;
  }
  .pt-xxl-6, .py-xxl-6 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-6, .px-xxl-6 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-6, .py-xxl-6 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-6, .px-xxl-6 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-7 {
    padding: 1.75rem !important;
  }
  .pt-xxl-7, .py-xxl-7 {
    padding-top: 1.75rem !important;
  }
  .pr-xxl-7, .px-xxl-7 {
    padding-right: 1.75rem !important;
  }
  .pb-xxl-7, .py-xxl-7 {
    padding-bottom: 1.75rem !important;
  }
  .pl-xxl-7, .px-xxl-7 {
    padding-left: 1.75rem !important;
  }
  .p-xxl-8 {
    padding: 2rem !important;
  }
  .pt-xxl-8, .py-xxl-8 {
    padding-top: 2rem !important;
  }
  .pr-xxl-8, .px-xxl-8 {
    padding-right: 2rem !important;
  }
  .pb-xxl-8, .py-xxl-8 {
    padding-bottom: 2rem !important;
  }
  .pl-xxl-8, .px-xxl-8 {
    padding-left: 2rem !important;
  }
  .p-xxl-9 {
    padding: 2.25rem !important;
  }
  .pt-xxl-9, .py-xxl-9 {
    padding-top: 2.25rem !important;
  }
  .pr-xxl-9, .px-xxl-9 {
    padding-right: 2.25rem !important;
  }
  .pb-xxl-9, .py-xxl-9 {
    padding-bottom: 2.25rem !important;
  }
  .pl-xxl-9, .px-xxl-9 {
    padding-left: 2.25rem !important;
  }
  .p-xxl-10 {
    padding: 2.5rem !important;
  }
  .pt-xxl-10, .py-xxl-10 {
    padding-top: 2.5rem !important;
  }
  .pr-xxl-10, .px-xxl-10 {
    padding-right: 2.5rem !important;
  }
  .pb-xxl-10, .py-xxl-10 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xxl-10, .px-xxl-10 {
    padding-left: 2.5rem !important;
  }
  .p-xxl-11 {
    padding: 2.75rem !important;
  }
  .pt-xxl-11, .py-xxl-11 {
    padding-top: 2.75rem !important;
  }
  .pr-xxl-11, .px-xxl-11 {
    padding-right: 2.75rem !important;
  }
  .pb-xxl-11, .py-xxl-11 {
    padding-bottom: 2.75rem !important;
  }
  .pl-xxl-11, .px-xxl-11 {
    padding-left: 2.75rem !important;
  }
  .p-xxl-12 {
    padding: 3rem !important;
  }
  .pt-xxl-12, .py-xxl-12 {
    padding-top: 3rem !important;
  }
  .pr-xxl-12, .px-xxl-12 {
    padding-right: 3rem !important;
  }
  .pb-xxl-12, .py-xxl-12 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-12, .px-xxl-12 {
    padding-left: 3rem !important;
  }
  .p-xxl-13 {
    padding: 3.25rem !important;
  }
  .pt-xxl-13, .py-xxl-13 {
    padding-top: 3.25rem !important;
  }
  .pr-xxl-13, .px-xxl-13 {
    padding-right: 3.25rem !important;
  }
  .pb-xxl-13, .py-xxl-13 {
    padding-bottom: 3.25rem !important;
  }
  .pl-xxl-13, .px-xxl-13 {
    padding-left: 3.25rem !important;
  }
  .p-xxl-14 {
    padding: 3.5rem !important;
  }
  .pt-xxl-14, .py-xxl-14 {
    padding-top: 3.5rem !important;
  }
  .pr-xxl-14, .px-xxl-14 {
    padding-right: 3.5rem !important;
  }
  .pb-xxl-14, .py-xxl-14 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xxl-14, .px-xxl-14 {
    padding-left: 3.5rem !important;
  }
  .p-xxl-15 {
    padding: 3.75rem !important;
  }
  .pt-xxl-15, .py-xxl-15 {
    padding-top: 3.75rem !important;
  }
  .pr-xxl-15, .px-xxl-15 {
    padding-right: 3.75rem !important;
  }
  .pb-xxl-15, .py-xxl-15 {
    padding-bottom: 3.75rem !important;
  }
  .pl-xxl-15, .px-xxl-15 {
    padding-left: 3.75rem !important;
  }
  .p-xxl-16 {
    padding: 4rem !important;
  }
  .pt-xxl-16, .py-xxl-16 {
    padding-top: 4rem !important;
  }
  .pr-xxl-16, .px-xxl-16 {
    padding-right: 4rem !important;
  }
  .pb-xxl-16, .py-xxl-16 {
    padding-bottom: 4rem !important;
  }
  .pl-xxl-16, .px-xxl-16 {
    padding-left: 4rem !important;
  }
  .p-xxl-17 {
    padding: 4.25rem !important;
  }
  .pt-xxl-17, .py-xxl-17 {
    padding-top: 4.25rem !important;
  }
  .pr-xxl-17, .px-xxl-17 {
    padding-right: 4.25rem !important;
  }
  .pb-xxl-17, .py-xxl-17 {
    padding-bottom: 4.25rem !important;
  }
  .pl-xxl-17, .px-xxl-17 {
    padding-left: 4.25rem !important;
  }
  .p-xxl-18 {
    padding: 4.5rem !important;
  }
  .pt-xxl-18, .py-xxl-18 {
    padding-top: 4.5rem !important;
  }
  .pr-xxl-18, .px-xxl-18 {
    padding-right: 4.5rem !important;
  }
  .pb-xxl-18, .py-xxl-18 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xxl-18, .px-xxl-18 {
    padding-left: 4.5rem !important;
  }
  .p-xxl-19 {
    padding: 4.75rem !important;
  }
  .pt-xxl-19, .py-xxl-19 {
    padding-top: 4.75rem !important;
  }
  .pr-xxl-19, .px-xxl-19 {
    padding-right: 4.75rem !important;
  }
  .pb-xxl-19, .py-xxl-19 {
    padding-bottom: 4.75rem !important;
  }
  .pl-xxl-19, .px-xxl-19 {
    padding-left: 4.75rem !important;
  }
  .p-xxl-20 {
    padding: 5rem !important;
  }
  .pt-xxl-20, .py-xxl-20 {
    padding-top: 5rem !important;
  }
  .pr-xxl-20, .px-xxl-20 {
    padding-right: 5rem !important;
  }
  .pb-xxl-20, .py-xxl-20 {
    padding-bottom: 5rem !important;
  }
  .pl-xxl-20, .px-xxl-20 {
    padding-left: 5rem !important;
  }
  .p-xxl-21 {
    padding: 5.25rem !important;
  }
  .pt-xxl-21, .py-xxl-21 {
    padding-top: 5.25rem !important;
  }
  .pr-xxl-21, .px-xxl-21 {
    padding-right: 5.25rem !important;
  }
  .pb-xxl-21, .py-xxl-21 {
    padding-bottom: 5.25rem !important;
  }
  .pl-xxl-21, .px-xxl-21 {
    padding-left: 5.25rem !important;
  }
  .p-xxl-22 {
    padding: 5.5rem !important;
  }
  .pt-xxl-22, .py-xxl-22 {
    padding-top: 5.5rem !important;
  }
  .pr-xxl-22, .px-xxl-22 {
    padding-right: 5.5rem !important;
  }
  .pb-xxl-22, .py-xxl-22 {
    padding-bottom: 5.5rem !important;
  }
  .pl-xxl-22, .px-xxl-22 {
    padding-left: 5.5rem !important;
  }
  .p-xxl-23 {
    padding: 5.75rem !important;
  }
  .pt-xxl-23, .py-xxl-23 {
    padding-top: 5.75rem !important;
  }
  .pr-xxl-23, .px-xxl-23 {
    padding-right: 5.75rem !important;
  }
  .pb-xxl-23, .py-xxl-23 {
    padding-bottom: 5.75rem !important;
  }
  .pl-xxl-23, .px-xxl-23 {
    padding-left: 5.75rem !important;
  }
  .p-xxl-24 {
    padding: 6rem !important;
  }
  .pt-xxl-24, .py-xxl-24 {
    padding-top: 6rem !important;
  }
  .pr-xxl-24, .px-xxl-24 {
    padding-right: 6rem !important;
  }
  .pb-xxl-24, .py-xxl-24 {
    padding-bottom: 6rem !important;
  }
  .pl-xxl-24, .px-xxl-24 {
    padding-left: 6rem !important;
  }
  .p-xxl-25 {
    padding: 6.25rem !important;
  }
  .pt-xxl-25, .py-xxl-25 {
    padding-top: 6.25rem !important;
  }
  .pr-xxl-25, .px-xxl-25 {
    padding-right: 6.25rem !important;
  }
  .pb-xxl-25, .py-xxl-25 {
    padding-bottom: 6.25rem !important;
  }
  .pl-xxl-25, .px-xxl-25 {
    padding-left: 6.25rem !important;
  }
  .p-xxl-26 {
    padding: 6.5rem !important;
  }
  .pt-xxl-26, .py-xxl-26 {
    padding-top: 6.5rem !important;
  }
  .pr-xxl-26, .px-xxl-26 {
    padding-right: 6.5rem !important;
  }
  .pb-xxl-26, .py-xxl-26 {
    padding-bottom: 6.5rem !important;
  }
  .pl-xxl-26, .px-xxl-26 {
    padding-left: 6.5rem !important;
  }
  .p-xxl-27 {
    padding: 6.75rem !important;
  }
  .pt-xxl-27, .py-xxl-27 {
    padding-top: 6.75rem !important;
  }
  .pr-xxl-27, .px-xxl-27 {
    padding-right: 6.75rem !important;
  }
  .pb-xxl-27, .py-xxl-27 {
    padding-bottom: 6.75rem !important;
  }
  .pl-xxl-27, .px-xxl-27 {
    padding-left: 6.75rem !important;
  }
  .p-xxl-28 {
    padding: 7rem !important;
  }
  .pt-xxl-28, .py-xxl-28 {
    padding-top: 7rem !important;
  }
  .pr-xxl-28, .px-xxl-28 {
    padding-right: 7rem !important;
  }
  .pb-xxl-28, .py-xxl-28 {
    padding-bottom: 7rem !important;
  }
  .pl-xxl-28, .px-xxl-28 {
    padding-left: 7rem !important;
  }
  .p-xxl-29 {
    padding: 7.25rem !important;
  }
  .pt-xxl-29, .py-xxl-29 {
    padding-top: 7.25rem !important;
  }
  .pr-xxl-29, .px-xxl-29 {
    padding-right: 7.25rem !important;
  }
  .pb-xxl-29, .py-xxl-29 {
    padding-bottom: 7.25rem !important;
  }
  .pl-xxl-29, .px-xxl-29 {
    padding-left: 7.25rem !important;
  }
  .p-xxl-30 {
    padding: 7.5rem !important;
  }
  .pt-xxl-30, .py-xxl-30 {
    padding-top: 7.5rem !important;
  }
  .pr-xxl-30, .px-xxl-30 {
    padding-right: 7.5rem !important;
  }
  .pb-xxl-30, .py-xxl-30 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xxl-30, .px-xxl-30 {
    padding-left: 7.5rem !important;
  }
  .p-xxl-31 {
    padding: 7.75rem !important;
  }
  .pt-xxl-31, .py-xxl-31 {
    padding-top: 7.75rem !important;
  }
  .pr-xxl-31, .px-xxl-31 {
    padding-right: 7.75rem !important;
  }
  .pb-xxl-31, .py-xxl-31 {
    padding-bottom: 7.75rem !important;
  }
  .pl-xxl-31, .px-xxl-31 {
    padding-left: 7.75rem !important;
  }
  .p-xxl-32 {
    padding: 8rem !important;
  }
  .pt-xxl-32, .py-xxl-32 {
    padding-top: 8rem !important;
  }
  .pr-xxl-32, .px-xxl-32 {
    padding-right: 8rem !important;
  }
  .pb-xxl-32, .py-xxl-32 {
    padding-bottom: 8rem !important;
  }
  .pl-xxl-32, .px-xxl-32 {
    padding-left: 8rem !important;
  }
  .p-xxl-33 {
    padding: 8.25rem !important;
  }
  .pt-xxl-33, .py-xxl-33 {
    padding-top: 8.25rem !important;
  }
  .pr-xxl-33, .px-xxl-33 {
    padding-right: 8.25rem !important;
  }
  .pb-xxl-33, .py-xxl-33 {
    padding-bottom: 8.25rem !important;
  }
  .pl-xxl-33, .px-xxl-33 {
    padding-left: 8.25rem !important;
  }
  .p-xxl-34 {
    padding: 8.5rem !important;
  }
  .pt-xxl-34, .py-xxl-34 {
    padding-top: 8.5rem !important;
  }
  .pr-xxl-34, .px-xxl-34 {
    padding-right: 8.5rem !important;
  }
  .pb-xxl-34, .py-xxl-34 {
    padding-bottom: 8.5rem !important;
  }
  .pl-xxl-34, .px-xxl-34 {
    padding-left: 8.5rem !important;
  }
  .p-xxl-35 {
    padding: 8.75rem !important;
  }
  .pt-xxl-35, .py-xxl-35 {
    padding-top: 8.75rem !important;
  }
  .pr-xxl-35, .px-xxl-35 {
    padding-right: 8.75rem !important;
  }
  .pb-xxl-35, .py-xxl-35 {
    padding-bottom: 8.75rem !important;
  }
  .pl-xxl-35, .px-xxl-35 {
    padding-left: 8.75rem !important;
  }
  .p-xxl-36 {
    padding: 9rem !important;
  }
  .pt-xxl-36, .py-xxl-36 {
    padding-top: 9rem !important;
  }
  .pr-xxl-36, .px-xxl-36 {
    padding-right: 9rem !important;
  }
  .pb-xxl-36, .py-xxl-36 {
    padding-bottom: 9rem !important;
  }
  .pl-xxl-36, .px-xxl-36 {
    padding-left: 9rem !important;
  }
  .p-xxl-37 {
    padding: 9.25rem !important;
  }
  .pt-xxl-37, .py-xxl-37 {
    padding-top: 9.25rem !important;
  }
  .pr-xxl-37, .px-xxl-37 {
    padding-right: 9.25rem !important;
  }
  .pb-xxl-37, .py-xxl-37 {
    padding-bottom: 9.25rem !important;
  }
  .pl-xxl-37, .px-xxl-37 {
    padding-left: 9.25rem !important;
  }
  .p-xxl-48 {
    padding: 9.5rem !important;
  }
  .pt-xxl-48, .py-xxl-48 {
    padding-top: 9.5rem !important;
  }
  .pr-xxl-48, .px-xxl-48 {
    padding-right: 9.5rem !important;
  }
  .pb-xxl-48, .py-xxl-48 {
    padding-bottom: 9.5rem !important;
  }
  .pl-xxl-48, .px-xxl-48 {
    padding-left: 9.5rem !important;
  }
  .p-xxl-39 {
    padding: 9.75rem !important;
  }
  .pt-xxl-39, .py-xxl-39 {
    padding-top: 9.75rem !important;
  }
  .pr-xxl-39, .px-xxl-39 {
    padding-right: 9.75rem !important;
  }
  .pb-xxl-39, .py-xxl-39 {
    padding-bottom: 9.75rem !important;
  }
  .pl-xxl-39, .px-xxl-39 {
    padding-left: 9.75rem !important;
  }
  .p-xxl-40 {
    padding: 10rem !important;
  }
  .pt-xxl-40, .py-xxl-40 {
    padding-top: 10rem !important;
  }
  .pr-xxl-40, .px-xxl-40 {
    padding-right: 10rem !important;
  }
  .pb-xxl-40, .py-xxl-40 {
    padding-bottom: 10rem !important;
  }
  .pl-xxl-40, .px-xxl-40 {
    padding-left: 10rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1, .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1, .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1, .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1, .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2, .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2, .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2, .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2, .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -0.75rem !important;
  }
  .mt-xxl-n3, .my-xxl-n3 {
    margin-top: -0.75rem !important;
  }
  .mr-xxl-n3, .mx-xxl-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-xxl-n3, .my-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ml-xxl-n3, .mx-xxl-n3 {
    margin-left: -0.75rem !important;
  }
  .m-xxl-n4 {
    margin: -1rem !important;
  }
  .mt-xxl-n4, .my-xxl-n4 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n4, .mx-xxl-n4 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n4, .my-xxl-n4 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n4, .mx-xxl-n4 {
    margin-left: -1rem !important;
  }
  .m-xxl-n5 {
    margin: -1.25rem !important;
  }
  .mt-xxl-n5, .my-xxl-n5 {
    margin-top: -1.25rem !important;
  }
  .mr-xxl-n5, .mx-xxl-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-xxl-n5, .my-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ml-xxl-n5, .mx-xxl-n5 {
    margin-left: -1.25rem !important;
  }
  .m-xxl-n6 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n6, .my-xxl-n6 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n6, .mx-xxl-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n6, .my-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n6, .mx-xxl-n6 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n7 {
    margin: -1.75rem !important;
  }
  .mt-xxl-n7, .my-xxl-n7 {
    margin-top: -1.75rem !important;
  }
  .mr-xxl-n7, .mx-xxl-n7 {
    margin-right: -1.75rem !important;
  }
  .mb-xxl-n7, .my-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }
  .ml-xxl-n7, .mx-xxl-n7 {
    margin-left: -1.75rem !important;
  }
  .m-xxl-n8 {
    margin: -2rem !important;
  }
  .mt-xxl-n8, .my-xxl-n8 {
    margin-top: -2rem !important;
  }
  .mr-xxl-n8, .mx-xxl-n8 {
    margin-right: -2rem !important;
  }
  .mb-xxl-n8, .my-xxl-n8 {
    margin-bottom: -2rem !important;
  }
  .ml-xxl-n8, .mx-xxl-n8 {
    margin-left: -2rem !important;
  }
  .m-xxl-n9 {
    margin: -2.25rem !important;
  }
  .mt-xxl-n9, .my-xxl-n9 {
    margin-top: -2.25rem !important;
  }
  .mr-xxl-n9, .mx-xxl-n9 {
    margin-right: -2.25rem !important;
  }
  .mb-xxl-n9, .my-xxl-n9 {
    margin-bottom: -2.25rem !important;
  }
  .ml-xxl-n9, .mx-xxl-n9 {
    margin-left: -2.25rem !important;
  }
  .m-xxl-n10 {
    margin: -2.5rem !important;
  }
  .mt-xxl-n10, .my-xxl-n10 {
    margin-top: -2.5rem !important;
  }
  .mr-xxl-n10, .mx-xxl-n10 {
    margin-right: -2.5rem !important;
  }
  .mb-xxl-n10, .my-xxl-n10 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xxl-n10, .mx-xxl-n10 {
    margin-left: -2.5rem !important;
  }
  .m-xxl-n11 {
    margin: -2.75rem !important;
  }
  .mt-xxl-n11, .my-xxl-n11 {
    margin-top: -2.75rem !important;
  }
  .mr-xxl-n11, .mx-xxl-n11 {
    margin-right: -2.75rem !important;
  }
  .mb-xxl-n11, .my-xxl-n11 {
    margin-bottom: -2.75rem !important;
  }
  .ml-xxl-n11, .mx-xxl-n11 {
    margin-left: -2.75rem !important;
  }
  .m-xxl-n12 {
    margin: -3rem !important;
  }
  .mt-xxl-n12, .my-xxl-n12 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n12, .mx-xxl-n12 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n12, .my-xxl-n12 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n12, .mx-xxl-n12 {
    margin-left: -3rem !important;
  }
  .m-xxl-n13 {
    margin: -3.25rem !important;
  }
  .mt-xxl-n13, .my-xxl-n13 {
    margin-top: -3.25rem !important;
  }
  .mr-xxl-n13, .mx-xxl-n13 {
    margin-right: -3.25rem !important;
  }
  .mb-xxl-n13, .my-xxl-n13 {
    margin-bottom: -3.25rem !important;
  }
  .ml-xxl-n13, .mx-xxl-n13 {
    margin-left: -3.25rem !important;
  }
  .m-xxl-n14 {
    margin: -3.5rem !important;
  }
  .mt-xxl-n14, .my-xxl-n14 {
    margin-top: -3.5rem !important;
  }
  .mr-xxl-n14, .mx-xxl-n14 {
    margin-right: -3.5rem !important;
  }
  .mb-xxl-n14, .my-xxl-n14 {
    margin-bottom: -3.5rem !important;
  }
  .ml-xxl-n14, .mx-xxl-n14 {
    margin-left: -3.5rem !important;
  }
  .m-xxl-n15 {
    margin: -3.75rem !important;
  }
  .mt-xxl-n15, .my-xxl-n15 {
    margin-top: -3.75rem !important;
  }
  .mr-xxl-n15, .mx-xxl-n15 {
    margin-right: -3.75rem !important;
  }
  .mb-xxl-n15, .my-xxl-n15 {
    margin-bottom: -3.75rem !important;
  }
  .ml-xxl-n15, .mx-xxl-n15 {
    margin-left: -3.75rem !important;
  }
  .m-xxl-n16 {
    margin: -4rem !important;
  }
  .mt-xxl-n16, .my-xxl-n16 {
    margin-top: -4rem !important;
  }
  .mr-xxl-n16, .mx-xxl-n16 {
    margin-right: -4rem !important;
  }
  .mb-xxl-n16, .my-xxl-n16 {
    margin-bottom: -4rem !important;
  }
  .ml-xxl-n16, .mx-xxl-n16 {
    margin-left: -4rem !important;
  }
  .m-xxl-n17 {
    margin: -4.25rem !important;
  }
  .mt-xxl-n17, .my-xxl-n17 {
    margin-top: -4.25rem !important;
  }
  .mr-xxl-n17, .mx-xxl-n17 {
    margin-right: -4.25rem !important;
  }
  .mb-xxl-n17, .my-xxl-n17 {
    margin-bottom: -4.25rem !important;
  }
  .ml-xxl-n17, .mx-xxl-n17 {
    margin-left: -4.25rem !important;
  }
  .m-xxl-n18 {
    margin: -4.5rem !important;
  }
  .mt-xxl-n18, .my-xxl-n18 {
    margin-top: -4.5rem !important;
  }
  .mr-xxl-n18, .mx-xxl-n18 {
    margin-right: -4.5rem !important;
  }
  .mb-xxl-n18, .my-xxl-n18 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xxl-n18, .mx-xxl-n18 {
    margin-left: -4.5rem !important;
  }
  .m-xxl-n19 {
    margin: -4.75rem !important;
  }
  .mt-xxl-n19, .my-xxl-n19 {
    margin-top: -4.75rem !important;
  }
  .mr-xxl-n19, .mx-xxl-n19 {
    margin-right: -4.75rem !important;
  }
  .mb-xxl-n19, .my-xxl-n19 {
    margin-bottom: -4.75rem !important;
  }
  .ml-xxl-n19, .mx-xxl-n19 {
    margin-left: -4.75rem !important;
  }
  .m-xxl-n20 {
    margin: -5rem !important;
  }
  .mt-xxl-n20, .my-xxl-n20 {
    margin-top: -5rem !important;
  }
  .mr-xxl-n20, .mx-xxl-n20 {
    margin-right: -5rem !important;
  }
  .mb-xxl-n20, .my-xxl-n20 {
    margin-bottom: -5rem !important;
  }
  .ml-xxl-n20, .mx-xxl-n20 {
    margin-left: -5rem !important;
  }
  .m-xxl-n21 {
    margin: -5.25rem !important;
  }
  .mt-xxl-n21, .my-xxl-n21 {
    margin-top: -5.25rem !important;
  }
  .mr-xxl-n21, .mx-xxl-n21 {
    margin-right: -5.25rem !important;
  }
  .mb-xxl-n21, .my-xxl-n21 {
    margin-bottom: -5.25rem !important;
  }
  .ml-xxl-n21, .mx-xxl-n21 {
    margin-left: -5.25rem !important;
  }
  .m-xxl-n22 {
    margin: -5.5rem !important;
  }
  .mt-xxl-n22, .my-xxl-n22 {
    margin-top: -5.5rem !important;
  }
  .mr-xxl-n22, .mx-xxl-n22 {
    margin-right: -5.5rem !important;
  }
  .mb-xxl-n22, .my-xxl-n22 {
    margin-bottom: -5.5rem !important;
  }
  .ml-xxl-n22, .mx-xxl-n22 {
    margin-left: -5.5rem !important;
  }
  .m-xxl-n23 {
    margin: -5.75rem !important;
  }
  .mt-xxl-n23, .my-xxl-n23 {
    margin-top: -5.75rem !important;
  }
  .mr-xxl-n23, .mx-xxl-n23 {
    margin-right: -5.75rem !important;
  }
  .mb-xxl-n23, .my-xxl-n23 {
    margin-bottom: -5.75rem !important;
  }
  .ml-xxl-n23, .mx-xxl-n23 {
    margin-left: -5.75rem !important;
  }
  .m-xxl-n24 {
    margin: -6rem !important;
  }
  .mt-xxl-n24, .my-xxl-n24 {
    margin-top: -6rem !important;
  }
  .mr-xxl-n24, .mx-xxl-n24 {
    margin-right: -6rem !important;
  }
  .mb-xxl-n24, .my-xxl-n24 {
    margin-bottom: -6rem !important;
  }
  .ml-xxl-n24, .mx-xxl-n24 {
    margin-left: -6rem !important;
  }
  .m-xxl-n25 {
    margin: -6.25rem !important;
  }
  .mt-xxl-n25, .my-xxl-n25 {
    margin-top: -6.25rem !important;
  }
  .mr-xxl-n25, .mx-xxl-n25 {
    margin-right: -6.25rem !important;
  }
  .mb-xxl-n25, .my-xxl-n25 {
    margin-bottom: -6.25rem !important;
  }
  .ml-xxl-n25, .mx-xxl-n25 {
    margin-left: -6.25rem !important;
  }
  .m-xxl-n26 {
    margin: -6.5rem !important;
  }
  .mt-xxl-n26, .my-xxl-n26 {
    margin-top: -6.5rem !important;
  }
  .mr-xxl-n26, .mx-xxl-n26 {
    margin-right: -6.5rem !important;
  }
  .mb-xxl-n26, .my-xxl-n26 {
    margin-bottom: -6.5rem !important;
  }
  .ml-xxl-n26, .mx-xxl-n26 {
    margin-left: -6.5rem !important;
  }
  .m-xxl-n27 {
    margin: -6.75rem !important;
  }
  .mt-xxl-n27, .my-xxl-n27 {
    margin-top: -6.75rem !important;
  }
  .mr-xxl-n27, .mx-xxl-n27 {
    margin-right: -6.75rem !important;
  }
  .mb-xxl-n27, .my-xxl-n27 {
    margin-bottom: -6.75rem !important;
  }
  .ml-xxl-n27, .mx-xxl-n27 {
    margin-left: -6.75rem !important;
  }
  .m-xxl-n28 {
    margin: -7rem !important;
  }
  .mt-xxl-n28, .my-xxl-n28 {
    margin-top: -7rem !important;
  }
  .mr-xxl-n28, .mx-xxl-n28 {
    margin-right: -7rem !important;
  }
  .mb-xxl-n28, .my-xxl-n28 {
    margin-bottom: -7rem !important;
  }
  .ml-xxl-n28, .mx-xxl-n28 {
    margin-left: -7rem !important;
  }
  .m-xxl-n29 {
    margin: -7.25rem !important;
  }
  .mt-xxl-n29, .my-xxl-n29 {
    margin-top: -7.25rem !important;
  }
  .mr-xxl-n29, .mx-xxl-n29 {
    margin-right: -7.25rem !important;
  }
  .mb-xxl-n29, .my-xxl-n29 {
    margin-bottom: -7.25rem !important;
  }
  .ml-xxl-n29, .mx-xxl-n29 {
    margin-left: -7.25rem !important;
  }
  .m-xxl-n30 {
    margin: -7.5rem !important;
  }
  .mt-xxl-n30, .my-xxl-n30 {
    margin-top: -7.5rem !important;
  }
  .mr-xxl-n30, .mx-xxl-n30 {
    margin-right: -7.5rem !important;
  }
  .mb-xxl-n30, .my-xxl-n30 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xxl-n30, .mx-xxl-n30 {
    margin-left: -7.5rem !important;
  }
  .m-xxl-n31 {
    margin: -7.75rem !important;
  }
  .mt-xxl-n31, .my-xxl-n31 {
    margin-top: -7.75rem !important;
  }
  .mr-xxl-n31, .mx-xxl-n31 {
    margin-right: -7.75rem !important;
  }
  .mb-xxl-n31, .my-xxl-n31 {
    margin-bottom: -7.75rem !important;
  }
  .ml-xxl-n31, .mx-xxl-n31 {
    margin-left: -7.75rem !important;
  }
  .m-xxl-n32 {
    margin: -8rem !important;
  }
  .mt-xxl-n32, .my-xxl-n32 {
    margin-top: -8rem !important;
  }
  .mr-xxl-n32, .mx-xxl-n32 {
    margin-right: -8rem !important;
  }
  .mb-xxl-n32, .my-xxl-n32 {
    margin-bottom: -8rem !important;
  }
  .ml-xxl-n32, .mx-xxl-n32 {
    margin-left: -8rem !important;
  }
  .m-xxl-n33 {
    margin: -8.25rem !important;
  }
  .mt-xxl-n33, .my-xxl-n33 {
    margin-top: -8.25rem !important;
  }
  .mr-xxl-n33, .mx-xxl-n33 {
    margin-right: -8.25rem !important;
  }
  .mb-xxl-n33, .my-xxl-n33 {
    margin-bottom: -8.25rem !important;
  }
  .ml-xxl-n33, .mx-xxl-n33 {
    margin-left: -8.25rem !important;
  }
  .m-xxl-n34 {
    margin: -8.5rem !important;
  }
  .mt-xxl-n34, .my-xxl-n34 {
    margin-top: -8.5rem !important;
  }
  .mr-xxl-n34, .mx-xxl-n34 {
    margin-right: -8.5rem !important;
  }
  .mb-xxl-n34, .my-xxl-n34 {
    margin-bottom: -8.5rem !important;
  }
  .ml-xxl-n34, .mx-xxl-n34 {
    margin-left: -8.5rem !important;
  }
  .m-xxl-n35 {
    margin: -8.75rem !important;
  }
  .mt-xxl-n35, .my-xxl-n35 {
    margin-top: -8.75rem !important;
  }
  .mr-xxl-n35, .mx-xxl-n35 {
    margin-right: -8.75rem !important;
  }
  .mb-xxl-n35, .my-xxl-n35 {
    margin-bottom: -8.75rem !important;
  }
  .ml-xxl-n35, .mx-xxl-n35 {
    margin-left: -8.75rem !important;
  }
  .m-xxl-n36 {
    margin: -9rem !important;
  }
  .mt-xxl-n36, .my-xxl-n36 {
    margin-top: -9rem !important;
  }
  .mr-xxl-n36, .mx-xxl-n36 {
    margin-right: -9rem !important;
  }
  .mb-xxl-n36, .my-xxl-n36 {
    margin-bottom: -9rem !important;
  }
  .ml-xxl-n36, .mx-xxl-n36 {
    margin-left: -9rem !important;
  }
  .m-xxl-n37 {
    margin: -9.25rem !important;
  }
  .mt-xxl-n37, .my-xxl-n37 {
    margin-top: -9.25rem !important;
  }
  .mr-xxl-n37, .mx-xxl-n37 {
    margin-right: -9.25rem !important;
  }
  .mb-xxl-n37, .my-xxl-n37 {
    margin-bottom: -9.25rem !important;
  }
  .ml-xxl-n37, .mx-xxl-n37 {
    margin-left: -9.25rem !important;
  }
  .m-xxl-n48 {
    margin: -9.5rem !important;
  }
  .mt-xxl-n48, .my-xxl-n48 {
    margin-top: -9.5rem !important;
  }
  .mr-xxl-n48, .mx-xxl-n48 {
    margin-right: -9.5rem !important;
  }
  .mb-xxl-n48, .my-xxl-n48 {
    margin-bottom: -9.5rem !important;
  }
  .ml-xxl-n48, .mx-xxl-n48 {
    margin-left: -9.5rem !important;
  }
  .m-xxl-n39 {
    margin: -9.75rem !important;
  }
  .mt-xxl-n39, .my-xxl-n39 {
    margin-top: -9.75rem !important;
  }
  .mr-xxl-n39, .mx-xxl-n39 {
    margin-right: -9.75rem !important;
  }
  .mb-xxl-n39, .my-xxl-n39 {
    margin-bottom: -9.75rem !important;
  }
  .ml-xxl-n39, .mx-xxl-n39 {
    margin-left: -9.75rem !important;
  }
  .m-xxl-n40 {
    margin: -10rem !important;
  }
  .mt-xxl-n40, .my-xxl-n40 {
    margin-top: -10rem !important;
  }
  .mr-xxl-n40, .mx-xxl-n40 {
    margin-right: -10rem !important;
  }
  .mb-xxl-n40, .my-xxl-n40 {
    margin-bottom: -10rem !important;
  }
  .ml-xxl-n40, .mx-xxl-n40 {
    margin-left: -10rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto, .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto, .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto, .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto, .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border-radius: 0.42rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.42rem - 1px);
  border-top-right-radius: calc(0.42rem - 1px);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.42rem - 1px);
  border-bottom-left-radius: calc(0.42rem - 1px);
}

.card>.card-header+.list-group, .card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2.25rem;
}

.card-title {
  margin-bottom: 2rem;
}

.card-subtitle {
  margin-top: -1rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 2.25rem;
}

.card-header {
  padding: 2rem 2.25rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #ebedf3;
}

.card-header:first-child {
  border-radius: calc(0.42rem - 1px) calc(0.42rem - 1px) 0 0;
}

.card-footer {
  padding: 2rem 2.25rem;
  background-color: #ffffff;
  border-top: 1px solid #ebedf3;
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.42rem - 1px) calc(0.42rem - 1px);
}

.card-header-tabs {
  margin-right: -1.125rem;
  margin-bottom: -2rem;
  margin-left: -1.125rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -1.125rem;
  margin-left: -1.125rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.42rem - 1px);
}

.card-img, .card-img-top, .card-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: calc(0.42rem - 1px);
  border-top-right-radius: calc(0.42rem - 1px);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: calc(0.42rem - 1px);
  border-bottom-left-radius: calc(0.42rem - 1px);
}

.card-deck .card {
  margin-bottom: 12.5px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -12.5px;
    margin-left: -12.5px;
  }
  .card-deck .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 12.5px;
    margin-bottom: 0;
    margin-left: 12.5px;
  }
}

.card-group>.card {
  margin-bottom: 12.5px;
}

@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group>.card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group>.card:not(:last-child) .card-img-top, .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group>.card:not(:last-child) .card-img-bottom, .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group>.card:not(:first-child) .card-img-top, .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group>.card:not(:first-child) .card-img-bottom, .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 2rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.card.card-custom {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  border: 0;
}

.card.card-custom>.card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-height: 70px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent;
}

.card.card-custom>.card-header .card-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.5rem;
  margin-left: 0;
}

.card.card-custom>.card-header .card-title .card-icon {
  margin-right: 0.75rem;
  line-height: 0;
}

.card.card-custom>.card-header .card-title .card-icon i {
  font-size: 1.25rem;
  color: #7e8299;
  line-height: 0;
}

.card.card-custom>.card-header .card-title .card-icon i:after, .card.card-custom>.card-header .card-title .card-icon i:before {
  line-height: 0;
}

.card.card-custom>.card-header .card-title .card-icon .svg-icon svg {
  height: 24px;
  width: 24px;
}

.card.card-custom>.card-header .card-title .card-icon .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #7e8299;
}

.card.card-custom>.card-header .card-title .card-icon .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.card.card-custom>.card-header .card-title, .card.card-custom>.card-header .card-title .card-label {
  font-weight: 500;
  font-size: 18px;
  color: #181c32;
}

.card.card-custom>.card-header .card-title .card-label {
  margin: 0 0.75rem 0 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.card.card-custom>.card-header .card-title small {
  color: #b5b5c3;
  font-size: 1rem;
}

.card.card-custom>.card-header .card-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.5rem 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.card.card-custom>.card-header.card-header-tabs-line {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.card.card-custom>.card-header.card-header-tabs-line .card-toolbar {
  margin: 0;
}

.card.card-custom>.card-header.card-header-tabs-line .nav {
  border-bottom-color: transparent;
}

.card.card-custom>.card-header.card-header-tabs-line .nav .nav-item {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.card.card-custom>.card-header.card-header-tabs-line .nav .nav-link {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.card.card-custom>.card-header.card-header-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.card.card-custom>.card-body {
  padding: 2rem 2.25rem;
}

.card.card-custom>.card-footer {
  background-color: transparent;
}

.card.card-custom.card-stretch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.card.card-custom.card-stretch.gutter-b {
  height: calc(100% - 25px);
}

.card.card-custom.card-stretch.card-stretch-half {
  height: 50%;
}

.card.card-custom.card-stretch.card-stretch-half.gutter-b {
  height: calc(50% - 25px);
}

.card.card-custom.card-stretch.card-stretch-third {
  height: 33.33%;
}

.card.card-custom.card-stretch.card-stretch-third.gutter-b {
  height: calc(33.33% - 25px);
}

.card.card-custom.card-stretch.card-stretch-fourth {
  height: 25%;
}

.card.card-custom.card-stretch.card-stretch-fourth.gutter-b {
  height: calc(25% - 25px);
}

.card.card-custom.card-fit>.card-header {
  border-bottom: 0;
}

.card.card-custom.card-fit>.card-footer {
  border-top: 0;
}

.card.card-custom.card-space {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.card.card-custom.card-space>.card-header {
  padding-left: 0;
  padding-right: 0;
}

.card.card-custom.card-space>form>.card-body, .card.card-custom.card-space>.card-body {
  padding-left: 0;
  padding-right: 0;
}

.card.card-custom.card-space>form>.card-footer, .card.card-custom.card-space>.card-footer {
  padding-left: 0;
  padding-right: 0;
}

.card.card-custom.card-sticky>.card-header {
  -webkit-transition: left 0.3s, right 0.3s, height 0.3s;
  transition: left 0.3s, right 0.3s, height 0.3s;
  min-height: 70px;
}

.card-sticky-on .card.card-custom.card-sticky>.card-header {
  -webkit-transition: left 0.3s, right 0.3s, height 0.3s;
  transition: left 0.3s, right 0.3s, height 0.3s;
  position: fixed;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);
  box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);
  z-index: 101;
  background: #ffffff;
}

.card.card-custom.card-transparent {
  background-color: transparent;
}

.card.card-custom.card-shadowless {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.card.card-custom.card-border {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ebedf3;
}

.card.card-custom.card-border2 {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid var(--color-border2);
}

.card.card-custom .card-scroll {
  position: relative;
  overflow: auto;
}

.card.card-custom.card-collapsed>form, .card.card-custom.card-collapsed>.card-body {
  display: none;
}

.card.card-custom .card-header .card-toolbar [data-card-tool="toggle"] i {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.card.card-custom.card-collapsed .card-header .card-toolbar [data-card-tool="toggle"] i, .card.card-custom.card-collapse .card-header .card-toolbar [data-card-tool="toggle"] i {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.card-spacer {
  padding: 2rem 2.25rem !important;
}

.card-spacer-x {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.card-spacer-y {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.card-rounded {
  border-radius: 0.42rem;
}

.card-rounded-top {
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem;
}

.card-rounded-bottom {
  border-bottom-left-radius: 0.42rem;
  border-bottom-right-radius: 0.42rem;
}

@media (max-width: 767.98px) {
  .card.card-custom>.card-header:not(.flex-nowrap) {
    min-height: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.square-card {
  border-radius: inherit !important;
}

.no-shadow-card {
  box-shadow: none !important;
}

.rounded-card {
  border-radius: 0.42rem;
}

.no-radius-card {
  border-radius: 0 !important;
}

.no-border-card {
  border: none !important;
  border-radius: inherit !important;
}

.no-bg-card {
  background-color: transparent !important;
}

@media (max-width: 768px) {
  .no-background-card {
    background-color: transparent !important;
  }
}

.rounded-card-top {
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem;
}

.rounded-card-bottom {
  border-bottom-left-radius: 0.42rem;
  border-bottom-right-radius: 0.42rem;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex;
}

.d-flex-important {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1400px) {
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.symbol {
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
}

.symbol .symbol-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 500;
  line-height: 0;
  color: #3f4254;
  background-color: #f3f6f9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}

.symbol .symbol-badge {
  position: absolute;
  border: 2px solid #ffffff;
  border-radius: 100%;
  top: 0;
  right: 0;
}

.symbol>img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 0.42rem;
}

.symbol.symbol-circle {
  border-radius: 50%;
}

.symbol.symbol-circle>img {
  border-radius: 50%;
}

.symbol.symbol-circle .symbol-label {
  border-radius: 50%;
}

.symbol.symbol-primary .symbol-label {
  background-color: #3699ff;
  color: #ffffff;
}

.symbol.symbol-light-primary .symbol-label {
  background-color: #e1f0ff;
  color: #3699ff;
}

.symbol.symbol-secondary .symbol-label {
  background-color: #e4e6ef;
  color: #3f4254;
}

.symbol.symbol-light-secondary .symbol-label {
  background-color: #ebedf3;
  color: #e4e6ef;
}

.symbol.symbol-success .symbol-label {
  background-color: #1bc5bd;
  color: #ffffff;
}

.symbol.symbol-light-success .symbol-label {
  background-color: #c9f7f5;
  color: #1bc5bd;
}

.symbol.symbol-info .symbol-label {
  background-color: #8950fc;
  color: #ffffff;
}

.symbol.symbol-light-info .symbol-label {
  background-color: #eee5ff;
  color: #8950fc;
}

.symbol.symbol-warning .symbol-label {
  background-color: #ffa800;
  color: #ffffff;
}

.symbol.symbol-light-warning .symbol-label {
  background-color: #fff4de;
  color: #ffa800;
}

.symbol.symbol-danger .symbol-label {
  background-color: #f64e60;
  color: #ffffff;
}

.symbol.symbol-light-danger .symbol-label {
  background-color: #ffe2e5;
  color: #f64e60;
}

.symbol.symbol-light .symbol-label {
  background-color: #f3f6f9;
  color: #7e8299;
}

.symbol.symbol-light-light .symbol-label {
  background-color: #f3f6f9;
  color: #f3f6f9;
}

.symbol.symbol-dark .symbol-label {
  background-color: #181c32;
  color: #ffffff;
}

.symbol.symbol-light-dark .symbol-label {
  background-color: #d1d3e0;
  color: #181c32;
}

.symbol.symbol-white .symbol-label {
  background-color: #ffffff;
  color: #3f4254;
}

.symbol.symbol-light-white .symbol-label {
  background-color: #ffffff;
  color: #ffffff;
}

.symbol.symbol-20>img {
  width: 100%;
  max-width: 20px;
  height: 20px;
}

.symbol.symbol-20 .symbol-label {
  width: 20px;
  height: 20px;
}

.symbol.symbol-20 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-20 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-20.symbol-circle .symbol-badge {
  top: -7.9px;
  right: -7.9px;
}

.symbol.symbol-20.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -7.9px;
}

.symbol.symbol-20.symbol-fixed .symbol-label {
  width: 20px;
  height: 20px;
}

.symbol.symbol-20.symbol-fixed>img {
  width: 20px;
  height: 20px;
  max-width: auto;
}

.symbol.symbol-20.symbol-2by3 .symbol-label {
  height: 20px;
  width: 30px;
}

.symbol.symbol-20.symbol-2by3>img {
  height: 20px;
  width: 30px;
  max-width: auto;
}

.symbol.symbol-25>img {
  width: 100%;
  max-width: 25px;
  height: 25px;
}

.symbol.symbol-25 .symbol-label {
  width: 25px;
  height: 25px;
}

.symbol.symbol-25 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-25 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-25.symbol-circle .symbol-badge {
  top: -6.875px;
  right: -6.875px;
}

.symbol.symbol-25.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6.875px;
}

.symbol.symbol-25.symbol-fixed .symbol-label {
  width: 25px;
  height: 25px;
}

.symbol.symbol-25.symbol-fixed>img {
  width: 25px;
  height: 25px;
  max-width: auto;
}

.symbol.symbol-25.symbol-2by3 .symbol-label {
  height: 25px;
  width: 37.5px;
}

.symbol.symbol-25.symbol-2by3>img {
  height: 25px;
  width: 37.5px;
  max-width: auto;
}

.symbol.symbol-30>img {
  width: 100%;
  max-width: 30px;
  height: 30px;
}

.symbol.symbol-30 .symbol-label {
  width: 30px;
  height: 30px;
}

.symbol.symbol-30 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-30 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-30.symbol-circle .symbol-badge {
  top: -5.85px;
  right: -5.85px;
}

.symbol.symbol-30.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -5.85px;
}

.symbol.symbol-30.symbol-fixed .symbol-label {
  width: 30px;
  height: 30px;
}

.symbol.symbol-30.symbol-fixed>img {
  width: 30px;
  height: 30px;
  max-width: auto;
}

.symbol.symbol-30.symbol-2by3 .symbol-label {
  height: 30px;
  width: 45px;
}

.symbol.symbol-30.symbol-2by3>img {
  height: 30px;
  width: 45px;
  max-width: auto;
}

.symbol.symbol-35>img {
  width: 100%;
  max-width: 35px;
  height: 35px;
}

.symbol.symbol-35 .symbol-label {
  width: 35px;
  height: 35px;
}

.symbol.symbol-35 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-35 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-35.symbol-circle .symbol-badge {
  top: -4.825px;
  right: -4.825px;
}

.symbol.symbol-35.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -4.825px;
}

.symbol.symbol-35.symbol-fixed .symbol-label {
  width: 35px;
  height: 35px;
}

.symbol.symbol-35.symbol-fixed>img {
  width: 35px;
  height: 35px;
  max-width: auto;
}

.symbol.symbol-35.symbol-2by3 .symbol-label {
  height: 35px;
  width: 52.5px;
}

.symbol.symbol-35.symbol-2by3>img {
  height: 35px;
  width: 52.5px;
  max-width: auto;
}

.symbol.symbol-40>img {
  width: 100%;
  max-width: 40px;
  height: 40px;
}

.symbol.symbol-40 .symbol-label {
  width: 40px;
  height: 40px;
}

.symbol.symbol-40 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-40 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-40.symbol-circle .symbol-badge {
  top: -3.8px;
  right: -3.8px;
}

.symbol.symbol-40.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -3.8px;
}

.symbol.symbol-40.symbol-fixed .symbol-label {
  width: 40px;
  height: 40px;
}

.symbol.symbol-40.symbol-fixed>img {
  width: 40px;
  height: 40px;
  max-width: auto;
}

.symbol.symbol-40.symbol-2by3 .symbol-label {
  height: 40px;
  width: 60px;
}

.symbol.symbol-40.symbol-2by3>img {
  height: 40px;
  width: 60px;
  max-width: auto;
}

.symbol.symbol-45>img {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.symbol.symbol-45 .symbol-label {
  width: 45px;
  height: 45px;
}

.symbol.symbol-45 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-45 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-45.symbol-circle .symbol-badge {
  top: -2.775px;
  right: -2.775px;
}

.symbol.symbol-45.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -2.775px;
}

.symbol.symbol-45.symbol-fixed .symbol-label {
  width: 45px;
  height: 45px;
}

.symbol.symbol-45.symbol-fixed>img {
  width: 45px;
  height: 45px;
  max-width: auto;
}

.symbol.symbol-45.symbol-2by3 .symbol-label {
  height: 45px;
  width: 67.5px;
}

.symbol.symbol-45.symbol-2by3>img {
  height: 45px;
  width: 67.5px;
  max-width: auto;
}

.symbol>img {
  width: 100%;
  max-width: 50px;
  height: 50px;
}

.symbol .symbol-label {
  width: 50px;
  height: 50px;
}

.symbol .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-circle .symbol-badge {
  top: -1.75px;
  right: -1.75px;
}

.symbol.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -1.75px;
}

.symbol.symbol-fixed .symbol-label {
  width: 50px;
  height: 50px;
}

.symbol.symbol-fixed>img {
  width: 50px;
  height: 50px;
  max-width: auto;
}

.symbol.symbol-2by3 .symbol-label {
  height: 50px;
  width: 75px;
}

.symbol.symbol-2by3>img {
  height: 50px;
  width: 75px;
  max-width: auto;
}

.symbol.symbol-50>img {
  width: 100%;
  max-width: 50px;
  height: 50px;
}

.symbol.symbol-50 .symbol-label {
  width: 50px;
  height: 50px;
}

.symbol.symbol-50 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-50 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-50.symbol-circle .symbol-badge {
  top: -1.75px;
  right: -1.75px;
}

.symbol.symbol-50.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -1.75px;
}

.symbol.symbol-50.symbol-fixed .symbol-label {
  width: 50px;
  height: 50px;
}

.symbol.symbol-50.symbol-fixed>img {
  width: 50px;
  height: 50px;
  max-width: auto;
}

.symbol.symbol-50.symbol-2by3 .symbol-label {
  height: 50px;
  width: 75px;
}

.symbol.symbol-50.symbol-2by3>img {
  height: 50px;
  width: 75px;
  max-width: auto;
}

.symbol.symbol-55>img {
  width: 100%;
  max-width: 55px;
  height: 55px;
}

.symbol.symbol-55 .symbol-label {
  width: 55px;
  height: 55px;
}

.symbol.symbol-55 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-55 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-55.symbol-circle .symbol-badge {
  top: -0.725px;
  right: -0.725px;
}

.symbol.symbol-55.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -0.725px;
}

.symbol.symbol-55.symbol-fixed .symbol-label {
  width: 55px;
  height: 55px;
}

.symbol.symbol-55.symbol-fixed>img {
  width: 55px;
  height: 55px;
  max-width: auto;
}

.symbol.symbol-55.symbol-2by3 .symbol-label {
  height: 55px;
  width: 82.5px;
}

.symbol.symbol-55.symbol-2by3>img {
  height: 55px;
  width: 82.5px;
  max-width: auto;
}

.symbol.symbol-60>img {
  width: 100%;
  max-width: 60px;
  height: 60px;
}

.symbol.symbol-60 .symbol-label {
  width: 60px;
  height: 60px;
}

.symbol.symbol-60 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-60 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-60.symbol-circle .symbol-badge {
  top: 0.3px;
  right: 0.3px;
}

.symbol.symbol-60.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: 0.3px;
}

.symbol.symbol-60.symbol-fixed .symbol-label {
  width: 60px;
  height: 60px;
}

.symbol.symbol-60.symbol-fixed>img {
  width: 60px;
  height: 60px;
  max-width: auto;
}

.symbol.symbol-60.symbol-2by3 .symbol-label {
  height: 60px;
  width: 90px;
}

.symbol.symbol-60.symbol-2by3>img {
  height: 60px;
  width: 90px;
  max-width: auto;
}

.symbol.symbol-65>img {
  width: 100%;
  max-width: 65px;
  height: 65px;
}

.symbol.symbol-65 .symbol-label {
  width: 65px;
  height: 65px;
}

.symbol.symbol-65 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-65 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-65.symbol-circle .symbol-badge {
  top: 1.325px;
  right: 1.325px;
}

.symbol.symbol-65.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: 1.325px;
}

.symbol.symbol-65.symbol-fixed .symbol-label {
  width: 65px;
  height: 65px;
}

.symbol.symbol-65.symbol-fixed>img {
  width: 65px;
  height: 65px;
  max-width: auto;
}

.symbol.symbol-65.symbol-2by3 .symbol-label {
  height: 65px;
  width: 97.5px;
}

.symbol.symbol-65.symbol-2by3>img {
  height: 65px;
  width: 97.5px;
  max-width: auto;
}

.symbol.symbol-70>img {
  width: 100%;
  max-width: 70px;
  height: 70px;
}

.symbol.symbol-70 .symbol-label {
  width: 70px;
  height: 70px;
}

.symbol.symbol-70 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-70 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-70.symbol-circle .symbol-badge {
  top: 2.35px;
  right: 2.35px;
}

.symbol.symbol-70.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: 2.35px;
}

.symbol.symbol-70.symbol-fixed .symbol-label {
  width: 70px;
  height: 70px;
}

.symbol.symbol-70.symbol-fixed>img {
  width: 70px;
  height: 70px;
  max-width: auto;
}

.symbol.symbol-70.symbol-2by3 .symbol-label {
  height: 70px;
  width: 105px;
}

.symbol.symbol-70.symbol-2by3>img {
  height: 70px;
  width: 105px;
  max-width: auto;
}

.symbol.symbol-75>img {
  width: 100%;
  max-width: 75px;
  height: 75px;
}

.symbol.symbol-75 .symbol-label {
  width: 75px;
  height: 75px;
}

.symbol.symbol-75 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-75 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-75.symbol-circle .symbol-badge {
  top: 3.375px;
  right: 3.375px;
}

.symbol.symbol-75.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: 3.375px;
}

.symbol.symbol-75.symbol-fixed .symbol-label {
  width: 75px;
  height: 75px;
}

.symbol.symbol-75.symbol-fixed>img {
  width: 75px;
  height: 75px;
  max-width: auto;
}

.symbol.symbol-75.symbol-2by3 .symbol-label {
  height: 75px;
  width: 112.5px;
}

.symbol.symbol-75.symbol-2by3>img {
  height: 75px;
  width: 112.5px;
  max-width: auto;
}

.symbol.symbol-80>img {
  width: 100%;
  max-width: 80px;
  height: 80px;
}

.symbol.symbol-80 .symbol-label {
  width: 80px;
  height: 80px;
}

.symbol.symbol-80 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-80 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-80.symbol-circle .symbol-badge {
  top: 4.4px;
  right: 4.4px;
}

.symbol.symbol-80.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: 4.4px;
}

.symbol.symbol-80.symbol-fixed .symbol-label {
  width: 80px;
  height: 80px;
}

.symbol.symbol-80.symbol-fixed>img {
  width: 80px;
  height: 80px;
  max-width: auto;
}

.symbol.symbol-80.symbol-2by3 .symbol-label {
  height: 80px;
  width: 120px;
}

.symbol.symbol-80.symbol-2by3>img {
  height: 80px;
  width: 120px;
  max-width: auto;
}

.symbol.symbol-85>img {
  width: 100%;
  max-width: 85px;
  height: 85px;
}

.symbol.symbol-85 .symbol-label {
  width: 85px;
  height: 85px;
}

.symbol.symbol-85 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-85 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-85.symbol-circle .symbol-badge {
  top: 5.425px;
  right: 5.425px;
}

.symbol.symbol-85.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: 5.425px;
}

.symbol.symbol-85.symbol-fixed .symbol-label {
  width: 85px;
  height: 85px;
}

.symbol.symbol-85.symbol-fixed>img {
  width: 85px;
  height: 85px;
  max-width: auto;
}

.symbol.symbol-85.symbol-2by3 .symbol-label {
  height: 85px;
  width: 127.5px;
}

.symbol.symbol-85.symbol-2by3>img {
  height: 85px;
  width: 127.5px;
  max-width: auto;
}

.symbol.symbol-90>img {
  width: 100%;
  max-width: 90px;
  height: 90px;
}

.symbol.symbol-90 .symbol-label {
  width: 90px;
  height: 90px;
}

.symbol.symbol-90 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-90 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-90.symbol-circle .symbol-badge {
  top: 6.45px;
  right: 6.45px;
}

.symbol.symbol-90.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: 6.45px;
}

.symbol.symbol-90.symbol-fixed .symbol-label {
  width: 90px;
  height: 90px;
}

.symbol.symbol-90.symbol-fixed>img {
  width: 90px;
  height: 90px;
  max-width: auto;
}

.symbol.symbol-90.symbol-2by3 .symbol-label {
  height: 90px;
  width: 135px;
}

.symbol.symbol-90.symbol-2by3>img {
  height: 90px;
  width: 135px;
  max-width: auto;
}

.symbol.symbol-95>img {
  width: 100%;
  max-width: 95px;
  height: 95px;
}

.symbol.symbol-95 .symbol-label {
  width: 95px;
  height: 95px;
}

.symbol.symbol-95 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-95 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-95.symbol-circle .symbol-badge {
  top: 7.475px;
  right: 7.475px;
}

.symbol.symbol-95.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: 7.475px;
}

.symbol.symbol-95.symbol-fixed .symbol-label {
  width: 95px;
  height: 95px;
}

.symbol.symbol-95.symbol-fixed>img {
  width: 95px;
  height: 95px;
  max-width: auto;
}

.symbol.symbol-95.symbol-2by3 .symbol-label {
  height: 95px;
  width: 142.5px;
}

.symbol.symbol-95.symbol-2by3>img {
  height: 95px;
  width: 142.5px;
  max-width: auto;
}

.symbol.symbol-100>img {
  width: 100%;
  max-width: 100px;
  height: 100px;
}

.symbol.symbol-100 .symbol-label {
  width: 100px;
  height: 100px;
}

.symbol.symbol-100 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-100 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-100.symbol-circle .symbol-badge {
  top: 8.5px;
  right: 8.5px;
}

.symbol.symbol-100.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: 8.5px;
}

.symbol.symbol-100.symbol-fixed .symbol-label {
  width: 100px;
  height: 100px;
}

.symbol.symbol-100.symbol-fixed>img {
  width: 100px;
  height: 100px;
  max-width: auto;
}

.symbol.symbol-100.symbol-2by3 .symbol-label {
  height: 100px;
  width: 150px;
}

.symbol.symbol-100.symbol-2by3>img {
  height: 100px;
  width: 150px;
  max-width: auto;
}

.symbol.symbol-120>img {
  width: 100%;
  max-width: 120px;
  height: 120px;
}

.symbol.symbol-120 .symbol-label {
  width: 120px;
  height: 120px;
}

.symbol.symbol-120 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-120 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-120.symbol-circle .symbol-badge {
  top: 12.6px;
  right: 12.6px;
}

.symbol.symbol-120.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: 12.6px;
}

.symbol.symbol-120.symbol-fixed .symbol-label {
  width: 120px;
  height: 120px;
}

.symbol.symbol-120.symbol-fixed>img {
  width: 120px;
  height: 120px;
  max-width: auto;
}

.symbol.symbol-120.symbol-2by3 .symbol-label {
  height: 120px;
  width: 180px;
}

.symbol.symbol-120.symbol-2by3>img {
  height: 120px;
  width: 180px;
  max-width: auto;
}

.symbol.symbol-130>img {
  width: 100%;
  max-width: 130px;
  height: 130px;
}

.symbol.symbol-130 .symbol-label {
  width: 130px;
  height: 130px;
}

.symbol.symbol-130 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-130 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-130.symbol-circle .symbol-badge {
  top: 14.65px;
  right: 14.65px;
}

.symbol.symbol-130.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: 14.65px;
}

.symbol.symbol-130.symbol-fixed .symbol-label {
  width: 130px;
  height: 130px;
}

.symbol.symbol-130.symbol-fixed>img {
  width: 130px;
  height: 130px;
  max-width: auto;
}

.symbol.symbol-130.symbol-2by3 .symbol-label {
  height: 130px;
  width: 195px;
}

.symbol.symbol-130.symbol-2by3>img {
  height: 130px;
  width: 195px;
  max-width: auto;
}

.symbol.symbol-140>img {
  width: 100%;
  max-width: 140px;
  height: 140px;
}

.symbol.symbol-140 .symbol-label {
  width: 140px;
  height: 140px;
}

.symbol.symbol-140 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-140 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-140.symbol-circle .symbol-badge {
  top: 16.7px;
  right: 16.7px;
}

.symbol.symbol-140.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: 16.7px;
}

.symbol.symbol-140.symbol-fixed .symbol-label {
  width: 140px;
  height: 140px;
}

.symbol.symbol-140.symbol-fixed>img {
  width: 140px;
  height: 140px;
  max-width: auto;
}

.symbol.symbol-140.symbol-2by3 .symbol-label {
  height: 140px;
  width: 210px;
}

.symbol.symbol-140.symbol-2by3>img {
  height: 140px;
  width: 210px;
  max-width: auto;
}

.symbol.symbol-150>img {
  width: 100%;
  max-width: 150px;
  height: 150px;
}

.symbol.symbol-150 .symbol-label {
  width: 150px;
  height: 150px;
}

.symbol.symbol-150 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}

.symbol.symbol-150 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}

.symbol.symbol-150.symbol-circle .symbol-badge {
  top: 18.75px;
  right: 18.75px;
}

.symbol.symbol-150.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: 18.75px;
}

.symbol.symbol-150.symbol-fixed .symbol-label {
  width: 150px;
  height: 150px;
}

.symbol.symbol-150.symbol-fixed>img {
  width: 150px;
  height: 150px;
  max-width: auto;
}

.symbol.symbol-150.symbol-2by3 .symbol-label {
  height: 150px;
  width: 225px;
}

.symbol.symbol-150.symbol-2by3>img {
  height: 150px;
  width: 225px;
  max-width: auto;
}

@media (min-width: 576px) {
  .symbol.symbol-sm-20>img {
    width: 100%;
    max-width: 20px;
    height: 20px;
  }
  .symbol.symbol-sm-20 .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-sm-20 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-20 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-20.symbol-circle .symbol-badge {
    top: -7.9px;
    right: -7.9px;
  }
  .symbol.symbol-sm-20.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -7.9px;
  }
  .symbol.symbol-sm-20.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-sm-20.symbol-fixed>img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-sm-20.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-sm-20.symbol-2by3>img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-sm-25>img {
    width: 100%;
    max-width: 25px;
    height: 25px;
  }
  .symbol.symbol-sm-25 .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-sm-25 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-25 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-25.symbol-circle .symbol-badge {
    top: -6.875px;
    right: -6.875px;
  }
  .symbol.symbol-sm-25.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6.875px;
  }
  .symbol.symbol-sm-25.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-sm-25.symbol-fixed>img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-sm-25.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-sm-25.symbol-2by3>img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-30>img {
    width: 100%;
    max-width: 30px;
    height: 30px;
  }
  .symbol.symbol-sm-30 .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-sm-30 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-30 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-30.symbol-circle .symbol-badge {
    top: -5.85px;
    right: -5.85px;
  }
  .symbol.symbol-sm-30.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -5.85px;
  }
  .symbol.symbol-sm-30.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-sm-30.symbol-fixed>img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-sm-30.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-sm-30.symbol-2by3>img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-sm-35>img {
    width: 100%;
    max-width: 35px;
    height: 35px;
  }
  .symbol.symbol-sm-35 .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-sm-35 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-35 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-35.symbol-circle .symbol-badge {
    top: -4.825px;
    right: -4.825px;
  }
  .symbol.symbol-sm-35.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -4.825px;
  }
  .symbol.symbol-sm-35.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-sm-35.symbol-fixed>img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-sm-35.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-sm-35.symbol-2by3>img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-40>img {
    width: 100%;
    max-width: 40px;
    height: 40px;
  }
  .symbol.symbol-sm-40 .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-sm-40 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-40 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-40.symbol-circle .symbol-badge {
    top: -3.8px;
    right: -3.8px;
  }
  .symbol.symbol-sm-40.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -3.8px;
  }
  .symbol.symbol-sm-40.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-sm-40.symbol-fixed>img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-sm-40.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-sm-40.symbol-2by3>img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-sm-45>img {
    width: 100%;
    max-width: 45px;
    height: 45px;
  }
  .symbol.symbol-sm-45 .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-sm-45 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-45 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-45.symbol-circle .symbol-badge {
    top: -2.775px;
    right: -2.775px;
  }
  .symbol.symbol-sm-45.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -2.775px;
  }
  .symbol.symbol-sm-45.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-sm-45.symbol-fixed>img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-sm-45.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-sm-45.symbol-2by3>img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
  .symbol .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-circle .symbol-badge {
    top: -1.75px;
    right: -1.75px;
  }
  .symbol.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -1.75px;
  }
  .symbol.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-fixed>img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-2by3>img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-sm-50>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
  .symbol.symbol-sm-50 .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-sm-50 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-50 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-50.symbol-circle .symbol-badge {
    top: -1.75px;
    right: -1.75px;
  }
  .symbol.symbol-sm-50.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -1.75px;
  }
  .symbol.symbol-sm-50.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-sm-50.symbol-fixed>img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-sm-50.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-sm-50.symbol-2by3>img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-sm-55>img {
    width: 100%;
    max-width: 55px;
    height: 55px;
  }
  .symbol.symbol-sm-55 .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-sm-55 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-55 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-55.symbol-circle .symbol-badge {
    top: -0.725px;
    right: -0.725px;
  }
  .symbol.symbol-sm-55.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -0.725px;
  }
  .symbol.symbol-sm-55.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-sm-55.symbol-fixed>img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-sm-55.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-sm-55.symbol-2by3>img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-60>img {
    width: 100%;
    max-width: 60px;
    height: 60px;
  }
  .symbol.symbol-sm-60 .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-sm-60 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-60 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-60.symbol-circle .symbol-badge {
    top: 0.3px;
    right: 0.3px;
  }
  .symbol.symbol-sm-60.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 0.3px;
  }
  .symbol.symbol-sm-60.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-sm-60.symbol-fixed>img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-sm-60.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-sm-60.symbol-2by3>img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-sm-65>img {
    width: 100%;
    max-width: 65px;
    height: 65px;
  }
  .symbol.symbol-sm-65 .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-sm-65 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-65 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-65.symbol-circle .symbol-badge {
    top: 1.325px;
    right: 1.325px;
  }
  .symbol.symbol-sm-65.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 1.325px;
  }
  .symbol.symbol-sm-65.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-sm-65.symbol-fixed>img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-sm-65.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-sm-65.symbol-2by3>img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-70>img {
    width: 100%;
    max-width: 70px;
    height: 70px;
  }
  .symbol.symbol-sm-70 .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-sm-70 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-70 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-70.symbol-circle .symbol-badge {
    top: 2.35px;
    right: 2.35px;
  }
  .symbol.symbol-sm-70.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 2.35px;
  }
  .symbol.symbol-sm-70.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-sm-70.symbol-fixed>img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-sm-70.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-sm-70.symbol-2by3>img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-sm-75>img {
    width: 100%;
    max-width: 75px;
    height: 75px;
  }
  .symbol.symbol-sm-75 .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-sm-75 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-75 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-75.symbol-circle .symbol-badge {
    top: 3.375px;
    right: 3.375px;
  }
  .symbol.symbol-sm-75.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 3.375px;
  }
  .symbol.symbol-sm-75.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-sm-75.symbol-fixed>img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-sm-75.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-sm-75.symbol-2by3>img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-80>img {
    width: 100%;
    max-width: 80px;
    height: 80px;
  }
  .symbol.symbol-sm-80 .symbol-label {
    width: 80px;
    height: 80px;
  }
  .symbol.symbol-sm-80 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-80 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-80.symbol-circle .symbol-badge {
    top: 4.4px;
    right: 4.4px;
  }
  .symbol.symbol-sm-80.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 4.4px;
  }
  .symbol.symbol-sm-80.symbol-fixed .symbol-label {
    width: 80px;
    height: 80px;
  }
  .symbol.symbol-sm-80.symbol-fixed>img {
    width: 80px;
    height: 80px;
    max-width: auto;
  }
  .symbol.symbol-sm-80.symbol-2by3 .symbol-label {
    height: 80px;
    width: 120px;
  }
  .symbol.symbol-sm-80.symbol-2by3>img {
    height: 80px;
    width: 120px;
    max-width: auto;
  }
  .symbol.symbol-sm-85>img {
    width: 100%;
    max-width: 85px;
    height: 85px;
  }
  .symbol.symbol-sm-85 .symbol-label {
    width: 85px;
    height: 85px;
  }
  .symbol.symbol-sm-85 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-85 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-85.symbol-circle .symbol-badge {
    top: 5.425px;
    right: 5.425px;
  }
  .symbol.symbol-sm-85.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 5.425px;
  }
  .symbol.symbol-sm-85.symbol-fixed .symbol-label {
    width: 85px;
    height: 85px;
  }
  .symbol.symbol-sm-85.symbol-fixed>img {
    width: 85px;
    height: 85px;
    max-width: auto;
  }
  .symbol.symbol-sm-85.symbol-2by3 .symbol-label {
    height: 85px;
    width: 127.5px;
  }
  .symbol.symbol-sm-85.symbol-2by3>img {
    height: 85px;
    width: 127.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-90>img {
    width: 100%;
    max-width: 90px;
    height: 90px;
  }
  .symbol.symbol-sm-90 .symbol-label {
    width: 90px;
    height: 90px;
  }
  .symbol.symbol-sm-90 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-90 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-90.symbol-circle .symbol-badge {
    top: 6.45px;
    right: 6.45px;
  }
  .symbol.symbol-sm-90.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 6.45px;
  }
  .symbol.symbol-sm-90.symbol-fixed .symbol-label {
    width: 90px;
    height: 90px;
  }
  .symbol.symbol-sm-90.symbol-fixed>img {
    width: 90px;
    height: 90px;
    max-width: auto;
  }
  .symbol.symbol-sm-90.symbol-2by3 .symbol-label {
    height: 90px;
    width: 135px;
  }
  .symbol.symbol-sm-90.symbol-2by3>img {
    height: 90px;
    width: 135px;
    max-width: auto;
  }
  .symbol.symbol-sm-95>img {
    width: 100%;
    max-width: 95px;
    height: 95px;
  }
  .symbol.symbol-sm-95 .symbol-label {
    width: 95px;
    height: 95px;
  }
  .symbol.symbol-sm-95 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-95 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-95.symbol-circle .symbol-badge {
    top: 7.475px;
    right: 7.475px;
  }
  .symbol.symbol-sm-95.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 7.475px;
  }
  .symbol.symbol-sm-95.symbol-fixed .symbol-label {
    width: 95px;
    height: 95px;
  }
  .symbol.symbol-sm-95.symbol-fixed>img {
    width: 95px;
    height: 95px;
    max-width: auto;
  }
  .symbol.symbol-sm-95.symbol-2by3 .symbol-label {
    height: 95px;
    width: 142.5px;
  }
  .symbol.symbol-sm-95.symbol-2by3>img {
    height: 95px;
    width: 142.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-100>img {
    width: 100%;
    max-width: 100px;
    height: 100px;
  }
  .symbol.symbol-sm-100 .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-sm-100 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-100 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-100.symbol-circle .symbol-badge {
    top: 8.5px;
    right: 8.5px;
  }
  .symbol.symbol-sm-100.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 8.5px;
  }
  .symbol.symbol-sm-100.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-sm-100.symbol-fixed>img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-sm-100.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-sm-100.symbol-2by3>img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-sm-120>img {
    width: 100%;
    max-width: 120px;
    height: 120px;
  }
  .symbol.symbol-sm-120 .symbol-label {
    width: 120px;
    height: 120px;
  }
  .symbol.symbol-sm-120 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-120 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-120.symbol-circle .symbol-badge {
    top: 12.6px;
    right: 12.6px;
  }
  .symbol.symbol-sm-120.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 12.6px;
  }
  .symbol.symbol-sm-120.symbol-fixed .symbol-label {
    width: 120px;
    height: 120px;
  }
  .symbol.symbol-sm-120.symbol-fixed>img {
    width: 120px;
    height: 120px;
    max-width: auto;
  }
  .symbol.symbol-sm-120.symbol-2by3 .symbol-label {
    height: 120px;
    width: 180px;
  }
  .symbol.symbol-sm-120.symbol-2by3>img {
    height: 120px;
    width: 180px;
    max-width: auto;
  }
  .symbol.symbol-sm-130>img {
    width: 100%;
    max-width: 130px;
    height: 130px;
  }
  .symbol.symbol-sm-130 .symbol-label {
    width: 130px;
    height: 130px;
  }
  .symbol.symbol-sm-130 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-130 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-130.symbol-circle .symbol-badge {
    top: 14.65px;
    right: 14.65px;
  }
  .symbol.symbol-sm-130.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 14.65px;
  }
  .symbol.symbol-sm-130.symbol-fixed .symbol-label {
    width: 130px;
    height: 130px;
  }
  .symbol.symbol-sm-130.symbol-fixed>img {
    width: 130px;
    height: 130px;
    max-width: auto;
  }
  .symbol.symbol-sm-130.symbol-2by3 .symbol-label {
    height: 130px;
    width: 195px;
  }
  .symbol.symbol-sm-130.symbol-2by3>img {
    height: 130px;
    width: 195px;
    max-width: auto;
  }
  .symbol.symbol-sm-140>img {
    width: 100%;
    max-width: 140px;
    height: 140px;
  }
  .symbol.symbol-sm-140 .symbol-label {
    width: 140px;
    height: 140px;
  }
  .symbol.symbol-sm-140 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-140 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-140.symbol-circle .symbol-badge {
    top: 16.7px;
    right: 16.7px;
  }
  .symbol.symbol-sm-140.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 16.7px;
  }
  .symbol.symbol-sm-140.symbol-fixed .symbol-label {
    width: 140px;
    height: 140px;
  }
  .symbol.symbol-sm-140.symbol-fixed>img {
    width: 140px;
    height: 140px;
    max-width: auto;
  }
  .symbol.symbol-sm-140.symbol-2by3 .symbol-label {
    height: 140px;
    width: 210px;
  }
  .symbol.symbol-sm-140.symbol-2by3>img {
    height: 140px;
    width: 210px;
    max-width: auto;
  }
  .symbol.symbol-sm-150>img {
    width: 100%;
    max-width: 150px;
    height: 150px;
  }
  .symbol.symbol-sm-150 .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-sm-150 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-sm-150 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-sm-150.symbol-circle .symbol-badge {
    top: 18.75px;
    right: 18.75px;
  }
  .symbol.symbol-sm-150.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 18.75px;
  }
  .symbol.symbol-sm-150.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-sm-150.symbol-fixed>img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-sm-150.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-sm-150.symbol-2by3>img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
}

@media (min-width: 768px) {
  .symbol.symbol-md-20>img {
    width: 100%;
    max-width: 20px;
    height: 20px;
  }
  .symbol.symbol-md-20 .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-md-20 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-20 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-20.symbol-circle .symbol-badge {
    top: -7.9px;
    right: -7.9px;
  }
  .symbol.symbol-md-20.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -7.9px;
  }
  .symbol.symbol-md-20.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-md-20.symbol-fixed>img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-md-20.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-md-20.symbol-2by3>img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-md-25>img {
    width: 100%;
    max-width: 25px;
    height: 25px;
  }
  .symbol.symbol-md-25 .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-md-25 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-25 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-25.symbol-circle .symbol-badge {
    top: -6.875px;
    right: -6.875px;
  }
  .symbol.symbol-md-25.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6.875px;
  }
  .symbol.symbol-md-25.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-md-25.symbol-fixed>img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-md-25.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-md-25.symbol-2by3>img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-md-30>img {
    width: 100%;
    max-width: 30px;
    height: 30px;
  }
  .symbol.symbol-md-30 .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-md-30 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-30 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-30.symbol-circle .symbol-badge {
    top: -5.85px;
    right: -5.85px;
  }
  .symbol.symbol-md-30.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -5.85px;
  }
  .symbol.symbol-md-30.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-md-30.symbol-fixed>img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-md-30.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-md-30.symbol-2by3>img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-md-35>img {
    width: 100%;
    max-width: 35px;
    height: 35px;
  }
  .symbol.symbol-md-35 .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-md-35 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-35 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-35.symbol-circle .symbol-badge {
    top: -4.825px;
    right: -4.825px;
  }
  .symbol.symbol-md-35.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -4.825px;
  }
  .symbol.symbol-md-35.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-md-35.symbol-fixed>img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-md-35.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-md-35.symbol-2by3>img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-md-40>img {
    width: 100%;
    max-width: 40px;
    height: 40px;
  }
  .symbol.symbol-md-40 .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-md-40 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-40 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-40.symbol-circle .symbol-badge {
    top: -3.8px;
    right: -3.8px;
  }
  .symbol.symbol-md-40.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -3.8px;
  }
  .symbol.symbol-md-40.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-md-40.symbol-fixed>img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-md-40.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-md-40.symbol-2by3>img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-md-45>img {
    width: 100%;
    max-width: 45px;
    height: 45px;
  }
  .symbol.symbol-md-45 .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-md-45 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-45 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-45.symbol-circle .symbol-badge {
    top: -2.775px;
    right: -2.775px;
  }
  .symbol.symbol-md-45.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -2.775px;
  }
  .symbol.symbol-md-45.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-md-45.symbol-fixed>img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-md-45.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-md-45.symbol-2by3>img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
  .symbol .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-circle .symbol-badge {
    top: -1.75px;
    right: -1.75px;
  }
  .symbol.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -1.75px;
  }
  .symbol.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-fixed>img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-2by3>img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-md-50>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
  .symbol.symbol-md-50 .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-md-50 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-50 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-50.symbol-circle .symbol-badge {
    top: -1.75px;
    right: -1.75px;
  }
  .symbol.symbol-md-50.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -1.75px;
  }
  .symbol.symbol-md-50.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-md-50.symbol-fixed>img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-md-50.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-md-50.symbol-2by3>img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-md-55>img {
    width: 100%;
    max-width: 55px;
    height: 55px;
  }
  .symbol.symbol-md-55 .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-md-55 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-55 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-55.symbol-circle .symbol-badge {
    top: -0.725px;
    right: -0.725px;
  }
  .symbol.symbol-md-55.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -0.725px;
  }
  .symbol.symbol-md-55.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-md-55.symbol-fixed>img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-md-55.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-md-55.symbol-2by3>img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-md-60>img {
    width: 100%;
    max-width: 60px;
    height: 60px;
  }
  .symbol.symbol-md-60 .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-md-60 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-60 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-60.symbol-circle .symbol-badge {
    top: 0.3px;
    right: 0.3px;
  }
  .symbol.symbol-md-60.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 0.3px;
  }
  .symbol.symbol-md-60.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-md-60.symbol-fixed>img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-md-60.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-md-60.symbol-2by3>img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-md-65>img {
    width: 100%;
    max-width: 65px;
    height: 65px;
  }
  .symbol.symbol-md-65 .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-md-65 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-65 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-65.symbol-circle .symbol-badge {
    top: 1.325px;
    right: 1.325px;
  }
  .symbol.symbol-md-65.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 1.325px;
  }
  .symbol.symbol-md-65.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-md-65.symbol-fixed>img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-md-65.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-md-65.symbol-2by3>img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-md-70>img {
    width: 100%;
    max-width: 70px;
    height: 70px;
  }
  .symbol.symbol-md-70 .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-md-70 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-70 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-70.symbol-circle .symbol-badge {
    top: 2.35px;
    right: 2.35px;
  }
  .symbol.symbol-md-70.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 2.35px;
  }
  .symbol.symbol-md-70.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-md-70.symbol-fixed>img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-md-70.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-md-70.symbol-2by3>img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-md-75>img {
    width: 100%;
    max-width: 75px;
    height: 75px;
  }
  .symbol.symbol-md-75 .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-md-75 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-75 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-75.symbol-circle .symbol-badge {
    top: 3.375px;
    right: 3.375px;
  }
  .symbol.symbol-md-75.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 3.375px;
  }
  .symbol.symbol-md-75.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-md-75.symbol-fixed>img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-md-75.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-md-75.symbol-2by3>img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-md-80>img {
    width: 100%;
    max-width: 80px;
    height: 80px;
  }
  .symbol.symbol-md-80 .symbol-label {
    width: 80px;
    height: 80px;
  }
  .symbol.symbol-md-80 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-80 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-80.symbol-circle .symbol-badge {
    top: 4.4px;
    right: 4.4px;
  }
  .symbol.symbol-md-80.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 4.4px;
  }
  .symbol.symbol-md-80.symbol-fixed .symbol-label {
    width: 80px;
    height: 80px;
  }
  .symbol.symbol-md-80.symbol-fixed>img {
    width: 80px;
    height: 80px;
    max-width: auto;
  }
  .symbol.symbol-md-80.symbol-2by3 .symbol-label {
    height: 80px;
    width: 120px;
  }
  .symbol.symbol-md-80.symbol-2by3>img {
    height: 80px;
    width: 120px;
    max-width: auto;
  }
  .symbol.symbol-md-85>img {
    width: 100%;
    max-width: 85px;
    height: 85px;
  }
  .symbol.symbol-md-85 .symbol-label {
    width: 85px;
    height: 85px;
  }
  .symbol.symbol-md-85 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-85 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-85.symbol-circle .symbol-badge {
    top: 5.425px;
    right: 5.425px;
  }
  .symbol.symbol-md-85.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 5.425px;
  }
  .symbol.symbol-md-85.symbol-fixed .symbol-label {
    width: 85px;
    height: 85px;
  }
  .symbol.symbol-md-85.symbol-fixed>img {
    width: 85px;
    height: 85px;
    max-width: auto;
  }
  .symbol.symbol-md-85.symbol-2by3 .symbol-label {
    height: 85px;
    width: 127.5px;
  }
  .symbol.symbol-md-85.symbol-2by3>img {
    height: 85px;
    width: 127.5px;
    max-width: auto;
  }
  .symbol.symbol-md-90>img {
    width: 100%;
    max-width: 90px;
    height: 90px;
  }
  .symbol.symbol-md-90 .symbol-label {
    width: 90px;
    height: 90px;
  }
  .symbol.symbol-md-90 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-90 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-90.symbol-circle .symbol-badge {
    top: 6.45px;
    right: 6.45px;
  }
  .symbol.symbol-md-90.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 6.45px;
  }
  .symbol.symbol-md-90.symbol-fixed .symbol-label {
    width: 90px;
    height: 90px;
  }
  .symbol.symbol-md-90.symbol-fixed>img {
    width: 90px;
    height: 90px;
    max-width: auto;
  }
  .symbol.symbol-md-90.symbol-2by3 .symbol-label {
    height: 90px;
    width: 135px;
  }
  .symbol.symbol-md-90.symbol-2by3>img {
    height: 90px;
    width: 135px;
    max-width: auto;
  }
  .symbol.symbol-md-95>img {
    width: 100%;
    max-width: 95px;
    height: 95px;
  }
  .symbol.symbol-md-95 .symbol-label {
    width: 95px;
    height: 95px;
  }
  .symbol.symbol-md-95 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-95 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-95.symbol-circle .symbol-badge {
    top: 7.475px;
    right: 7.475px;
  }
  .symbol.symbol-md-95.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 7.475px;
  }
  .symbol.symbol-md-95.symbol-fixed .symbol-label {
    width: 95px;
    height: 95px;
  }
  .symbol.symbol-md-95.symbol-fixed>img {
    width: 95px;
    height: 95px;
    max-width: auto;
  }
  .symbol.symbol-md-95.symbol-2by3 .symbol-label {
    height: 95px;
    width: 142.5px;
  }
  .symbol.symbol-md-95.symbol-2by3>img {
    height: 95px;
    width: 142.5px;
    max-width: auto;
  }
  .symbol.symbol-md-100>img {
    width: 100%;
    max-width: 100px;
    height: 100px;
  }
  .symbol.symbol-md-100 .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-md-100 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-100 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-100.symbol-circle .symbol-badge {
    top: 8.5px;
    right: 8.5px;
  }
  .symbol.symbol-md-100.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 8.5px;
  }
  .symbol.symbol-md-100.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-md-100.symbol-fixed>img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-md-100.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-md-100.symbol-2by3>img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-md-120>img {
    width: 100%;
    max-width: 120px;
    height: 120px;
  }
  .symbol.symbol-md-120 .symbol-label {
    width: 120px;
    height: 120px;
  }
  .symbol.symbol-md-120 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-120 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-120.symbol-circle .symbol-badge {
    top: 12.6px;
    right: 12.6px;
  }
  .symbol.symbol-md-120.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 12.6px;
  }
  .symbol.symbol-md-120.symbol-fixed .symbol-label {
    width: 120px;
    height: 120px;
  }
  .symbol.symbol-md-120.symbol-fixed>img {
    width: 120px;
    height: 120px;
    max-width: auto;
  }
  .symbol.symbol-md-120.symbol-2by3 .symbol-label {
    height: 120px;
    width: 180px;
  }
  .symbol.symbol-md-120.symbol-2by3>img {
    height: 120px;
    width: 180px;
    max-width: auto;
  }
  .symbol.symbol-md-130>img {
    width: 100%;
    max-width: 130px;
    height: 130px;
  }
  .symbol.symbol-md-130 .symbol-label {
    width: 130px;
    height: 130px;
  }
  .symbol.symbol-md-130 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-130 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-130.symbol-circle .symbol-badge {
    top: 14.65px;
    right: 14.65px;
  }
  .symbol.symbol-md-130.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 14.65px;
  }
  .symbol.symbol-md-130.symbol-fixed .symbol-label {
    width: 130px;
    height: 130px;
  }
  .symbol.symbol-md-130.symbol-fixed>img {
    width: 130px;
    height: 130px;
    max-width: auto;
  }
  .symbol.symbol-md-130.symbol-2by3 .symbol-label {
    height: 130px;
    width: 195px;
  }
  .symbol.symbol-md-130.symbol-2by3>img {
    height: 130px;
    width: 195px;
    max-width: auto;
  }
  .symbol.symbol-md-140>img {
    width: 100%;
    max-width: 140px;
    height: 140px;
  }
  .symbol.symbol-md-140 .symbol-label {
    width: 140px;
    height: 140px;
  }
  .symbol.symbol-md-140 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-140 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-140.symbol-circle .symbol-badge {
    top: 16.7px;
    right: 16.7px;
  }
  .symbol.symbol-md-140.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 16.7px;
  }
  .symbol.symbol-md-140.symbol-fixed .symbol-label {
    width: 140px;
    height: 140px;
  }
  .symbol.symbol-md-140.symbol-fixed>img {
    width: 140px;
    height: 140px;
    max-width: auto;
  }
  .symbol.symbol-md-140.symbol-2by3 .symbol-label {
    height: 140px;
    width: 210px;
  }
  .symbol.symbol-md-140.symbol-2by3>img {
    height: 140px;
    width: 210px;
    max-width: auto;
  }
  .symbol.symbol-md-150>img {
    width: 100%;
    max-width: 150px;
    height: 150px;
  }
  .symbol.symbol-md-150 .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-md-150 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-md-150 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-md-150.symbol-circle .symbol-badge {
    top: 18.75px;
    right: 18.75px;
  }
  .symbol.symbol-md-150.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 18.75px;
  }
  .symbol.symbol-md-150.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-md-150.symbol-fixed>img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-md-150.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-md-150.symbol-2by3>img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
}

@media (min-width: 992px) {
  .symbol.symbol-lg-20>img {
    width: 100%;
    max-width: 20px;
    height: 20px;
  }
  .symbol.symbol-lg-20 .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-lg-20 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-20 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-20.symbol-circle .symbol-badge {
    top: -7.9px;
    right: -7.9px;
  }
  .symbol.symbol-lg-20.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -7.9px;
  }
  .symbol.symbol-lg-20.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-lg-20.symbol-fixed>img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-lg-20.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-lg-20.symbol-2by3>img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-lg-25>img {
    width: 100%;
    max-width: 25px;
    height: 25px;
  }
  .symbol.symbol-lg-25 .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-lg-25 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-25 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-25.symbol-circle .symbol-badge {
    top: -6.875px;
    right: -6.875px;
  }
  .symbol.symbol-lg-25.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6.875px;
  }
  .symbol.symbol-lg-25.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-lg-25.symbol-fixed>img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-lg-25.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-lg-25.symbol-2by3>img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-30>img {
    width: 100%;
    max-width: 30px;
    height: 30px;
  }
  .symbol.symbol-lg-30 .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-lg-30 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-30 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-30.symbol-circle .symbol-badge {
    top: -5.85px;
    right: -5.85px;
  }
  .symbol.symbol-lg-30.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -5.85px;
  }
  .symbol.symbol-lg-30.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-lg-30.symbol-fixed>img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-lg-30.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-lg-30.symbol-2by3>img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-lg-35>img {
    width: 100%;
    max-width: 35px;
    height: 35px;
  }
  .symbol.symbol-lg-35 .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-lg-35 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-35 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-35.symbol-circle .symbol-badge {
    top: -4.825px;
    right: -4.825px;
  }
  .symbol.symbol-lg-35.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -4.825px;
  }
  .symbol.symbol-lg-35.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-lg-35.symbol-fixed>img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-lg-35.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-lg-35.symbol-2by3>img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-40>img {
    width: 100%;
    max-width: 40px;
    height: 40px;
  }
  .symbol.symbol-lg-40 .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-lg-40 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-40 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-40.symbol-circle .symbol-badge {
    top: -3.8px;
    right: -3.8px;
  }
  .symbol.symbol-lg-40.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -3.8px;
  }
  .symbol.symbol-lg-40.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-lg-40.symbol-fixed>img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-lg-40.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-lg-40.symbol-2by3>img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-lg-45>img {
    width: 100%;
    max-width: 45px;
    height: 45px;
  }
  .symbol.symbol-lg-45 .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-lg-45 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-45 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-45.symbol-circle .symbol-badge {
    top: -2.775px;
    right: -2.775px;
  }
  .symbol.symbol-lg-45.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -2.775px;
  }
  .symbol.symbol-lg-45.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-lg-45.symbol-fixed>img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-lg-45.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-lg-45.symbol-2by3>img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
  .symbol .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-circle .symbol-badge {
    top: -1.75px;
    right: -1.75px;
  }
  .symbol.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -1.75px;
  }
  .symbol.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-fixed>img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-2by3>img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-lg-50>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
  .symbol.symbol-lg-50 .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-lg-50 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-50 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-50.symbol-circle .symbol-badge {
    top: -1.75px;
    right: -1.75px;
  }
  .symbol.symbol-lg-50.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -1.75px;
  }
  .symbol.symbol-lg-50.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-lg-50.symbol-fixed>img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-lg-50.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-lg-50.symbol-2by3>img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-lg-55>img {
    width: 100%;
    max-width: 55px;
    height: 55px;
  }
  .symbol.symbol-lg-55 .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-lg-55 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-55 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-55.symbol-circle .symbol-badge {
    top: -0.725px;
    right: -0.725px;
  }
  .symbol.symbol-lg-55.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -0.725px;
  }
  .symbol.symbol-lg-55.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-lg-55.symbol-fixed>img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-lg-55.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-lg-55.symbol-2by3>img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-60>img {
    width: 100%;
    max-width: 60px;
    height: 60px;
  }
  .symbol.symbol-lg-60 .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-lg-60 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-60 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-60.symbol-circle .symbol-badge {
    top: 0.3px;
    right: 0.3px;
  }
  .symbol.symbol-lg-60.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 0.3px;
  }
  .symbol.symbol-lg-60.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-lg-60.symbol-fixed>img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-lg-60.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-lg-60.symbol-2by3>img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-lg-65>img {
    width: 100%;
    max-width: 65px;
    height: 65px;
  }
  .symbol.symbol-lg-65 .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-lg-65 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-65 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-65.symbol-circle .symbol-badge {
    top: 1.325px;
    right: 1.325px;
  }
  .symbol.symbol-lg-65.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 1.325px;
  }
  .symbol.symbol-lg-65.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-lg-65.symbol-fixed>img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-lg-65.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-lg-65.symbol-2by3>img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-70>img {
    width: 100%;
    max-width: 70px;
    height: 70px;
  }
  .symbol.symbol-lg-70 .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-lg-70 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-70 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-70.symbol-circle .symbol-badge {
    top: 2.35px;
    right: 2.35px;
  }
  .symbol.symbol-lg-70.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 2.35px;
  }
  .symbol.symbol-lg-70.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-lg-70.symbol-fixed>img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-lg-70.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-lg-70.symbol-2by3>img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-lg-75>img {
    width: 100%;
    max-width: 75px;
    height: 75px;
  }
  .symbol.symbol-lg-75 .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-lg-75 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-75 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-75.symbol-circle .symbol-badge {
    top: 3.375px;
    right: 3.375px;
  }
  .symbol.symbol-lg-75.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 3.375px;
  }
  .symbol.symbol-lg-75.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-lg-75.symbol-fixed>img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-lg-75.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-lg-75.symbol-2by3>img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-80>img {
    width: 100%;
    max-width: 80px;
    height: 80px;
  }
  .symbol.symbol-lg-80 .symbol-label {
    width: 80px;
    height: 80px;
  }
  .symbol.symbol-lg-80 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-80 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-80.symbol-circle .symbol-badge {
    top: 4.4px;
    right: 4.4px;
  }
  .symbol.symbol-lg-80.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 4.4px;
  }
  .symbol.symbol-lg-80.symbol-fixed .symbol-label {
    width: 80px;
    height: 80px;
  }
  .symbol.symbol-lg-80.symbol-fixed>img {
    width: 80px;
    height: 80px;
    max-width: auto;
  }
  .symbol.symbol-lg-80.symbol-2by3 .symbol-label {
    height: 80px;
    width: 120px;
  }
  .symbol.symbol-lg-80.symbol-2by3>img {
    height: 80px;
    width: 120px;
    max-width: auto;
  }
  .symbol.symbol-lg-85>img {
    width: 100%;
    max-width: 85px;
    height: 85px;
  }
  .symbol.symbol-lg-85 .symbol-label {
    width: 85px;
    height: 85px;
  }
  .symbol.symbol-lg-85 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-85 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-85.symbol-circle .symbol-badge {
    top: 5.425px;
    right: 5.425px;
  }
  .symbol.symbol-lg-85.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 5.425px;
  }
  .symbol.symbol-lg-85.symbol-fixed .symbol-label {
    width: 85px;
    height: 85px;
  }
  .symbol.symbol-lg-85.symbol-fixed>img {
    width: 85px;
    height: 85px;
    max-width: auto;
  }
  .symbol.symbol-lg-85.symbol-2by3 .symbol-label {
    height: 85px;
    width: 127.5px;
  }
  .symbol.symbol-lg-85.symbol-2by3>img {
    height: 85px;
    width: 127.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-90>img {
    width: 100%;
    max-width: 90px;
    height: 90px;
  }
  .symbol.symbol-lg-90 .symbol-label {
    width: 90px;
    height: 90px;
  }
  .symbol.symbol-lg-90 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-90 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-90.symbol-circle .symbol-badge {
    top: 6.45px;
    right: 6.45px;
  }
  .symbol.symbol-lg-90.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 6.45px;
  }
  .symbol.symbol-lg-90.symbol-fixed .symbol-label {
    width: 90px;
    height: 90px;
  }
  .symbol.symbol-lg-90.symbol-fixed>img {
    width: 90px;
    height: 90px;
    max-width: auto;
  }
  .symbol.symbol-lg-90.symbol-2by3 .symbol-label {
    height: 90px;
    width: 135px;
  }
  .symbol.symbol-lg-90.symbol-2by3>img {
    height: 90px;
    width: 135px;
    max-width: auto;
  }
  .symbol.symbol-lg-95>img {
    width: 100%;
    max-width: 95px;
    height: 95px;
  }
  .symbol.symbol-lg-95 .symbol-label {
    width: 95px;
    height: 95px;
  }
  .symbol.symbol-lg-95 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-95 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-95.symbol-circle .symbol-badge {
    top: 7.475px;
    right: 7.475px;
  }
  .symbol.symbol-lg-95.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 7.475px;
  }
  .symbol.symbol-lg-95.symbol-fixed .symbol-label {
    width: 95px;
    height: 95px;
  }
  .symbol.symbol-lg-95.symbol-fixed>img {
    width: 95px;
    height: 95px;
    max-width: auto;
  }
  .symbol.symbol-lg-95.symbol-2by3 .symbol-label {
    height: 95px;
    width: 142.5px;
  }
  .symbol.symbol-lg-95.symbol-2by3>img {
    height: 95px;
    width: 142.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-100>img {
    width: 100%;
    max-width: 100px;
    height: 100px;
  }
  .symbol.symbol-lg-100 .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-lg-100 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-100 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-100.symbol-circle .symbol-badge {
    top: 8.5px;
    right: 8.5px;
  }
  .symbol.symbol-lg-100.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 8.5px;
  }
  .symbol.symbol-lg-100.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-lg-100.symbol-fixed>img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-lg-100.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-lg-100.symbol-2by3>img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-lg-120>img {
    width: 100%;
    max-width: 120px;
    height: 120px;
  }
  .symbol.symbol-lg-120 .symbol-label {
    width: 120px;
    height: 120px;
  }
  .symbol.symbol-lg-120 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-120 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-120.symbol-circle .symbol-badge {
    top: 12.6px;
    right: 12.6px;
  }
  .symbol.symbol-lg-120.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 12.6px;
  }
  .symbol.symbol-lg-120.symbol-fixed .symbol-label {
    width: 120px;
    height: 120px;
  }
  .symbol.symbol-lg-120.symbol-fixed>img {
    width: 120px;
    height: 120px;
    max-width: auto;
  }
  .symbol.symbol-lg-120.symbol-2by3 .symbol-label {
    height: 120px;
    width: 180px;
  }
  .symbol.symbol-lg-120.symbol-2by3>img {
    height: 120px;
    width: 180px;
    max-width: auto;
  }
  .symbol.symbol-lg-130>img {
    width: 100%;
    max-width: 130px;
    height: 130px;
  }
  .symbol.symbol-lg-130 .symbol-label {
    width: 130px;
    height: 130px;
  }
  .symbol.symbol-lg-130 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-130 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-130.symbol-circle .symbol-badge {
    top: 14.65px;
    right: 14.65px;
  }
  .symbol.symbol-lg-130.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 14.65px;
  }
  .symbol.symbol-lg-130.symbol-fixed .symbol-label {
    width: 130px;
    height: 130px;
  }
  .symbol.symbol-lg-130.symbol-fixed>img {
    width: 130px;
    height: 130px;
    max-width: auto;
  }
  .symbol.symbol-lg-130.symbol-2by3 .symbol-label {
    height: 130px;
    width: 195px;
  }
  .symbol.symbol-lg-130.symbol-2by3>img {
    height: 130px;
    width: 195px;
    max-width: auto;
  }
  .symbol.symbol-lg-140>img {
    width: 100%;
    max-width: 140px;
    height: 140px;
  }
  .symbol.symbol-lg-140 .symbol-label {
    width: 140px;
    height: 140px;
  }
  .symbol.symbol-lg-140 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-140 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-140.symbol-circle .symbol-badge {
    top: 16.7px;
    right: 16.7px;
  }
  .symbol.symbol-lg-140.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 16.7px;
  }
  .symbol.symbol-lg-140.symbol-fixed .symbol-label {
    width: 140px;
    height: 140px;
  }
  .symbol.symbol-lg-140.symbol-fixed>img {
    width: 140px;
    height: 140px;
    max-width: auto;
  }
  .symbol.symbol-lg-140.symbol-2by3 .symbol-label {
    height: 140px;
    width: 210px;
  }
  .symbol.symbol-lg-140.symbol-2by3>img {
    height: 140px;
    width: 210px;
    max-width: auto;
  }
  .symbol.symbol-lg-150>img {
    width: 100%;
    max-width: 150px;
    height: 150px;
  }
  .symbol.symbol-lg-150 .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-lg-150 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-lg-150 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-lg-150.symbol-circle .symbol-badge {
    top: 18.75px;
    right: 18.75px;
  }
  .symbol.symbol-lg-150.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 18.75px;
  }
  .symbol.symbol-lg-150.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-lg-150.symbol-fixed>img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-lg-150.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-lg-150.symbol-2by3>img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
}

@media (min-width: 1200px) {
  .symbol.symbol-xl-20>img {
    width: 100%;
    max-width: 20px;
    height: 20px;
  }
  .symbol.symbol-xl-20 .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xl-20 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-20 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-20.symbol-circle .symbol-badge {
    top: -7.9px;
    right: -7.9px;
  }
  .symbol.symbol-xl-20.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -7.9px;
  }
  .symbol.symbol-xl-20.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xl-20.symbol-fixed>img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-xl-20.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-xl-20.symbol-2by3>img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-xl-25>img {
    width: 100%;
    max-width: 25px;
    height: 25px;
  }
  .symbol.symbol-xl-25 .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xl-25 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-25 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-25.symbol-circle .symbol-badge {
    top: -6.875px;
    right: -6.875px;
  }
  .symbol.symbol-xl-25.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6.875px;
  }
  .symbol.symbol-xl-25.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xl-25.symbol-fixed>img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-xl-25.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-xl-25.symbol-2by3>img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-30>img {
    width: 100%;
    max-width: 30px;
    height: 30px;
  }
  .symbol.symbol-xl-30 .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xl-30 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-30 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-30.symbol-circle .symbol-badge {
    top: -5.85px;
    right: -5.85px;
  }
  .symbol.symbol-xl-30.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -5.85px;
  }
  .symbol.symbol-xl-30.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xl-30.symbol-fixed>img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-xl-30.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-xl-30.symbol-2by3>img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-xl-35>img {
    width: 100%;
    max-width: 35px;
    height: 35px;
  }
  .symbol.symbol-xl-35 .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xl-35 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-35 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-35.symbol-circle .symbol-badge {
    top: -4.825px;
    right: -4.825px;
  }
  .symbol.symbol-xl-35.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -4.825px;
  }
  .symbol.symbol-xl-35.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xl-35.symbol-fixed>img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-xl-35.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-xl-35.symbol-2by3>img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-40>img {
    width: 100%;
    max-width: 40px;
    height: 40px;
  }
  .symbol.symbol-xl-40 .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xl-40 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-40 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-40.symbol-circle .symbol-badge {
    top: -3.8px;
    right: -3.8px;
  }
  .symbol.symbol-xl-40.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -3.8px;
  }
  .symbol.symbol-xl-40.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xl-40.symbol-fixed>img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-xl-40.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-xl-40.symbol-2by3>img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-xl-45>img {
    width: 100%;
    max-width: 45px;
    height: 45px;
  }
  .symbol.symbol-xl-45 .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xl-45 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-45 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-45.symbol-circle .symbol-badge {
    top: -2.775px;
    right: -2.775px;
  }
  .symbol.symbol-xl-45.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -2.775px;
  }
  .symbol.symbol-xl-45.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xl-45.symbol-fixed>img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-xl-45.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-xl-45.symbol-2by3>img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
  .symbol .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-circle .symbol-badge {
    top: -1.75px;
    right: -1.75px;
  }
  .symbol.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -1.75px;
  }
  .symbol.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-fixed>img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-2by3>img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-xl-50>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
  .symbol.symbol-xl-50 .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xl-50 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-50 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-50.symbol-circle .symbol-badge {
    top: -1.75px;
    right: -1.75px;
  }
  .symbol.symbol-xl-50.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -1.75px;
  }
  .symbol.symbol-xl-50.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xl-50.symbol-fixed>img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-xl-50.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-xl-50.symbol-2by3>img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-xl-55>img {
    width: 100%;
    max-width: 55px;
    height: 55px;
  }
  .symbol.symbol-xl-55 .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xl-55 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-55 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-55.symbol-circle .symbol-badge {
    top: -0.725px;
    right: -0.725px;
  }
  .symbol.symbol-xl-55.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -0.725px;
  }
  .symbol.symbol-xl-55.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xl-55.symbol-fixed>img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-xl-55.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-xl-55.symbol-2by3>img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-60>img {
    width: 100%;
    max-width: 60px;
    height: 60px;
  }
  .symbol.symbol-xl-60 .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xl-60 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-60 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-60.symbol-circle .symbol-badge {
    top: 0.3px;
    right: 0.3px;
  }
  .symbol.symbol-xl-60.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 0.3px;
  }
  .symbol.symbol-xl-60.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xl-60.symbol-fixed>img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-xl-60.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-xl-60.symbol-2by3>img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-xl-65>img {
    width: 100%;
    max-width: 65px;
    height: 65px;
  }
  .symbol.symbol-xl-65 .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xl-65 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-65 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-65.symbol-circle .symbol-badge {
    top: 1.325px;
    right: 1.325px;
  }
  .symbol.symbol-xl-65.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 1.325px;
  }
  .symbol.symbol-xl-65.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xl-65.symbol-fixed>img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-xl-65.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-xl-65.symbol-2by3>img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-70>img {
    width: 100%;
    max-width: 70px;
    height: 70px;
  }
  .symbol.symbol-xl-70 .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xl-70 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-70 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-70.symbol-circle .symbol-badge {
    top: 2.35px;
    right: 2.35px;
  }
  .symbol.symbol-xl-70.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 2.35px;
  }
  .symbol.symbol-xl-70.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xl-70.symbol-fixed>img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-xl-70.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-xl-70.symbol-2by3>img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-xl-75>img {
    width: 100%;
    max-width: 75px;
    height: 75px;
  }
  .symbol.symbol-xl-75 .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xl-75 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-75 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-75.symbol-circle .symbol-badge {
    top: 3.375px;
    right: 3.375px;
  }
  .symbol.symbol-xl-75.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 3.375px;
  }
  .symbol.symbol-xl-75.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xl-75.symbol-fixed>img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-xl-75.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-xl-75.symbol-2by3>img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-80>img {
    width: 100%;
    max-width: 80px;
    height: 80px;
  }
  .symbol.symbol-xl-80 .symbol-label {
    width: 80px;
    height: 80px;
  }
  .symbol.symbol-xl-80 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-80 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-80.symbol-circle .symbol-badge {
    top: 4.4px;
    right: 4.4px;
  }
  .symbol.symbol-xl-80.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 4.4px;
  }
  .symbol.symbol-xl-80.symbol-fixed .symbol-label {
    width: 80px;
    height: 80px;
  }
  .symbol.symbol-xl-80.symbol-fixed>img {
    width: 80px;
    height: 80px;
    max-width: auto;
  }
  .symbol.symbol-xl-80.symbol-2by3 .symbol-label {
    height: 80px;
    width: 120px;
  }
  .symbol.symbol-xl-80.symbol-2by3>img {
    height: 80px;
    width: 120px;
    max-width: auto;
  }
  .symbol.symbol-xl-85>img {
    width: 100%;
    max-width: 85px;
    height: 85px;
  }
  .symbol.symbol-xl-85 .symbol-label {
    width: 85px;
    height: 85px;
  }
  .symbol.symbol-xl-85 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-85 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-85.symbol-circle .symbol-badge {
    top: 5.425px;
    right: 5.425px;
  }
  .symbol.symbol-xl-85.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 5.425px;
  }
  .symbol.symbol-xl-85.symbol-fixed .symbol-label {
    width: 85px;
    height: 85px;
  }
  .symbol.symbol-xl-85.symbol-fixed>img {
    width: 85px;
    height: 85px;
    max-width: auto;
  }
  .symbol.symbol-xl-85.symbol-2by3 .symbol-label {
    height: 85px;
    width: 127.5px;
  }
  .symbol.symbol-xl-85.symbol-2by3>img {
    height: 85px;
    width: 127.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-90>img {
    width: 100%;
    max-width: 90px;
    height: 90px;
  }
  .symbol.symbol-xl-90 .symbol-label {
    width: 90px;
    height: 90px;
  }
  .symbol.symbol-xl-90 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-90 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-90.symbol-circle .symbol-badge {
    top: 6.45px;
    right: 6.45px;
  }
  .symbol.symbol-xl-90.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 6.45px;
  }
  .symbol.symbol-xl-90.symbol-fixed .symbol-label {
    width: 90px;
    height: 90px;
  }
  .symbol.symbol-xl-90.symbol-fixed>img {
    width: 90px;
    height: 90px;
    max-width: auto;
  }
  .symbol.symbol-xl-90.symbol-2by3 .symbol-label {
    height: 90px;
    width: 135px;
  }
  .symbol.symbol-xl-90.symbol-2by3>img {
    height: 90px;
    width: 135px;
    max-width: auto;
  }
  .symbol.symbol-xl-95>img {
    width: 100%;
    max-width: 95px;
    height: 95px;
  }
  .symbol.symbol-xl-95 .symbol-label {
    width: 95px;
    height: 95px;
  }
  .symbol.symbol-xl-95 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-95 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-95.symbol-circle .symbol-badge {
    top: 7.475px;
    right: 7.475px;
  }
  .symbol.symbol-xl-95.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 7.475px;
  }
  .symbol.symbol-xl-95.symbol-fixed .symbol-label {
    width: 95px;
    height: 95px;
  }
  .symbol.symbol-xl-95.symbol-fixed>img {
    width: 95px;
    height: 95px;
    max-width: auto;
  }
  .symbol.symbol-xl-95.symbol-2by3 .symbol-label {
    height: 95px;
    width: 142.5px;
  }
  .symbol.symbol-xl-95.symbol-2by3>img {
    height: 95px;
    width: 142.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-100>img {
    width: 100%;
    max-width: 100px;
    height: 100px;
  }
  .symbol.symbol-xl-100 .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xl-100 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-100 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-100.symbol-circle .symbol-badge {
    top: 8.5px;
    right: 8.5px;
  }
  .symbol.symbol-xl-100.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 8.5px;
  }
  .symbol.symbol-xl-100.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xl-100.symbol-fixed>img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-xl-100.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-xl-100.symbol-2by3>img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-xl-120>img {
    width: 100%;
    max-width: 120px;
    height: 120px;
  }
  .symbol.symbol-xl-120 .symbol-label {
    width: 120px;
    height: 120px;
  }
  .symbol.symbol-xl-120 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-120 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-120.symbol-circle .symbol-badge {
    top: 12.6px;
    right: 12.6px;
  }
  .symbol.symbol-xl-120.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 12.6px;
  }
  .symbol.symbol-xl-120.symbol-fixed .symbol-label {
    width: 120px;
    height: 120px;
  }
  .symbol.symbol-xl-120.symbol-fixed>img {
    width: 120px;
    height: 120px;
    max-width: auto;
  }
  .symbol.symbol-xl-120.symbol-2by3 .symbol-label {
    height: 120px;
    width: 180px;
  }
  .symbol.symbol-xl-120.symbol-2by3>img {
    height: 120px;
    width: 180px;
    max-width: auto;
  }
  .symbol.symbol-xl-130>img {
    width: 100%;
    max-width: 130px;
    height: 130px;
  }
  .symbol.symbol-xl-130 .symbol-label {
    width: 130px;
    height: 130px;
  }
  .symbol.symbol-xl-130 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-130 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-130.symbol-circle .symbol-badge {
    top: 14.65px;
    right: 14.65px;
  }
  .symbol.symbol-xl-130.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 14.65px;
  }
  .symbol.symbol-xl-130.symbol-fixed .symbol-label {
    width: 130px;
    height: 130px;
  }
  .symbol.symbol-xl-130.symbol-fixed>img {
    width: 130px;
    height: 130px;
    max-width: auto;
  }
  .symbol.symbol-xl-130.symbol-2by3 .symbol-label {
    height: 130px;
    width: 195px;
  }
  .symbol.symbol-xl-130.symbol-2by3>img {
    height: 130px;
    width: 195px;
    max-width: auto;
  }
  .symbol.symbol-xl-140>img {
    width: 100%;
    max-width: 140px;
    height: 140px;
  }
  .symbol.symbol-xl-140 .symbol-label {
    width: 140px;
    height: 140px;
  }
  .symbol.symbol-xl-140 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-140 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-140.symbol-circle .symbol-badge {
    top: 16.7px;
    right: 16.7px;
  }
  .symbol.symbol-xl-140.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 16.7px;
  }
  .symbol.symbol-xl-140.symbol-fixed .symbol-label {
    width: 140px;
    height: 140px;
  }
  .symbol.symbol-xl-140.symbol-fixed>img {
    width: 140px;
    height: 140px;
    max-width: auto;
  }
  .symbol.symbol-xl-140.symbol-2by3 .symbol-label {
    height: 140px;
    width: 210px;
  }
  .symbol.symbol-xl-140.symbol-2by3>img {
    height: 140px;
    width: 210px;
    max-width: auto;
  }
  .symbol.symbol-xl-150>img {
    width: 100%;
    max-width: 150px;
    height: 150px;
  }
  .symbol.symbol-xl-150 .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xl-150 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xl-150 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xl-150.symbol-circle .symbol-badge {
    top: 18.75px;
    right: 18.75px;
  }
  .symbol.symbol-xl-150.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 18.75px;
  }
  .symbol.symbol-xl-150.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xl-150.symbol-fixed>img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-xl-150.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-xl-150.symbol-2by3>img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
}

@media (min-width: 1400px) {
  .symbol.symbol-xxl-20>img {
    width: 100%;
    max-width: 20px;
    height: 20px;
  }
  .symbol.symbol-xxl-20 .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xxl-20 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-20 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-20.symbol-circle .symbol-badge {
    top: -7.9px;
    right: -7.9px;
  }
  .symbol.symbol-xxl-20.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -7.9px;
  }
  .symbol.symbol-xxl-20.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xxl-20.symbol-fixed>img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-xxl-20.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-xxl-20.symbol-2by3>img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-xxl-25>img {
    width: 100%;
    max-width: 25px;
    height: 25px;
  }
  .symbol.symbol-xxl-25 .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xxl-25 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-25 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-25.symbol-circle .symbol-badge {
    top: -6.875px;
    right: -6.875px;
  }
  .symbol.symbol-xxl-25.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6.875px;
  }
  .symbol.symbol-xxl-25.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xxl-25.symbol-fixed>img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-xxl-25.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-xxl-25.symbol-2by3>img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-30>img {
    width: 100%;
    max-width: 30px;
    height: 30px;
  }
  .symbol.symbol-xxl-30 .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xxl-30 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-30 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-30.symbol-circle .symbol-badge {
    top: -5.85px;
    right: -5.85px;
  }
  .symbol.symbol-xxl-30.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -5.85px;
  }
  .symbol.symbol-xxl-30.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xxl-30.symbol-fixed>img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-xxl-30.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-xxl-30.symbol-2by3>img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-xxl-35>img {
    width: 100%;
    max-width: 35px;
    height: 35px;
  }
  .symbol.symbol-xxl-35 .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xxl-35 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-35 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-35.symbol-circle .symbol-badge {
    top: -4.825px;
    right: -4.825px;
  }
  .symbol.symbol-xxl-35.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -4.825px;
  }
  .symbol.symbol-xxl-35.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xxl-35.symbol-fixed>img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-xxl-35.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-xxl-35.symbol-2by3>img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-40>img {
    width: 100%;
    max-width: 40px;
    height: 40px;
  }
  .symbol.symbol-xxl-40 .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xxl-40 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-40 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-40.symbol-circle .symbol-badge {
    top: -3.8px;
    right: -3.8px;
  }
  .symbol.symbol-xxl-40.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -3.8px;
  }
  .symbol.symbol-xxl-40.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xxl-40.symbol-fixed>img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-xxl-40.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-xxl-40.symbol-2by3>img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-xxl-45>img {
    width: 100%;
    max-width: 45px;
    height: 45px;
  }
  .symbol.symbol-xxl-45 .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xxl-45 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-45 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-45.symbol-circle .symbol-badge {
    top: -2.775px;
    right: -2.775px;
  }
  .symbol.symbol-xxl-45.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -2.775px;
  }
  .symbol.symbol-xxl-45.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xxl-45.symbol-fixed>img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-xxl-45.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-xxl-45.symbol-2by3>img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
  .symbol .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-circle .symbol-badge {
    top: -1.75px;
    right: -1.75px;
  }
  .symbol.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -1.75px;
  }
  .symbol.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-fixed>img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-2by3>img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-xxl-50>img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
  .symbol.symbol-xxl-50 .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xxl-50 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-50 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-50.symbol-circle .symbol-badge {
    top: -1.75px;
    right: -1.75px;
  }
  .symbol.symbol-xxl-50.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -1.75px;
  }
  .symbol.symbol-xxl-50.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xxl-50.symbol-fixed>img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-xxl-50.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-xxl-50.symbol-2by3>img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-xxl-55>img {
    width: 100%;
    max-width: 55px;
    height: 55px;
  }
  .symbol.symbol-xxl-55 .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xxl-55 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-55 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-55.symbol-circle .symbol-badge {
    top: -0.725px;
    right: -0.725px;
  }
  .symbol.symbol-xxl-55.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -0.725px;
  }
  .symbol.symbol-xxl-55.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xxl-55.symbol-fixed>img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-xxl-55.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-xxl-55.symbol-2by3>img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-60>img {
    width: 100%;
    max-width: 60px;
    height: 60px;
  }
  .symbol.symbol-xxl-60 .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xxl-60 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-60 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-60.symbol-circle .symbol-badge {
    top: 0.3px;
    right: 0.3px;
  }
  .symbol.symbol-xxl-60.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 0.3px;
  }
  .symbol.symbol-xxl-60.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xxl-60.symbol-fixed>img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-xxl-60.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-xxl-60.symbol-2by3>img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-xxl-65>img {
    width: 100%;
    max-width: 65px;
    height: 65px;
  }
  .symbol.symbol-xxl-65 .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xxl-65 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-65 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-65.symbol-circle .symbol-badge {
    top: 1.325px;
    right: 1.325px;
  }
  .symbol.symbol-xxl-65.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 1.325px;
  }
  .symbol.symbol-xxl-65.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xxl-65.symbol-fixed>img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-xxl-65.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-xxl-65.symbol-2by3>img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-70>img {
    width: 100%;
    max-width: 70px;
    height: 70px;
  }
  .symbol.symbol-xxl-70 .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xxl-70 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-70 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-70.symbol-circle .symbol-badge {
    top: 2.35px;
    right: 2.35px;
  }
  .symbol.symbol-xxl-70.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 2.35px;
  }
  .symbol.symbol-xxl-70.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xxl-70.symbol-fixed>img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-xxl-70.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-xxl-70.symbol-2by3>img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-xxl-75>img {
    width: 100%;
    max-width: 75px;
    height: 75px;
  }
  .symbol.symbol-xxl-75 .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xxl-75 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-75 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-75.symbol-circle .symbol-badge {
    top: 3.375px;
    right: 3.375px;
  }
  .symbol.symbol-xxl-75.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 3.375px;
  }
  .symbol.symbol-xxl-75.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xxl-75.symbol-fixed>img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-xxl-75.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-xxl-75.symbol-2by3>img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-80>img {
    width: 100%;
    max-width: 80px;
    height: 80px;
  }
  .symbol.symbol-xxl-80 .symbol-label {
    width: 80px;
    height: 80px;
  }
  .symbol.symbol-xxl-80 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-80 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-80.symbol-circle .symbol-badge {
    top: 4.4px;
    right: 4.4px;
  }
  .symbol.symbol-xxl-80.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 4.4px;
  }
  .symbol.symbol-xxl-80.symbol-fixed .symbol-label {
    width: 80px;
    height: 80px;
  }
  .symbol.symbol-xxl-80.symbol-fixed>img {
    width: 80px;
    height: 80px;
    max-width: auto;
  }
  .symbol.symbol-xxl-80.symbol-2by3 .symbol-label {
    height: 80px;
    width: 120px;
  }
  .symbol.symbol-xxl-80.symbol-2by3>img {
    height: 80px;
    width: 120px;
    max-width: auto;
  }
  .symbol.symbol-xxl-85>img {
    width: 100%;
    max-width: 85px;
    height: 85px;
  }
  .symbol.symbol-xxl-85 .symbol-label {
    width: 85px;
    height: 85px;
  }
  .symbol.symbol-xxl-85 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-85 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-85.symbol-circle .symbol-badge {
    top: 5.425px;
    right: 5.425px;
  }
  .symbol.symbol-xxl-85.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 5.425px;
  }
  .symbol.symbol-xxl-85.symbol-fixed .symbol-label {
    width: 85px;
    height: 85px;
  }
  .symbol.symbol-xxl-85.symbol-fixed>img {
    width: 85px;
    height: 85px;
    max-width: auto;
  }
  .symbol.symbol-xxl-85.symbol-2by3 .symbol-label {
    height: 85px;
    width: 127.5px;
  }
  .symbol.symbol-xxl-85.symbol-2by3>img {
    height: 85px;
    width: 127.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-90>img {
    width: 100%;
    max-width: 90px;
    height: 90px;
  }
  .symbol.symbol-xxl-90 .symbol-label {
    width: 90px;
    height: 90px;
  }
  .symbol.symbol-xxl-90 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-90 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-90.symbol-circle .symbol-badge {
    top: 6.45px;
    right: 6.45px;
  }
  .symbol.symbol-xxl-90.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 6.45px;
  }
  .symbol.symbol-xxl-90.symbol-fixed .symbol-label {
    width: 90px;
    height: 90px;
  }
  .symbol.symbol-xxl-90.symbol-fixed>img {
    width: 90px;
    height: 90px;
    max-width: auto;
  }
  .symbol.symbol-xxl-90.symbol-2by3 .symbol-label {
    height: 90px;
    width: 135px;
  }
  .symbol.symbol-xxl-90.symbol-2by3>img {
    height: 90px;
    width: 135px;
    max-width: auto;
  }
  .symbol.symbol-xxl-95>img {
    width: 100%;
    max-width: 95px;
    height: 95px;
  }
  .symbol.symbol-xxl-95 .symbol-label {
    width: 95px;
    height: 95px;
  }
  .symbol.symbol-xxl-95 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-95 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-95.symbol-circle .symbol-badge {
    top: 7.475px;
    right: 7.475px;
  }
  .symbol.symbol-xxl-95.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 7.475px;
  }
  .symbol.symbol-xxl-95.symbol-fixed .symbol-label {
    width: 95px;
    height: 95px;
  }
  .symbol.symbol-xxl-95.symbol-fixed>img {
    width: 95px;
    height: 95px;
    max-width: auto;
  }
  .symbol.symbol-xxl-95.symbol-2by3 .symbol-label {
    height: 95px;
    width: 142.5px;
  }
  .symbol.symbol-xxl-95.symbol-2by3>img {
    height: 95px;
    width: 142.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-100>img {
    width: 100%;
    max-width: 100px;
    height: 100px;
  }
  .symbol.symbol-xxl-100 .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xxl-100 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-100 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-100.symbol-circle .symbol-badge {
    top: 8.5px;
    right: 8.5px;
  }
  .symbol.symbol-xxl-100.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 8.5px;
  }
  .symbol.symbol-xxl-100.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xxl-100.symbol-fixed>img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-xxl-100.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-xxl-100.symbol-2by3>img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-xxl-120>img {
    width: 100%;
    max-width: 120px;
    height: 120px;
  }
  .symbol.symbol-xxl-120 .symbol-label {
    width: 120px;
    height: 120px;
  }
  .symbol.symbol-xxl-120 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-120 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-120.symbol-circle .symbol-badge {
    top: 12.6px;
    right: 12.6px;
  }
  .symbol.symbol-xxl-120.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 12.6px;
  }
  .symbol.symbol-xxl-120.symbol-fixed .symbol-label {
    width: 120px;
    height: 120px;
  }
  .symbol.symbol-xxl-120.symbol-fixed>img {
    width: 120px;
    height: 120px;
    max-width: auto;
  }
  .symbol.symbol-xxl-120.symbol-2by3 .symbol-label {
    height: 120px;
    width: 180px;
  }
  .symbol.symbol-xxl-120.symbol-2by3>img {
    height: 120px;
    width: 180px;
    max-width: auto;
  }
  .symbol.symbol-xxl-130>img {
    width: 100%;
    max-width: 130px;
    height: 130px;
  }
  .symbol.symbol-xxl-130 .symbol-label {
    width: 130px;
    height: 130px;
  }
  .symbol.symbol-xxl-130 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-130 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-130.symbol-circle .symbol-badge {
    top: 14.65px;
    right: 14.65px;
  }
  .symbol.symbol-xxl-130.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 14.65px;
  }
  .symbol.symbol-xxl-130.symbol-fixed .symbol-label {
    width: 130px;
    height: 130px;
  }
  .symbol.symbol-xxl-130.symbol-fixed>img {
    width: 130px;
    height: 130px;
    max-width: auto;
  }
  .symbol.symbol-xxl-130.symbol-2by3 .symbol-label {
    height: 130px;
    width: 195px;
  }
  .symbol.symbol-xxl-130.symbol-2by3>img {
    height: 130px;
    width: 195px;
    max-width: auto;
  }
  .symbol.symbol-xxl-140>img {
    width: 100%;
    max-width: 140px;
    height: 140px;
  }
  .symbol.symbol-xxl-140 .symbol-label {
    width: 140px;
    height: 140px;
  }
  .symbol.symbol-xxl-140 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-140 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-140.symbol-circle .symbol-badge {
    top: 16.7px;
    right: 16.7px;
  }
  .symbol.symbol-xxl-140.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 16.7px;
  }
  .symbol.symbol-xxl-140.symbol-fixed .symbol-label {
    width: 140px;
    height: 140px;
  }
  .symbol.symbol-xxl-140.symbol-fixed>img {
    width: 140px;
    height: 140px;
    max-width: auto;
  }
  .symbol.symbol-xxl-140.symbol-2by3 .symbol-label {
    height: 140px;
    width: 210px;
  }
  .symbol.symbol-xxl-140.symbol-2by3>img {
    height: 140px;
    width: 210px;
    max-width: auto;
  }
  .symbol.symbol-xxl-150>img {
    width: 100%;
    max-width: 150px;
    height: 150px;
  }
  .symbol.symbol-xxl-150 .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xxl-150 .symbol-badge {
    width: 12px;
    height: 12px;
    top: -6px;
    right: -6px;
  }
  .symbol.symbol-xxl-150 .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: -6px;
  }
  .symbol.symbol-xxl-150.symbol-circle .symbol-badge {
    top: 18.75px;
    right: 18.75px;
  }
  .symbol.symbol-xxl-150.symbol-circle .symbol-badge.symbol-badge-bottom {
    top: auto;
    bottom: 18.75px;
  }
  .symbol.symbol-xxl-150.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xxl-150.symbol-fixed>img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-xxl-150.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-xxl-150.symbol-2by3>img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
}

.symbol-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.symbol-group .symbol {
  position: relative;
  z-index: 0;
  margin-left: -10px;
  border: 2px solid #ffffff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.symbol-group .symbol:first-child {
  margin-left: 0;
}

.symbol-group .symbol:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
}

.symbol-group.symbol-hover .symbol {
  cursor: pointer;
}

.symbol-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.symbol-list .symbol {
  margin-right: 0.5rem;
}

.symbol-list .symbol:last-child {
  margin-right: 0;
}

.pulse {
  position: relative;
}

.pulse .pulse-ring {
  display: block;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  position: absolute;
  -webkit-animation: animation-pulse 3.5s ease-out;
  animation: animation-pulse 3.5s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  border-width: 3px;
  border-style: solid;
  border-color: #e4e6ef;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .pulse .pulse-ring {
    top: 2px;
    left: 2px;
  }
}

.pulse.pulse-primary .pulse-ring {
  border-color: rgba(54, 153, 255, 0.75);
}

.pulse.pulse-secondary .pulse-ring {
  border-color: rgba(228, 230, 239, 0.75);
}

.pulse.pulse-success .pulse-ring {
  border-color: rgba(27, 197, 189, 0.75);
}

.pulse.pulse-info .pulse-ring {
  border-color: rgba(137, 80, 252, 0.75);
}

.pulse.pulse-warning .pulse-ring {
  border-color: rgba(255, 168, 0, 0.75);
}

.pulse.pulse-danger .pulse-ring {
  border-color: rgba(246, 78, 96, 0.75);
}

.pulse.pulse-light .pulse-ring {
  border-color: rgba(243, 246, 249, 0.75);
}

.pulse.pulse-dark .pulse-ring {
  border-color: rgba(24, 28, 50, 0.75);
}

.pulse.pulse-white .pulse-ring {
  border-color: rgba(255, 255, 255, 0.75);
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: 600 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #3699ff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0073e9 !important;
}

.text-secondary {
  color: #e4e6ef !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #b4bad3 !important;
}

.text-ansp-footer {
  color: #b4bad3 !important;
}

a.text-ansp-footer:hover, a.text-ansp-footer:focus {
  color: #fec33c !important;
}

.text-success {
  color: #2c8a30 !important;
}

.background-success {
  background-color: #2c8a30 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #2c8a30 !important;
}

.text-info {
  color: #8950fc !important;
}

.background-info {
  background-color: #8950fc !important;
}

a.text-info:hover, a.text-info:focus {
  color: #5605fb !important;
}

.text-warning {
  color: #ffa800 !important;
}

.background-warning {
  background-color: #ffa800 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b37600 !important;
}

.text-danger {
  color: #f64e60 !important;
}

.background-danger {
  background-color: #f64e60 !important;
}

.background-primary {
  background-color: var(--buttons-buttonMain) !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ec0c24 !important;
}

.text-light {
  color: #f3f6f9 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #c0d0e0 !important;
}

.text-dark {
  color: #181c32 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-white {
  color: #ffffff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-body {
  color: #3f4254 !important;
}

.text-muted {
  color: #b5b5c3 !important;
}

.bell-notifications {
  color: var(--color-bell);
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.display1 {
  font-size: 5.5rem !important;
}

.display2 {
  font-size: 4.5rem !important;
}

.display3 {
  font-size: 3.5rem !important;
}

.display4 {
  font-size: 2.5rem !important;
}

.display5 {
  font-size: 2.25rem !important;
}

@media (min-width: 576px) {
  .font-size-h1-sm {
    font-size: 2rem !important;
  }
  .font-size-h2-sm {
    font-size: 1.75rem !important;
  }
  .font-size-h3-sm {
    font-size: 1.5rem !important;
  }
  .font-size-h4-sm {
    font-size: 1.35rem !important;
  }
  .font-size-h5-sm {
    font-size: 1.25rem !important;
  }
  .font-size-h6-sm {
    font-size: 14px !important;
  }
  .display1-sm {
    font-size: 5.5rem !important;
  }
  .display2-sm {
    font-size: 4.5rem !important;
  }
  .display3-sm {
    font-size: 3.5rem !important;
  }
  .display4-sm {
    font-size: 2.5rem !important;
  }
  .display5-sm {
    font-size: 2.25rem !important;
  }
}

@media (min-width: 768px) {
  .font-size-h1-md {
    font-size: 2rem !important;
  }
  .font-size-h2-md {
    font-size: 1.75rem !important;
  }
  .font-size-h3-md {
    font-size: 1.5rem !important;
  }
  .font-size-h4-md {
    font-size: 1.35rem !important;
  }
  .font-size-h5-md {
    font-size: 1.25rem !important;
  }
  .font-size-h6-md {
    font-size: 1.175rem !important;
  }
  .display1-md {
    font-size: 5.5rem !important;
  }
  .display2-md {
    font-size: 4.5rem !important;
  }
  .display3-md {
    font-size: 3.5rem !important;
  }
  .display4-md {
    font-size: 2.5rem !important;
  }
  .display5-md {
    font-size: 2.25rem !important;
  }
}

@media (min-width: 992px) {
  .font-size-h1-lg {
    font-size: 2rem !important;
  }
  .font-size-h2-lg {
    font-size: 1.75rem !important;
  }
  .font-size-h3-lg {
    font-size: 1.5rem !important;
  }
  .font-size-h4-lg {
    font-size: 1.35rem !important;
  }
  .font-size-h5-lg {
    font-size: 1.25rem !important;
  }
  .font-size-h6-lg {
    font-size: 1.175rem !important;
  }
  .display1-lg {
    font-size: 5.5rem !important;
  }
  .display2-lg {
    font-size: 4.5rem !important;
  }
  .display3-lg {
    font-size: 3.5rem !important;
  }
  .display4-lg {
    font-size: 2.5rem !important;
  }
  .display5-lg {
    font-size: 2.25rem !important;
  }
}

@media (min-width: 1200px) {
  .font-size-h1-xl {
    font-size: 2rem !important;
  }
  .font-size-h2-xl {
    font-size: 1.75rem !important;
  }
  .font-size-h3-xl {
    font-size: 1.5rem !important;
  }
  .font-size-h4-xl {
    font-size: 1.35rem !important;
  }
  .font-size-h5-xl {
    font-size: 1.25rem !important;
  }
  .font-size-h6-xl {
    font-size: 1.175rem !important;
  }
  .display1-xl {
    font-size: 5.5rem !important;
  }
  .display2-xl {
    font-size: 4.5rem !important;
  }
  .display3-xl {
    font-size: 3.5rem !important;
  }
  .display4-xl {
    font-size: 2.5rem !important;
  }
  .display5-xl {
    font-size: 2.25rem !important;
  }
}

@media (min-width: 1400px) {
  .font-size-h1-xxl {
    font-size: 2rem !important;
  }
  .font-size-h2-xxl {
    font-size: 1.75rem !important;
  }
  .font-size-h3-xxl {
    font-size: 1.5rem !important;
  }
  .font-size-h4-xxl {
    font-size: 1.35rem !important;
  }
  .font-size-h5-xxl {
    font-size: 1.25rem !important;
  }
  .font-size-h6-xxl {
    font-size: 1.175rem !important;
  }
  .display1-xxl {
    font-size: 5.5rem !important;
  }
  .display2-xxl {
    font-size: 4.5rem !important;
  }
  .display3-xxl {
    font-size: 3.5rem !important;
  }
  .display4-xxl {
    font-size: 2.5rem !important;
  }
  .display5-xxl {
    font-size: 2.25rem !important;
  }
}

a.text-hover-primary, .text-hover-primary {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

a.text-hover-primary:hover, .text-hover-primary:hover {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  color: #3699ff !important;
}

a.text-hover-primary:hover i, .text-hover-primary:hover i {
  color: #3699ff !important;
}

a.text-hover-primary:hover .svg-icon svg g [fill], .text-hover-primary:hover .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #3699ff;
}

a.text-hover-primary:hover .svg-icon svg:hover g [fill], .text-hover-primary:hover .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.hoverable:hover a.text-hover-primary, .hoverable:hover .text-hover-primary {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  color: #3699ff !important;
}

.hoverable:hover a.text-hover-primary i, .hoverable:hover .text-hover-primary i {
  color: #3699ff !important;
}

.hoverable:hover a.text-hover-primary .svg-icon svg g [fill], .hoverable:hover .text-hover-primary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #3699ff !important;
}

.hoverable:hover a.text-hover-primary .svg-icon svg:hover g [fill], .hoverable:hover .text-hover-primary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
}

.navi .navi-item {
  padding: 0;
  display: block;
  list-style: none;
}

.navi .navi-item .navi-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
}

.navi .navi-item .navi-link .navi-icon {
  line-height: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.navi .navi-item .navi-link .navi-icon:before {
  line-height: 0;
}

.navi .navi-item .navi-link .navi-bullet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 0.75rem;
}

.navi .navi-item .navi-link .navi-text {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.navi .navi-item .navi-link .navi-label {
  line-height: 0;
  margin-left: 0.75rem;
}

.navi .navi-item .navi-link .navi-toolbar {
  margin-left: 0.75rem;
}

.navi .navi-item .navi-link .navi-arrow {
  line-height: 0;
  margin-left: 0.75rem;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #7e8299;
}

.navi .navi-item .navi-link .navi-arrow:before {
  line-height: 0;
  font-family: Ki;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  content: "";
  font-size: 0.6rem;
}

.navi .navi-item .navi-link .navi-arrow.navi-arrow-down {
  -webkit-transform: rotateZ(-90deg)/*rtl:ignore*/
  ;
  transform: rotateZ(-90deg)/*rtl:ignore*/
  ;
}

[direction="rtl"] .navi .navi-item .navi-link .navi-arrow.navi-arrow-down {
  -webkit-transform: rotateZ(90deg)/*rtl:ignore*/
  ;
  transform: rotateZ(90deg)/*rtl:ignore*/
  ;
}

.navi .navi-item:last-child {
  border-bottom: 0 !important;
}

.navi .navi-item:last-child .navi-link {
  border-bottom: 0 !important;
}

.navi .navi-separator {
  margin: 0;
  padding: 0;
  height: 0;
  display: block;
  border-bottom: 1px solid #ebedf3;
}

.navi .navi-separator.navi-separator-dashed {
  border-bottom: 1px dashed #ebedf3;
}

.navi .navi-header, .navi .navi-footer, .navi .navi-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navi .navi-section {
  padding: 0.75rem 1.5rem;
}

.navi .navi-header {
  padding: 0.75rem 1.5rem;
}

.navi .navi-footer {
  padding: 0.75rem 1.5rem;
}

.navi.navi-spacer-x-0 .navi-item .navi-link {
  padding-left: 0;
  padding-right: 0;
}

.navi .navi-item .navi-link .navi-icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2rem;
  flex: 0 0 2rem;
}

.navi .navi-item .navi-link .navi-icon i {
  font-size: 1.25rem;
}

.navi .navi-item .navi-link .navi-icon svg {
  height: 22px;
  width: 22px;
}

.navi.navi-icon-lg .navi-item .navi-link .navi-icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 3rem;
  flex: 0 0 3rem;
}

.navi.navi-icon-lg .navi-item .navi-link .navi-icon i {
  font-size: 1.5rem;
}

.navi.navi-icon-lg .navi-item .navi-link .navi-icon svg {
  height: 28px;
  width: 28px;
}

.navi.navi-icon-xl .navi-item .navi-link .navi-icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 4rem;
  flex: 0 0 4rem;
}

.navi.navi-icon-xl .navi-item .navi-link .navi-icon i {
  font-size: 1.75rem;
}

.navi.navi-icon-xl .navi-item .navi-link .navi-icon svg {
  height: 32px;
  width: 32px;
}

.navi.navi-icon-center .navi-item .navi-link .navi-icon {
  text-align: center;
}

.navi .navi-item .navi-link {
  font-size: 13px;
}

.navi .navi-item .navi-link .navi-text {
  font-size: 13px;
}

.navi.navi-font-lg .navi-item .navi-link {
  font-size: 1.1rem;
}

.navi.navi-font-lg .navi-item .navi-link .navi-text {
  font-size: 1.1rem;
}

.navi.navi-font-xl .navi-item .navi-link {
  font-size: 1.2rem;
}

.navi.navi-font-xl .navi-item .navi-link .navi-text {
  font-size: 1.2rem;
}

.navi.navi-light .navi-item .navi-link .navi-text {
  font-weight: 300;
}

.navi.navi-lighter .navi-item .navi-link .navi-text {
  font-weight: lighter;
}

.navi.navi-bold .navi-item .navi-link .navi-text {
  font-weight: 500;
}

.navi.navi-bolder .navi-item .navi-link .navi-text {
  font-weight: 600;
}

.navi.navi-boldest .navi-item .navi-link .navi-text {
  font-weight: 700;
}

.navi .navi-item .navi-link {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #3f4254;
}

.navi .navi-item .navi-link .navi-bullet .bullet {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link .navi-text {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link .navi-icon {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link .navi-icon i {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link .navi-icon .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi .navi-item .navi-link .navi-arrow {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link .navi-bullet .bullet {
  background-color: #e4e6ef;
}

.navi .navi-item .navi-link .navi-text {
  color: #3f4254;
}

.navi .navi-item .navi-link .navi-icon i {
  color: #b5b5c3;
}

.navi .navi-item .navi-link .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}

.navi .navi-item .navi-link .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi .navi-item .navi-link .navi-arrow {
  color: #b5b5c3;
}

.navi .navi-item .navi-link.disabled {
  opacity: 0.6;
}

.navi .navi-item .navi-link:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #3699ff;
}

.navi .navi-item .navi-link:hover .navi-bullet .bullet {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link:hover .navi-text {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link:hover .navi-icon {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link:hover .navi-icon i {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link:hover .navi-icon .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi .navi-item .navi-link:hover .navi-arrow {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link:hover .navi-bullet .bullet {
  background-color: #3699ff;
}

.navi .navi-item .navi-link:hover .navi-text {
  color: #3699ff;
}

.navi .navi-item .navi-link:hover .navi-icon i {
  color: #3699ff;
}

.navi .navi-item .navi-link:hover .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #3699ff;
}

.navi .navi-item .navi-link:hover .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi .navi-item .navi-link:hover .navi-arrow {
  color: #3699ff;
}

.navi .navi-item .navi-link.active {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #3699ff;
}

.navi .navi-item .navi-link.active .navi-bullet .bullet {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link.active .navi-text {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link.active .navi-icon {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link.active .navi-icon i {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link.active .navi-icon .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi .navi-item .navi-link.active .navi-arrow {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link.active .navi-bullet .bullet {
  background-color: #3699ff;
}

.navi .navi-item .navi-link.active .navi-text {
  color: #3699ff;
}

.navi .navi-item .navi-link.active .navi-icon i {
  color: #3699ff;
}

.navi .navi-item .navi-link.active .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #3699ff;
}

.navi .navi-item .navi-link.active .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi .navi-item .navi-link.active .navi-arrow {
  color: #3699ff;
}

.navi.navi-light-icon .navi-item .navi-link {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #3f4254;
}

.navi.navi-light-icon .navi-item .navi-link .navi-bullet .bullet {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link .navi-text {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link .navi-icon {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link .navi-icon i {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link .navi-icon .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-light-icon .navi-item .navi-link .navi-arrow {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link .navi-bullet .bullet {
  background-color: #e4e6ef;
}

.navi.navi-light-icon .navi-item .navi-link .navi-text {
  color: #3f4254;
}

.navi.navi-light-icon .navi-item .navi-link .navi-icon i {
  color: #d1d3e0;
}

.navi.navi-light-icon .navi-item .navi-link .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #d1d3e0;
}

.navi.navi-light-icon .navi-item .navi-link .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-light-icon .navi-item .navi-link .navi-arrow {
  color: #b5b5c3;
}

.navi.navi-light-icon .navi-item .navi-link.disabled {
  opacity: 0.6;
}

.navi.navi-light-icon .navi-item .navi-link:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #3699ff;
}

.navi.navi-light-icon .navi-item .navi-link:hover .navi-bullet .bullet {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link:hover .navi-text {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link:hover .navi-icon {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link:hover .navi-icon i {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link:hover .navi-icon .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-light-icon .navi-item .navi-link:hover .navi-arrow {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link:hover .navi-bullet .bullet {
  background-color: #3699ff;
}

.navi.navi-light-icon .navi-item .navi-link:hover .navi-text {
  color: #3699ff;
}

.navi.navi-light-icon .navi-item .navi-link:hover .navi-icon i {
  color: #3699ff;
}

.navi.navi-light-icon .navi-item .navi-link:hover .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #3699ff;
}

.navi.navi-light-icon .navi-item .navi-link:hover .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-light-icon .navi-item .navi-link:hover .navi-arrow {
  color: #3699ff;
}

.navi.navi-light-icon .navi-item .navi-link.active {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #3699ff;
}

.navi.navi-light-icon .navi-item .navi-link.active .navi-bullet .bullet {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link.active .navi-text {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link.active .navi-icon {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link.active .navi-icon i {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link.active .navi-icon .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-light-icon .navi-item .navi-link.active .navi-arrow {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link.active .navi-bullet .bullet {
  background-color: #3699ff;
}

.navi.navi-light-icon .navi-item .navi-link.active .navi-text {
  color: #3699ff;
}

.navi.navi-light-icon .navi-item .navi-link.active .navi-icon i {
  color: #3699ff;
}

.navi.navi-light-icon .navi-item .navi-link.active .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #3699ff;
}

.navi.navi-light-icon .navi-item .navi-link.active .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-light-icon .navi-item .navi-link.active .navi-arrow {
  color: #3699ff;
}

.navi.navi-border .navi-item .navi-link {
  border-bottom: 1px solid #ebedf3;
}

.navi.navi-link-rounded .navi-item .navi-link {
  border-radius: 0.42rem;
}

.navi.navi-link-rounded-lg .navi-item .navi-link {
  border-radius: 0.85rem;
}

.navi.navi-hover .navi-item .navi-link:hover {
  background-color: #f3f6f9;
}

.navi.navi-active .navi-item .navi-link.active {
  background-color: #f3f6f9;
}

.navi.navi-accent .navi-item .navi-link {
  position: relative;
}

.navi.navi-accent .navi-item .navi-link:before {
  display: block;
  left: 0;
  top: 8px;
  bottom: 8px;
  position: absolute;
  width: 4px;
  background-color: transparent;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  border-radius: 2px;
  content: " ";
}

.navi.navi-accent .navi-item .navi-link.active:before {
  background-color: #3699ff;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-accent.navi-primary .navi-item .navi-link:hover {
  color: #3699ff;
}

.navi.navi-accent.navi-primary .navi-item .navi-link:hover .navi-text {
  color: #3699ff;
}

.navi.navi-accent.navi-primary .navi-item .navi-link:hover .navi-icon i {
  color: #3699ff;
}

.navi.navi-accent.navi-primary .navi-item .navi-link:hover .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #3699ff;
}

.navi.navi-accent.navi-primary .navi-item .navi-link:hover .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-primary .navi-item .navi-link:hover .navi-arrow {
  color: #3699ff;
}

.navi.navi-accent.navi-primary .navi-item .navi-link.active {
  color: #3699ff;
}

.navi.navi-accent.navi-primary .navi-item .navi-link.active .navi-text {
  color: #3699ff;
}

.navi.navi-accent.navi-primary .navi-item .navi-link.active .navi-icon i {
  color: #3699ff;
}

.navi.navi-accent.navi-primary .navi-item .navi-link.active .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #3699ff;
}

.navi.navi-accent.navi-primary .navi-item .navi-link.active .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-primary .navi-item .navi-link.active .navi-arrow {
  color: #3699ff;
}

.navi.navi-accent.navi-primary .navi-item .navi-link.active:before {
  background-color: #3699ff;
}

.navi.navi-accent.navi-secondary .navi-item .navi-link:hover {
  color: #e4e6ef;
}

.navi.navi-accent.navi-secondary .navi-item .navi-link:hover .navi-text {
  color: #e4e6ef;
}

.navi.navi-accent.navi-secondary .navi-item .navi-link:hover .navi-icon i {
  color: #e4e6ef;
}

.navi.navi-accent.navi-secondary .navi-item .navi-link:hover .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}

.navi.navi-accent.navi-secondary .navi-item .navi-link:hover .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-secondary .navi-item .navi-link:hover .navi-arrow {
  color: #e4e6ef;
}

.navi.navi-accent.navi-secondary .navi-item .navi-link.active {
  color: #e4e6ef;
}

.navi.navi-accent.navi-secondary .navi-item .navi-link.active .navi-text {
  color: #e4e6ef;
}

.navi.navi-accent.navi-secondary .navi-item .navi-link.active .navi-icon i {
  color: #e4e6ef;
}

.navi.navi-accent.navi-secondary .navi-item .navi-link.active .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}

.navi.navi-accent.navi-secondary .navi-item .navi-link.active .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-secondary .navi-item .navi-link.active .navi-arrow {
  color: #e4e6ef;
}

.navi.navi-accent.navi-secondary .navi-item .navi-link.active:before {
  background-color: #e4e6ef;
}

.navi.navi-accent.navi-success .navi-item .navi-link:hover {
  color: #1bc5bd;
}

.navi.navi-accent.navi-success .navi-item .navi-link:hover .navi-text {
  color: #1bc5bd;
}

.navi.navi-accent.navi-success .navi-item .navi-link:hover .navi-icon i {
  color: #1bc5bd;
}

.navi.navi-accent.navi-success .navi-item .navi-link:hover .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1bc5bd;
}

.navi.navi-accent.navi-success .navi-item .navi-link:hover .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-success .navi-item .navi-link:hover .navi-arrow {
  color: #1bc5bd;
}

.navi.navi-accent.navi-success .navi-item .navi-link.active {
  color: #1bc5bd;
}

.navi.navi-accent.navi-success .navi-item .navi-link.active .navi-text {
  color: #1bc5bd;
}

.navi.navi-accent.navi-success .navi-item .navi-link.active .navi-icon i {
  color: #1bc5bd;
}

.navi.navi-accent.navi-success .navi-item .navi-link.active .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1bc5bd;
}

.navi.navi-accent.navi-success .navi-item .navi-link.active .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-success .navi-item .navi-link.active .navi-arrow {
  color: #1bc5bd;
}

.navi.navi-accent.navi-success .navi-item .navi-link.active:before {
  background-color: #1bc5bd;
}

.navi.navi-accent.navi-info .navi-item .navi-link:hover {
  color: #8950fc;
}

.navi.navi-accent.navi-info .navi-item .navi-link:hover .navi-text {
  color: #8950fc;
}

.navi.navi-accent.navi-info .navi-item .navi-link:hover .navi-icon i {
  color: #8950fc;
}

.navi.navi-accent.navi-info .navi-item .navi-link:hover .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950fc;
}

.navi.navi-accent.navi-info .navi-item .navi-link:hover .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-info .navi-item .navi-link:hover .navi-arrow {
  color: #8950fc;
}

.navi.navi-accent.navi-info .navi-item .navi-link.active {
  color: #8950fc;
}

.navi.navi-accent.navi-info .navi-item .navi-link.active .navi-text {
  color: #8950fc;
}

.navi.navi-accent.navi-info .navi-item .navi-link.active .navi-icon i {
  color: #8950fc;
}

.navi.navi-accent.navi-info .navi-item .navi-link.active .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950fc;
}

.navi.navi-accent.navi-info .navi-item .navi-link.active .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-info .navi-item .navi-link.active .navi-arrow {
  color: #8950fc;
}

.navi.navi-accent.navi-info .navi-item .navi-link.active:before {
  background-color: #8950fc;
}

.navi.navi-accent.navi-warning .navi-item .navi-link:hover {
  color: #ffa800;
}

.navi.navi-accent.navi-warning .navi-item .navi-link:hover .navi-text {
  color: #ffa800;
}

.navi.navi-accent.navi-warning .navi-item .navi-link:hover .navi-icon i {
  color: #ffa800;
}

.navi.navi-accent.navi-warning .navi-item .navi-link:hover .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffa800;
}

.navi.navi-accent.navi-warning .navi-item .navi-link:hover .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-warning .navi-item .navi-link:hover .navi-arrow {
  color: #ffa800;
}

.navi.navi-accent.navi-warning .navi-item .navi-link.active {
  color: #ffa800;
}

.navi.navi-accent.navi-warning .navi-item .navi-link.active .navi-text {
  color: #ffa800;
}

.navi.navi-accent.navi-warning .navi-item .navi-link.active .navi-icon i {
  color: #ffa800;
}

.navi.navi-accent.navi-warning .navi-item .navi-link.active .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffa800;
}

.navi.navi-accent.navi-warning .navi-item .navi-link.active .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-warning .navi-item .navi-link.active .navi-arrow {
  color: #ffa800;
}

.navi.navi-accent.navi-warning .navi-item .navi-link.active:before {
  background-color: #ffa800;
}

.navi.navi-accent.navi-danger .navi-item .navi-link:hover {
  color: #f64e60;
}

.navi.navi-accent.navi-danger .navi-item .navi-link:hover .navi-text {
  color: #f64e60;
}

.navi.navi-accent.navi-danger .navi-item .navi-link:hover .navi-icon i {
  color: #f64e60;
}

.navi.navi-accent.navi-danger .navi-item .navi-link:hover .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #f64e60;
}

.navi.navi-accent.navi-danger .navi-item .navi-link:hover .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-danger .navi-item .navi-link:hover .navi-arrow {
  color: #f64e60;
}

.navi.navi-accent.navi-danger .navi-item .navi-link.active {
  color: #f64e60;
}

.navi.navi-accent.navi-danger .navi-item .navi-link.active .navi-text {
  color: #f64e60;
}

.navi.navi-accent.navi-danger .navi-item .navi-link.active .navi-icon i {
  color: #f64e60;
}

.navi.navi-accent.navi-danger .navi-item .navi-link.active .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #f64e60;
}

.navi.navi-accent.navi-danger .navi-item .navi-link.active .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-danger .navi-item .navi-link.active .navi-arrow {
  color: #f64e60;
}

.navi.navi-accent.navi-danger .navi-item .navi-link.active:before {
  background-color: #f64e60;
}

.navi.navi-accent.navi-light .navi-item .navi-link:hover {
  color: #f3f6f9;
}

.navi.navi-accent.navi-light .navi-item .navi-link:hover .navi-text {
  color: #f3f6f9;
}

.navi.navi-accent.navi-light .navi-item .navi-link:hover .navi-icon i {
  color: #f3f6f9;
}

.navi.navi-accent.navi-light .navi-item .navi-link:hover .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #f3f6f9;
}

.navi.navi-accent.navi-light .navi-item .navi-link:hover .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-light .navi-item .navi-link:hover .navi-arrow {
  color: #f3f6f9;
}

.navi.navi-accent.navi-light .navi-item .navi-link.active {
  color: #f3f6f9;
}

.navi.navi-accent.navi-light .navi-item .navi-link.active .navi-text {
  color: #f3f6f9;
}

.navi.navi-accent.navi-light .navi-item .navi-link.active .navi-icon i {
  color: #f3f6f9;
}

.navi.navi-accent.navi-light .navi-item .navi-link.active .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #f3f6f9;
}

.navi.navi-accent.navi-light .navi-item .navi-link.active .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-light .navi-item .navi-link.active .navi-arrow {
  color: #f3f6f9;
}

.navi.navi-accent.navi-light .navi-item .navi-link.active:before {
  background-color: #f3f6f9;
}

.navi.navi-accent.navi-dark .navi-item .navi-link:hover {
  color: #181c32;
}

.navi.navi-accent.navi-dark .navi-item .navi-link:hover .navi-text {
  color: #181c32;
}

.navi.navi-accent.navi-dark .navi-item .navi-link:hover .navi-icon i {
  color: #181c32;
}

.navi.navi-accent.navi-dark .navi-item .navi-link:hover .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #181c32;
}

.navi.navi-accent.navi-dark .navi-item .navi-link:hover .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-dark .navi-item .navi-link:hover .navi-arrow {
  color: #181c32;
}

.navi.navi-accent.navi-dark .navi-item .navi-link.active {
  color: #181c32;
}

.navi.navi-accent.navi-dark .navi-item .navi-link.active .navi-text {
  color: #181c32;
}

.navi.navi-accent.navi-dark .navi-item .navi-link.active .navi-icon i {
  color: #181c32;
}

.navi.navi-accent.navi-dark .navi-item .navi-link.active .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #181c32;
}

.navi.navi-accent.navi-dark .navi-item .navi-link.active .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-dark .navi-item .navi-link.active .navi-arrow {
  color: #181c32;
}

.navi.navi-accent.navi-dark .navi-item .navi-link.active:before {
  background-color: #181c32;
}

.navi.navi-accent.navi-white .navi-item .navi-link:hover {
  color: #ffffff;
}

.navi.navi-accent.navi-white .navi-item .navi-link:hover .navi-text {
  color: #ffffff;
}

.navi.navi-accent.navi-white .navi-item .navi-link:hover .navi-icon i {
  color: #ffffff;
}

.navi.navi-accent.navi-white .navi-item .navi-link:hover .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.navi.navi-accent.navi-white .navi-item .navi-link:hover .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-white .navi-item .navi-link:hover .navi-arrow {
  color: #ffffff;
}

.navi.navi-accent.navi-white .navi-item .navi-link.active {
  color: #ffffff;
}

.navi.navi-accent.navi-white .navi-item .navi-link.active .navi-text {
  color: #ffffff;
}

.navi.navi-accent.navi-white .navi-item .navi-link.active .navi-icon i {
  color: #ffffff;
}

.navi.navi-accent.navi-white .navi-item .navi-link.active .navi-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.navi.navi-accent.navi-white .navi-item .navi-link.active .navi-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.navi.navi-accent.navi-white .navi-item .navi-link.active .navi-arrow {
  color: #ffffff;
}

.navi.navi-accent.navi-white .navi-item .navi-link.active:before {
  background-color: #ffffff;
}

.card.card-custom>.card-header .card-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.5rem 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.card.card-custom>.card-header.card-header-tabs-line {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.card.card-custom>.card-header.card-header-tabs-line .card-toolbar {
  margin: 0;
}

.card.card-custom>.card-header.card-header-tabs-line .nav {
  border-bottom-color: transparent;
}

.card.card-custom>.card-header.card-header-tabs-line .nav .nav-item {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.card.card-custom>.card-header.card-header-tabs-line .nav .nav-link {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.card.card-custom .card-header .card-toolbar [data-card-tool="toggle"] i {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.card.card-custom.card-collapsed .card-header .card-toolbar [data-card-tool="toggle"] i, .card.card-custom.card-collapse .card-header .card-toolbar [data-card-tool="toggle"] i {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.form-group label {
  font-size: 12px;
  font-weight: 400;
  color: #3f4254;
}

.form-group .invalid-feedback, .form-group .valid-feedback {
  font-size: 0.9rem;
  font-weight: 400;
}

.form-group .form-text {
  font-size: 0.9rem;
  font-weight: 400;
}

.form-group {
  margin-bottom: 1.75rem;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -12.5px;
  margin-left: -12.5px;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 12.5px;
  padding-left: 12.5px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1>* {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2>* {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3>* {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4>* {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5>* {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6>* {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (max-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xxl-4>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xxl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xxl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xxl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xxl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xxl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xxl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xxl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xxl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xxl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xxl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xxl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xxl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xxl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xxl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
}

.col-form-label {
  padding-top: calc(0.65rem + 1px);
  padding-bottom: calc(0.65rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.825rem + 1px);
  padding-bottom: calc(0.825rem + 1px);
  font-size: 1.08rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.55rem + 1px);
  padding-bottom: calc(0.55rem + 1px);
  font-size: 0.925rem;
  line-height: 1.35;
}

.btn.btn-primary.btn-shadow {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.25) !important;
  box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.25) !important;
}

.btn.btn-primary.btn-shadow:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-primary.btn-shadow:focus:not(.btn-text), .btn.btn-primary.btn-shadow.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important;
}

.btn.btn-primary.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-primary.btn-shadow:not(:disabled):not(.disabled).active, .show>.btn.btn-primary.btn-shadow.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important;
}

.btn.btn-primary.btn-shadow-hover:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-primary.btn-shadow-hover:focus:not(.btn-text), .btn.btn-primary.btn-shadow-hover.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important;
}

.btn.btn-primary.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-primary.btn-shadow-hover:not(:disabled):not(.disabled).active, .show>.btn.btn-primary.btn-shadow-hover.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important;
}

.btn.btn-white.btn-shadow {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.5) !important;
  box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.5) !important;
}

.btn.btn-white.btn-shadow:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-white.btn-shadow:focus:not(.btn-text), .btn.btn-white.btn-shadow.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.6) !important;
  box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.6) !important;
}

.btn.btn-white.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-white.btn-shadow:not(:disabled):not(.disabled).active, .show>.btn.btn-white.btn-shadow.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.6) !important;
  box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.6) !important;
}

.btn.btn-white.btn-shadow-hover:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-white.btn-shadow-hover:focus:not(.btn-text), .btn.btn-white.btn-shadow-hover.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.6) !important;
  box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.6) !important;
}

.btn.btn-white.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-white.btn-shadow-hover:not(:disabled):not(.disabled).active, .show>.btn.btn-white.btn-shadow-hover.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.6) !important;
  box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.6) !important;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #3f4254;
}

.form-control:focus {
  color: #3f4254;
  background-color: #ffffff;
  border-color: #69b3ff;
  outline: 0;
}

.form-control::-webkit-input-placeholder {
  color: #b5b5c3;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #b5b5c3;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #b5b5c3;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #b5b5c3;
  opacity: 1;
}

.form-control::placeholder {
  color: #b5b5c3;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f3f6f9;
  opacity: 1;
}

input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #3f4254;
  background-color: #ffffff;
}

.form-control-file, .form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.65rem + 1px);
  padding-bottom: calc(0.65rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.825rem + 1px);
  padding-bottom: calc(0.825rem + 1px);
  font-size: 1.08rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.55rem + 1px);
  padding-bottom: calc(0.55rem + 1px);
  font-size: 0.925rem;
  line-height: 1.35;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.65rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #3f4254;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.form-control-lg {
  height: calc(1.5em + 1.65rem + 2px);
  padding: 10px 14px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 5px;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1.75rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col, .form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled]~.form-check-label, .form-check-input:disabled~.form-check-label {
  color: #b5b5c3;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.9rem;
  color: #1bc5bd;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: #1bc5bd;
  border-radius: 3px;
}

.was-validated :valid~.valid-feedback, .was-validated :valid~.valid-tooltip, .is-valid~.valid-feedback, .is-valid~.valid-tooltip {
  display: block;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.9rem;
  color: #f64e60;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: #f64e60;
  border-radius: 3px;
}

.was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip, .is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f64e60;
  padding-right: calc(1.5em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #f64e60;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.3rem);
  background-position: top calc(0.375em + 0.325rem) right calc(0.375em + 0.325rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f64e60;
  padding-right: calc(0.75em + 2.975rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233F4254' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e") #ffffff no-repeat center right 2rem / calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #f64e60;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label, .form-check-input.is-invalid~.form-check-label {
  color: #f64e60;
}

.was-validated .form-check-input:invalid~.invalid-feedback, .was-validated .form-check-input:invalid~.invalid-tooltip, .form-check-input.is-invalid~.invalid-feedback, .form-check-input.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label, .custom-control-input.is-invalid~.custom-control-label {
  color: #f64e60;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before, .custom-control-input.is-invalid~.custom-control-label::before {
  border-color: #f64e60;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #1bc5bd;
  padding-right: calc(1.5em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231BC5BD' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #1bc5bd;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.3rem);
  background-position: top calc(0.375em + 0.325rem) right calc(0.375em + 0.325rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #1bc5bd;
  padding-right: calc(0.75em + 2.975rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233F4254' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231BC5BD' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 2rem / calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #1bc5bd;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label, .form-check-input.is-valid~.form-check-label {
  color: #1bc5bd;
}

.was-validated .form-check-input:valid~.valid-feedback, .was-validated .form-check-input:valid~.valid-tooltip, .form-check-input.is-valid~.valid-feedback, .form-check-input.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label, .custom-control-input.is-valid~.custom-control-label {
  color: #1bc5bd;
}

.was-validated .custom-control-input:valid~.custom-control-label::before, .custom-control-input.is-valid~.custom-control-label::before {
  border-color: #1bc5bd;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before, .custom-control-input.is-valid:checked~.custom-control-label::before {
  border-color: #30e3da;
  background-color: #30e3da;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before, .custom-control-input.is-valid:focus~.custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
  border-color: #1bc5bd;
}

.was-validated .custom-file-input:valid~.custom-file-label, .custom-file-input.is-valid~.custom-file-label {
  border-color: #1bc5bd;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label, .custom-file-input.is-valid:focus~.custom-file-label {
  border-color: #1bc5bd;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.25);
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control, .input-group>.form-control-plaintext, .input-group>.custom-select, .input-group>.custom-file {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group>.form-control+.form-control, .input-group>.form-control+.custom-select, .input-group>.form-control+.custom-file, .input-group>.form-control-plaintext+.form-control, .input-group>.form-control-plaintext+.custom-select, .input-group>.form-control-plaintext+.custom-file, .input-group>.custom-select+.form-control, .input-group>.custom-select+.custom-select, .input-group>.custom-select+.custom-file, .input-group>.custom-file+.form-control, .input-group>.custom-file+.custom-select, .input-group>.custom-file+.custom-file {
  margin-left: -1px;
}

.input-group>.form-control:focus, .input-group>.custom-select:focus, .input-group>.custom-file .custom-file-input:focus~.custom-file-label {
  z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group>.form-control:not(:last-child), .input-group>.custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child), .input-group>.custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label, .input-group>.custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend, .input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn, .input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus, .input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn+.btn, .input-group-prepend .btn+.input-group-text, .input-group-prepend .input-group-text+.input-group-text, .input-group-prepend .input-group-text+.btn, .input-group-append .btn+.btn, .input-group-append .btn+.input-group-text, .input-group-append .input-group-text+.input-group-text, .input-group-append .input-group-text+.btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.65rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  text-align: center;
  white-space: nowrap;
  background-color: #f3f6f9;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
}

.input-group-text input[type="radio"], .input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg>.form-control:not(textarea), .input-group-lg>.custom-select {
  height: calc(1.5em + 1.65rem + 2px);
}

.input-group-lg>.form-control, .input-group-lg>.custom-select, .input-group-lg>.input-group-prepend>.input-group-text, .input-group-lg>.input-group-append>.input-group-text, .input-group-lg>.input-group-prepend>.btn, .input-group-lg>.input-group-append>.btn {
  padding: 0.825rem 1.42rem;
  font-size: 1.08rem;
  line-height: 1.5;
  border-radius: 0.42rem;
}

.input-group-sm>.form-control:not(textarea), .input-group-sm>.custom-select {
  height: calc(1.35em + 1.1rem + 2px);
}

.input-group-sm>.form-control, .input-group-sm>.custom-select, .input-group-sm>.input-group-prepend>.input-group-text, .input-group-sm>.input-group-append>.input-group-text, .input-group-sm>.input-group-prepend>.btn, .input-group-sm>.input-group-append>.btn {
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.input-group-lg>.custom-select, .input-group-sm>.custom-select {
  padding-right: 2rem;
}

.input-group>.input-group-prepend>.btn, .input-group>.input-group-prepend>.input-group-text, .input-group>.input-group-append:not(:last-child)>.btn, .input-group>.input-group-append:not(:last-child)>.input-group-text, .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle), .input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn, .input-group>.input-group-append>.input-group-text, .input-group>.input-group-prepend:not(:first-child)>.btn, .input-group>.input-group-prepend:not(:first-child)>.input-group-text, .input-group>.input-group-prepend:first-child>.btn:not(:first-child), .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #ffffff;
  border-color: #3699ff;
  background-color: #3699ff;
}

.custom-control-input:focus~.custom-control-label::before {
  -webkit-box-shadow: none, none;
  box-shadow: none, none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #69b3ff;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #ffffff;
  background-color: #e9f4ff;
  border-color: #e9f4ff;
}

.custom-control-input[disabled]~.custom-control-label, .custom-control-input:disabled~.custom-control-label {
  color: #7e8299;
}

.custom-control-input[disabled]~.custom-control-label::before, .custom-control-input:disabled~.custom-control-label::before {
  background-color: #f3f6f9;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #b5b5c3 solid 1px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.42rem;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  border-color: #3699ff;
  background-color: #3699ff;
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(54, 153, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
  background-color: rgba(54, 153, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(54, 153, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #b5b5c3;
  border-radius: 0.5rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: #ffffff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(54, 153, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 2rem 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233F4254' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #69b3ff;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(54, 153, 255, 0.25);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(54, 153, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #3f4254;
  background-color: #ffffff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}

.custom-select:disabled {
  color: #7e8299;
  background-color: #ebedf3;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #3f4254;
}

.custom-select-sm {
  height: calc(1.35em + 1.1rem + 2px);
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  padding-left: 0.75rem;
  font-size: 0.925rem;
}

.custom-select-lg {
  height: calc(1.5em + 1.65rem + 2px);
  padding-top: 0.825rem;
  padding-bottom: 0.825rem;
  padding-left: 1.42rem;
  font-size: 1.08rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
  border-color: #69b3ff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-file-input[disabled]~.custom-file-label, .custom-file-input:disabled~.custom-file-label {
  background-color: #f3f6f9;
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.3rem);
  padding: 0.65rem 1rem;
  line-height: 1.5;
  color: #3f4254;
  content: "Browse";
  background-color: #f3f6f9;
  border-left: inherit;
  border-radius: 0 0.42rem 0.42rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px #ffffff, none;
  box-shadow: 0 0 0 1px #ffffff, none;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, none;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, none;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3699ff;
  border: 0;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #e9f4ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e4e6ef;
  border-color: transparent;
  border-radius: 1rem;
  -webkit-box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3699ff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #e9f4ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e4e6ef;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #3699ff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #e9f4ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.custom-range::-ms-fill-lower {
  background-color: #e4e6ef;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #e4e6ef;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #b5b5c3;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #b5b5c3;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #b5b5c3;
}

.custom-control-label::before, .custom-file-label, .custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before, .custom-file-label, .custom-select {
    -webkit-transition: none;
    transition: none;
  }
}

.form-group .invalid-feedback, .form-group .valid-feedback {
  font-size: 0.9rem;
  font-weight: 400;
}

.form-group .form-text {
  font-size: 0.9rem;
  font-weight: 400;
}

.form-control[readonly] {
  background-color: #ffffff;
}

.form-control.form-control-pill {
  border-radius: 1.5;
}

.form-control.form-control-pill.form-control-sm {
  border-radius: 1.35;
}

.form-control.form-control-pill.form-control-lg {
  border-radius: 1.5;
}

.form-control:active, .form-control.active, .form-control:focus, .form-control.focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.form-control.form-control-solid {
  background-color: #ebf0f6;
  border-color: #ebf0f6;
  color: #3f4254;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.form-control.form-control-solid::-moz-placeholder {
  color: #b5b5c3;
  opacity: 1;
}

.form-control.form-control-solid:-ms-input-placeholder {
  color: #b5b5c3;
}

.form-control.form-control-solid::-webkit-input-placeholder {
  color: #b5b5c3;
}

.form-control.form-control-solid:active, .form-control.form-control-solid.active, .form-control.form-control-solid:focus, .form-control.form-control-solid.focus {
  background-color: #ebedf3;
  border-color: #ebedf3;
  color: #3f4254;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.form-control-iconless {
  background-image: none;
}

.form-control-iconless .form-control {
  background-image: none;
}

.input-group.input-group-solid {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f3f6f9;
  border-radius: 0.42rem;
}

.input-group.input-group-solid.active, .input-group.input-group-solid.focus {
  background-color: #ebedf3;
  border-color: #ebedf3;
  color: #3f4254;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.input-group.input-group-solid .form-control {
  border: 0;
  background-color: transparent;
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-group.input-group-solid .form-control.form-control-solid:active, .input-group.input-group-solid .form-control.form-control-solid.active, .input-group.input-group-solid .form-control.form-control-solid:focus, .input-group.input-group-solid .form-control.form-control-solid.focus {
  background-color: #ebedf3;
  border-color: #ebedf3;
  color: #3f4254;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.input-group.input-group-solid.input-group-sm {
  border-radius: 0.28rem;
}

.input-group.input-group-solid.input-group-lg {
  border-radius: 0.42rem;
}

.input-group.input-group-solid .input-group-prepend, .input-group.input-group-solid .input-group-append, .input-group.input-group-solid .input-group-text {
  background-color: transparent;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.input-group.input-group-solid .input-group-prepend .btn-secondary, .input-group.input-group-solid .input-group-append .btn-secondary, .input-group.input-group-solid .input-group-text .btn-secondary {
  border: 0 !important;
}

.input-group.input-group-solid .input-group-prepend~.form-control {
  padding-left: 0 !important;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.42rem;
}

.alert.alert-primary {
  background-color: #3699ff;
  border-color: #3699ff;
  color: #ffffff;
}

.alert.alert-primary .close i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-primary .close:focus i, .alert.alert-primary .close:hover i {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-secondary {
  background-color: #e4e6ef;
  border-color: #e4e6ef;
  color: #3f4254;
}

.alert.alert-secondary .close i {
  opacity: 0.8;
  color: #3f4254;
}

.alert.alert-secondary .close:focus i, .alert.alert-secondary .close:hover i {
  opacity: 1;
  color: #3f4254;
}

.alert.alert-success {
  background-color: #1bc5bd;
  border-color: #1bc5bd;
  color: #ffffff;
}

.alert.alert-success .close i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-success .close:focus i, .alert.alert-success .close:hover i {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-info {
  background-color: #8950fc;
  border-color: #8950fc;
  color: #ffffff;
}

.alert.alert-info .close i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-info .close:focus i, .alert.alert-info .close:hover i {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-warning {
  background-color: #ffa800;
  border-color: #ffa800;
  color: #ffffff;
}

.alert.alert-warning .close i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-warning .close:focus i, .alert.alert-warning .close:hover i {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-danger {
  background-color: #f64e60;
  border-color: #f64e60;
  color: #ffffff;
}

.alert.alert-danger .close i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-danger .close:focus i, .alert.alert-danger .close:hover i {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-light {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #7e8299;
}

.alert.alert-light .close i {
  opacity: 0.8;
  color: #7e8299;
}

.alert.alert-light .close:focus i, .alert.alert-light .close:hover i {
  opacity: 1;
  color: #7e8299;
}

.alert.alert-dark {
  background-color: #181c32;
  border-color: #181c32;
  color: #ffffff;
}

.alert.alert-dark .close i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-dark .close:focus i, .alert.alert-dark .close:hover i {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #3f4254;
}

.alert.alert-white .close i {
  opacity: 0.8;
  color: #3f4254;
}

.alert.alert-white .close:focus i, .alert.alert-white .close:hover i {
  opacity: 1;
  color: #3f4254;
}

.alert.alert-custom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 1.5rem 2rem;
}

.alert.alert-custom .alert-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1.25rem 0 0;
}

.alert.alert-custom .alert-icon i {
  font-size: 2.4rem;
}

.alert.alert-custom .alert-icon.alert-icon-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.alert.alert-custom .alert-text {
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.alert.alert-custom .alert-close {
  padding: 0 0 0 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  line-height: 0;
}

.alert.alert-custom .alert-close .close {
  text-shadow: none !important;
  cursor: pointer;
  line-height: 0;
}

.alert.alert-custom .alert-close i {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  font-size: 1rem;
}

.alert.alert-custom .alert-close i.ki {
  font-size: 0.75rem;
}

.alert.alert-custom .alert-close:focus i, .alert.alert-custom .alert-close:hover i {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.alert.alert-custom.alert-outline-2x {
  border-width: 2px;
}

.alert.alert-custom.alert-white {
  background-color: #ffffff;
  border-color: #ffffff;
}

.alert.alert-custom.alert-white .alert-icon i {
  color: #3f4254;
}

.alert.alert-custom.alert-white .alert-text {
  color: #3f4254;
}

.alert.alert-custom.alert-white .alert-close i {
  color: #3f4254;
}

.alert.alert-custom.alert-default {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
}

.alert.alert-custom.alert-shadow {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
}

.alert.alert-custom.alert-primary {
  background-color: #3699ff;
  border-color: #3699ff;
}

.alert.alert-custom.alert-primary .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-custom.alert-primary .alert-text {
  color: #ffffff;
}

.alert.alert-custom.alert-primary .alert-close i {
  color: #ffffff;
}

.alert.alert-custom.alert-primary .alert-close:focus, .alert.alert-custom.alert-primary .alert-close:hover {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-custom.alert-light-primary {
  background-color: #e1f0ff;
  border-color: transparent;
}

.alert.alert-custom.alert-light-primary .alert-icon i {
  color: #3699ff;
}

.alert.alert-custom.alert-light-primary .alert-text {
  color: #3699ff;
}

.alert.alert-custom.alert-light-primary .alert-close i {
  color: #3699ff;
}

.alert.alert-custom.alert-light-primary .alert-close:focus, .alert.alert-custom.alert-light-primary .alert-close:hover {
  color: #187de4;
}

.alert.alert-custom.alert-light-primary.alert-notice {
  border: 0;
  border-left: 4px solid #3699ff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.alert.alert-custom.alert-outline-primary {
  background-color: transparent;
  border-color: #3699ff;
}

.alert.alert-custom.alert-outline-primary .alert-icon i {
  color: #3699ff;
}

.alert.alert-custom.alert-outline-primary .alert-text {
  color: #3699ff;
}

.alert.alert-custom.alert-outline-primary .alert-close i {
  opacity: 0.8;
  color: #3699ff;
}

.alert.alert-custom.alert-outline-primary .alert-close:focus i, .alert.alert-custom.alert-outline-primary .alert-close:hover i {
  opacity: 1;
}

.alert.alert-custom.alert-secondary {
  background-color: #e4e6ef;
  border-color: #e4e6ef;
}

.alert.alert-custom.alert-secondary .alert-icon i {
  opacity: 0.8;
  color: #3f4254;
}

.alert.alert-custom.alert-secondary .alert-text {
  color: #3f4254;
}

.alert.alert-custom.alert-secondary .alert-close i {
  color: #3f4254;
}

.alert.alert-custom.alert-secondary .alert-close:focus, .alert.alert-custom.alert-secondary .alert-close:hover {
  opacity: 1;
  color: #3f4254;
}

.alert.alert-custom.alert-light-secondary {
  background-color: #ebedf3;
  border-color: transparent;
}

.alert.alert-custom.alert-light-secondary .alert-icon i {
  color: #e4e6ef;
}

.alert.alert-custom.alert-light-secondary .alert-text {
  color: #e4e6ef;
}

.alert.alert-custom.alert-light-secondary .alert-close i {
  color: #e4e6ef;
}

.alert.alert-custom.alert-light-secondary .alert-close:focus, .alert.alert-custom.alert-light-secondary .alert-close:hover {
  color: #d7dae7;
}

.alert.alert-custom.alert-light-secondary.alert-notice {
  border: 0;
  border-left: 4px solid #e4e6ef;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.alert.alert-custom.alert-outline-secondary {
  background-color: transparent;
  border-color: #e4e6ef;
}

.alert.alert-custom.alert-outline-secondary .alert-icon i {
  color: #3f4254;
}

.alert.alert-custom.alert-outline-secondary .alert-text {
  color: #3f4254;
}

.alert.alert-custom.alert-outline-secondary .alert-close i {
  opacity: 0.8;
  color: #3f4254;
}

.alert.alert-custom.alert-outline-secondary .alert-close:focus i, .alert.alert-custom.alert-outline-secondary .alert-close:hover i {
  opacity: 1;
}

.alert.alert-custom.alert-success {
  background-color: #1bc5bd;
  border-color: #1bc5bd;
}

.alert.alert-custom.alert-success .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-custom.alert-success .alert-text {
  color: #ffffff;
}

.alert.alert-custom.alert-success .alert-close i {
  color: #ffffff;
}

.alert.alert-custom.alert-success .alert-close:focus, .alert.alert-custom.alert-success .alert-close:hover {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-custom.alert-light-success {
  background-color: #c9f7f5;
  border-color: transparent;
}

.alert.alert-custom.alert-light-success .alert-icon i {
  color: #1bc5bd;
}

.alert.alert-custom.alert-light-success .alert-text {
  color: #1bc5bd;
}

.alert.alert-custom.alert-light-success .alert-close i {
  color: #1bc5bd;
}

.alert.alert-custom.alert-light-success .alert-close:focus, .alert.alert-custom.alert-light-success .alert-close:hover {
  color: #0bb7af;
}

.alert.alert-custom.alert-light-success.alert-notice {
  border: 0;
  border-left: 4px solid #1bc5bd;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.alert.alert-custom.alert-outline-success {
  background-color: transparent;
  border-color: #1bc5bd;
}

.alert.alert-custom.alert-outline-success .alert-icon i {
  color: #1bc5bd;
}

.alert.alert-custom.alert-outline-success .alert-text {
  color: #1bc5bd;
}

.alert.alert-custom.alert-outline-success .alert-close i {
  opacity: 0.8;
  color: #1bc5bd;
}

.alert.alert-custom.alert-outline-success .alert-close:focus i, .alert.alert-custom.alert-outline-success .alert-close:hover i {
  opacity: 1;
}

.alert.alert-custom.alert-info {
  background-color: #8950fc;
  border-color: #8950fc;
}

.alert.alert-custom.alert-info .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-custom.alert-info .alert-text {
  color: #ffffff;
}

.alert.alert-custom.alert-info .alert-close i {
  color: #ffffff;
}

.alert.alert-custom.alert-info .alert-close:focus, .alert.alert-custom.alert-info .alert-close:hover {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-custom.alert-light-info {
  background-color: #eee5ff;
  border-color: transparent;
}

.alert.alert-custom.alert-light-info .alert-icon i {
  color: #8950fc;
}

.alert.alert-custom.alert-light-info .alert-text {
  color: #8950fc;
}

.alert.alert-custom.alert-light-info .alert-close i {
  color: #8950fc;
}

.alert.alert-custom.alert-light-info .alert-close:focus, .alert.alert-custom.alert-light-info .alert-close:hover {
  color: #7337ee;
}

.alert.alert-custom.alert-light-info.alert-notice {
  border: 0;
  border-left: 4px solid #8950fc;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.alert.alert-custom.alert-outline-info {
  background-color: transparent;
  border-color: #8950fc;
}

.alert.alert-custom.alert-outline-info .alert-icon i {
  color: #8950fc;
}

.alert.alert-custom.alert-outline-info .alert-text {
  color: #8950fc;
}

.alert.alert-custom.alert-outline-info .alert-close i {
  opacity: 0.8;
  color: #8950fc;
}

.alert.alert-custom.alert-outline-info .alert-close:focus i, .alert.alert-custom.alert-outline-info .alert-close:hover i {
  opacity: 1;
}

.alert.alert-custom.alert-warning {
  background-color: #ffa800;
  border-color: #ffa800;
}

.alert.alert-custom.alert-warning .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-custom.alert-warning .alert-text {
  color: #ffffff;
}

.alert.alert-custom.alert-warning .alert-close i {
  color: #ffffff;
}

.alert.alert-custom.alert-warning .alert-close:focus, .alert.alert-custom.alert-warning .alert-close:hover {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-custom.alert-light-warning {
  background-color: #fff4de;
  border-color: transparent;
}

.alert.alert-custom.alert-light-warning .alert-icon i {
  color: #ffa800;
}

.alert.alert-custom.alert-light-warning .alert-text {
  color: #ffa800;
}

.alert.alert-custom.alert-light-warning .alert-close i {
  color: #ffa800;
}

.alert.alert-custom.alert-light-warning .alert-close:focus, .alert.alert-custom.alert-light-warning .alert-close:hover {
  color: #ee9d01;
}

.alert.alert-custom.alert-light-warning.alert-notice {
  border: 0;
  border-left: 4px solid #ffa800;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.alert.alert-custom.alert-outline-warning {
  background-color: transparent;
  border-color: #ffa800;
}

.alert.alert-custom.alert-outline-warning .alert-icon i {
  color: #ffa800;
}

.alert.alert-custom.alert-outline-warning .alert-text {
  color: #ffa800;
}

.alert.alert-custom.alert-outline-warning .alert-close i {
  opacity: 0.8;
  color: #ffa800;
}

.alert.alert-custom.alert-outline-warning .alert-close:focus i, .alert.alert-custom.alert-outline-warning .alert-close:hover i {
  opacity: 1;
}

.alert.alert-custom.alert-danger {
  background-color: #f64e60;
  border-color: #f64e60;
}

.alert.alert-custom.alert-danger .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-custom.alert-danger .alert-text {
  color: #ffffff;
}

.alert.alert-custom.alert-danger .alert-close i {
  color: #ffffff;
}

.alert.alert-custom.alert-danger .alert-close:focus, .alert.alert-custom.alert-danger .alert-close:hover {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-custom.alert-light-danger {
  background-color: #ffe2e5;
  border-color: transparent;
}

.alert.alert-custom.alert-light-danger .alert-icon i {
  color: #f64e60;
}

.alert.alert-custom.alert-light-danger .alert-text {
  color: #f64e60;
}

.alert.alert-custom.alert-light-danger .alert-close i {
  color: #f64e60;
}

.alert.alert-custom.alert-light-danger .alert-close:focus, .alert.alert-custom.alert-light-danger .alert-close:hover {
  color: #ee2d41;
}

.alert.alert-custom.alert-light-danger.alert-notice {
  border: 0;
  border-left: 4px solid #f64e60;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.alert.alert-custom.alert-outline-danger {
  background-color: transparent;
  border-color: #f64e60;
}

.alert.alert-custom.alert-outline-danger .alert-icon i {
  color: #f64e60;
}

.alert.alert-custom.alert-outline-danger .alert-text {
  color: #f64e60;
}

.alert.alert-custom.alert-outline-danger .alert-close i {
  opacity: 0.8;
  color: #f64e60;
}

.alert.alert-custom.alert-outline-danger .alert-close:focus i, .alert.alert-custom.alert-outline-danger .alert-close:hover i {
  opacity: 1;
}

.alert.alert-custom.alert-light {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
}

.alert.alert-custom.alert-light .alert-icon i {
  opacity: 0.8;
  color: #7e8299;
}

.alert.alert-custom.alert-light .alert-text {
  color: #7e8299;
}

.alert.alert-custom.alert-light .alert-close i {
  color: #7e8299;
}

.alert.alert-custom.alert-light .alert-close:focus, .alert.alert-custom.alert-light .alert-close:hover {
  opacity: 1;
  color: #7e8299;
}

.alert.alert-custom.alert-light-light {
  background-color: #f3f6f9;
  border-color: transparent;
}

.alert.alert-custom.alert-light-light .alert-icon i {
  color: #f3f6f9;
}

.alert.alert-custom.alert-light-light .alert-text {
  color: #f3f6f9;
}

.alert.alert-custom.alert-light-light .alert-close i {
  color: #f3f6f9;
}

.alert.alert-custom.alert-light-light .alert-close:focus, .alert.alert-custom.alert-light-light .alert-close:hover {
  color: #e4e6ef;
}

.alert.alert-custom.alert-light-light.alert-notice {
  border: 0;
  border-left: 4px solid #f3f6f9;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.alert.alert-custom.alert-outline-light {
  background-color: transparent;
  border-color: #f3f6f9;
}

.alert.alert-custom.alert-outline-light .alert-icon i {
  color: #3f4254;
}

.alert.alert-custom.alert-outline-light .alert-text {
  color: #3f4254;
}

.alert.alert-custom.alert-outline-light .alert-close i {
  opacity: 0.8;
  color: #3f4254;
}

.alert.alert-custom.alert-outline-light .alert-close:focus i, .alert.alert-custom.alert-outline-light .alert-close:hover i {
  opacity: 1;
}

.alert.alert-custom.alert-dark {
  background-color: #181c32;
  border-color: #181c32;
}

.alert.alert-custom.alert-dark .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-custom.alert-dark .alert-text {
  color: #ffffff;
}

.alert.alert-custom.alert-dark .alert-close i {
  color: #ffffff;
}

.alert.alert-custom.alert-dark .alert-close:focus, .alert.alert-custom.alert-dark .alert-close:hover {
  opacity: 1;
  color: #ffffff;
}

.alert.alert-custom.alert-light-dark {
  background-color: #d1d3e0;
  border-color: transparent;
}

.alert.alert-custom.alert-light-dark .alert-icon i {
  color: #181c32;
}

.alert.alert-custom.alert-light-dark .alert-text {
  color: #181c32;
}

.alert.alert-custom.alert-light-dark .alert-close i {
  color: #181c32;
}

.alert.alert-custom.alert-light-dark .alert-close:focus, .alert.alert-custom.alert-light-dark .alert-close:hover {
  color: #131628;
}

.alert.alert-custom.alert-light-dark.alert-notice {
  border: 0;
  border-left: 4px solid #181c32;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.alert.alert-custom.alert-outline-dark {
  background-color: transparent;
  border-color: #181c32;
}

.alert.alert-custom.alert-outline-dark .alert-icon i {
  color: #181c32;
}

.alert.alert-custom.alert-outline-dark .alert-text {
  color: #181c32;
}

.alert.alert-custom.alert-outline-dark .alert-close i {
  opacity: 0.8;
  color: #181c32;
}

.alert.alert-custom.alert-outline-dark .alert-close:focus i, .alert.alert-custom.alert-outline-dark .alert-close:hover i {
  opacity: 1;
}

.alert.alert-custom.alert-white {
  background-color: #ffffff;
  border-color: #ffffff;
}

.alert.alert-custom.alert-white .alert-icon i {
  opacity: 0.8;
  color: #3f4254;
}

.alert.alert-custom.alert-white .alert-text {
  color: #3f4254;
}

.alert.alert-custom.alert-white .alert-close i {
  color: #3f4254;
}

.alert.alert-custom.alert-white .alert-close:focus, .alert.alert-custom.alert-white .alert-close:hover {
  opacity: 1;
  color: #3f4254;
}

.alert.alert-custom.alert-light-white {
  background-color: #ffffff;
  border-color: transparent;
}

.alert.alert-custom.alert-light-white .alert-icon i {
  color: #ffffff;
}

.alert.alert-custom.alert-light-white .alert-text {
  color: #ffffff;
}

.alert.alert-custom.alert-light-white .alert-close i {
  color: #ffffff;
}

.alert.alert-custom.alert-light-white .alert-close:focus, .alert.alert-custom.alert-light-white .alert-close:hover {
  color: #f3f6f9;
}

.alert.alert-custom.alert-light-white.alert-notice {
  border: 0;
  border-left: 4px solid #ffffff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.alert.alert-custom.alert-outline-white {
  background-color: transparent;
  border-color: #ffffff;
}

.alert.alert-custom.alert-outline-white .alert-icon i {
  color: #ffffff;
}

.alert.alert-custom.alert-outline-white .alert-text {
  color: #ffffff;
}

.alert.alert-custom.alert-outline-white .alert-close i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-custom.alert-outline-white .alert-close:focus i, .alert.alert-custom.alert-outline-white .alert-close:hover i {
  opacity: 1;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0073e9 !important;
}

.text-secondary {
  color: #e4e6ef !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #b4bad3 !important;
}

.text-success {
  color: #2c8a30 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #2c8a30 !important;
}

.text-info {
  color: #8950fc !important;
}

a.text-info:hover, a.text-info:focus {
  color: #5605fb !important;
}

.text-warning {
  color: #ffa800 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b37600 !important;
}

.text-danger {
  color: #f64e60 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ec0c24 !important;
}

.text-light {
  color: #f3f6f9 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #c0d0e0 !important;
}

.text-dark {
  color: #181c32 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-white {
  color: #ffffff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-body {
  color: #3f4254 !important;
}

.text-muted {
  color: #b5b5c3 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-black {
  background-color: #2f2f2f !important;
}

a.bg-white:hover, a.bg-white:focus, button.bg-white:hover, button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.bg-transparent {
  background-color: transparent;
}

.bg-transparent-i {
  background-color: transparent !important;
}

.bgi-size-length {
  background-size: length;
}

.bgi-size-cover {
  background-size: cover;
}

.bgi-size-contain {
  background-size: contain;
}

.bgi-no-repeat {
  background-repeat: no-repeat;
}

.bgi-position-y-top {
  background-position-y: top;
}

.bgi-position-y-bottom {
  background-position-y: bottom;
}

.bgi-position-y-center {
  background-position-y: center;
}

.bgi-position-x-left {
  background-position-x: left;
}

.bgi-position-x-right {
  background-position-x: right;
}

.bgi-position-x-center {
  background-position-x: center;
}

.bgi-position-top {
  background-position: 0 top;
}

.bgi-position-bottom {
  background-position: 0 bottom;
}

.bgi-position-center {
  background-position: center;
}

.bg-transparent {
  background-color: transparent;
}

.bg-diagonal {
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
}

.bg-diagonal .card-body {
  position: relative;
  z-index: 2;
}

.bg-diagonal:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f3f6f9;
}

.bg-diagonal:after {
  content: " ";
  width: 200%;
  height: 1000px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 45%;
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
  background-color: #ffffff;
}

.fv-plugins-message-container {
  margin-top: 0.25rem;
}

.fv-plugins-message-container .fv-help-block {
  color: #f64e60;
  font-size: 0.9rem;
  font-weight: 400;
}

.progress-div {
  height: 15px;
  width: 100%;
  background-color: var(--color-background-13);
}

.progress-div-chatdelay {
  height: 4px;
  width: 100%;
  background-color: var(--color-background-13);
}

.progress-divV2 {
  height: 20px;
  width: 100%;
  background-color: var(--color-background-13);
  border-radius: 0.5rem;
}

.progress-divV3 {
  height: 24px;
  width: 100%;
  background-color: var(--color-background-13);
  border-radius: 0.5rem;
}

.progress-divV2Secondary {
  height: 10px;
  width: 100%;
  background-color: var(--color-background-13);
  border-radius: 0.5rem;
}

.progress {
  height: 15px;
  transition: 1s ease;
  transition-delay: 0.5s;
}

.progress-chatdelay {
  height: 4px;
  transition: 10s ease;
  transition-delay: 1s;
}

.progressV2 {
  height: 20px;
  transition: 1s ease;
  transition-delay: 0.5s;
}

.progressV3 {
  height: 12px;
  transition: 1s ease;
  transition-delay: 0.5s;
  margin-top: 6px;
}

.progressV2Secondary {
  height: 10px;
  transition: 1s ease;
  transition-delay: 0.5s;
}

.progressV2Secondary>span {
  font-size: 9px !important;
  line-height: 10px;
}

.table-wrap-bordered {
  border: 1px solid #e4e7ed;
}

.table-thead-color>thead>tr>th {
  background-color: #f5f7f9;
}

.imgEmblemTable {
  vertical-align: middle;
  margin-right: 5px;
  border-style: none;
}

table.lastMatches {
  font-size: 12px;
}

table.lastMatches tr:hover {
  cursor: default;
  background-color: var(--color-hover3);
}

table.lastMatches thead tr:hover {
  cursor: default;
  background-color: var(--color-hover) !important;
}

table.lastMatches tr td {
  height: 25px;
}

@media (max-width: 768px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 5px;
    overflow-x: scroll;
    overflow-y: hidden;
    border: none;
  }
  .table-responsive>.table {
    margin-bottom: 0;
    background-color: var(--color-background-10);
  }
  .table-responsive>.table>thead>tr>th, .table-responsive>.table>tbody>tr>th, .table-responsive>.table>tfoot>tr>th, .table-responsive>.table>thead>tr>td, .table-responsive>.table>tbody>tr>td, .table-responsive>.table>tfoot>tr>td {
    white-space: nowrap;
  }
  .table-responsive>.table-bordered {
    border: 0;
  }
  .table-responsive>.table-bordered>thead>tr>th:first-child, .table-responsive>.table-bordered>tbody>tr>th:first-child, .table-responsive>.table-bordered>tfoot>tr>th:first-child, .table-responsive>.table-bordered>thead>tr>td:first-child, .table-responsive>.table-bordered>tbody>tr>td:first-child, .table-responsive>.table-bordered>tfoot>tr>td:first-child {
    border-left: 0;
  }
  .table-responsive>.table-bordered>thead>tr>th:last-child, .table-responsive>.table-bordered>tbody>tr>th:last-child, .table-responsive>.table-bordered>tfoot>tr>th:last-child, .table-responsive>.table-bordered>thead>tr>td:last-child, .table-responsive>.table-bordered>tbody>tr>td:last-child, .table-responsive>.table-bordered>tfoot>tr>td:last-child {
    border-right: 0;
  }
  .table-responsive>.table-bordered>thead>tr:last-child>th, .table-responsive>.table-bordered>tbody>tr:last-child>th, .table-responsive>.table-bordered>tfoot>tr:last-child>th, .table-responsive>.table-bordered>thead>tr:last-child>td, .table-responsive>.table-bordered>tbody>tr:last-child>td, .table-responsive>.table-bordered>tfoot>tr:last-child>td {
    border-bottom: 0;
  }
}

@media (max-width: 768px) {
  .padLeftRightMobile {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 390px) {
  .overall-margin {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.statsCard {
  padding-bottom: 50px;
  background-image: linear-gradient( 90deg, rgba(236, 240, 246, 0.6) 8px, transparent 0), linear-gradient(90deg, rgba(236, 240, 246, 0.6) 8px, transparent 0), linear-gradient(90deg, rgba(236, 240, 246, 0.6) 8px, transparent 0), linear-gradient(90deg, rgba(236, 240, 246, 0.6) 8px, transparent 0), linear-gradient(90deg, rgba(236, 240, 246, 0.6) 8px, transparent 0), linear-gradient(90deg, rgba(236, 240, 246, 0.6) 8px, transparent 0), linear-gradient(90deg, rgba(0, 0, 0, 0.1) 8px, transparent 0), linear-gradient(90deg, rgba(0, 0, 0, 0.11) 8px, transparent 0);
  background-repeat: repeat-x;
  background-size: 72px 24px, 72px 30px, 72px 34px, 72px 40px, 72px 37px, 72px 29px, 144px 30px, 216px 37px;
  background-position: 0 100%, 12px 100%, 24px 100%, 36px 100%, 48px 100%, 60px 100%, 12px 100%, 48px 100%;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progressv2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #ebedf3;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #3699ff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient( 45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.progressv2.progress-xs {
  height: 24px;
  background: var(--color-bg-1);
}

.progressv2.progress-xs .progress-bar {
  border-radius: 0.28rem;
}

.progressv2.progress-sm {
  height: 0.75rem;
}

.progressv2.progress-sm .progress-bar {
  border-radius: 0.28rem;
}

.progressv2.progress-lg {
  height: 1.5rem;
}

.progressv2.progress-lg .progress-bar {
  border-radius: 0.85rem;
}

.mainColor {
  color: var(--buttons-buttonMain) !important;
}

.blackColor {
  color: #000000 !important;
}

.sm-80 {
  font-size: 80% !important;
}

.sm-70 {
  font-size: 70% !important;
}

.sm-60 {
  font-size: 60% !important;
}

.comparison-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 15px 0;
}

.comparison-bar .bar {
  max-width: 100%;
  margin: 0;
}

.comparison-bar.full .bar {
  margin: 0 1rem;
}

.comparison-bar .bar {
  background: transparent;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 20px;
  overflow: hidden;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 3px;
}

.comparison-bar .bar .bar-item.winner {
  color: #fff;
}

.comparison-bar .bar .bar-item {
  width: 55%;
  height: 20px;
  font-size: 9pt;
  font-weight: 500;
  line-height: 20px;
  padding-left: 5px;
  color: #fff;
  opacity: 0.6;
}

.comparison-bar .bar .bar-item:hover {
  opacity: 1;
}

.comparison-bar .bar .bar-item.draw {
  background: #ffb400;
  color: #fff;
}

.comparison-bar .bar .bar-item.loser {
  color: #fff;
}

.comparison-bar {
  margin: 0.5em 0 0.15em 0;
}

.cf {
  display: inline-table;
  min-height: 1%;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.pa {
  position: absolute;
}

.draw-line:before {
  content: "";
  width: 1px;
  left: 50%;
  top: -1.5em;
  z-index: 2;
  position: absolute;
  height: 20px;
  display: block;
  background: #697a8a;
}

@media (max-width: 768px) {
  .draw-line:before {
    content: "";
    width: 1px;
    left: 50%;
    top: -1.5em;
    z-index: 2;
    position: absolute;
    height: 10px;
    display: block;
    background: #697a8a;
  }
}

.ac {
  text-align: center;
}

.op5 {
  opacity: 0.5 !important;
}

.text-live {
  color: #e64646;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
}

.display-1 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.675rem + 5.1vw);
  }
}

.display-2 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.575rem + 3.9vw);
  }
}

.display-3 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.475rem + 2.7vw);
  }
}

.display-4 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.375rem + 1.5vw);
  }
}

.error.error-6 .error-title {
  font-size: 3rem !important;
}

@media (min-width: 768px) {
  .error.error-6 .error-title {
    font-size: 6rem !important;
  }
}

.card-shadow {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.d-block {
  display: block;
}

.yellow {
  color: #ffff00;
}

@media (min-width: 768px) {
  .bgHeaderHome {
    border-radius: 4px;
  }
}

@media (max-width: 768px) {
  .bgHeaderHome {
    border-radius: initial !important;
    margin-top: 0px !important;
  }
}

.bgCard {
  background-color: var(--color-background-1) !important;
  color: var(--color-blackAndWhiteR) !important;
}

.chatButtons {
  color: var(--color-background-16) !important;
}

.bgEvent {
  background-color: var(--color-background-16) !important;
}

.bgCard-2 {
  background-color: var(--color-background-2) !important;
}

.bgCard-14 {
  background-color: var(--color-background-14) !important;
}

.borderCard {
  border: 1px solid var(--color-background-1) !important;
}

.borderCard2 {
  border: 1px solid var(--color-border2) !important;
}

.bgCardSecondary {
  background-color: var(--color-background-10) !important;
  color: var(--color-blackAndWhiteR) !important;
}

.bgCardHeader {
  background-color: var(--color-background-11) !important;
  color: var(--color-blackAndWhiteR) !important;
}

.borderTopCard {
  border-top: 1px solid var(--color-border) !important;
}

.borderRightCard {
  border-right: 1px solid var(--color-border) !important;
}

.borderRightCard2 {
  border-right: 1px solid var(--color-border2) !important;
}

.pickCard {
  color: var(--color-picks) !important;
  margin-top: -8px;
  font-weight: 600;
  font-size: 16px;
}

.bgCardSlider {
  background-color: var(--color-background-1) !important;
  border-bottom: var(--color-background-1) !important;
}

@media (max-width: 768px) {
  .slickSliderMargin {
    margin-top: -8px;
    margin-left: -8px;
    margin-right: -8px;
  }
}

.optimizer-all-back {
  background-image: url();
  background-size: cover;
  -webkit-box-shadow: 0 3px 2px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1);
}

.white {
  color: #fff;
}

.dash-card {
  height: 440px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 1px;
  -webkit-box-shadow: 0px 3px 2px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.1);
  transition: border 0.1s ease-in-out;
  -moz-transition: border 0.1s ease-in-out;
  -webkit-transition: border 0.1s ease-in-out;
  -o-transition: border 0.1s ease-in-out;
}

.click-player-blank {
  font-size: 140%;
  color: #999;
  text-align: center;
  margin-top: 180px;
}

.dash-card-ticket {
  border-radius: 1px;
  transition: border 0.1s ease-in-out;
  -moz-transition: border 0.1s ease-in-out;
  -webkit-transition: border 0.1s ease-in-out;
  -o-transition: border 0.1s ease-in-out;
}

.click-player-blank-ticket {
  font-size: 120%;
  color: #999;
  text-align: center;
  margin-top: 20px;
  font-weight: initial;
}

.tableBorderDDD {
  border: 1px solid #ddd;
}

.red-highlight {
  background-color: rgb(244, 13, 75, 0.1);
}

.red-highlight-strong {
  background-color: rgb(244, 13, 75, 0.3);
}

.red-highlight-strongest {
  background-color: rgb(244, 13, 75, 0.5);
}

.blue-highlight {
  background-color: rgba(64, 153, 255, 0.1);
}

.green-highlight {
  background-color: rgba(50, 205, 50, 0.1);
}

.green-highlight-strong {
  background-color: rgba(50, 205, 50, 0.3);
}

.green-highlight-strongest {
  background-color: rgba(50, 205, 50, 0.5);
}

.blackColorAndBorder {
  color: #000000;
  border: 1px solid #000000;
}

.labelAddEveniment {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--color-bg-2);
  border-color: var(--color-border2);
  border-style: solid;
  border-width: 1px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  padding: 8px;
  color: var(--color-blackAndWhiteR);
}

@media (max-width: 768px) {
  .labelAddEveniment {
    font-size: 9px;
    background-color: var(--color-bg-3);
    border-color: var(--color-border3);
  }
}

.labelAddEveniment:focus {
  border: 1px solid var(--buttons-buttonMain);
}

.match-preview__action--ticket {
  padding: 20px 0px 0px;
  position: relative;
  margin-left: -25px;
  margin-right: -25px;
  overflow: hidden;
  background-image: linear-gradient(90deg, transparent 3px, #e4e7ed 0);
  background-repeat: repeat-x;
  background-size: 10px 1px;
  background-position: 0 22px;
}

@media (min-width: 768px) {
  .match-preview__action--ticket {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.match-preview__action--ticket:after, .match-preview__action--ticket:before {
  content: "";
  display: block;
  position: absolute;
  height: 22.5px;
  width: 22.5px;
  background-color: #edeff4;
  border: 1px solid #e4e7ed;
  border-radius: 50%;
  top: 11px;
}

.match-preview__action--ticket:before {
  left: -11px;
}

.match-preview__action--ticket:after {
  right: -11px;
}

.oddFinal {
  font-size: 24px;
  color: #ddd;
  text-align: center;
}

.emblemTicket {
  background-color: var(--color-bg-1);
  display: flex;
  padding: 8px;
  border-radius: 50px;
}

.emblemTicketHome {
  background-color: var(--color-bg-2);
  display: flex;
  padding: 8px;
  border-radius: 50px;
}

.emblemTicketSlider {
  display: flex;
  padding: 34px;
  border-radius: 50px;
}

.emblemHome {
  z-index: 2;
  border: 1px solid var(--color-border);
}

.emblemHomeSlider {
  z-index: 2;
  border: 1px solid var(--color-border2);
  background-color: #23232366;
}

.emblemAway {
  z-index: 1;
  border: 1px solid var(--color-border);
  margin-left: -8px;
}

.deleteButton {
  color: #fff !important;
  background-color: red !important;
  cursor: pointer;
  opacity: 0 !important;
  visibility: hidden !important;
  margin: 0px !important;
  width: 0 !important;
  min-width: 0 !important;
  transition: all 0.25s ease-in !important;
  border-radius: 0 !important;
  padding: 0px !important;
}

.deleteButton.active {
  color: #fff !important;
  background-color: red !important;
  cursor: pointer;
  margin: 0px !important;
  width: 40px !important;
  visibility: visible !important;
  opacity: 1 !important;
}

.ticket_margin {
  margin-right: -22px;
  margin-left: -22px;
}

.dashed_border_bottom {
  border-bottom: 1px dashed var(--color-border2);
}

.dashed_border_bottom:last-child {
  border-bottom: 0px;
}

@media (min-width: 768px) {
  .flex-direction-mobile {
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .flex-direction-mobile {
    flex-direction: column;
  }
}

.react-select__menu {
  z-index: 999 !important;
}

.boxMatch {
  margin-left: 0px;
  transition: all 0.25s ease-in !important;
}

.boxMatch.active {
  margin-left: -40px;
}

.fontWeight600 {
  font-weight: 600;
}

.colorTextButton {
  color: var(--color-textButton);
}

.separator.separator-solid {
  border-bottom: 1px solid var(--color-border2);
}

.separator.separator-ticket-desf {
  border-bottom: 4px solid #ebedf3;
}

.separator.separator-ticket-won {
  border-bottom: 4px solid #2bb759;
}

.separator.separator-ticket-lost {
  border-bottom: 4px solid #f64e60;
}

.separator {
  height: 0;
}

.gems {
  background-color: #fff;
  border: 1px solid var(--color-border);
  border-radius: 50%;
}

@media (min-width: 768px) {
  .miniChartM {
    margin-top: -20px;
    margin-bottom: -20px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (max-width: 768px) {
  .miniChartM {
    margin-top: -20px;
    margin-bottom: -20px;
    margin-left: -12px;
    margin-right: -12px;
  }
}

.swal-text {
  font-size: 16px;
  position: relative;
  float: none;
  line-height: normal;
  vertical-align: top;
  text-align: center !important;
  display: inline-block;
  margin: 0;
  padding: 0 10px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.64);
  max-width: calc(100% - 20px);
  overflow-wrap: break-word;
  box-sizing: border-box;
}

.cursor-default {
  cursor: default !important;
}

.custom-add-button {
  border-radius: 8px;
  width: 30px;
  box-shadow: 10px 15px 30px 13px rgba(0, 0, 0, 0.05);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-add-button span {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ddd;
  border-radius: 8px;
  color: #2f2f2f;
  font-size: 16px;
}

.custom-add-button:hover {
  background-color: #f2f2f2;
}

.custom-add-button span:hover {
  border: 1px dashed #ccc;
  color: #2f2f2f;
}

.tipster-add-button-sticky {
  z-index: 2;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background-color: var(--color-background-4);
  text-align: center;
  border-top: 1px solid var(--color-background-5);
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.left-0 {
  left: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.right-5 {
  right: 5px !important;
}

.right-20 {
  right: 20px !important;
}

.top--10 {
  top: -10px !important;
}

.top-0 {
  top: 0 !important;
}

.top-5 {
  top: 5px !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-20 {
  bottom: 20px !important;
}

.bottom-m6 {
  bottom: -6px !important;
}

.bottom-n30 {
  bottom: -30px !important;
}

.zindex-0 {
  z-index: 0;
}

.zindex-n1 {
  z-index: -1;
}

.zindex-1 {
  z-index: 1;
}

.zindex-2 {
  z-index: 2;
}

.zindex-3 {
  z-index: 3;
}

.zindex-4 {
  z-index: 4;
}

.zindex-5 {
  z-index: 5;
}

.zindex-11 {
  z-index: 11;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.svg-icon svg {
  height: 1.5rem;
  width: 1.5rem;
}

.svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}

.svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-icon-white svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.svg-icon.svg-icon-white svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-logo-white svg path {
  fill: #ffffff !important;
}

.svg-icon.svg-icon-primary svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #3699ff !important;
}

.svg-icon.svg-icon-primary svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-logo-primary svg path {
  fill: #3699ff !important;
}

.svg-icon.svg-icon-secondary svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #181c32 !important;
}

.svg-icon.svg-icon-secondary svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-logo-secondary svg path {
  fill: #181c32 !important;
}

.svg-icon.svg-icon-success svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1bc5bd !important;
}

.svg-icon.svg-icon-success svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-logo-success svg path {
  fill: #1bc5bd !important;
}

.svg-icon.svg-icon-info svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950fc !important;
}

.svg-icon.svg-icon-info svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-logo-info svg path {
  fill: #8950fc !important;
}

.svg-icon.svg-icon-warning svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffa800 !important;
}

.svg-icon.svg-icon-warning svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-logo-warning svg path {
  fill: #ffa800 !important;
}

.svg-icon.svg-icon-danger svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #f64e60 !important;
}

.svg-icon.svg-icon-danger svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-logo-danger svg path {
  fill: #f64e60 !important;
}

.svg-icon.svg-icon-light svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #f3f6f9 !important;
}

.svg-icon.svg-icon-light svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-logo-light svg path {
  fill: #f3f6f9 !important;
}

.svg-icon.svg-icon-dark svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #181c32 !important;
}

.svg-icon.svg-icon-dark svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-logo-dark svg path {
  fill: #181c32 !important;
}

.svg-icon.svg-icon-dark-75 svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #3f4254 !important;
}

.svg-icon.svg-icon-dark-75 svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-logo-dark-75 svg path {
  fill: #3f4254 !important;
}

.svg-icon.svg-icon-dark-65 svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #5e6278 !important;
}

.svg-icon.svg-icon-dark-65 svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-logo-dark-65 svg path {
  fill: #5e6278 !important;
}

.svg-icon.svg-icon-dark-50 svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #7e8299 !important;
}

.svg-icon.svg-icon-dark-50 svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-logo-dark-50 svg path {
  fill: #7e8299 !important;
}

.svg-icon.svg-icon-dark-25 svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #d1d3e0 !important;
}

.svg-icon.svg-icon-dark-25 svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-logo-dark-25 svg path {
  fill: #d1d3e0 !important;
}

.svg-icon.svg-icon-muted svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #b5b5c3 !important;
}

.svg-icon.svg-icon-muted svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.svg-icon.svg-logo-muted svg path {
  fill: #b5b5c3 !important;
}

.svg-icon.svg-icon-sm svg {
  height: 1.25rem !important;
  width: 1.25rem !important;
}

.svg-icon.svg-icon-md svg {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.svg-icon.svg-icon-lg svg {
  height: 1.75rem !important;
  width: 1.75rem !important;
}

.svg-icon.svg-icon-xl svg {
  height: 2rem !important;
  width: 2rem !important;
}

.svg-icon.svg-icon-xxl svg {
  height: 2.25rem !important;
  width: 2.25rem !important;
}

.svg-icon.svg-icon-1x svg {
  height: 1rem !important;
  width: 1rem !important;
}

.svg-icon.svg-icon-2x svg {
  height: 2rem !important;
  width: 2rem !important;
}

.svg-icon.svg-icon-3x svg {
  height: 3rem !important;
  width: 3rem !important;
}

.svg-icon.svg-icon-4x svg {
  height: 4rem !important;
  width: 4rem !important;
}

.svg-icon.svg-icon-5x svg {
  height: 5rem !important;
  width: 5rem !important;
}

.svg-icon.svg-icon-6x svg {
  height: 6rem !important;
  width: 6rem !important;
}

.svg-icon.svg-icon-7x svg {
  height: 7rem !important;
  width: 7rem !important;
}

.svg-icon.svg-icon-8x svg {
  height: 8rem !important;
  width: 8rem !important;
}

.svg-icon.svg-icon-9x svg {
  height: 9rem !important;
  width: 9rem !important;
}

.svg-icon.svg-icon-10x svg {
  height: 10rem !important;
  width: 10rem !important;
}

.svg-icon.svg-icon-full svg {
  height: 100% !important;
  width: 100% !important;
}

@media (min-width: 576px) {
  .svg-icon.svg-icon-sm-sm svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-sm-md svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-sm-lg svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-sm-xl svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-sm-xxl svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-sm-1x svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-sm-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-sm-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-sm-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-sm-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-sm-6x svg {
    height: 6rem !important;
    width: 6rem !important;
  }
  .svg-icon.svg-icon-sm-7x svg {
    height: 7rem !important;
    width: 7rem !important;
  }
  .svg-icon.svg-icon-sm-8x svg {
    height: 8rem !important;
    width: 8rem !important;
  }
  .svg-icon.svg-icon-sm-9x svg {
    height: 9rem !important;
    width: 9rem !important;
  }
  .svg-icon.svg-icon-sm-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-icon.svg-icon-sm-full svg {
    height: 100% !important;
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .svg-icon.svg-icon-md-sm svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-md-md svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-md-lg svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-md-xl svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-md-xxl svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-md-1x svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-md-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-md-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-md-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-md-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-md-6x svg {
    height: 6rem !important;
    width: 6rem !important;
  }
  .svg-icon.svg-icon-md-7x svg {
    height: 7rem !important;
    width: 7rem !important;
  }
  .svg-icon.svg-icon-md-8x svg {
    height: 8rem !important;
    width: 8rem !important;
  }
  .svg-icon.svg-icon-md-9x svg {
    height: 9rem !important;
    width: 9rem !important;
  }
  .svg-icon.svg-icon-md-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-icon.svg-icon-md-full svg {
    height: 100% !important;
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .svg-icon.svg-icon-lg-sm svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-lg-md svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-lg-lg svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-lg-xl svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-lg-xxl svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-lg-1x svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-lg-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-lg-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-lg-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-lg-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-lg-6x svg {
    height: 6rem !important;
    width: 6rem !important;
  }
  .svg-icon.svg-icon-lg-7x svg {
    height: 7rem !important;
    width: 7rem !important;
  }
  .svg-icon.svg-icon-lg-8x svg {
    height: 8rem !important;
    width: 8rem !important;
  }
  .svg-icon.svg-icon-lg-9x svg {
    height: 9rem !important;
    width: 9rem !important;
  }
  .svg-icon.svg-icon-lg-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-icon.svg-icon-lg-full svg {
    height: 100% !important;
    width: 100% !important;
  }
}

@media (min-width: 1200px) {
  .svg-icon.svg-icon-xl-sm svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-xl-md svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-xl-lg svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-xl-xl svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-xl-xxl svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-xl-1x svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-xl-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-xl-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-xl-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-xl-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-xl-6x svg {
    height: 6rem !important;
    width: 6rem !important;
  }
  .svg-icon.svg-icon-xl-7x svg {
    height: 7rem !important;
    width: 7rem !important;
  }
  .svg-icon.svg-icon-xl-8x svg {
    height: 8rem !important;
    width: 8rem !important;
  }
  .svg-icon.svg-icon-xl-9x svg {
    height: 9rem !important;
    width: 9rem !important;
  }
  .svg-icon.svg-icon-xl-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-icon.svg-icon-xl-full svg {
    height: 100% !important;
    width: 100% !important;
  }
}

@media (min-width: 1400px) {
  .svg-icon.svg-icon-xxl-sm svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-xxl-md svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-xxl-lg svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-xxl-xl svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-xxl-xxl svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-xxl-1x svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-xxl-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-xxl-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-xxl-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-xxl-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-xxl-6x svg {
    height: 6rem !important;
    width: 6rem !important;
  }
  .svg-icon.svg-icon-xxl-7x svg {
    height: 7rem !important;
    width: 7rem !important;
  }
  .svg-icon.svg-icon-xxl-8x svg {
    height: 8rem !important;
    width: 8rem !important;
  }
  .svg-icon.svg-icon-xxl-9x svg {
    height: 9rem !important;
    width: 9rem !important;
  }
  .svg-icon.svg-icon-xxl-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-icon.svg-icon-xxl-full svg {
    height: 100% !important;
    width: 100% !important;
  }
}

.svg-icon.svg-icon-flip svg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.svg.svg-fill-white {
  fill: #ffffff !important;
}

.svg.svg-fill-light-white {
  fill: #ffffff !important;
}

.svg.svg-fill-primary {
  fill: #3699ff !important;
}

.svg.svg-fill-light-primary {
  fill: #e1f0ff !important;
}

.svg.svg-fill-secondary {
  fill: #181c32 !important;
}

.svg.svg-fill-light-secondary {
  fill: #ebedf3 !important;
}

.svg.svg-fill-success {
  fill: #1bc5bd !important;
}

.svg.svg-fill-light-success {
  fill: #c9f7f5 !important;
}

.svg.svg-fill-info {
  fill: #8950fc !important;
}

.svg.svg-fill-light-info {
  fill: #eee5ff !important;
}

.svg.svg-fill-warning {
  fill: #ffa800 !important;
}

.svg.svg-fill-light-warning {
  fill: #fff4de !important;
}

.svg.svg-fill-danger {
  fill: #f64e60 !important;
}

.svg.svg-fill-light-danger {
  fill: #ffe2e5 !important;
}

.svg.svg-fill-light {
  fill: #f3f6f9 !important;
}

.svg.svg-fill-light-light {
  fill: #f3f6f9 !important;
}

.svg.svg-fill-dark {
  fill: #181c32 !important;
}

.svg.svg-fill-light-dark {
  fill: #d1d3e0 !important;
}

.svg.svg-fill-dark-75 {
  fill: #3f4254 !important;
}

.svg.svg-fill-dark-65 {
  fill: #5e6278 !important;
}

.svg.svg-fill-dark-50 {
  fill: #7e8299 !important;
}

.svg.svg-fill-dark-25 {
  fill: #d1d3e0 !important;
}

.svg.svg-fill-muted {
  fill: #b5b5c3 !important;
}

.opacity-1 {
  opacity: 0.01 !important;
}

.hover-opacity-1 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-1.active, .hover-opacity-1:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.01 !important;
}

.opacity-2 {
  opacity: 0.02 !important;
}

.hover-opacity-2 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-2.active, .hover-opacity-2:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.02 !important;
}

.opacity-3 {
  opacity: 0.03 !important;
}

.hover-opacity-3 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-3.active, .hover-opacity-3:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.03 !important;
}

.opacity-4 {
  opacity: 0.04 !important;
}

.hover-opacity-4 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-4.active, .hover-opacity-4:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.04 !important;
}

.opacity-5 {
  opacity: 0.05 !important;
}

.hover-opacity-5 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-5.active, .hover-opacity-5:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.05 !important;
}

.opacity-6 {
  opacity: 0.06 !important;
}

.hover-opacity-6 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-6.active, .hover-opacity-6:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.06 !important;
}

.opacity-7 {
  opacity: 0.07 !important;
}

.hover-opacity-7 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-7.active, .hover-opacity-7:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.07 !important;
}

.opacity-8 {
  opacity: 0.08 !important;
}

.hover-opacity-8 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-8.active, .hover-opacity-8:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.08 !important;
}

.opacity-9 {
  opacity: 0.09 !important;
}

.hover-opacity-9 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-9.active, .hover-opacity-9:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.09 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.hover-opacity-10 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-10.active, .hover-opacity-10:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.1 !important;
}

.opacity-11 {
  opacity: 0.11 !important;
}

.hover-opacity-11 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-11.active, .hover-opacity-11:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.11 !important;
}

.opacity-12 {
  opacity: 0.12 !important;
}

.hover-opacity-12 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-12.active, .hover-opacity-12:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.12 !important;
}

.opacity-13 {
  opacity: 0.13 !important;
}

.hover-opacity-13 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-13.active, .hover-opacity-13:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.13 !important;
}

.opacity-14 {
  opacity: 0.14 !important;
}

.hover-opacity-14 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-14.active, .hover-opacity-14:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.14 !important;
}

.opacity-15 {
  opacity: 0.15 !important;
}

.hover-opacity-15 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-15.active, .hover-opacity-15:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.15 !important;
}

.opacity-16 {
  opacity: 0.16 !important;
}

.hover-opacity-16 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-16.active, .hover-opacity-16:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.16 !important;
}

.opacity-17 {
  opacity: 0.17 !important;
}

.hover-opacity-17 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-17.active, .hover-opacity-17:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.17 !important;
}

.opacity-18 {
  opacity: 0.18 !important;
}

.hover-opacity-18 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-18.active, .hover-opacity-18:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.18 !important;
}

.opacity-19 {
  opacity: 0.19 !important;
}

.hover-opacity-19 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-19.active, .hover-opacity-19:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.19 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.hover-opacity-20 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-20.active, .hover-opacity-20:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.2 !important;
}

.opacity-21 {
  opacity: 0.21 !important;
}

.hover-opacity-21 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-21.active, .hover-opacity-21:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.21 !important;
}

.opacity-22 {
  opacity: 0.22 !important;
}

.hover-opacity-22 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-22.active, .hover-opacity-22:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.22 !important;
}

.opacity-23 {
  opacity: 0.23 !important;
}

.hover-opacity-23 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-23.active, .hover-opacity-23:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.23 !important;
}

.opacity-24 {
  opacity: 0.24 !important;
}

.hover-opacity-24 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-24.active, .hover-opacity-24:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.24 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.hover-opacity-25 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-25.active, .hover-opacity-25:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.25 !important;
}

.opacity-26 {
  opacity: 0.26 !important;
}

.hover-opacity-26 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-26.active, .hover-opacity-26:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.26 !important;
}

.opacity-27 {
  opacity: 0.27 !important;
}

.hover-opacity-27 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-27.active, .hover-opacity-27:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.27 !important;
}

.opacity-28 {
  opacity: 0.28 !important;
}

.hover-opacity-28 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-28.active, .hover-opacity-28:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.28 !important;
}

.opacity-29 {
  opacity: 0.29 !important;
}

.hover-opacity-29 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-29.active, .hover-opacity-29:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.29 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.hover-opacity-30 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-30.active, .hover-opacity-30:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.3 !important;
}

.opacity-31 {
  opacity: 0.31 !important;
}

.hover-opacity-31 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-31.active, .hover-opacity-31:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.31 !important;
}

.opacity-32 {
  opacity: 0.32 !important;
}

.hover-opacity-32 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-32.active, .hover-opacity-32:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.32 !important;
}

.opacity-33 {
  opacity: 0.33 !important;
}

.hover-opacity-33 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-33.active, .hover-opacity-33:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.33 !important;
}

.opacity-34 {
  opacity: 0.34 !important;
}

.hover-opacity-34 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-34.active, .hover-opacity-34:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.34 !important;
}

.opacity-35 {
  opacity: 0.35 !important;
}

.hover-opacity-35 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-35.active, .hover-opacity-35:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.35 !important;
}

.opacity-36 {
  opacity: 0.36 !important;
}

.hover-opacity-36 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-36.active, .hover-opacity-36:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.36 !important;
}

.opacity-37 {
  opacity: 0.37 !important;
}

.hover-opacity-37 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-37.active, .hover-opacity-37:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.37 !important;
}

.opacity-38 {
  opacity: 0.38 !important;
}

.hover-opacity-38 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-38.active, .hover-opacity-38:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.38 !important;
}

.opacity-39 {
  opacity: 0.39 !important;
}

.hover-opacity-39 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-39.active, .hover-opacity-39:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.39 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.hover-opacity-40 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-40.active, .hover-opacity-40:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.4 !important;
}

.opacity-41 {
  opacity: 0.41 !important;
}

.hover-opacity-41 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-41.active, .hover-opacity-41:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.41 !important;
}

.opacity-42 {
  opacity: 0.42 !important;
}

.hover-opacity-42 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-42.active, .hover-opacity-42:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.42 !important;
}

.opacity-43 {
  opacity: 0.43 !important;
}

.hover-opacity-43 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-43.active, .hover-opacity-43:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.43 !important;
}

.opacity-44 {
  opacity: 0.44 !important;
}

.hover-opacity-44 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-44.active, .hover-opacity-44:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.44 !important;
}

.opacity-45 {
  opacity: 0.45 !important;
}

.hover-opacity-45 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-45.active, .hover-opacity-45:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.45 !important;
}

.opacity-46 {
  opacity: 0.46 !important;
}

.hover-opacity-46 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-46.active, .hover-opacity-46:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.46 !important;
}

.opacity-47 {
  opacity: 0.47 !important;
}

.hover-opacity-47 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-47.active, .hover-opacity-47:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.47 !important;
}

.opacity-48 {
  opacity: 0.48 !important;
}

.hover-opacity-48 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-48.active, .hover-opacity-48:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.48 !important;
}

.opacity-49 {
  opacity: 0.49 !important;
}

.hover-opacity-49 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-49.active, .hover-opacity-49:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.49 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.hover-opacity-50 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-50.active, .hover-opacity-50:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.5 !important;
}

.opacity-51 {
  opacity: 0.51 !important;
}

.hover-opacity-51 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-51.active, .hover-opacity-51:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.51 !important;
}

.opacity-52 {
  opacity: 0.52 !important;
}

.hover-opacity-52 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-52.active, .hover-opacity-52:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.52 !important;
}

.opacity-53 {
  opacity: 0.53 !important;
}

.hover-opacity-53 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-53.active, .hover-opacity-53:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.53 !important;
}

.opacity-54 {
  opacity: 0.54 !important;
}

.hover-opacity-54 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-54.active, .hover-opacity-54:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.54 !important;
}

.opacity-55 {
  opacity: 0.55 !important;
}

.hover-opacity-55 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-55.active, .hover-opacity-55:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.55 !important;
}

.opacity-56 {
  opacity: 0.56 !important;
}

.hover-opacity-56 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-56.active, .hover-opacity-56:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.56 !important;
}

.opacity-57 {
  opacity: 0.57 !important;
}

.hover-opacity-57 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-57.active, .hover-opacity-57:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.57 !important;
}

.opacity-58 {
  opacity: 0.58 !important;
}

.hover-opacity-58 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-58.active, .hover-opacity-58:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.58 !important;
}

.opacity-59 {
  opacity: 0.59 !important;
}

.hover-opacity-59 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-59.active, .hover-opacity-59:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.59 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.hover-opacity-60 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-60.active, .hover-opacity-60:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.6 !important;
}

.opacity-61 {
  opacity: 0.61 !important;
}

.hover-opacity-61 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-61.active, .hover-opacity-61:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.61 !important;
}

.opacity-62 {
  opacity: 0.62 !important;
}

.hover-opacity-62 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-62.active, .hover-opacity-62:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.62 !important;
}

.opacity-63 {
  opacity: 0.63 !important;
}

.hover-opacity-63 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-63.active, .hover-opacity-63:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.63 !important;
}

.opacity-64 {
  opacity: 0.64 !important;
}

.hover-opacity-64 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-64.active, .hover-opacity-64:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.64 !important;
}

.opacity-65 {
  opacity: 0.65 !important;
}

.hover-opacity-65 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-65.active, .hover-opacity-65:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.65 !important;
}

.opacity-66 {
  opacity: 0.66 !important;
}

.hover-opacity-66 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-66.active, .hover-opacity-66:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.66 !important;
}

.opacity-67 {
  opacity: 0.67 !important;
}

.hover-opacity-67 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-67.active, .hover-opacity-67:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.67 !important;
}

.opacity-68 {
  opacity: 0.68 !important;
}

.hover-opacity-68 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-68.active, .hover-opacity-68:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.68 !important;
}

.opacity-69 {
  opacity: 0.69 !important;
}

.hover-opacity-69 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-69.active, .hover-opacity-69:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.69 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.hover-opacity-70 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-70.active, .hover-opacity-70:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.7 !important;
}

.opacity-71 {
  opacity: 0.71 !important;
}

.hover-opacity-71 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-71.active, .hover-opacity-71:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.71 !important;
}

.opacity-72 {
  opacity: 0.72 !important;
}

.hover-opacity-72 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-72.active, .hover-opacity-72:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.72 !important;
}

.opacity-73 {
  opacity: 0.73 !important;
}

.hover-opacity-73 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-73.active, .hover-opacity-73:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.73 !important;
}

.opacity-74 {
  opacity: 0.74 !important;
}

.hover-opacity-74 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-74.active, .hover-opacity-74:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.74 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.hover-opacity-75 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-75.active, .hover-opacity-75:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.75 !important;
}

.opacity-76 {
  opacity: 0.76 !important;
}

.hover-opacity-76 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-76.active, .hover-opacity-76:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.76 !important;
}

.opacity-77 {
  opacity: 0.77 !important;
}

.hover-opacity-77 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-77.active, .hover-opacity-77:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.77 !important;
}

.opacity-78 {
  opacity: 0.78 !important;
}

.hover-opacity-78 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-78.active, .hover-opacity-78:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.78 !important;
}

.opacity-79 {
  opacity: 0.79 !important;
}

.hover-opacity-79 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-79.active, .hover-opacity-79:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.79 !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.hover-opacity-80 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-80.active, .hover-opacity-80:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.8 !important;
}

.opacity-81 {
  opacity: 0.81 !important;
}

.hover-opacity-81 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-81.active, .hover-opacity-81:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.81 !important;
}

.opacity-82 {
  opacity: 0.82 !important;
}

.hover-opacity-82 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-82.active, .hover-opacity-82:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.82 !important;
}

.opacity-83 {
  opacity: 0.83 !important;
}

.hover-opacity-83 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-83.active, .hover-opacity-83:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.83 !important;
}

.opacity-84 {
  opacity: 0.84 !important;
}

.hover-opacity-84 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-84.active, .hover-opacity-84:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.84 !important;
}

.opacity-85 {
  opacity: 0.85 !important;
}

.hover-opacity-85 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-85.active, .hover-opacity-85:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.85 !important;
}

.opacity-86 {
  opacity: 0.86 !important;
}

.hover-opacity-86 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-86.active, .hover-opacity-86:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.86 !important;
}

.opacity-87 {
  opacity: 0.87 !important;
}

.hover-opacity-87 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-87.active, .hover-opacity-87:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.87 !important;
}

.opacity-88 {
  opacity: 0.88 !important;
}

.hover-opacity-88 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-88.active, .hover-opacity-88:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.88 !important;
}

.opacity-89 {
  opacity: 0.89 !important;
}

.hover-opacity-89 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-89.active, .hover-opacity-89:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.89 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.hover-opacity-90 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-90.active, .hover-opacity-90:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.9 !important;
}

.opacity-91 {
  opacity: 0.91 !important;
}

.hover-opacity-91 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-91.active, .hover-opacity-91:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.91 !important;
}

.opacity-92 {
  opacity: 0.92 !important;
}

.hover-opacity-92 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-92.active, .hover-opacity-92:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.92 !important;
}

.opacity-93 {
  opacity: 0.93 !important;
}

.hover-opacity-93 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-93.active, .hover-opacity-93:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.93 !important;
}

.opacity-94 {
  opacity: 0.94 !important;
}

.hover-opacity-94 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-94.active, .hover-opacity-94:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.94 !important;
}

.opacity-95 {
  opacity: 0.95 !important;
}

.hover-opacity-95 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-95.active, .hover-opacity-95:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.95 !important;
}

.opacity-96 {
  opacity: 0.96 !important;
}

.hover-opacity-96 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-96.active, .hover-opacity-96:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.96 !important;
}

.opacity-97 {
  opacity: 0.97 !important;
}

.hover-opacity-97 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-97.active, .hover-opacity-97:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.97 !important;
}

.opacity-98 {
  opacity: 0.98 !important;
}

.hover-opacity-98 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-98.active, .hover-opacity-98:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.98 !important;
}

.opacity-99 {
  opacity: 0.99 !important;
}

.hover-opacity-99 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-99.active, .hover-opacity-99:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 0.99 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.hover-opacity-100 {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.hover-opacity-100.active, .hover-opacity-100:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  opacity: 1 !important;
}

@media (min-width: 576px) {
  .opacity-sm-1 {
    opacity: 0.01 !important;
  }
  .hover-opacity-sm-1 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-1.active, .hover-opacity-sm-1:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.01 !important;
  }
  .opacity-sm-2 {
    opacity: 0.02 !important;
  }
  .hover-opacity-sm-2 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-2.active, .hover-opacity-sm-2:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.02 !important;
  }
  .opacity-sm-3 {
    opacity: 0.03 !important;
  }
  .hover-opacity-sm-3 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-3.active, .hover-opacity-sm-3:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.03 !important;
  }
  .opacity-sm-4 {
    opacity: 0.04 !important;
  }
  .hover-opacity-sm-4 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-4.active, .hover-opacity-sm-4:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.04 !important;
  }
  .opacity-sm-5 {
    opacity: 0.05 !important;
  }
  .hover-opacity-sm-5 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-5.active, .hover-opacity-sm-5:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.05 !important;
  }
  .opacity-sm-6 {
    opacity: 0.06 !important;
  }
  .hover-opacity-sm-6 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-6.active, .hover-opacity-sm-6:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.06 !important;
  }
  .opacity-sm-7 {
    opacity: 0.07 !important;
  }
  .hover-opacity-sm-7 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-7.active, .hover-opacity-sm-7:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.07 !important;
  }
  .opacity-sm-8 {
    opacity: 0.08 !important;
  }
  .hover-opacity-sm-8 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-8.active, .hover-opacity-sm-8:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.08 !important;
  }
  .opacity-sm-9 {
    opacity: 0.09 !important;
  }
  .hover-opacity-sm-9 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-9.active, .hover-opacity-sm-9:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.09 !important;
  }
  .opacity-sm-10 {
    opacity: 0.1 !important;
  }
  .hover-opacity-sm-10 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-10.active, .hover-opacity-sm-10:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.1 !important;
  }
  .opacity-sm-11 {
    opacity: 0.11 !important;
  }
  .hover-opacity-sm-11 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-11.active, .hover-opacity-sm-11:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.11 !important;
  }
  .opacity-sm-12 {
    opacity: 0.12 !important;
  }
  .hover-opacity-sm-12 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-12.active, .hover-opacity-sm-12:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.12 !important;
  }
  .opacity-sm-13 {
    opacity: 0.13 !important;
  }
  .hover-opacity-sm-13 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-13.active, .hover-opacity-sm-13:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.13 !important;
  }
  .opacity-sm-14 {
    opacity: 0.14 !important;
  }
  .hover-opacity-sm-14 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-14.active, .hover-opacity-sm-14:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.14 !important;
  }
  .opacity-sm-15 {
    opacity: 0.15 !important;
  }
  .hover-opacity-sm-15 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-15.active, .hover-opacity-sm-15:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.15 !important;
  }
  .opacity-sm-16 {
    opacity: 0.16 !important;
  }
  .hover-opacity-sm-16 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-16.active, .hover-opacity-sm-16:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.16 !important;
  }
  .opacity-sm-17 {
    opacity: 0.17 !important;
  }
  .hover-opacity-sm-17 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-17.active, .hover-opacity-sm-17:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.17 !important;
  }
  .opacity-sm-18 {
    opacity: 0.18 !important;
  }
  .hover-opacity-sm-18 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-18.active, .hover-opacity-sm-18:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.18 !important;
  }
  .opacity-sm-19 {
    opacity: 0.19 !important;
  }
  .hover-opacity-sm-19 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-19.active, .hover-opacity-sm-19:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.19 !important;
  }
  .opacity-sm-20 {
    opacity: 0.2 !important;
  }
  .hover-opacity-sm-20 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-20.active, .hover-opacity-sm-20:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.2 !important;
  }
  .opacity-sm-21 {
    opacity: 0.21 !important;
  }
  .hover-opacity-sm-21 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-21.active, .hover-opacity-sm-21:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.21 !important;
  }
  .opacity-sm-22 {
    opacity: 0.22 !important;
  }
  .hover-opacity-sm-22 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-22.active, .hover-opacity-sm-22:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.22 !important;
  }
  .opacity-sm-23 {
    opacity: 0.23 !important;
  }
  .hover-opacity-sm-23 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-23.active, .hover-opacity-sm-23:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.23 !important;
  }
  .opacity-sm-24 {
    opacity: 0.24 !important;
  }
  .hover-opacity-sm-24 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-24.active, .hover-opacity-sm-24:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.24 !important;
  }
  .opacity-sm-25 {
    opacity: 0.25 !important;
  }
  .hover-opacity-sm-25 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-25.active, .hover-opacity-sm-25:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.25 !important;
  }
  .opacity-sm-26 {
    opacity: 0.26 !important;
  }
  .hover-opacity-sm-26 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-26.active, .hover-opacity-sm-26:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.26 !important;
  }
  .opacity-sm-27 {
    opacity: 0.27 !important;
  }
  .hover-opacity-sm-27 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-27.active, .hover-opacity-sm-27:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.27 !important;
  }
  .opacity-sm-28 {
    opacity: 0.28 !important;
  }
  .hover-opacity-sm-28 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-28.active, .hover-opacity-sm-28:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.28 !important;
  }
  .opacity-sm-29 {
    opacity: 0.29 !important;
  }
  .hover-opacity-sm-29 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-29.active, .hover-opacity-sm-29:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.29 !important;
  }
  .opacity-sm-30 {
    opacity: 0.3 !important;
  }
  .hover-opacity-sm-30 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-30.active, .hover-opacity-sm-30:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.3 !important;
  }
  .opacity-sm-31 {
    opacity: 0.31 !important;
  }
  .hover-opacity-sm-31 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-31.active, .hover-opacity-sm-31:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.31 !important;
  }
  .opacity-sm-32 {
    opacity: 0.32 !important;
  }
  .hover-opacity-sm-32 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-32.active, .hover-opacity-sm-32:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.32 !important;
  }
  .opacity-sm-33 {
    opacity: 0.33 !important;
  }
  .hover-opacity-sm-33 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-33.active, .hover-opacity-sm-33:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.33 !important;
  }
  .opacity-sm-34 {
    opacity: 0.34 !important;
  }
  .hover-opacity-sm-34 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-34.active, .hover-opacity-sm-34:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.34 !important;
  }
  .opacity-sm-35 {
    opacity: 0.35 !important;
  }
  .hover-opacity-sm-35 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-35.active, .hover-opacity-sm-35:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.35 !important;
  }
  .opacity-sm-36 {
    opacity: 0.36 !important;
  }
  .hover-opacity-sm-36 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-36.active, .hover-opacity-sm-36:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.36 !important;
  }
  .opacity-sm-37 {
    opacity: 0.37 !important;
  }
  .hover-opacity-sm-37 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-37.active, .hover-opacity-sm-37:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.37 !important;
  }
  .opacity-sm-38 {
    opacity: 0.38 !important;
  }
  .hover-opacity-sm-38 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-38.active, .hover-opacity-sm-38:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.38 !important;
  }
  .opacity-sm-39 {
    opacity: 0.39 !important;
  }
  .hover-opacity-sm-39 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-39.active, .hover-opacity-sm-39:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.39 !important;
  }
  .opacity-sm-40 {
    opacity: 0.4 !important;
  }
  .hover-opacity-sm-40 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-40.active, .hover-opacity-sm-40:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.4 !important;
  }
  .opacity-sm-41 {
    opacity: 0.41 !important;
  }
  .hover-opacity-sm-41 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-41.active, .hover-opacity-sm-41:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.41 !important;
  }
  .opacity-sm-42 {
    opacity: 0.42 !important;
  }
  .hover-opacity-sm-42 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-42.active, .hover-opacity-sm-42:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.42 !important;
  }
  .opacity-sm-43 {
    opacity: 0.43 !important;
  }
  .hover-opacity-sm-43 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-43.active, .hover-opacity-sm-43:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.43 !important;
  }
  .opacity-sm-44 {
    opacity: 0.44 !important;
  }
  .hover-opacity-sm-44 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-44.active, .hover-opacity-sm-44:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.44 !important;
  }
  .opacity-sm-45 {
    opacity: 0.45 !important;
  }
  .hover-opacity-sm-45 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-45.active, .hover-opacity-sm-45:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.45 !important;
  }
  .opacity-sm-46 {
    opacity: 0.46 !important;
  }
  .hover-opacity-sm-46 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-46.active, .hover-opacity-sm-46:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.46 !important;
  }
  .opacity-sm-47 {
    opacity: 0.47 !important;
  }
  .hover-opacity-sm-47 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-47.active, .hover-opacity-sm-47:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.47 !important;
  }
  .opacity-sm-48 {
    opacity: 0.48 !important;
  }
  .hover-opacity-sm-48 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-48.active, .hover-opacity-sm-48:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.48 !important;
  }
  .opacity-sm-49 {
    opacity: 0.49 !important;
  }
  .hover-opacity-sm-49 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-49.active, .hover-opacity-sm-49:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.49 !important;
  }
  .opacity-sm-50 {
    opacity: 0.5 !important;
  }
  .hover-opacity-sm-50 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-50.active, .hover-opacity-sm-50:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.5 !important;
  }
  .opacity-sm-51 {
    opacity: 0.51 !important;
  }
  .hover-opacity-sm-51 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-51.active, .hover-opacity-sm-51:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.51 !important;
  }
  .opacity-sm-52 {
    opacity: 0.52 !important;
  }
  .hover-opacity-sm-52 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-52.active, .hover-opacity-sm-52:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.52 !important;
  }
  .opacity-sm-53 {
    opacity: 0.53 !important;
  }
  .hover-opacity-sm-53 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-53.active, .hover-opacity-sm-53:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.53 !important;
  }
  .opacity-sm-54 {
    opacity: 0.54 !important;
  }
  .hover-opacity-sm-54 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-54.active, .hover-opacity-sm-54:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.54 !important;
  }
  .opacity-sm-55 {
    opacity: 0.55 !important;
  }
  .hover-opacity-sm-55 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-55.active, .hover-opacity-sm-55:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.55 !important;
  }
  .opacity-sm-56 {
    opacity: 0.56 !important;
  }
  .hover-opacity-sm-56 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-56.active, .hover-opacity-sm-56:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.56 !important;
  }
  .opacity-sm-57 {
    opacity: 0.57 !important;
  }
  .hover-opacity-sm-57 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-57.active, .hover-opacity-sm-57:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.57 !important;
  }
  .opacity-sm-58 {
    opacity: 0.58 !important;
  }
  .hover-opacity-sm-58 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-58.active, .hover-opacity-sm-58:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.58 !important;
  }
  .opacity-sm-59 {
    opacity: 0.59 !important;
  }
  .hover-opacity-sm-59 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-59.active, .hover-opacity-sm-59:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.59 !important;
  }
  .opacity-sm-60 {
    opacity: 0.6 !important;
  }
  .hover-opacity-sm-60 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-60.active, .hover-opacity-sm-60:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.6 !important;
  }
  .opacity-sm-61 {
    opacity: 0.61 !important;
  }
  .hover-opacity-sm-61 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-61.active, .hover-opacity-sm-61:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.61 !important;
  }
  .opacity-sm-62 {
    opacity: 0.62 !important;
  }
  .hover-opacity-sm-62 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-62.active, .hover-opacity-sm-62:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.62 !important;
  }
  .opacity-sm-63 {
    opacity: 0.63 !important;
  }
  .hover-opacity-sm-63 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-63.active, .hover-opacity-sm-63:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.63 !important;
  }
  .opacity-sm-64 {
    opacity: 0.64 !important;
  }
  .hover-opacity-sm-64 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-64.active, .hover-opacity-sm-64:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.64 !important;
  }
  .opacity-sm-65 {
    opacity: 0.65 !important;
  }
  .hover-opacity-sm-65 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-65.active, .hover-opacity-sm-65:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.65 !important;
  }
  .opacity-sm-66 {
    opacity: 0.66 !important;
  }
  .hover-opacity-sm-66 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-66.active, .hover-opacity-sm-66:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.66 !important;
  }
  .opacity-sm-67 {
    opacity: 0.67 !important;
  }
  .hover-opacity-sm-67 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-67.active, .hover-opacity-sm-67:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.67 !important;
  }
  .opacity-sm-68 {
    opacity: 0.68 !important;
  }
  .hover-opacity-sm-68 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-68.active, .hover-opacity-sm-68:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.68 !important;
  }
  .opacity-sm-69 {
    opacity: 0.69 !important;
  }
  .hover-opacity-sm-69 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-69.active, .hover-opacity-sm-69:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.69 !important;
  }
  .opacity-sm-70 {
    opacity: 0.7 !important;
  }
  .hover-opacity-sm-70 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-70.active, .hover-opacity-sm-70:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.7 !important;
  }
  .opacity-sm-71 {
    opacity: 0.71 !important;
  }
  .hover-opacity-sm-71 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-71.active, .hover-opacity-sm-71:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.71 !important;
  }
  .opacity-sm-72 {
    opacity: 0.72 !important;
  }
  .hover-opacity-sm-72 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-72.active, .hover-opacity-sm-72:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.72 !important;
  }
  .opacity-sm-73 {
    opacity: 0.73 !important;
  }
  .hover-opacity-sm-73 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-73.active, .hover-opacity-sm-73:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.73 !important;
  }
  .opacity-sm-74 {
    opacity: 0.74 !important;
  }
  .hover-opacity-sm-74 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-74.active, .hover-opacity-sm-74:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.74 !important;
  }
  .opacity-sm-75 {
    opacity: 0.75 !important;
  }
  .hover-opacity-sm-75 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-75.active, .hover-opacity-sm-75:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.75 !important;
  }
  .opacity-sm-76 {
    opacity: 0.76 !important;
  }
  .hover-opacity-sm-76 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-76.active, .hover-opacity-sm-76:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.76 !important;
  }
  .opacity-sm-77 {
    opacity: 0.77 !important;
  }
  .hover-opacity-sm-77 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-77.active, .hover-opacity-sm-77:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.77 !important;
  }
  .opacity-sm-78 {
    opacity: 0.78 !important;
  }
  .hover-opacity-sm-78 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-78.active, .hover-opacity-sm-78:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.78 !important;
  }
  .opacity-sm-79 {
    opacity: 0.79 !important;
  }
  .hover-opacity-sm-79 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-79.active, .hover-opacity-sm-79:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.79 !important;
  }
  .opacity-sm-80 {
    opacity: 0.8 !important;
  }
  .hover-opacity-sm-80 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-80.active, .hover-opacity-sm-80:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.8 !important;
  }
  .opacity-sm-81 {
    opacity: 0.81 !important;
  }
  .hover-opacity-sm-81 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-81.active, .hover-opacity-sm-81:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.81 !important;
  }
  .opacity-sm-82 {
    opacity: 0.82 !important;
  }
  .hover-opacity-sm-82 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-82.active, .hover-opacity-sm-82:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.82 !important;
  }
  .opacity-sm-83 {
    opacity: 0.83 !important;
  }
  .hover-opacity-sm-83 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-83.active, .hover-opacity-sm-83:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.83 !important;
  }
  .opacity-sm-84 {
    opacity: 0.84 !important;
  }
  .hover-opacity-sm-84 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-84.active, .hover-opacity-sm-84:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.84 !important;
  }
  .opacity-sm-85 {
    opacity: 0.85 !important;
  }
  .hover-opacity-sm-85 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-85.active, .hover-opacity-sm-85:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.85 !important;
  }
  .opacity-sm-86 {
    opacity: 0.86 !important;
  }
  .hover-opacity-sm-86 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-86.active, .hover-opacity-sm-86:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.86 !important;
  }
  .opacity-sm-87 {
    opacity: 0.87 !important;
  }
  .hover-opacity-sm-87 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-87.active, .hover-opacity-sm-87:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.87 !important;
  }
  .opacity-sm-88 {
    opacity: 0.88 !important;
  }
  .hover-opacity-sm-88 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-88.active, .hover-opacity-sm-88:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.88 !important;
  }
  .opacity-sm-89 {
    opacity: 0.89 !important;
  }
  .hover-opacity-sm-89 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-89.active, .hover-opacity-sm-89:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.89 !important;
  }
  .opacity-sm-90 {
    opacity: 0.9 !important;
  }
  .hover-opacity-sm-90 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-90.active, .hover-opacity-sm-90:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.9 !important;
  }
  .opacity-sm-91 {
    opacity: 0.91 !important;
  }
  .hover-opacity-sm-91 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-91.active, .hover-opacity-sm-91:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.91 !important;
  }
  .opacity-sm-92 {
    opacity: 0.92 !important;
  }
  .hover-opacity-sm-92 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-92.active, .hover-opacity-sm-92:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.92 !important;
  }
  .opacity-sm-93 {
    opacity: 0.93 !important;
  }
  .hover-opacity-sm-93 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-93.active, .hover-opacity-sm-93:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.93 !important;
  }
  .opacity-sm-94 {
    opacity: 0.94 !important;
  }
  .hover-opacity-sm-94 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-94.active, .hover-opacity-sm-94:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.94 !important;
  }
  .opacity-sm-95 {
    opacity: 0.95 !important;
  }
  .hover-opacity-sm-95 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-95.active, .hover-opacity-sm-95:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.95 !important;
  }
  .opacity-sm-96 {
    opacity: 0.96 !important;
  }
  .hover-opacity-sm-96 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-96.active, .hover-opacity-sm-96:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.96 !important;
  }
  .opacity-sm-97 {
    opacity: 0.97 !important;
  }
  .hover-opacity-sm-97 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-97.active, .hover-opacity-sm-97:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.97 !important;
  }
  .opacity-sm-98 {
    opacity: 0.98 !important;
  }
  .hover-opacity-sm-98 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-98.active, .hover-opacity-sm-98:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.98 !important;
  }
  .opacity-sm-99 {
    opacity: 0.99 !important;
  }
  .hover-opacity-sm-99 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-99.active, .hover-opacity-sm-99:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.99 !important;
  }
  .opacity-sm-100 {
    opacity: 1 !important;
  }
  .hover-opacity-sm-100 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-sm-100.active, .hover-opacity-sm-100:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 1 !important;
  }
}

@media (min-width: 768px) {
  .opacity-md-1 {
    opacity: 0.01 !important;
  }
  .hover-opacity-md-1 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-1.active, .hover-opacity-md-1:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.01 !important;
  }
  .opacity-md-2 {
    opacity: 0.02 !important;
  }
  .hover-opacity-md-2 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-2.active, .hover-opacity-md-2:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.02 !important;
  }
  .opacity-md-3 {
    opacity: 0.03 !important;
  }
  .hover-opacity-md-3 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-3.active, .hover-opacity-md-3:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.03 !important;
  }
  .opacity-md-4 {
    opacity: 0.04 !important;
  }
  .hover-opacity-md-4 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-4.active, .hover-opacity-md-4:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.04 !important;
  }
  .opacity-md-5 {
    opacity: 0.05 !important;
  }
  .hover-opacity-md-5 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-5.active, .hover-opacity-md-5:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.05 !important;
  }
  .opacity-md-6 {
    opacity: 0.06 !important;
  }
  .hover-opacity-md-6 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-6.active, .hover-opacity-md-6:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.06 !important;
  }
  .opacity-md-7 {
    opacity: 0.07 !important;
  }
  .hover-opacity-md-7 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-7.active, .hover-opacity-md-7:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.07 !important;
  }
  .opacity-md-8 {
    opacity: 0.08 !important;
  }
  .hover-opacity-md-8 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-8.active, .hover-opacity-md-8:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.08 !important;
  }
  .opacity-md-9 {
    opacity: 0.09 !important;
  }
  .hover-opacity-md-9 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-9.active, .hover-opacity-md-9:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.09 !important;
  }
  .opacity-md-10 {
    opacity: 0.1 !important;
  }
  .hover-opacity-md-10 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-10.active, .hover-opacity-md-10:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.1 !important;
  }
  .opacity-md-11 {
    opacity: 0.11 !important;
  }
  .hover-opacity-md-11 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-11.active, .hover-opacity-md-11:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.11 !important;
  }
  .opacity-md-12 {
    opacity: 0.12 !important;
  }
  .hover-opacity-md-12 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-12.active, .hover-opacity-md-12:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.12 !important;
  }
  .opacity-md-13 {
    opacity: 0.13 !important;
  }
  .hover-opacity-md-13 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-13.active, .hover-opacity-md-13:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.13 !important;
  }
  .opacity-md-14 {
    opacity: 0.14 !important;
  }
  .hover-opacity-md-14 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-14.active, .hover-opacity-md-14:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.14 !important;
  }
  .opacity-md-15 {
    opacity: 0.15 !important;
  }
  .hover-opacity-md-15 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-15.active, .hover-opacity-md-15:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.15 !important;
  }
  .opacity-md-16 {
    opacity: 0.16 !important;
  }
  .hover-opacity-md-16 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-16.active, .hover-opacity-md-16:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.16 !important;
  }
  .opacity-md-17 {
    opacity: 0.17 !important;
  }
  .hover-opacity-md-17 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-17.active, .hover-opacity-md-17:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.17 !important;
  }
  .opacity-md-18 {
    opacity: 0.18 !important;
  }
  .hover-opacity-md-18 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-18.active, .hover-opacity-md-18:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.18 !important;
  }
  .opacity-md-19 {
    opacity: 0.19 !important;
  }
  .hover-opacity-md-19 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-19.active, .hover-opacity-md-19:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.19 !important;
  }
  .opacity-md-20 {
    opacity: 0.2 !important;
  }
  .hover-opacity-md-20 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-20.active, .hover-opacity-md-20:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.2 !important;
  }
  .opacity-md-21 {
    opacity: 0.21 !important;
  }
  .hover-opacity-md-21 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-21.active, .hover-opacity-md-21:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.21 !important;
  }
  .opacity-md-22 {
    opacity: 0.22 !important;
  }
  .hover-opacity-md-22 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-22.active, .hover-opacity-md-22:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.22 !important;
  }
  .opacity-md-23 {
    opacity: 0.23 !important;
  }
  .hover-opacity-md-23 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-23.active, .hover-opacity-md-23:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.23 !important;
  }
  .opacity-md-24 {
    opacity: 0.24 !important;
  }
  .hover-opacity-md-24 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-24.active, .hover-opacity-md-24:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.24 !important;
  }
  .opacity-md-25 {
    opacity: 0.25 !important;
  }
  .hover-opacity-md-25 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-25.active, .hover-opacity-md-25:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.25 !important;
  }
  .opacity-md-26 {
    opacity: 0.26 !important;
  }
  .hover-opacity-md-26 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-26.active, .hover-opacity-md-26:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.26 !important;
  }
  .opacity-md-27 {
    opacity: 0.27 !important;
  }
  .hover-opacity-md-27 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-27.active, .hover-opacity-md-27:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.27 !important;
  }
  .opacity-md-28 {
    opacity: 0.28 !important;
  }
  .hover-opacity-md-28 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-28.active, .hover-opacity-md-28:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.28 !important;
  }
  .opacity-md-29 {
    opacity: 0.29 !important;
  }
  .hover-opacity-md-29 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-29.active, .hover-opacity-md-29:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.29 !important;
  }
  .opacity-md-30 {
    opacity: 0.3 !important;
  }
  .hover-opacity-md-30 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-30.active, .hover-opacity-md-30:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.3 !important;
  }
  .opacity-md-31 {
    opacity: 0.31 !important;
  }
  .hover-opacity-md-31 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-31.active, .hover-opacity-md-31:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.31 !important;
  }
  .opacity-md-32 {
    opacity: 0.32 !important;
  }
  .hover-opacity-md-32 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-32.active, .hover-opacity-md-32:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.32 !important;
  }
  .opacity-md-33 {
    opacity: 0.33 !important;
  }
  .hover-opacity-md-33 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-33.active, .hover-opacity-md-33:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.33 !important;
  }
  .opacity-md-34 {
    opacity: 0.34 !important;
  }
  .hover-opacity-md-34 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-34.active, .hover-opacity-md-34:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.34 !important;
  }
  .opacity-md-35 {
    opacity: 0.35 !important;
  }
  .hover-opacity-md-35 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-35.active, .hover-opacity-md-35:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.35 !important;
  }
  .opacity-md-36 {
    opacity: 0.36 !important;
  }
  .hover-opacity-md-36 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-36.active, .hover-opacity-md-36:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.36 !important;
  }
  .opacity-md-37 {
    opacity: 0.37 !important;
  }
  .hover-opacity-md-37 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-37.active, .hover-opacity-md-37:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.37 !important;
  }
  .opacity-md-38 {
    opacity: 0.38 !important;
  }
  .hover-opacity-md-38 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-38.active, .hover-opacity-md-38:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.38 !important;
  }
  .opacity-md-39 {
    opacity: 0.39 !important;
  }
  .hover-opacity-md-39 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-39.active, .hover-opacity-md-39:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.39 !important;
  }
  .opacity-md-40 {
    opacity: 0.4 !important;
  }
  .hover-opacity-md-40 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-40.active, .hover-opacity-md-40:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.4 !important;
  }
  .opacity-md-41 {
    opacity: 0.41 !important;
  }
  .hover-opacity-md-41 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-41.active, .hover-opacity-md-41:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.41 !important;
  }
  .opacity-md-42 {
    opacity: 0.42 !important;
  }
  .hover-opacity-md-42 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-42.active, .hover-opacity-md-42:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.42 !important;
  }
  .opacity-md-43 {
    opacity: 0.43 !important;
  }
  .hover-opacity-md-43 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-43.active, .hover-opacity-md-43:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.43 !important;
  }
  .opacity-md-44 {
    opacity: 0.44 !important;
  }
  .hover-opacity-md-44 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-44.active, .hover-opacity-md-44:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.44 !important;
  }
  .opacity-md-45 {
    opacity: 0.45 !important;
  }
  .hover-opacity-md-45 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-45.active, .hover-opacity-md-45:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.45 !important;
  }
  .opacity-md-46 {
    opacity: 0.46 !important;
  }
  .hover-opacity-md-46 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-46.active, .hover-opacity-md-46:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.46 !important;
  }
  .opacity-md-47 {
    opacity: 0.47 !important;
  }
  .hover-opacity-md-47 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-47.active, .hover-opacity-md-47:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.47 !important;
  }
  .opacity-md-48 {
    opacity: 0.48 !important;
  }
  .hover-opacity-md-48 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-48.active, .hover-opacity-md-48:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.48 !important;
  }
  .opacity-md-49 {
    opacity: 0.49 !important;
  }
  .hover-opacity-md-49 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-49.active, .hover-opacity-md-49:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.49 !important;
  }
  .opacity-md-50 {
    opacity: 0.5 !important;
  }
  .hover-opacity-md-50 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-50.active, .hover-opacity-md-50:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.5 !important;
  }
  .opacity-md-51 {
    opacity: 0.51 !important;
  }
  .hover-opacity-md-51 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-51.active, .hover-opacity-md-51:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.51 !important;
  }
  .opacity-md-52 {
    opacity: 0.52 !important;
  }
  .hover-opacity-md-52 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-52.active, .hover-opacity-md-52:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.52 !important;
  }
  .opacity-md-53 {
    opacity: 0.53 !important;
  }
  .hover-opacity-md-53 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-53.active, .hover-opacity-md-53:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.53 !important;
  }
  .opacity-md-54 {
    opacity: 0.54 !important;
  }
  .hover-opacity-md-54 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-54.active, .hover-opacity-md-54:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.54 !important;
  }
  .opacity-md-55 {
    opacity: 0.55 !important;
  }
  .hover-opacity-md-55 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-55.active, .hover-opacity-md-55:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.55 !important;
  }
  .opacity-md-56 {
    opacity: 0.56 !important;
  }
  .hover-opacity-md-56 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-56.active, .hover-opacity-md-56:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.56 !important;
  }
  .opacity-md-57 {
    opacity: 0.57 !important;
  }
  .hover-opacity-md-57 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-57.active, .hover-opacity-md-57:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.57 !important;
  }
  .opacity-md-58 {
    opacity: 0.58 !important;
  }
  .hover-opacity-md-58 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-58.active, .hover-opacity-md-58:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.58 !important;
  }
  .opacity-md-59 {
    opacity: 0.59 !important;
  }
  .hover-opacity-md-59 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-59.active, .hover-opacity-md-59:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.59 !important;
  }
  .opacity-md-60 {
    opacity: 0.6 !important;
  }
  .hover-opacity-md-60 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-60.active, .hover-opacity-md-60:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.6 !important;
  }
  .opacity-md-61 {
    opacity: 0.61 !important;
  }
  .hover-opacity-md-61 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-61.active, .hover-opacity-md-61:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.61 !important;
  }
  .opacity-md-62 {
    opacity: 0.62 !important;
  }
  .hover-opacity-md-62 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-62.active, .hover-opacity-md-62:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.62 !important;
  }
  .opacity-md-63 {
    opacity: 0.63 !important;
  }
  .hover-opacity-md-63 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-63.active, .hover-opacity-md-63:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.63 !important;
  }
  .opacity-md-64 {
    opacity: 0.64 !important;
  }
  .hover-opacity-md-64 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-64.active, .hover-opacity-md-64:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.64 !important;
  }
  .opacity-md-65 {
    opacity: 0.65 !important;
  }
  .hover-opacity-md-65 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-65.active, .hover-opacity-md-65:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.65 !important;
  }
  .opacity-md-66 {
    opacity: 0.66 !important;
  }
  .hover-opacity-md-66 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-66.active, .hover-opacity-md-66:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.66 !important;
  }
  .opacity-md-67 {
    opacity: 0.67 !important;
  }
  .hover-opacity-md-67 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-67.active, .hover-opacity-md-67:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.67 !important;
  }
  .opacity-md-68 {
    opacity: 0.68 !important;
  }
  .hover-opacity-md-68 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-68.active, .hover-opacity-md-68:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.68 !important;
  }
  .opacity-md-69 {
    opacity: 0.69 !important;
  }
  .hover-opacity-md-69 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-69.active, .hover-opacity-md-69:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.69 !important;
  }
  .opacity-md-70 {
    opacity: 0.7 !important;
  }
  .hover-opacity-md-70 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-70.active, .hover-opacity-md-70:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.7 !important;
  }
  .opacity-md-71 {
    opacity: 0.71 !important;
  }
  .hover-opacity-md-71 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-71.active, .hover-opacity-md-71:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.71 !important;
  }
  .opacity-md-72 {
    opacity: 0.72 !important;
  }
  .hover-opacity-md-72 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-72.active, .hover-opacity-md-72:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.72 !important;
  }
  .opacity-md-73 {
    opacity: 0.73 !important;
  }
  .hover-opacity-md-73 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-73.active, .hover-opacity-md-73:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.73 !important;
  }
  .opacity-md-74 {
    opacity: 0.74 !important;
  }
  .hover-opacity-md-74 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-74.active, .hover-opacity-md-74:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.74 !important;
  }
  .opacity-md-75 {
    opacity: 0.75 !important;
  }
  .hover-opacity-md-75 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-75.active, .hover-opacity-md-75:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.75 !important;
  }
  .opacity-md-76 {
    opacity: 0.76 !important;
  }
  .hover-opacity-md-76 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-76.active, .hover-opacity-md-76:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.76 !important;
  }
  .opacity-md-77 {
    opacity: 0.77 !important;
  }
  .hover-opacity-md-77 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-77.active, .hover-opacity-md-77:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.77 !important;
  }
  .opacity-md-78 {
    opacity: 0.78 !important;
  }
  .hover-opacity-md-78 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-78.active, .hover-opacity-md-78:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.78 !important;
  }
  .opacity-md-79 {
    opacity: 0.79 !important;
  }
  .hover-opacity-md-79 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-79.active, .hover-opacity-md-79:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.79 !important;
  }
  .opacity-md-80 {
    opacity: 0.8 !important;
  }
  .hover-opacity-md-80 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-80.active, .hover-opacity-md-80:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.8 !important;
  }
  .opacity-md-81 {
    opacity: 0.81 !important;
  }
  .hover-opacity-md-81 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-81.active, .hover-opacity-md-81:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.81 !important;
  }
  .opacity-md-82 {
    opacity: 0.82 !important;
  }
  .hover-opacity-md-82 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-82.active, .hover-opacity-md-82:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.82 !important;
  }
  .opacity-md-83 {
    opacity: 0.83 !important;
  }
  .hover-opacity-md-83 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-83.active, .hover-opacity-md-83:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.83 !important;
  }
  .opacity-md-84 {
    opacity: 0.84 !important;
  }
  .hover-opacity-md-84 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-84.active, .hover-opacity-md-84:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.84 !important;
  }
  .opacity-md-85 {
    opacity: 0.85 !important;
  }
  .hover-opacity-md-85 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-85.active, .hover-opacity-md-85:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.85 !important;
  }
  .opacity-md-86 {
    opacity: 0.86 !important;
  }
  .hover-opacity-md-86 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-86.active, .hover-opacity-md-86:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.86 !important;
  }
  .opacity-md-87 {
    opacity: 0.87 !important;
  }
  .hover-opacity-md-87 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-87.active, .hover-opacity-md-87:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.87 !important;
  }
  .opacity-md-88 {
    opacity: 0.88 !important;
  }
  .hover-opacity-md-88 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-88.active, .hover-opacity-md-88:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.88 !important;
  }
  .opacity-md-89 {
    opacity: 0.89 !important;
  }
  .hover-opacity-md-89 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-89.active, .hover-opacity-md-89:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.89 !important;
  }
  .opacity-md-90 {
    opacity: 0.9 !important;
  }
  .hover-opacity-md-90 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-90.active, .hover-opacity-md-90:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.9 !important;
  }
  .opacity-md-91 {
    opacity: 0.91 !important;
  }
  .hover-opacity-md-91 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-91.active, .hover-opacity-md-91:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.91 !important;
  }
  .opacity-md-92 {
    opacity: 0.92 !important;
  }
  .hover-opacity-md-92 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-92.active, .hover-opacity-md-92:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.92 !important;
  }
  .opacity-md-93 {
    opacity: 0.93 !important;
  }
  .hover-opacity-md-93 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-93.active, .hover-opacity-md-93:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.93 !important;
  }
  .opacity-md-94 {
    opacity: 0.94 !important;
  }
  .hover-opacity-md-94 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-94.active, .hover-opacity-md-94:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.94 !important;
  }
  .opacity-md-95 {
    opacity: 0.95 !important;
  }
  .hover-opacity-md-95 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-95.active, .hover-opacity-md-95:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.95 !important;
  }
  .opacity-md-96 {
    opacity: 0.96 !important;
  }
  .hover-opacity-md-96 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-96.active, .hover-opacity-md-96:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.96 !important;
  }
  .opacity-md-97 {
    opacity: 0.97 !important;
  }
  .hover-opacity-md-97 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-97.active, .hover-opacity-md-97:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.97 !important;
  }
  .opacity-md-98 {
    opacity: 0.98 !important;
  }
  .hover-opacity-md-98 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-98.active, .hover-opacity-md-98:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.98 !important;
  }
  .opacity-md-99 {
    opacity: 0.99 !important;
  }
  .hover-opacity-md-99 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-99.active, .hover-opacity-md-99:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.99 !important;
  }
  .opacity-md-100 {
    opacity: 1 !important;
  }
  .hover-opacity-md-100 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-md-100.active, .hover-opacity-md-100:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 1 !important;
  }
}

@media (min-width: 992px) {
  .opacity-lg-1 {
    opacity: 0.01 !important;
  }
  .hover-opacity-lg-1 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-1.active, .hover-opacity-lg-1:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.01 !important;
  }
  .opacity-lg-2 {
    opacity: 0.02 !important;
  }
  .hover-opacity-lg-2 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-2.active, .hover-opacity-lg-2:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.02 !important;
  }
  .opacity-lg-3 {
    opacity: 0.03 !important;
  }
  .hover-opacity-lg-3 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-3.active, .hover-opacity-lg-3:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.03 !important;
  }
  .opacity-lg-4 {
    opacity: 0.04 !important;
  }
  .hover-opacity-lg-4 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-4.active, .hover-opacity-lg-4:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.04 !important;
  }
  .opacity-lg-5 {
    opacity: 0.05 !important;
  }
  .hover-opacity-lg-5 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-5.active, .hover-opacity-lg-5:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.05 !important;
  }
  .opacity-lg-6 {
    opacity: 0.06 !important;
  }
  .hover-opacity-lg-6 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-6.active, .hover-opacity-lg-6:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.06 !important;
  }
  .opacity-lg-7 {
    opacity: 0.07 !important;
  }
  .hover-opacity-lg-7 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-7.active, .hover-opacity-lg-7:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.07 !important;
  }
  .opacity-lg-8 {
    opacity: 0.08 !important;
  }
  .hover-opacity-lg-8 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-8.active, .hover-opacity-lg-8:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.08 !important;
  }
  .opacity-lg-9 {
    opacity: 0.09 !important;
  }
  .hover-opacity-lg-9 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-9.active, .hover-opacity-lg-9:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.09 !important;
  }
  .opacity-lg-10 {
    opacity: 0.1 !important;
  }
  .hover-opacity-lg-10 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-10.active, .hover-opacity-lg-10:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.1 !important;
  }
  .opacity-lg-11 {
    opacity: 0.11 !important;
  }
  .hover-opacity-lg-11 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-11.active, .hover-opacity-lg-11:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.11 !important;
  }
  .opacity-lg-12 {
    opacity: 0.12 !important;
  }
  .hover-opacity-lg-12 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-12.active, .hover-opacity-lg-12:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.12 !important;
  }
  .opacity-lg-13 {
    opacity: 0.13 !important;
  }
  .hover-opacity-lg-13 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-13.active, .hover-opacity-lg-13:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.13 !important;
  }
  .opacity-lg-14 {
    opacity: 0.14 !important;
  }
  .hover-opacity-lg-14 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-14.active, .hover-opacity-lg-14:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.14 !important;
  }
  .opacity-lg-15 {
    opacity: 0.15 !important;
  }
  .hover-opacity-lg-15 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-15.active, .hover-opacity-lg-15:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.15 !important;
  }
  .opacity-lg-16 {
    opacity: 0.16 !important;
  }
  .hover-opacity-lg-16 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-16.active, .hover-opacity-lg-16:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.16 !important;
  }
  .opacity-lg-17 {
    opacity: 0.17 !important;
  }
  .hover-opacity-lg-17 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-17.active, .hover-opacity-lg-17:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.17 !important;
  }
  .opacity-lg-18 {
    opacity: 0.18 !important;
  }
  .hover-opacity-lg-18 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-18.active, .hover-opacity-lg-18:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.18 !important;
  }
  .opacity-lg-19 {
    opacity: 0.19 !important;
  }
  .hover-opacity-lg-19 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-19.active, .hover-opacity-lg-19:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.19 !important;
  }
  .opacity-lg-20 {
    opacity: 0.2 !important;
  }
  .hover-opacity-lg-20 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-20.active, .hover-opacity-lg-20:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.2 !important;
  }
  .opacity-lg-21 {
    opacity: 0.21 !important;
  }
  .hover-opacity-lg-21 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-21.active, .hover-opacity-lg-21:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.21 !important;
  }
  .opacity-lg-22 {
    opacity: 0.22 !important;
  }
  .hover-opacity-lg-22 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-22.active, .hover-opacity-lg-22:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.22 !important;
  }
  .opacity-lg-23 {
    opacity: 0.23 !important;
  }
  .hover-opacity-lg-23 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-23.active, .hover-opacity-lg-23:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.23 !important;
  }
  .opacity-lg-24 {
    opacity: 0.24 !important;
  }
  .hover-opacity-lg-24 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-24.active, .hover-opacity-lg-24:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.24 !important;
  }
  .opacity-lg-25 {
    opacity: 0.25 !important;
  }
  .hover-opacity-lg-25 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-25.active, .hover-opacity-lg-25:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.25 !important;
  }
  .opacity-lg-26 {
    opacity: 0.26 !important;
  }
  .hover-opacity-lg-26 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-26.active, .hover-opacity-lg-26:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.26 !important;
  }
  .opacity-lg-27 {
    opacity: 0.27 !important;
  }
  .hover-opacity-lg-27 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-27.active, .hover-opacity-lg-27:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.27 !important;
  }
  .opacity-lg-28 {
    opacity: 0.28 !important;
  }
  .hover-opacity-lg-28 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-28.active, .hover-opacity-lg-28:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.28 !important;
  }
  .opacity-lg-29 {
    opacity: 0.29 !important;
  }
  .hover-opacity-lg-29 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-29.active, .hover-opacity-lg-29:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.29 !important;
  }
  .opacity-lg-30 {
    opacity: 0.3 !important;
  }
  .hover-opacity-lg-30 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-30.active, .hover-opacity-lg-30:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.3 !important;
  }
  .opacity-lg-31 {
    opacity: 0.31 !important;
  }
  .hover-opacity-lg-31 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-31.active, .hover-opacity-lg-31:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.31 !important;
  }
  .opacity-lg-32 {
    opacity: 0.32 !important;
  }
  .hover-opacity-lg-32 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-32.active, .hover-opacity-lg-32:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.32 !important;
  }
  .opacity-lg-33 {
    opacity: 0.33 !important;
  }
  .hover-opacity-lg-33 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-33.active, .hover-opacity-lg-33:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.33 !important;
  }
  .opacity-lg-34 {
    opacity: 0.34 !important;
  }
  .hover-opacity-lg-34 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-34.active, .hover-opacity-lg-34:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.34 !important;
  }
  .opacity-lg-35 {
    opacity: 0.35 !important;
  }
  .hover-opacity-lg-35 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-35.active, .hover-opacity-lg-35:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.35 !important;
  }
  .opacity-lg-36 {
    opacity: 0.36 !important;
  }
  .hover-opacity-lg-36 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-36.active, .hover-opacity-lg-36:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.36 !important;
  }
  .opacity-lg-37 {
    opacity: 0.37 !important;
  }
  .hover-opacity-lg-37 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-37.active, .hover-opacity-lg-37:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.37 !important;
  }
  .opacity-lg-38 {
    opacity: 0.38 !important;
  }
  .hover-opacity-lg-38 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-38.active, .hover-opacity-lg-38:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.38 !important;
  }
  .opacity-lg-39 {
    opacity: 0.39 !important;
  }
  .hover-opacity-lg-39 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-39.active, .hover-opacity-lg-39:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.39 !important;
  }
  .opacity-lg-40 {
    opacity: 0.4 !important;
  }
  .hover-opacity-lg-40 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-40.active, .hover-opacity-lg-40:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.4 !important;
  }
  .opacity-lg-41 {
    opacity: 0.41 !important;
  }
  .hover-opacity-lg-41 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-41.active, .hover-opacity-lg-41:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.41 !important;
  }
  .opacity-lg-42 {
    opacity: 0.42 !important;
  }
  .hover-opacity-lg-42 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-42.active, .hover-opacity-lg-42:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.42 !important;
  }
  .opacity-lg-43 {
    opacity: 0.43 !important;
  }
  .hover-opacity-lg-43 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-43.active, .hover-opacity-lg-43:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.43 !important;
  }
  .opacity-lg-44 {
    opacity: 0.44 !important;
  }
  .hover-opacity-lg-44 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-44.active, .hover-opacity-lg-44:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.44 !important;
  }
  .opacity-lg-45 {
    opacity: 0.45 !important;
  }
  .hover-opacity-lg-45 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-45.active, .hover-opacity-lg-45:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.45 !important;
  }
  .opacity-lg-46 {
    opacity: 0.46 !important;
  }
  .hover-opacity-lg-46 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-46.active, .hover-opacity-lg-46:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.46 !important;
  }
  .opacity-lg-47 {
    opacity: 0.47 !important;
  }
  .hover-opacity-lg-47 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-47.active, .hover-opacity-lg-47:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.47 !important;
  }
  .opacity-lg-48 {
    opacity: 0.48 !important;
  }
  .hover-opacity-lg-48 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-48.active, .hover-opacity-lg-48:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.48 !important;
  }
  .opacity-lg-49 {
    opacity: 0.49 !important;
  }
  .hover-opacity-lg-49 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-49.active, .hover-opacity-lg-49:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.49 !important;
  }
  .opacity-lg-50 {
    opacity: 0.5 !important;
  }
  .hover-opacity-lg-50 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-50.active, .hover-opacity-lg-50:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.5 !important;
  }
  .opacity-lg-51 {
    opacity: 0.51 !important;
  }
  .hover-opacity-lg-51 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-51.active, .hover-opacity-lg-51:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.51 !important;
  }
  .opacity-lg-52 {
    opacity: 0.52 !important;
  }
  .hover-opacity-lg-52 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-52.active, .hover-opacity-lg-52:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.52 !important;
  }
  .opacity-lg-53 {
    opacity: 0.53 !important;
  }
  .hover-opacity-lg-53 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-53.active, .hover-opacity-lg-53:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.53 !important;
  }
  .opacity-lg-54 {
    opacity: 0.54 !important;
  }
  .hover-opacity-lg-54 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-54.active, .hover-opacity-lg-54:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.54 !important;
  }
  .opacity-lg-55 {
    opacity: 0.55 !important;
  }
  .hover-opacity-lg-55 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-55.active, .hover-opacity-lg-55:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.55 !important;
  }
  .opacity-lg-56 {
    opacity: 0.56 !important;
  }
  .hover-opacity-lg-56 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-56.active, .hover-opacity-lg-56:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.56 !important;
  }
  .opacity-lg-57 {
    opacity: 0.57 !important;
  }
  .hover-opacity-lg-57 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-57.active, .hover-opacity-lg-57:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.57 !important;
  }
  .opacity-lg-58 {
    opacity: 0.58 !important;
  }
  .hover-opacity-lg-58 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-58.active, .hover-opacity-lg-58:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.58 !important;
  }
  .opacity-lg-59 {
    opacity: 0.59 !important;
  }
  .hover-opacity-lg-59 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-59.active, .hover-opacity-lg-59:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.59 !important;
  }
  .opacity-lg-60 {
    opacity: 0.6 !important;
  }
  .hover-opacity-lg-60 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-60.active, .hover-opacity-lg-60:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.6 !important;
  }
  .opacity-lg-61 {
    opacity: 0.61 !important;
  }
  .hover-opacity-lg-61 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-61.active, .hover-opacity-lg-61:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.61 !important;
  }
  .opacity-lg-62 {
    opacity: 0.62 !important;
  }
  .hover-opacity-lg-62 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-62.active, .hover-opacity-lg-62:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.62 !important;
  }
  .opacity-lg-63 {
    opacity: 0.63 !important;
  }
  .hover-opacity-lg-63 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-63.active, .hover-opacity-lg-63:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.63 !important;
  }
  .opacity-lg-64 {
    opacity: 0.64 !important;
  }
  .hover-opacity-lg-64 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-64.active, .hover-opacity-lg-64:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.64 !important;
  }
  .opacity-lg-65 {
    opacity: 0.65 !important;
  }
  .hover-opacity-lg-65 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-65.active, .hover-opacity-lg-65:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.65 !important;
  }
  .opacity-lg-66 {
    opacity: 0.66 !important;
  }
  .hover-opacity-lg-66 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-66.active, .hover-opacity-lg-66:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.66 !important;
  }
  .opacity-lg-67 {
    opacity: 0.67 !important;
  }
  .hover-opacity-lg-67 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-67.active, .hover-opacity-lg-67:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.67 !important;
  }
  .opacity-lg-68 {
    opacity: 0.68 !important;
  }
  .hover-opacity-lg-68 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-68.active, .hover-opacity-lg-68:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.68 !important;
  }
  .opacity-lg-69 {
    opacity: 0.69 !important;
  }
  .hover-opacity-lg-69 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-69.active, .hover-opacity-lg-69:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.69 !important;
  }
  .opacity-lg-70 {
    opacity: 0.7 !important;
  }
  .hover-opacity-lg-70 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-70.active, .hover-opacity-lg-70:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.7 !important;
  }
  .opacity-lg-71 {
    opacity: 0.71 !important;
  }
  .hover-opacity-lg-71 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-71.active, .hover-opacity-lg-71:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.71 !important;
  }
  .opacity-lg-72 {
    opacity: 0.72 !important;
  }
  .hover-opacity-lg-72 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-72.active, .hover-opacity-lg-72:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.72 !important;
  }
  .opacity-lg-73 {
    opacity: 0.73 !important;
  }
  .hover-opacity-lg-73 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-73.active, .hover-opacity-lg-73:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.73 !important;
  }
  .opacity-lg-74 {
    opacity: 0.74 !important;
  }
  .hover-opacity-lg-74 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-74.active, .hover-opacity-lg-74:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.74 !important;
  }
  .opacity-lg-75 {
    opacity: 0.75 !important;
  }
  .hover-opacity-lg-75 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-75.active, .hover-opacity-lg-75:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.75 !important;
  }
  .opacity-lg-76 {
    opacity: 0.76 !important;
  }
  .hover-opacity-lg-76 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-76.active, .hover-opacity-lg-76:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.76 !important;
  }
  .opacity-lg-77 {
    opacity: 0.77 !important;
  }
  .hover-opacity-lg-77 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-77.active, .hover-opacity-lg-77:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.77 !important;
  }
  .opacity-lg-78 {
    opacity: 0.78 !important;
  }
  .hover-opacity-lg-78 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-78.active, .hover-opacity-lg-78:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.78 !important;
  }
  .opacity-lg-79 {
    opacity: 0.79 !important;
  }
  .hover-opacity-lg-79 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-79.active, .hover-opacity-lg-79:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.79 !important;
  }
  .opacity-lg-80 {
    opacity: 0.8 !important;
  }
  .hover-opacity-lg-80 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-80.active, .hover-opacity-lg-80:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.8 !important;
  }
  .opacity-lg-81 {
    opacity: 0.81 !important;
  }
  .hover-opacity-lg-81 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-81.active, .hover-opacity-lg-81:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.81 !important;
  }
  .opacity-lg-82 {
    opacity: 0.82 !important;
  }
  .hover-opacity-lg-82 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-82.active, .hover-opacity-lg-82:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.82 !important;
  }
  .opacity-lg-83 {
    opacity: 0.83 !important;
  }
  .hover-opacity-lg-83 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-83.active, .hover-opacity-lg-83:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.83 !important;
  }
  .opacity-lg-84 {
    opacity: 0.84 !important;
  }
  .hover-opacity-lg-84 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-84.active, .hover-opacity-lg-84:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.84 !important;
  }
  .opacity-lg-85 {
    opacity: 0.85 !important;
  }
  .hover-opacity-lg-85 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-85.active, .hover-opacity-lg-85:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.85 !important;
  }
  .opacity-lg-86 {
    opacity: 0.86 !important;
  }
  .hover-opacity-lg-86 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-86.active, .hover-opacity-lg-86:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.86 !important;
  }
  .opacity-lg-87 {
    opacity: 0.87 !important;
  }
  .hover-opacity-lg-87 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-87.active, .hover-opacity-lg-87:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.87 !important;
  }
  .opacity-lg-88 {
    opacity: 0.88 !important;
  }
  .hover-opacity-lg-88 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-88.active, .hover-opacity-lg-88:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.88 !important;
  }
  .opacity-lg-89 {
    opacity: 0.89 !important;
  }
  .hover-opacity-lg-89 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-89.active, .hover-opacity-lg-89:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.89 !important;
  }
  .opacity-lg-90 {
    opacity: 0.9 !important;
  }
  .hover-opacity-lg-90 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-90.active, .hover-opacity-lg-90:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.9 !important;
  }
  .opacity-lg-91 {
    opacity: 0.91 !important;
  }
  .hover-opacity-lg-91 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-91.active, .hover-opacity-lg-91:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.91 !important;
  }
  .opacity-lg-92 {
    opacity: 0.92 !important;
  }
  .hover-opacity-lg-92 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-92.active, .hover-opacity-lg-92:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.92 !important;
  }
  .opacity-lg-93 {
    opacity: 0.93 !important;
  }
  .hover-opacity-lg-93 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-93.active, .hover-opacity-lg-93:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.93 !important;
  }
  .opacity-lg-94 {
    opacity: 0.94 !important;
  }
  .hover-opacity-lg-94 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-94.active, .hover-opacity-lg-94:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.94 !important;
  }
  .opacity-lg-95 {
    opacity: 0.95 !important;
  }
  .hover-opacity-lg-95 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-95.active, .hover-opacity-lg-95:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.95 !important;
  }
  .opacity-lg-96 {
    opacity: 0.96 !important;
  }
  .hover-opacity-lg-96 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-96.active, .hover-opacity-lg-96:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.96 !important;
  }
  .opacity-lg-97 {
    opacity: 0.97 !important;
  }
  .hover-opacity-lg-97 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-97.active, .hover-opacity-lg-97:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.97 !important;
  }
  .opacity-lg-98 {
    opacity: 0.98 !important;
  }
  .hover-opacity-lg-98 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-98.active, .hover-opacity-lg-98:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.98 !important;
  }
  .opacity-lg-99 {
    opacity: 0.99 !important;
  }
  .hover-opacity-lg-99 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-99.active, .hover-opacity-lg-99:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.99 !important;
  }
  .opacity-lg-100 {
    opacity: 1 !important;
  }
  .hover-opacity-lg-100 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-lg-100.active, .hover-opacity-lg-100:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 1 !important;
  }
}

@media (min-width: 1200px) {
  .opacity-xl-1 {
    opacity: 0.01 !important;
  }
  .hover-opacity-xl-1 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-1.active, .hover-opacity-xl-1:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.01 !important;
  }
  .opacity-xl-2 {
    opacity: 0.02 !important;
  }
  .hover-opacity-xl-2 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-2.active, .hover-opacity-xl-2:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.02 !important;
  }
  .opacity-xl-3 {
    opacity: 0.03 !important;
  }
  .hover-opacity-xl-3 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-3.active, .hover-opacity-xl-3:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.03 !important;
  }
  .opacity-xl-4 {
    opacity: 0.04 !important;
  }
  .hover-opacity-xl-4 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-4.active, .hover-opacity-xl-4:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.04 !important;
  }
  .opacity-xl-5 {
    opacity: 0.05 !important;
  }
  .hover-opacity-xl-5 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-5.active, .hover-opacity-xl-5:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.05 !important;
  }
  .opacity-xl-6 {
    opacity: 0.06 !important;
  }
  .hover-opacity-xl-6 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-6.active, .hover-opacity-xl-6:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.06 !important;
  }
  .opacity-xl-7 {
    opacity: 0.07 !important;
  }
  .hover-opacity-xl-7 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-7.active, .hover-opacity-xl-7:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.07 !important;
  }
  .opacity-xl-8 {
    opacity: 0.08 !important;
  }
  .hover-opacity-xl-8 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-8.active, .hover-opacity-xl-8:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.08 !important;
  }
  .opacity-xl-9 {
    opacity: 0.09 !important;
  }
  .hover-opacity-xl-9 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-9.active, .hover-opacity-xl-9:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.09 !important;
  }
  .opacity-xl-10 {
    opacity: 0.1 !important;
  }
  .hover-opacity-xl-10 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-10.active, .hover-opacity-xl-10:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.1 !important;
  }
  .opacity-xl-11 {
    opacity: 0.11 !important;
  }
  .hover-opacity-xl-11 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-11.active, .hover-opacity-xl-11:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.11 !important;
  }
  .opacity-xl-12 {
    opacity: 0.12 !important;
  }
  .hover-opacity-xl-12 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-12.active, .hover-opacity-xl-12:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.12 !important;
  }
  .opacity-xl-13 {
    opacity: 0.13 !important;
  }
  .hover-opacity-xl-13 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-13.active, .hover-opacity-xl-13:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.13 !important;
  }
  .opacity-xl-14 {
    opacity: 0.14 !important;
  }
  .hover-opacity-xl-14 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-14.active, .hover-opacity-xl-14:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.14 !important;
  }
  .opacity-xl-15 {
    opacity: 0.15 !important;
  }
  .hover-opacity-xl-15 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-15.active, .hover-opacity-xl-15:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.15 !important;
  }
  .opacity-xl-16 {
    opacity: 0.16 !important;
  }
  .hover-opacity-xl-16 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-16.active, .hover-opacity-xl-16:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.16 !important;
  }
  .opacity-xl-17 {
    opacity: 0.17 !important;
  }
  .hover-opacity-xl-17 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-17.active, .hover-opacity-xl-17:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.17 !important;
  }
  .opacity-xl-18 {
    opacity: 0.18 !important;
  }
  .hover-opacity-xl-18 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-18.active, .hover-opacity-xl-18:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.18 !important;
  }
  .opacity-xl-19 {
    opacity: 0.19 !important;
  }
  .hover-opacity-xl-19 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-19.active, .hover-opacity-xl-19:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.19 !important;
  }
  .opacity-xl-20 {
    opacity: 0.2 !important;
  }
  .hover-opacity-xl-20 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-20.active, .hover-opacity-xl-20:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.2 !important;
  }
  .opacity-xl-21 {
    opacity: 0.21 !important;
  }
  .hover-opacity-xl-21 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-21.active, .hover-opacity-xl-21:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.21 !important;
  }
  .opacity-xl-22 {
    opacity: 0.22 !important;
  }
  .hover-opacity-xl-22 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-22.active, .hover-opacity-xl-22:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.22 !important;
  }
  .opacity-xl-23 {
    opacity: 0.23 !important;
  }
  .hover-opacity-xl-23 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-23.active, .hover-opacity-xl-23:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.23 !important;
  }
  .opacity-xl-24 {
    opacity: 0.24 !important;
  }
  .hover-opacity-xl-24 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-24.active, .hover-opacity-xl-24:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.24 !important;
  }
  .opacity-xl-25 {
    opacity: 0.25 !important;
  }
  .hover-opacity-xl-25 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-25.active, .hover-opacity-xl-25:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.25 !important;
  }
  .opacity-xl-26 {
    opacity: 0.26 !important;
  }
  .hover-opacity-xl-26 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-26.active, .hover-opacity-xl-26:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.26 !important;
  }
  .opacity-xl-27 {
    opacity: 0.27 !important;
  }
  .hover-opacity-xl-27 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-27.active, .hover-opacity-xl-27:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.27 !important;
  }
  .opacity-xl-28 {
    opacity: 0.28 !important;
  }
  .hover-opacity-xl-28 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-28.active, .hover-opacity-xl-28:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.28 !important;
  }
  .opacity-xl-29 {
    opacity: 0.29 !important;
  }
  .hover-opacity-xl-29 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-29.active, .hover-opacity-xl-29:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.29 !important;
  }
  .opacity-xl-30 {
    opacity: 0.3 !important;
  }
  .hover-opacity-xl-30 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-30.active, .hover-opacity-xl-30:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.3 !important;
  }
  .opacity-xl-31 {
    opacity: 0.31 !important;
  }
  .hover-opacity-xl-31 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-31.active, .hover-opacity-xl-31:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.31 !important;
  }
  .opacity-xl-32 {
    opacity: 0.32 !important;
  }
  .hover-opacity-xl-32 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-32.active, .hover-opacity-xl-32:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.32 !important;
  }
  .opacity-xl-33 {
    opacity: 0.33 !important;
  }
  .hover-opacity-xl-33 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-33.active, .hover-opacity-xl-33:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.33 !important;
  }
  .opacity-xl-34 {
    opacity: 0.34 !important;
  }
  .hover-opacity-xl-34 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-34.active, .hover-opacity-xl-34:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.34 !important;
  }
  .opacity-xl-35 {
    opacity: 0.35 !important;
  }
  .hover-opacity-xl-35 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-35.active, .hover-opacity-xl-35:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.35 !important;
  }
  .opacity-xl-36 {
    opacity: 0.36 !important;
  }
  .hover-opacity-xl-36 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-36.active, .hover-opacity-xl-36:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.36 !important;
  }
  .opacity-xl-37 {
    opacity: 0.37 !important;
  }
  .hover-opacity-xl-37 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-37.active, .hover-opacity-xl-37:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.37 !important;
  }
  .opacity-xl-38 {
    opacity: 0.38 !important;
  }
  .hover-opacity-xl-38 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-38.active, .hover-opacity-xl-38:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.38 !important;
  }
  .opacity-xl-39 {
    opacity: 0.39 !important;
  }
  .hover-opacity-xl-39 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-39.active, .hover-opacity-xl-39:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.39 !important;
  }
  .opacity-xl-40 {
    opacity: 0.4 !important;
  }
  .hover-opacity-xl-40 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-40.active, .hover-opacity-xl-40:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.4 !important;
  }
  .opacity-xl-41 {
    opacity: 0.41 !important;
  }
  .hover-opacity-xl-41 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-41.active, .hover-opacity-xl-41:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.41 !important;
  }
  .opacity-xl-42 {
    opacity: 0.42 !important;
  }
  .hover-opacity-xl-42 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-42.active, .hover-opacity-xl-42:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.42 !important;
  }
  .opacity-xl-43 {
    opacity: 0.43 !important;
  }
  .hover-opacity-xl-43 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-43.active, .hover-opacity-xl-43:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.43 !important;
  }
  .opacity-xl-44 {
    opacity: 0.44 !important;
  }
  .hover-opacity-xl-44 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-44.active, .hover-opacity-xl-44:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.44 !important;
  }
  .opacity-xl-45 {
    opacity: 0.45 !important;
  }
  .hover-opacity-xl-45 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-45.active, .hover-opacity-xl-45:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.45 !important;
  }
  .opacity-xl-46 {
    opacity: 0.46 !important;
  }
  .hover-opacity-xl-46 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-46.active, .hover-opacity-xl-46:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.46 !important;
  }
  .opacity-xl-47 {
    opacity: 0.47 !important;
  }
  .hover-opacity-xl-47 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-47.active, .hover-opacity-xl-47:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.47 !important;
  }
  .opacity-xl-48 {
    opacity: 0.48 !important;
  }
  .hover-opacity-xl-48 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-48.active, .hover-opacity-xl-48:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.48 !important;
  }
  .opacity-xl-49 {
    opacity: 0.49 !important;
  }
  .hover-opacity-xl-49 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-49.active, .hover-opacity-xl-49:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.49 !important;
  }
  .opacity-xl-50 {
    opacity: 0.5 !important;
  }
  .hover-opacity-xl-50 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-50.active, .hover-opacity-xl-50:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.5 !important;
  }
  .opacity-xl-51 {
    opacity: 0.51 !important;
  }
  .hover-opacity-xl-51 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-51.active, .hover-opacity-xl-51:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.51 !important;
  }
  .opacity-xl-52 {
    opacity: 0.52 !important;
  }
  .hover-opacity-xl-52 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-52.active, .hover-opacity-xl-52:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.52 !important;
  }
  .opacity-xl-53 {
    opacity: 0.53 !important;
  }
  .hover-opacity-xl-53 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-53.active, .hover-opacity-xl-53:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.53 !important;
  }
  .opacity-xl-54 {
    opacity: 0.54 !important;
  }
  .hover-opacity-xl-54 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-54.active, .hover-opacity-xl-54:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.54 !important;
  }
  .opacity-xl-55 {
    opacity: 0.55 !important;
  }
  .hover-opacity-xl-55 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-55.active, .hover-opacity-xl-55:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.55 !important;
  }
  .opacity-xl-56 {
    opacity: 0.56 !important;
  }
  .hover-opacity-xl-56 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-56.active, .hover-opacity-xl-56:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.56 !important;
  }
  .opacity-xl-57 {
    opacity: 0.57 !important;
  }
  .hover-opacity-xl-57 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-57.active, .hover-opacity-xl-57:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.57 !important;
  }
  .opacity-xl-58 {
    opacity: 0.58 !important;
  }
  .hover-opacity-xl-58 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-58.active, .hover-opacity-xl-58:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.58 !important;
  }
  .opacity-xl-59 {
    opacity: 0.59 !important;
  }
  .hover-opacity-xl-59 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-59.active, .hover-opacity-xl-59:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.59 !important;
  }
  .opacity-xl-60 {
    opacity: 0.6 !important;
  }
  .hover-opacity-xl-60 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-60.active, .hover-opacity-xl-60:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.6 !important;
  }
  .opacity-xl-61 {
    opacity: 0.61 !important;
  }
  .hover-opacity-xl-61 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-61.active, .hover-opacity-xl-61:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.61 !important;
  }
  .opacity-xl-62 {
    opacity: 0.62 !important;
  }
  .hover-opacity-xl-62 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-62.active, .hover-opacity-xl-62:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.62 !important;
  }
  .opacity-xl-63 {
    opacity: 0.63 !important;
  }
  .hover-opacity-xl-63 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-63.active, .hover-opacity-xl-63:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.63 !important;
  }
  .opacity-xl-64 {
    opacity: 0.64 !important;
  }
  .hover-opacity-xl-64 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-64.active, .hover-opacity-xl-64:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.64 !important;
  }
  .opacity-xl-65 {
    opacity: 0.65 !important;
  }
  .hover-opacity-xl-65 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-65.active, .hover-opacity-xl-65:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.65 !important;
  }
  .opacity-xl-66 {
    opacity: 0.66 !important;
  }
  .hover-opacity-xl-66 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-66.active, .hover-opacity-xl-66:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.66 !important;
  }
  .opacity-xl-67 {
    opacity: 0.67 !important;
  }
  .hover-opacity-xl-67 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-67.active, .hover-opacity-xl-67:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.67 !important;
  }
  .opacity-xl-68 {
    opacity: 0.68 !important;
  }
  .hover-opacity-xl-68 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-68.active, .hover-opacity-xl-68:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.68 !important;
  }
  .opacity-xl-69 {
    opacity: 0.69 !important;
  }
  .hover-opacity-xl-69 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-69.active, .hover-opacity-xl-69:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.69 !important;
  }
  .opacity-xl-70 {
    opacity: 0.7 !important;
  }
  .hover-opacity-xl-70 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-70.active, .hover-opacity-xl-70:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.7 !important;
  }
  .opacity-xl-71 {
    opacity: 0.71 !important;
  }
  .hover-opacity-xl-71 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-71.active, .hover-opacity-xl-71:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.71 !important;
  }
  .opacity-xl-72 {
    opacity: 0.72 !important;
  }
  .hover-opacity-xl-72 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-72.active, .hover-opacity-xl-72:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.72 !important;
  }
  .opacity-xl-73 {
    opacity: 0.73 !important;
  }
  .hover-opacity-xl-73 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-73.active, .hover-opacity-xl-73:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.73 !important;
  }
  .opacity-xl-74 {
    opacity: 0.74 !important;
  }
  .hover-opacity-xl-74 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-74.active, .hover-opacity-xl-74:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.74 !important;
  }
  .opacity-xl-75 {
    opacity: 0.75 !important;
  }
  .hover-opacity-xl-75 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-75.active, .hover-opacity-xl-75:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.75 !important;
  }
  .opacity-xl-76 {
    opacity: 0.76 !important;
  }
  .hover-opacity-xl-76 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-76.active, .hover-opacity-xl-76:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.76 !important;
  }
  .opacity-xl-77 {
    opacity: 0.77 !important;
  }
  .hover-opacity-xl-77 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-77.active, .hover-opacity-xl-77:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.77 !important;
  }
  .opacity-xl-78 {
    opacity: 0.78 !important;
  }
  .hover-opacity-xl-78 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-78.active, .hover-opacity-xl-78:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.78 !important;
  }
  .opacity-xl-79 {
    opacity: 0.79 !important;
  }
  .hover-opacity-xl-79 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-79.active, .hover-opacity-xl-79:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.79 !important;
  }
  .opacity-xl-80 {
    opacity: 0.8 !important;
  }
  .hover-opacity-xl-80 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-80.active, .hover-opacity-xl-80:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.8 !important;
  }
  .opacity-xl-81 {
    opacity: 0.81 !important;
  }
  .hover-opacity-xl-81 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-81.active, .hover-opacity-xl-81:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.81 !important;
  }
  .opacity-xl-82 {
    opacity: 0.82 !important;
  }
  .hover-opacity-xl-82 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-82.active, .hover-opacity-xl-82:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.82 !important;
  }
  .opacity-xl-83 {
    opacity: 0.83 !important;
  }
  .hover-opacity-xl-83 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-83.active, .hover-opacity-xl-83:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.83 !important;
  }
  .opacity-xl-84 {
    opacity: 0.84 !important;
  }
  .hover-opacity-xl-84 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-84.active, .hover-opacity-xl-84:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.84 !important;
  }
  .opacity-xl-85 {
    opacity: 0.85 !important;
  }
  .hover-opacity-xl-85 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-85.active, .hover-opacity-xl-85:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.85 !important;
  }
  .opacity-xl-86 {
    opacity: 0.86 !important;
  }
  .hover-opacity-xl-86 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-86.active, .hover-opacity-xl-86:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.86 !important;
  }
  .opacity-xl-87 {
    opacity: 0.87 !important;
  }
  .hover-opacity-xl-87 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-87.active, .hover-opacity-xl-87:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.87 !important;
  }
  .opacity-xl-88 {
    opacity: 0.88 !important;
  }
  .hover-opacity-xl-88 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-88.active, .hover-opacity-xl-88:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.88 !important;
  }
  .opacity-xl-89 {
    opacity: 0.89 !important;
  }
  .hover-opacity-xl-89 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-89.active, .hover-opacity-xl-89:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.89 !important;
  }
  .opacity-xl-90 {
    opacity: 0.9 !important;
  }
  .hover-opacity-xl-90 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-90.active, .hover-opacity-xl-90:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.9 !important;
  }
  .opacity-xl-91 {
    opacity: 0.91 !important;
  }
  .hover-opacity-xl-91 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-91.active, .hover-opacity-xl-91:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.91 !important;
  }
  .opacity-xl-92 {
    opacity: 0.92 !important;
  }
  .hover-opacity-xl-92 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-92.active, .hover-opacity-xl-92:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.92 !important;
  }
  .opacity-xl-93 {
    opacity: 0.93 !important;
  }
  .hover-opacity-xl-93 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-93.active, .hover-opacity-xl-93:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.93 !important;
  }
  .opacity-xl-94 {
    opacity: 0.94 !important;
  }
  .hover-opacity-xl-94 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-94.active, .hover-opacity-xl-94:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.94 !important;
  }
  .opacity-xl-95 {
    opacity: 0.95 !important;
  }
  .hover-opacity-xl-95 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-95.active, .hover-opacity-xl-95:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.95 !important;
  }
  .opacity-xl-96 {
    opacity: 0.96 !important;
  }
  .hover-opacity-xl-96 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-96.active, .hover-opacity-xl-96:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.96 !important;
  }
  .opacity-xl-97 {
    opacity: 0.97 !important;
  }
  .hover-opacity-xl-97 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-97.active, .hover-opacity-xl-97:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.97 !important;
  }
  .opacity-xl-98 {
    opacity: 0.98 !important;
  }
  .hover-opacity-xl-98 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-98.active, .hover-opacity-xl-98:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.98 !important;
  }
  .opacity-xl-99 {
    opacity: 0.99 !important;
  }
  .hover-opacity-xl-99 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-99.active, .hover-opacity-xl-99:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.99 !important;
  }
  .opacity-xl-100 {
    opacity: 1 !important;
  }
  .hover-opacity-xl-100 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xl-100.active, .hover-opacity-xl-100:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 1 !important;
  }
}

@media (min-width: 1400px) {
  .opacity-xxl-1 {
    opacity: 0.01 !important;
  }
  .hover-opacity-xxl-1 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-1.active, .hover-opacity-xxl-1:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.01 !important;
  }
  .opacity-xxl-2 {
    opacity: 0.02 !important;
  }
  .hover-opacity-xxl-2 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-2.active, .hover-opacity-xxl-2:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.02 !important;
  }
  .opacity-xxl-3 {
    opacity: 0.03 !important;
  }
  .hover-opacity-xxl-3 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-3.active, .hover-opacity-xxl-3:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.03 !important;
  }
  .opacity-xxl-4 {
    opacity: 0.04 !important;
  }
  .hover-opacity-xxl-4 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-4.active, .hover-opacity-xxl-4:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.04 !important;
  }
  .opacity-xxl-5 {
    opacity: 0.05 !important;
  }
  .hover-opacity-xxl-5 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-5.active, .hover-opacity-xxl-5:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.05 !important;
  }
  .opacity-xxl-6 {
    opacity: 0.06 !important;
  }
  .hover-opacity-xxl-6 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-6.active, .hover-opacity-xxl-6:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.06 !important;
  }
  .opacity-xxl-7 {
    opacity: 0.07 !important;
  }
  .hover-opacity-xxl-7 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-7.active, .hover-opacity-xxl-7:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.07 !important;
  }
  .opacity-xxl-8 {
    opacity: 0.08 !important;
  }
  .hover-opacity-xxl-8 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-8.active, .hover-opacity-xxl-8:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.08 !important;
  }
  .opacity-xxl-9 {
    opacity: 0.09 !important;
  }
  .hover-opacity-xxl-9 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-9.active, .hover-opacity-xxl-9:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.09 !important;
  }
  .opacity-xxl-10 {
    opacity: 0.1 !important;
  }
  .hover-opacity-xxl-10 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-10.active, .hover-opacity-xxl-10:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.1 !important;
  }
  .opacity-xxl-11 {
    opacity: 0.11 !important;
  }
  .hover-opacity-xxl-11 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-11.active, .hover-opacity-xxl-11:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.11 !important;
  }
  .opacity-xxl-12 {
    opacity: 0.12 !important;
  }
  .hover-opacity-xxl-12 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-12.active, .hover-opacity-xxl-12:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.12 !important;
  }
  .opacity-xxl-13 {
    opacity: 0.13 !important;
  }
  .hover-opacity-xxl-13 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-13.active, .hover-opacity-xxl-13:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.13 !important;
  }
  .opacity-xxl-14 {
    opacity: 0.14 !important;
  }
  .hover-opacity-xxl-14 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-14.active, .hover-opacity-xxl-14:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.14 !important;
  }
  .opacity-xxl-15 {
    opacity: 0.15 !important;
  }
  .hover-opacity-xxl-15 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-15.active, .hover-opacity-xxl-15:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.15 !important;
  }
  .opacity-xxl-16 {
    opacity: 0.16 !important;
  }
  .hover-opacity-xxl-16 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-16.active, .hover-opacity-xxl-16:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.16 !important;
  }
  .opacity-xxl-17 {
    opacity: 0.17 !important;
  }
  .hover-opacity-xxl-17 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-17.active, .hover-opacity-xxl-17:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.17 !important;
  }
  .opacity-xxl-18 {
    opacity: 0.18 !important;
  }
  .hover-opacity-xxl-18 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-18.active, .hover-opacity-xxl-18:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.18 !important;
  }
  .opacity-xxl-19 {
    opacity: 0.19 !important;
  }
  .hover-opacity-xxl-19 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-19.active, .hover-opacity-xxl-19:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.19 !important;
  }
  .opacity-xxl-20 {
    opacity: 0.2 !important;
  }
  .hover-opacity-xxl-20 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-20.active, .hover-opacity-xxl-20:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.2 !important;
  }
  .opacity-xxl-21 {
    opacity: 0.21 !important;
  }
  .hover-opacity-xxl-21 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-21.active, .hover-opacity-xxl-21:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.21 !important;
  }
  .opacity-xxl-22 {
    opacity: 0.22 !important;
  }
  .hover-opacity-xxl-22 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-22.active, .hover-opacity-xxl-22:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.22 !important;
  }
  .opacity-xxl-23 {
    opacity: 0.23 !important;
  }
  .hover-opacity-xxl-23 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-23.active, .hover-opacity-xxl-23:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.23 !important;
  }
  .opacity-xxl-24 {
    opacity: 0.24 !important;
  }
  .hover-opacity-xxl-24 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-24.active, .hover-opacity-xxl-24:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.24 !important;
  }
  .opacity-xxl-25 {
    opacity: 0.25 !important;
  }
  .hover-opacity-xxl-25 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-25.active, .hover-opacity-xxl-25:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.25 !important;
  }
  .opacity-xxl-26 {
    opacity: 0.26 !important;
  }
  .hover-opacity-xxl-26 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-26.active, .hover-opacity-xxl-26:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.26 !important;
  }
  .opacity-xxl-27 {
    opacity: 0.27 !important;
  }
  .hover-opacity-xxl-27 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-27.active, .hover-opacity-xxl-27:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.27 !important;
  }
  .opacity-xxl-28 {
    opacity: 0.28 !important;
  }
  .hover-opacity-xxl-28 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-28.active, .hover-opacity-xxl-28:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.28 !important;
  }
  .opacity-xxl-29 {
    opacity: 0.29 !important;
  }
  .hover-opacity-xxl-29 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-29.active, .hover-opacity-xxl-29:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.29 !important;
  }
  .opacity-xxl-30 {
    opacity: 0.3 !important;
  }
  .hover-opacity-xxl-30 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-30.active, .hover-opacity-xxl-30:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.3 !important;
  }
  .opacity-xxl-31 {
    opacity: 0.31 !important;
  }
  .hover-opacity-xxl-31 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-31.active, .hover-opacity-xxl-31:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.31 !important;
  }
  .opacity-xxl-32 {
    opacity: 0.32 !important;
  }
  .hover-opacity-xxl-32 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-32.active, .hover-opacity-xxl-32:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.32 !important;
  }
  .opacity-xxl-33 {
    opacity: 0.33 !important;
  }
  .hover-opacity-xxl-33 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-33.active, .hover-opacity-xxl-33:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.33 !important;
  }
  .opacity-xxl-34 {
    opacity: 0.34 !important;
  }
  .hover-opacity-xxl-34 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-34.active, .hover-opacity-xxl-34:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.34 !important;
  }
  .opacity-xxl-35 {
    opacity: 0.35 !important;
  }
  .hover-opacity-xxl-35 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-35.active, .hover-opacity-xxl-35:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.35 !important;
  }
  .opacity-xxl-36 {
    opacity: 0.36 !important;
  }
  .hover-opacity-xxl-36 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-36.active, .hover-opacity-xxl-36:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.36 !important;
  }
  .opacity-xxl-37 {
    opacity: 0.37 !important;
  }
  .hover-opacity-xxl-37 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-37.active, .hover-opacity-xxl-37:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.37 !important;
  }
  .opacity-xxl-38 {
    opacity: 0.38 !important;
  }
  .hover-opacity-xxl-38 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-38.active, .hover-opacity-xxl-38:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.38 !important;
  }
  .opacity-xxl-39 {
    opacity: 0.39 !important;
  }
  .hover-opacity-xxl-39 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-39.active, .hover-opacity-xxl-39:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.39 !important;
  }
  .opacity-xxl-40 {
    opacity: 0.4 !important;
  }
  .hover-opacity-xxl-40 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-40.active, .hover-opacity-xxl-40:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.4 !important;
  }
  .opacity-xxl-41 {
    opacity: 0.41 !important;
  }
  .hover-opacity-xxl-41 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-41.active, .hover-opacity-xxl-41:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.41 !important;
  }
  .opacity-xxl-42 {
    opacity: 0.42 !important;
  }
  .hover-opacity-xxl-42 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-42.active, .hover-opacity-xxl-42:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.42 !important;
  }
  .opacity-xxl-43 {
    opacity: 0.43 !important;
  }
  .hover-opacity-xxl-43 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-43.active, .hover-opacity-xxl-43:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.43 !important;
  }
  .opacity-xxl-44 {
    opacity: 0.44 !important;
  }
  .hover-opacity-xxl-44 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-44.active, .hover-opacity-xxl-44:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.44 !important;
  }
  .opacity-xxl-45 {
    opacity: 0.45 !important;
  }
  .hover-opacity-xxl-45 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-45.active, .hover-opacity-xxl-45:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.45 !important;
  }
  .opacity-xxl-46 {
    opacity: 0.46 !important;
  }
  .hover-opacity-xxl-46 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-46.active, .hover-opacity-xxl-46:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.46 !important;
  }
  .opacity-xxl-47 {
    opacity: 0.47 !important;
  }
  .hover-opacity-xxl-47 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-47.active, .hover-opacity-xxl-47:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.47 !important;
  }
  .opacity-xxl-48 {
    opacity: 0.48 !important;
  }
  .hover-opacity-xxl-48 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-48.active, .hover-opacity-xxl-48:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.48 !important;
  }
  .opacity-xxl-49 {
    opacity: 0.49 !important;
  }
  .hover-opacity-xxl-49 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-49.active, .hover-opacity-xxl-49:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.49 !important;
  }
  .opacity-xxl-50 {
    opacity: 0.5 !important;
  }
  .hover-opacity-xxl-50 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-50.active, .hover-opacity-xxl-50:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.5 !important;
  }
  .opacity-xxl-51 {
    opacity: 0.51 !important;
  }
  .hover-opacity-xxl-51 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-51.active, .hover-opacity-xxl-51:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.51 !important;
  }
  .opacity-xxl-52 {
    opacity: 0.52 !important;
  }
  .hover-opacity-xxl-52 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-52.active, .hover-opacity-xxl-52:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.52 !important;
  }
  .opacity-xxl-53 {
    opacity: 0.53 !important;
  }
  .hover-opacity-xxl-53 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-53.active, .hover-opacity-xxl-53:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.53 !important;
  }
  .opacity-xxl-54 {
    opacity: 0.54 !important;
  }
  .hover-opacity-xxl-54 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-54.active, .hover-opacity-xxl-54:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.54 !important;
  }
  .opacity-xxl-55 {
    opacity: 0.55 !important;
  }
  .hover-opacity-xxl-55 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-55.active, .hover-opacity-xxl-55:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.55 !important;
  }
  .opacity-xxl-56 {
    opacity: 0.56 !important;
  }
  .hover-opacity-xxl-56 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-56.active, .hover-opacity-xxl-56:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.56 !important;
  }
  .opacity-xxl-57 {
    opacity: 0.57 !important;
  }
  .hover-opacity-xxl-57 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-57.active, .hover-opacity-xxl-57:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.57 !important;
  }
  .opacity-xxl-58 {
    opacity: 0.58 !important;
  }
  .hover-opacity-xxl-58 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-58.active, .hover-opacity-xxl-58:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.58 !important;
  }
  .opacity-xxl-59 {
    opacity: 0.59 !important;
  }
  .hover-opacity-xxl-59 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-59.active, .hover-opacity-xxl-59:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.59 !important;
  }
  .opacity-xxl-60 {
    opacity: 0.6 !important;
  }
  .hover-opacity-xxl-60 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-60.active, .hover-opacity-xxl-60:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.6 !important;
  }
  .opacity-xxl-61 {
    opacity: 0.61 !important;
  }
  .hover-opacity-xxl-61 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-61.active, .hover-opacity-xxl-61:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.61 !important;
  }
  .opacity-xxl-62 {
    opacity: 0.62 !important;
  }
  .hover-opacity-xxl-62 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-62.active, .hover-opacity-xxl-62:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.62 !important;
  }
  .opacity-xxl-63 {
    opacity: 0.63 !important;
  }
  .hover-opacity-xxl-63 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-63.active, .hover-opacity-xxl-63:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.63 !important;
  }
  .opacity-xxl-64 {
    opacity: 0.64 !important;
  }
  .hover-opacity-xxl-64 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-64.active, .hover-opacity-xxl-64:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.64 !important;
  }
  .opacity-xxl-65 {
    opacity: 0.65 !important;
  }
  .hover-opacity-xxl-65 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-65.active, .hover-opacity-xxl-65:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.65 !important;
  }
  .opacity-xxl-66 {
    opacity: 0.66 !important;
  }
  .hover-opacity-xxl-66 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-66.active, .hover-opacity-xxl-66:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.66 !important;
  }
  .opacity-xxl-67 {
    opacity: 0.67 !important;
  }
  .hover-opacity-xxl-67 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-67.active, .hover-opacity-xxl-67:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.67 !important;
  }
  .opacity-xxl-68 {
    opacity: 0.68 !important;
  }
  .hover-opacity-xxl-68 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-68.active, .hover-opacity-xxl-68:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.68 !important;
  }
  .opacity-xxl-69 {
    opacity: 0.69 !important;
  }
  .hover-opacity-xxl-69 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-69.active, .hover-opacity-xxl-69:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.69 !important;
  }
  .opacity-xxl-70 {
    opacity: 0.7 !important;
  }
  .hover-opacity-xxl-70 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-70.active, .hover-opacity-xxl-70:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.7 !important;
  }
  .opacity-xxl-71 {
    opacity: 0.71 !important;
  }
  .hover-opacity-xxl-71 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-71.active, .hover-opacity-xxl-71:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.71 !important;
  }
  .opacity-xxl-72 {
    opacity: 0.72 !important;
  }
  .hover-opacity-xxl-72 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-72.active, .hover-opacity-xxl-72:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.72 !important;
  }
  .opacity-xxl-73 {
    opacity: 0.73 !important;
  }
  .hover-opacity-xxl-73 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-73.active, .hover-opacity-xxl-73:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.73 !important;
  }
  .opacity-xxl-74 {
    opacity: 0.74 !important;
  }
  .hover-opacity-xxl-74 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-74.active, .hover-opacity-xxl-74:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.74 !important;
  }
  .opacity-xxl-75 {
    opacity: 0.75 !important;
  }
  .hover-opacity-xxl-75 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-75.active, .hover-opacity-xxl-75:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.75 !important;
  }
  .opacity-xxl-76 {
    opacity: 0.76 !important;
  }
  .hover-opacity-xxl-76 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-76.active, .hover-opacity-xxl-76:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.76 !important;
  }
  .opacity-xxl-77 {
    opacity: 0.77 !important;
  }
  .hover-opacity-xxl-77 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-77.active, .hover-opacity-xxl-77:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.77 !important;
  }
  .opacity-xxl-78 {
    opacity: 0.78 !important;
  }
  .hover-opacity-xxl-78 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-78.active, .hover-opacity-xxl-78:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.78 !important;
  }
  .opacity-xxl-79 {
    opacity: 0.79 !important;
  }
  .hover-opacity-xxl-79 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-79.active, .hover-opacity-xxl-79:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.79 !important;
  }
  .opacity-xxl-80 {
    opacity: 0.8 !important;
  }
  .hover-opacity-xxl-80 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-80.active, .hover-opacity-xxl-80:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.8 !important;
  }
  .opacity-xxl-81 {
    opacity: 0.81 !important;
  }
  .hover-opacity-xxl-81 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-81.active, .hover-opacity-xxl-81:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.81 !important;
  }
  .opacity-xxl-82 {
    opacity: 0.82 !important;
  }
  .hover-opacity-xxl-82 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-82.active, .hover-opacity-xxl-82:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.82 !important;
  }
  .opacity-xxl-83 {
    opacity: 0.83 !important;
  }
  .hover-opacity-xxl-83 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-83.active, .hover-opacity-xxl-83:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.83 !important;
  }
  .opacity-xxl-84 {
    opacity: 0.84 !important;
  }
  .hover-opacity-xxl-84 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-84.active, .hover-opacity-xxl-84:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.84 !important;
  }
  .opacity-xxl-85 {
    opacity: 0.85 !important;
  }
  .hover-opacity-xxl-85 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-85.active, .hover-opacity-xxl-85:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.85 !important;
  }
  .opacity-xxl-86 {
    opacity: 0.86 !important;
  }
  .hover-opacity-xxl-86 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-86.active, .hover-opacity-xxl-86:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.86 !important;
  }
  .opacity-xxl-87 {
    opacity: 0.87 !important;
  }
  .hover-opacity-xxl-87 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-87.active, .hover-opacity-xxl-87:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.87 !important;
  }
  .opacity-xxl-88 {
    opacity: 0.88 !important;
  }
  .hover-opacity-xxl-88 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-88.active, .hover-opacity-xxl-88:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.88 !important;
  }
  .opacity-xxl-89 {
    opacity: 0.89 !important;
  }
  .hover-opacity-xxl-89 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-89.active, .hover-opacity-xxl-89:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.89 !important;
  }
  .opacity-xxl-90 {
    opacity: 0.9 !important;
  }
  .hover-opacity-xxl-90 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-90.active, .hover-opacity-xxl-90:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.9 !important;
  }
  .opacity-xxl-91 {
    opacity: 0.91 !important;
  }
  .hover-opacity-xxl-91 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-91.active, .hover-opacity-xxl-91:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.91 !important;
  }
  .opacity-xxl-92 {
    opacity: 0.92 !important;
  }
  .hover-opacity-xxl-92 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-92.active, .hover-opacity-xxl-92:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.92 !important;
  }
  .opacity-xxl-93 {
    opacity: 0.93 !important;
  }
  .hover-opacity-xxl-93 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-93.active, .hover-opacity-xxl-93:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.93 !important;
  }
  .opacity-xxl-94 {
    opacity: 0.94 !important;
  }
  .hover-opacity-xxl-94 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-94.active, .hover-opacity-xxl-94:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.94 !important;
  }
  .opacity-xxl-95 {
    opacity: 0.95 !important;
  }
  .hover-opacity-xxl-95 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-95.active, .hover-opacity-xxl-95:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.95 !important;
  }
  .opacity-xxl-96 {
    opacity: 0.96 !important;
  }
  .hover-opacity-xxl-96 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-96.active, .hover-opacity-xxl-96:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.96 !important;
  }
  .opacity-xxl-97 {
    opacity: 0.97 !important;
  }
  .hover-opacity-xxl-97 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-97.active, .hover-opacity-xxl-97:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.97 !important;
  }
  .opacity-xxl-98 {
    opacity: 0.98 !important;
  }
  .hover-opacity-xxl-98 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-98.active, .hover-opacity-xxl-98:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.98 !important;
  }
  .opacity-xxl-99 {
    opacity: 0.99 !important;
  }
  .hover-opacity-xxl-99 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-99.active, .hover-opacity-xxl-99:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 0.99 !important;
  }
  .opacity-xxl-100 {
    opacity: 1 !important;
  }
  .hover-opacity-xxl-100 {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  .hover-opacity-xxl-100.active, .hover-opacity-xxl-100:hover {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    opacity: 1 !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.normalFixture {
  background-color: var(--color-background-1) !important;
}

.featuredFixture {
  background-color: #fbc33b !important;
}

.normalFixture .fixtureHour {}

.featuredFixture .fixtureHour {
  color: #ffffff;
}

.featuredFixture .fixtureDate {
  color: #ffffff;
}

.featuredFixture .fixtureLiga {
  color: #000000 !important;
}

.featuredFixture .fixtureHomeTeam {
  color: #ffffff;
}

.featuredFixture .fixtureAwayTeam {
  color: #ffffff;
}

.featuredFixture .fixtureInfo {
  color: #ffffff !important;
}

.normalFixture .fixtureDate {
  color: #b5b5c3 !important;
}

.featuredFixture .separator {
  border-bottom: 1px solid var(--color-border) !important;
}

.bgCardOrange {
  background-color: var(--color-background-15) !important;
}

.colorOrange {
  color: var(--color-background-15) !important;
}

.primary-btn {
  padding: 5px 15px 5px 15px;
  background-color: var(--buttons-buttonMain);
  border-radius: 5px;
}

.rightBar::-webkit-scrollbar {
  width: 5px;
}

/* Track */

.rightBar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

.rightBar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

.rightBar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.nameFunction {
  position: relative;
  color: #fff;
  padding: 0px 4px;
  border-radius: 30px;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.adminFunction {
  background: rgb(127 43 191);
}

.modFunction {
  background: rgb(140, 86, 121);
}

.vipFunction {
  background: rgb(255, 190, 2);
}

.tipsterFunction {
  background: rgb(0, 142, 255);
}

.absoluteNewMessages {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  background-color: red;
  padding: 10px;
  text-align: center;
  opacity: 0.5;
}

.normalTicket {
  height: 264px;
  transition: height 0.55s ease;
  background-color: var(--color-background-1) !important;
}

.selectedTicket {
  height: calc(100% - 8px);
  transition: height 0.65s ease;
  background-color: var(--color-background-1) !important;
}

.background-featured-match {
  background: url("https://images.daznservices.com/di/library/GOAL/9f/dd/luis-suarez-atletico-madrid-2020-21_tf4lgzztkaza18qjx5uiyrj29.jpg");
  position: relative;
  background-size: cover;
}

.layer-featured-match {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.layer-featured-match .event-info {
  position: absolute;
  bottom: 16px;
  left: 16px;
  width: calc(100% - 32px);
}

.header-info .timer-icon {
  background-color: #181818;
  border-radius: 25%;
}

.avatar-near:first-child {
  color: #fff;
}

.avatar-near {
  color: #fff;
  margin-left: -5px;
}

.avatar-box {
  border: 1px solid var(--color-border2);
  border-radius: 24px;
  padding: 6px 12px 6px 12px;
  background-color: #23232366;
}

.user-level-nav {
  text-transform: uppercase;
  font-size: 9px;
}

.user-level-normal {
  color: var(--colors-blackAndWhite);
}

.user-level-premium {
  color: #fec33c;
}

.user-level-tipster {
  color: #3699ff !important;
}

.user-level-vip {
  color: #d4a010 !important;
}

.user-level-mod {
  color: #4bc31a !important;
}

.user-level-admin {
  color: #c31a1a !important;
}

.slot-iframe_fullscreen {
  border-radius: 0;
  z-index: 25;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
}

.card-bg-1-graph {
  background-color: var(--color-bg-1) !important;
  color: #000000 !important;
}

.card-bg-1 {
  background-color: var(--color-bg-1) !important;
  color: var(--color-blackAndWhiteR) !important;
}

.card-bg-2 {
  background-color: var(--color-bg-2) !important;
  color: var(--color-blackAndWhiteR) !important;
}

.card-bg-3 {
  background-color: #ffffff !important;
  color: #000 !important;
}

.card-bg-4 {
  background-color: var(--color-bg-4) !important;
  color: var(--color-blackAndWhiteR) !important;
}

.borderCard {
  border: 1px solid var(--color-border3) !important;
}

.card-box-shadow {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.bordered-card2 {
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.filiKB {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  margin: calc(-0.25rem);
  overflow: visible;
}

.filiKB>* {
  margin: calc(0.25rem);
}

.iBPXGk {
  font-size: 0.625rem;
  line-height: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  transition: color 0.2s ease 0s;
  color: rgb(134, 151, 162);
}

@media (max-width: 768px) {
  .jgaXZm {
    --switch-width: 4rem !important;
    --switch-height: 2rem !important;
    position: relative;
    display: inline-block;
    width: var(--switch-width);
    height: var(--switch-height);
    flex-shrink: 0;
  }
  .filiKB {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin: calc(-0.25rem);
    overflow: visible;
  }
}

@media (max-width: 320px) {
  .filiKB {
    display: none;
  }
}

.jgaXZm {
  --switch-width: 4rem;
  --switch-height: 2rem;
  position: relative;
  display: inline-block;
  width: var(--switch-width);
  height: var(--switch-height);
  flex-shrink: 0;
}

.bJJvsb {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.fYrmvv {
  position: absolute;
  width: var(--switch-width);
  height: var(--switch-height);
  inset: 0px;
  cursor: pointer;
  background-color: var(--color-bg-1);
  background-image: url();
  background-position: left 0.5rem center, right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 18px;
  border-radius: calc(var(--switch-width) + 0.125rem);
  transition-property: background-color;
  transition-duration: 0.4s;
  transition-timing-function: ease;
}

@media (max-width: 768px) {
  .fYrmvv {
    background-size: 15px !important;
  }
}

.bJJvsb:checked+.Switch__Slider-sc-11hx91j-0::before {
  transform: translateX(calc(var(--switch-width) / 2));
}

.fYrmvv::before {
  --switch-indicator-size: calc(var(--switch-height) - 0.5rem);
  content: "";
  position: absolute;
  width: var(--switch-indicator-size);
  height: var(--switch-indicator-size);
  left: 0.25rem;
  bottom: 0.25rem;
  background-color: var(--color-bg-2);
  border-radius: 50%;
  transition-duration: inherit;
  transition-timing-function: inherit;
  transition-delay: inherit;
  transition-property: background-color, transform;
  will-change: transform;
}

.kdCtkB {
  color: rgb(255, 255, 255);
  font-size: 0.625rem;
  line-height: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  transition: color 0.2s ease 0s;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

@media (min-width: 1280px) {
  .slot-iframe {
    height: 512px;
    margin-top: 0;
  }
}

.slot-iframe_fullscreen {
  border-radius: 0;
  z-index: 9999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
}

.slot-fullscreen-button {
  top: -1rem;
  right: 2rem;
  width: unset;
  transition: 0.2s ease-out;
  position: absolute;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.slot-fullscreen-button_fullscreen {
  top: 2rem;
  right: 2rem;
}

.slot-thumbnail-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slot-thumbnail-container:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.leading-paragraph {
  line-height: 2rem;
}

.leading-paragraph-1 {
  line-height: 18px;
}

.outline-none {
  outline: none !important;
}

.line-height-13 {
  line-height: 13px !important;
}

.fix-margins {
  margin-right: 12.5px !important;
  margin-left: 12.5px !important;
}

.mark-bordered {
  border: 15px solid var(--color-bg-1);
  border-radius: 50%;
}

.bordered-emblem {
  padding: 15px;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

.lipalipa-predictie {
  padding: 8px 16px;
  background-color: var(--color-bell);
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: -30px;
}

.cota-lipa {
  padding: 4px 8px;
  background-color: #696969;
  border-radius: 4px;
}

.lipaEmblems {
  width: 50px !important;
  height: 50px !important;
}

@media (max-width: 768px) {
  .lipaEmblems {
    width: 25px !important;
    height: 25px !important;
  }
  .vsLipa {
    font-size: 24px !important;
  }
}

.vsLipa {
  font-size: 42px;
}

.zIndex999 {
  z-index: 9999 !important;
}

@media (max-width: 768px) {
  .m-0-mobile {
    margin: 0px !important;
  }
}

@media (max-width: 768px) {
  .m-second-header {
    margin-left: -8px;
    margin-right: -8px;
    margin-top: -8px;
    border-bottom: 1px solid var(--color-border);
  }
}

.m-second-header {
  border: 1px solid var(--color-border);
}

.overflow-hidden {
  overflow-y: hidden !important;
}

.button-mask-header {
  padding: 2px;
  background-color: var(--color-bg-2);
  border-radius: 6px;
}

.scroll-to-bottom-button {
  background-color: #fec33c5e !important;
  bottom: 10px !important;
}

.role-block {
  color: #fff;
  margin-left: 5px;
  position: relative;
  padding: 2px 4px;
  border-radius: 30px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  text-transform: uppercase;
}

.status-user {
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 24px;
  bottom: -2px;
  right: -2px;
  z-index: 99999;
  border: 1px solid #fff;
}

.new-state:after {
  clear: both;
  position: absolute;
  top: -10px;
  right: -13px;
  content: "";
  background: url() 0 0 no-repeat;
  height: 20px;
  width: 20px;
}

.new-state-nav:after {
  clear: both;
  position: absolute;
  top: 20px;
  right: -5px;
  content: "";
  background: url() 0 0 no-repeat;
  height: 20px;
  width: 20px;
}

.mask-progress-chat {
  background-color: var(--color-background-13);
  height: 4px;
  min-height: 4px;
  width: 100%;
}

textarea.chat-input {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
  -webkit-rtl-ordering: logical;
  flex-direction: column;
  resize: auto;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  column-count: initial !important;
  margin: 0em;
  font: 400 13.3333px Arial;
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
  padding: 2px;
}

.chat-input input {
  border: 0;
  padding: 12px 40px 12px 10px;
  min-height: 42px;
  line-height: 18px;
  resize: none;
  width: 100%;
}

.chat-input {
  border-color: #5c6b78;
  border: 1px solid;
  font-size: 14px;
  width: 100%;
  border-radius: 3px;
  display: flex;
  align-items: center;
  position: relative;
}

.button-submit-chat {
  padding: 0;
  line-height: 44px;
  font-size: 20px;
  margin: 0 0 0 10px;
  transition: all 0.2s ease;
}

.multiline-text_button-holder {
  position: absolute;
  right: 10px;
  bottom: 11px;
  height: 20px;
}

@media (max-width: 768px) {
  .dashboard-eveniment-mask {
    margin-right: 0px;
    margin-left: 0px;
    display: none;
  }
  .dashboard-evenimente-card {
    min-width: 355px;
  }
}

@media (min-width: 768px) {
  .dashboard-eveniment {
    margin-right: 0px;
  }
  .dashboard-evenimente-card {
    min-width: 220px;
  }
}

@media (min-width: 768px) {
  .dashboard-eveniment-mask {
    margin-right: 0px;
  }
}

@media (max-width: 320px) {
  .dashboard-eveniment-mask {
    display: none;
  }
}

.card-header-chat {
  position: absolute;
  background: #fec33c;
  z-index: 9;
  height: 16px;
  right: 0;
  top: -10px;
  padding: 0 4px;
  border-radius: 0 6px;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  color: #2f2f2f;
}

.card-user-status-online {
  width: 14px;
  height: 14px;
  position: absolute;
  background: #2c8a30;
  border-radius: 24px;
  border: 3px solid var(--color-bg-1);
  bottom: -4px;
  right: -4px;
  z-index: 100;
}

.card-user-status-offline {
  width: 14px;
  height: 14px;
  position: absolute;
  background: #f64e60;
  border-radius: 24px;
  border: 3px solid var(--color-bg-1);
  bottom: -4px;
  right: -4px;
  z-index: 100;
}

.page__main {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page__panel {
  display: flex;
  flex: 0 0 370px;
  width: 370px;
  z-index: 1;
}

.page__panel-hidden {
  display: none;
  visibility: hidden;
}

.breadcrumbs .button-breadcrumb:hover {
  color: #fec33c;
}

/* GRADIENTS  */

/* NAV  */

/* PREMIUM  */

.gradient-border {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  height: 44px;
  width: 44px;
  background: linear-gradient( 60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

/* PREMIUM  */

/* TIPSTER  */

.gradient-border-tipster-nav {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-tipster-nav:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  height: 44px;
  width: 44px;
  background: linear-gradient( 60deg, #3269a2, #3699ff, #2196f3, #00bcd4, #a0d1f9, #87c3f3, #03a9f4, #1663a0);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

/* TIPSTER  */

/* NORMAL  */

.gradient-border-normal {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-normal:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  height: 44px;
  width: 44px;
  background: linear-gradient( 60deg, #f79533, #f79533, #f79533, #f79533, #f79533, #f79533, #f79533, #f79533);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

/* NORMAL  */

/* MODERATOR  */

.gradient-border-mod {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-mod:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  height: 44px;
  width: 44px;
  background: linear-gradient( 60deg, #4caf50, #8bc34a, #10d417, #4caf50, #8bc34a, #10f519, #4caf50, #8bc34a);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

/* MODERATOR  */

/* VIP  */

.gradient-border-vip {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-vip:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  height: 44px;
  width: 44px;
  background: linear-gradient( 60deg, #ff0000, #ff7600, #d4a110, #fbff00, #d4a110, #ff7600, #ff0000, #4ab8c3);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

/* VIP  */

/* ADMIN  */

.gradient-border-admin {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-admin:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  height: 44px;
  width: 44px;
  background: linear-gradient( 60deg, #ff0000, #ff0000, #ff0000, #ff0000, #d41010, #ff0000, #ff0000, #c34a4a);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

/* ADMIN  */

/* MOBILE  */

@media (max-width: 768px) {
  .gradient-border-normal:after {
    height: 38px !important;
    width: 38px !important;
  }
  .gradient-border:after {
    height: 38px !important;
    width: 38px !important;
  }
  .gradient-border-tipster-nav:after {
    height: 38px !important;
    width: 38px !important;
  }
  .gradient-border-admin:after {
    height: 38px !important;
    width: 38px !important;
  }
}

/* MOBILE */

/* NAV  */

/* RIGHT NAV */

.gradient-border-big-normal {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-big-normal:after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: 128px;
  width: 128px;
  background: linear-gradient( 60deg, #f79533, #f79533, #f79533, #f79533, #f79533, #f79533, #f79533, #f79533);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.gradient-border-big-premium {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-big-premium:after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: 128px;
  width: 128px;
  background: linear-gradient( 60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.gradient-border-big-tipster {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-big-tipster:after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: 128px;
  width: 128px;
  background: linear-gradient( 60deg, #3269a2, #3699ff, #2196f3, #00bcd4, #a0d1f9, #87c3f3, #03a9f4, #1663a0);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.gradient-border-big-vip {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-big-vip:after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: 128px;
  width: 128px;
  background: linear-gradient( 60deg, #ff0000, #ff7600, #d4a110, #fbff00, #d4a110, #ff7600, #ff0000, #4ab8c3);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.gradient-border-big-mod {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-big-mod:after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: 128px;
  width: 128px;
  background: linear-gradient( 60deg, #4caf50, #8bc34a, #10d417, #4caf50, #8bc34a, #10f519, #4caf50, #8bc34a);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.gradient-border-big-admin {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-big-admin:after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: 128px;
  width: 128px;
  background: linear-gradient( 60deg, #ff0000, #ff0000, #ff0000, #ff0000, #d41010, #ff0000, #ff0000, #c34a4a);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

/* RIGHT NAV */

.modal-screen {
  border-radius: 3px;
  width: 500px;
  margin: 0 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-eveniment-details {
  background: var(--color-background-15);
  padding: 0 8px;
  border-bottom-left-radius: 4px;
  position: absolute;
  bottom: -6px;
  right: 0;
  font-size: 11px;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
}

.header-banner {
  min-height: 120px;
  background: var(--color-bg-3);
  text-align: center;
}

.header-banner .header-wrapper {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  height: 100%;
}

/* PREMIUM BANNER */

.premium-banner {
  position: relative !important;
}

.premium-banner:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient( 60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

/* PREMIUM BANNER */

/* TIPSER BANNER */

.tipser-banner {
  position: relative !important;
}

.tipser-banner:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient( 60deg, #3269a2, #3699ff, #2196f3, #00bcd4, #a0d1f9, #87c3f3, #03a9f4, #1663a0);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

/* TIPSER BANNER */

/* TIPSTER AV BANNER */

.gradient-border-premium-banner {
  position: relative !important;
  border-radius: 50%;
}

.gradient-border-premium-banner:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  height: 66px;
  width: 66px;
  background: linear-gradient( 60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: calc(2 * 50%);
  z-index: 10;
  -webkit-animation: animatedgradient 3s ease alternate infinite;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

/* TIPSTER AV BANNER */

.bg-dark {
  background-color: #15181d;
}

.mt-50px {
  margin-top: 50px;
}

.row-alt {
  background-color: hsla(0, 0%, 100%, 0.02);
}

.card-bg-4 {
  background-color: #292e38;
}

.btn-info {
  background-color: #008a81 !important;
  border-color: #008a81 !important;
}

.btn {
  font-size: 0.875rem;
  position: relative;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  text-transform: none;
  will-change: transform;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.btn-info {
  -webkit-box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.btn {
  font-weight: 600;
  line-height: 1.5;
  display: inline-block;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  text-align: center;
  vertical-align: middle;
  color: #525f7f;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  background-color: transparent;
}

.max-h-320px {
  max-height: 320px;
}

.m-height-620px {
  min-height: 620px;
}

.btn-primary {
  color: #000 !important;
  background-color: #ffffff !important;
  border-color: #9462f5 !important;
}

.btn-primary:hover {
  color: #182634 !important;
  background-color: #ffffff !important;
  border-color: #15181d !important;
}

.btn-secondary {
  color: #182634 !important;
  background-color: #f7b126 !important;
  border-color: #15181d !important;
}

.btn-secondary:hover {
  color: #182634 !important;
  background-color: #ffffff !important;
  border-color: #15181d !important;
}

.btn-outline-secondary {
  color: #000000 !important;
  background-color: #ffffff !important;
  border-color: #9462f5 !important;
}

.btn-outline-secondary:hover {
  color: #15181d !important;
  background-color: #ffffff !important;
  border-color: #182634 !important;
}

.flex-cont {
  flex-direction: row;
}

@media (max-width: 991.98px) {
  .flex-cont {
    flex-direction: column;
  }
}

.lp-tokens {
  background-color: #9462f5;
  border: 2px solid #9462f5;
  border-radius: 50%;
  height: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mln-20px {
  margin-left: -20px;
}

.DBTfNv {
  margin-bottom: -7%;
  z-index: 10;
  width: 420px;
}

@media (max-width: 991.98px) {
  .DBTfNv {
    margin-bottom: -7%;
    z-index: 10;
    width: 340px;
  }
}

.blbXGfv {
  color: #000;
  line-height: 42px;
  border: 28px solid #f7b126;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 32.5px;
  z-index: 5;
  font-size: 48px;
  text-align: right;
}

.shadow-box-orange {
  border: 1px solid #ab57e4;
  box-shadow: 0 0 15px 0 #b2b2b257;
  border-radius: 10px;
}

.card_left_column span {
  font-weight: 600;
  font-size: 14px;
}

.card_right_column span {
  font-weight: 600;
  font-size: 14px;
}

/* my css start */

body {
  /* background-image: url('../assets/body_back.svg');   */
  background-color: #f4f4f4 !important;
}

.card_img {
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}

.card_img_ludo {
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}

.header_img {
  width: 100%;
  text-align: center;
  padding: 10px 0 70px 0;
}

.card_setting {
  border: 1px solid rgb(171, 87, 228);
  border-radius: 24px;
  box-shadow: 0px 2px 12px -8px rgb(25 19 38 / 10%), 0px 1px 1px rgb(25 19 38 / 5%);
  overflow: hidden;
}

.burger {
  margin-bottom: 20px;
}

.meal_swap h1 {
  color: #1a1a1a;
  font-weight: 600;
  font-size: 30px;
}

.title_haverest {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}

.title_haverest h4 {
  color: rgb(35, 35, 35);
  font-size: 17px;
  font-weight: 600;
}

.title_haverest p {
  color: rgb(189, 194, 196);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

.card_btn {
  margin-top: 30px;
  text-align: left;
}

.wallet_btn {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(148, 98, 245);
  border: 0px;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 4%) 0px 4px 8px 0px, rgb(0 0 0 / 2%) 0px 8px 16px 0px, rgb(0 0 0 / 4%) 0px 0px 0px 1px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  width: max-content;
  height: 48px;
  line-height: 1;
  letter-spacing: 0.03em;
  -webkit-box-pack: center;
  justify-content: center;
  outline: 0px;
  padding: 0px 24px;
  transition: background-color 0.2s ease 0s;
  opacity: 1;
}

.wallet_btn:hover {
  opacity: 0.6;
}

.card_content h3 {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.1;
  color: rgb(47, 53, 55);
  margin-bottom: 14px;
}

.card_content h2 {
  font-size: 40px;
  font-weight: 800;
  line-height: 1.1;
  color: rgb(47, 53, 55);
}

.card_content h4 {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.1;
  color: rgb(47, 53, 55);
}

.card_content h5 {
  color: rgb(35, 35, 35);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

.card-bg-change {
  background-color: #648813;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms;
}

.card_content p {
  color: #648813;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;
}

.meal_state_id {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;
}

.main_offer {
  padding: 50px 0px;
  background:#ffffff;
  max-height: 100%;
}

.copy_img {
  position: absolute;
  right: 52px;
  top: 86px;
  width: 18px;
}

.refferal_link {
  margin-bottom: 30px;
  background-color: #fff;
  color: #280D5F;
  font-weight: 500;
  border: 1px solid #a90409;
  border-radius: 22px;
  padding: 10px;
  border-color: #a90409;
  box-shadow: inset 0px 2px 2px -1px rgb(74 74 104 / 10%);
  display: block;
  font-size: 16px;
  height: 40px;
  outline: 0;
  width: 100%;
}

.referral_para {
  margin-bottom: 30px;
  color: #280D5F;
  font-weight: 500;
  font-size: 16px;
}

.card_setting:hover {
  opacity: 0.8;
}

.makeStyles-parent-2 .MuiPaper-root {
  min-width: 300px !important;
}

.makeStyles-parent-2 .MuiPaper-root {
  color: var(--color-blackAndWhiteR);
  background-color: #ffffff;
}

.market p {
  color: rgb(95, 120, 136);
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
}

.market_value {
  color: rgb(95, 120, 136);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
}

.main_body {
  padding: 50px 0;
}

.header_img h1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.1;
  color: rgb(0, 0, 0);
}

.header_img p {
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

.card_ludo {
  height: 302px;
  min-height: 302px;
  overflow-y: scroll;
}

/* Farm section Start */

.farm_card {
  background-color: #238fff;
  /* border: 1px solid #6e32f8; */
  padding: 25px 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.farm_pair_section {
  display: flex;
  padding: 0px;
  justify-content: space-between;
}

.farm_pair_img {
  width: 60px;
}

.farm_pair_icon {
  width: 18px;
  margin-right: 5px;
}

.farm_pair_content {}

.farm_pair_content h3 {
  color: rgb(0, 0, 0);
  text-align: right;
  margin-bottom: 10px;
}

.farm_pair_content_btm {
  display: inline-flex;
  align-items: center;
}

.farm_pair_content_btm .core {
  color: #080819;
  font-size: 13px;
  padding: 5px 10px;
  background-color: #fff;
  margin-right: 10px;
  font-weight: 600;
  line-height: 1;
  border-radius: 50px;
  align-items: center;
  display: inline-flex;
}

.farm_pair_content_btm .multiple {
  color: #fff;
  font-size: 13px;
  padding: 5px 10px;
  font-weight: 600;
  border-radius: 50px;
  background:rgb(0, 0, 0);
  letter-spacing: 1px;
  line-height: 1;
}

.farm_count_section {
  padding: 30px 5px 20px;
  display: flex;
  justify-content: space-between;
}

.farm_count_left {
  display: inline-grid;
}

.farm_count_left span {
  color:rgb(0, 0, 0);
  font-size: 13px;
  padding: 2px 0;
}

.farm_count_right {
  display: inline-grid;
}

.farm_count_right span {
  color: rgb(0, 0, 0);
  font-size: 13px;
  padding: 2px 0;
  text-align: right;
}

.farm_harvest_section {
  display: flex;
  justify-content: space-between;
  padding: 0px 5px 10px;
  align-items: center;
}

.farm_harvest_count h3 {
  color: rgb(0, 0, 0);
  text-align: right;
  margin-bottom: 5px;
  font-size: 15px;
}

.farm_harvest_count span {
  color:black;
  font-size: 13px;
}

.farm_harvest_btn button {
  position: relative;
  display: block;
  margin: 27px auto;
  padding: 9px;
  overflow: hidden;
  background: rebeccapurple;
  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 60%);
  color: #ecf0f1;
  transition: background-color .3s;
  font-weight: 600;
  font-size: 13px;
  border-color: white !important;
}

.farm_unlock_wallet {
  padding-top: 20px;
  padding-bottom: 15px;
}

.farm_unlock_wallet h3 {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.farm_unlock_wallet span {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding-top: 5px;
}

.unlock_btn {
  border: 0px;
  border-radius: 5px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  padding: 11px 24px;
  background:rgb(0, 0, 0);
  width: 100%;
  margin-top: 15px;
}

.farm-dep-with {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.farm-dep-with .deposit-btn {
  border: 0px;
  border-radius: 5px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  padding: 11px 24px;
  background:rgb(0, 0, 0);
  width: 100%;
  margin-right: 15px;
}

.farm-dep-with .withdraw-btn {
  border: 0px;
  border-radius: 5px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  padding: 11px 24px;
  background: rgb(0, 0, 0);
  width: 100%;
}

/* Modal CSS Start */

.modal-depo-bal {
  margin-bottom: 10px;
}

.modal-depo-bal label {
  font-size: 15px;
  color: #32325d;
  font-weight: 600;
}

.modal-depo-bal p {
  font-size: 14px;
  font-weight: 600;
  color: #32325d;
  margin-bottom: 10px;
}

.modal-depo-bal h3 {
  font-size: 15px;
  text-align: left;
}

.modal-depo-bal h3 span {
  font-size: 18px;
  font-weight: 600;
  color: #32325d;
  margin-left: 5px;
}

.modal-depo-bal input {
  padding: 10px;
  border-radius: 5px;
  background: #32325d;
}

.modal-depo-bal button {
  background: linear-gradient(132deg, rgba(193, 101, 221, 1) 0%, rgba(92, 39, 254, 1) 100%, rgba(0, 0, 0, 1) 100%);
}

/* Modal CSS End */

.farm_details_card {
  border-top: 1px solid #381580;
  margin-top: 10px;
}

.farm_details_card p {
  text-align: center;
  padding-top: 15px;
  font-size: 15px;
  cursor: pointer;
  padding-bottom: 5px;
}

.farm_details {
  display: flex;
  justify-content: space-between;
}

.farm_details_left {
  display: inline-grid;
}

.farm_details_left a {
  color:rgb(0, 0, 0);
  font-size: 13px;
  padding: 5px 0;
}

.farm_details_left span svg {
  width: 15px;
  fill:rgb(0, 0, 0);
}

.farm_details_left span.liquidity {
  color:rgb(0, 0, 0);
  font-size: 15px;
  padding: 5px 0;
}

.farm_details_right {
  display: inline-grid;
}

.farm_details_right span {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  text-align: right;
}

/* Farm section End */

/* My css end */

/* My css valuet page start */

.eth_bnb {
  text-align: right;
}

.eth_bnb h1 {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.1;
  color: rgb(47, 53, 55);
}

.eth_bnb p {
  -webkit-box-align: center;
  align-items: center;
  background-color: transparent;
  border: 2px solid rgb(148, 98, 245);
  border-radius: 16px;
  color: rgb(148, 98, 245);
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  height: 28px;
  line-height: 1.5;
  padding: 0px 8px;
  white-space: nowrap;
  margin: 0;
}

.eth_bnb h6 {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(148, 98, 245);
  border: 2px solid rgb(148, 98, 245);
  border-radius: 16px;
  color: rgb(255, 255, 255);
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  height: 28px;
  line-height: 1.5;
  padding: 0px 8px;
  white-space: nowrap;
  margin-left: 10px !important;
  margin: 0;
}

.apr_value {
  text-align: right;
}

.apr_value h3 {
  color: rgb(95, 120, 136);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 10px;
}

.apr_value p {
  min-height: 20px;
  display: block;
  background-color: rgb(233, 234, 235);
  border-radius: 4px;
  height: 24px;
  width: 80px;
  margin: 0;
  text-align: center;
  color: rgb(95, 120, 136);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 10px;
}

.etl_ern p span {
  color: rgb(148, 98, 245);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  padding-right: 3px;
}

.etl_ern p {
  color: rgb(35, 35, 35);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
}

.ethereum_btn {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(148, 98, 245);
  border: 0px;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 4%) 0px 4px 8px 0px, rgb(0 0 0 / 2%) 0px 8px 16px 0px, rgb(0 0 0 / 4%) 0px 0px 0px 1px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  width: max-content;
  height: 48px;
  line-height: 1;
  letter-spacing: 0.03em;
  -webkit-box-pack: center;
  justify-content: center;
  outline: 0px;
  padding: 0px 24px;
  transition: background-color 0.2s ease 0s;
  opacity: 1;
}

.ethereum_btn:disabled {
  background-color: rgb(233, 234, 235);
  border-color: rgb(233, 234, 235);
  box-shadow: none;
  color: rgb(189, 194, 196);
  cursor: not-allowed;
}

.apr_value h1 {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.1;
  color: rgb(47, 53, 55);
  margin: 0;
}

img.exchange_eth {
  width: 64px;
}

/* My css valuet page End */