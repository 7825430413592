.nav {
  display: flex;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  flex-wrap: wrap;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #292e38;
}
.nav-pills .nav-link {
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.75rem 1rem;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #fff;
  background-color: transparent;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.nav-pills .nav-link {
  color: #5e72e4;
  background-color: #fff;
  -webkit-box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}
.nav-pills .nav-link {
  border-radius: 0.375rem;
}
.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.img-fluid,
.img-thumbnail {
  max-width: 70%;
  height: auto;
}
/* square buttons */
.rec.rec-arrow {
  display: none;
}
/* square buttons */
.rec.rec-dot {
  display: none;
}

.z-index6 {
  z-index: 6;
}
.rec-carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.background-stones {
  background-image: url(https://d36mxiodymuqjm.cloudfront.net/website/battle/backgrounds/bg_stone-floor.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.mw1100 {
  max-width: 1100px;
  margin: 0 auto;
}

.section-title h2::before,
.section-title h2::after {
  content: "";
  width: 50px;
  height: 2px;
  background: #182634;
  display: inline-block;
}
.section-title h2::before {
  margin: 0 15px 10px 0;
}
.section-title h2::after {
  margin: 0 0 10px 15px;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  color: #fff;
}

@media (max-width: 768px) {
  .section-title h2 {
    font-size: 18px;
  }

  .section-title h2::before {
    margin: 0 7.5px 5px 0;
  }
  .section-title h2::after {
    margin: 0 0 5px 7.5px;
  }
}

.token-details {
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .token-details {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

.tokenomics-data {
  flex-direction: row;
}
@media (max-width: 768px) {
  .timeline .tl-container .date {
    top: calc(50% - 11px) !important;
  }
  .tokenomics-data {
    flex-direction: column;
  }

  .timeline .tl-container::before {
    content: "";
    position: absolute;
    width: 35px !important;
    height: 2px;
    top: calc(50% - 1px);
    right: 8px;
    background: #1f232b;
    z-index: 1;
  }
  .timeline .tl-container .icon {
    width: 20px !important;
    height: 20px !important;
    top: calc(50% - 10px) !important;
  }
  .timeline .tl-container.left .icon,
  .timeline .tl-container.right .icon {
    left: 130px !important;
  }
}
.timeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px 0;
}
.timeline .tl-container::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  top: calc(50% - 1px);
  right: 8px;
  background: #1f232b;
  z-index: 1;
}
.timeline .tl-container.left {
  left: 0;
}
[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}
[data-aos][data-aos][data-aos-easing="ease-in-sine"],
body[data-aos-easing="ease-in-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
[data-aos][data-aos][data-aos-easing="ease"],
body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}
[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}
.timeline .tl-container {
  padding: 15px 30px;
  position: relative;
  background: inherit;
  width: 50%;
}
[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}
[data-aos="fade-right"] {
  transform: translate3d(-100px, 0, 0);
}
.timeline .tl-container::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  right: -8px;
  background: #1f232b;
  border: 2px solid #2a3344;
  border-radius: 16px;
  z-index: 1;
}
.timeline .tl-container .date {
  position: absolute;
  display: inline-block;
  top: calc(50% - 11px);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
}
.timeline .tl-container.left .date {
  right: -75px;
}
.timeline .tl-container .icon {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 9px 0;
  top: calc(50% - 20px);
  background: var(--color-bg-3);
  border: 2px solid #2a3344;
  border-radius: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  z-index: 1;
}
.timeline .tl-container.left .icon {
  right: 56px;
}
.timeline .tl-container.right {
  left: 50%;
}
.timeline .tl-container .content {
  padding: 30px 90px 30px 30px;
  background: var(--color-bg-3);
  position: relative;
  border-radius: 0 500px 500px 0;
}
.timeline .tl-container.right::before {
  left: 8px;
}
.timeline .tl-container.right .content {
  padding: 30px 30px 30px 90px;
  border-radius: 500px 0 0 500px;
}
.timeline .tl-container .content {
  padding: 30px 90px 30px 30px;
  background: var(--color-bg-3);
  position: relative;
  border-radius: 0 500px 500px 0;
}
.timeline .tl-container.right::after {
  left: -8px;
}
.timeline .tl-container.right .date {
  left: -75px;
}
.timeline .tl-container.left .date {
  right: -75px;
}
.timeline::after {
  content: "";
  position: absolute;
  width: 2px;
  background: #1f232b;
  top: -48px;
  bottom: -67px;
  left: 50%;
  margin-left: -1px;
}
.timeline .tl-container.right .icon {
  left: 56px;
}
@media (max-width: 768px) {
  .timeline::after {
    top: -38px !important;
    bottom: -52px !important;
  }
  .timeline .tl-container.left::before,
  .timeline .tl-container.right::before {
    left: 100px;
    border-color: transparent #006e51 transparent transparent;
  }
  .timeline .tl-container.left .content,
  .timeline .tl-container.right .content {
    padding: 30px 30px 30px 45px !important;
    border-radius: 500px 0 0 500px;
  }
  .timeline .tl-container.left::after,
  .timeline .tl-container.right::after {
    left: 82px;
  }
  .timeline .tl-container {
    width: 100%;
    padding-left: 120px;
    padding-right: 0px !important;
  }
  .timeline .tl-container.left::after,
  .timeline .tl-container.right::after {
    left: 82px;
  }
  .timeline .tl-container.right {
    left: 0;
  }
  .timeline .tl-container.left .content,
  .timeline .tl-container.right .content {
    padding: 30px 30px 30px 90px;
    border-radius: 500px 0 0 500px;
  }
  .timeline .tl-container.left .date,
  .timeline .tl-container.right .date {
    right: auto;
    left: 15px;
  }
  .timeline .tl-container.left .icon,
  .timeline .tl-container.right .icon {
    right: auto;
    left: 146px;
  }
  .timeline .tl-container .content h2 {
    font-size: 0.75rem;
  }
}
@media (max-width: 768px) {
  .timeline::after {
    left: 90px;
  }
}
.bbottom {
  border-bottom: 1px solid var(--color-bg-2);
}
@media (max-width: 768px) {
  .tokenomics {
    font-size: 9px;
  }
}

.socialFacebook {
  color: #f2f2f2;
}

.socialTwitter {
  color: #f2f2f2;
}

.socialInstagram {
  color: #f2f2f2;
}

.socialReddit {
  color: #f2f2f2;
}

.socialMedium {
  color: #f2f2f2;
}

.socialTikTok {
  color: #f2f2f2;
}

.socialDiscord {
  color: #f2f2f2;
}
.socialTelegram {
  color: #f2f2f2;
}
.socialFacebook:hover {
  color: #3b5998;
}

.socialTwitter:hover {
  color: #00acee;
}

.socialInstagram:hover {
  color: #d6249f;
}

.socialReddit:hover {
  color: #ff5700;
}

.socialMedium:hover {
  color: #00ab6c;
}

.socialTikTok:hover {
  color: #010101;
}

.socialDiscord:hover {
  color: #7289d9;
}
.socialTelegram:hover {
  color: #0088cc;
}

.recomended-coin {
  position: absolute;
  font-size: 8px;
  bottom: 0;
  left: 3px;
  font-weight: 800;
  color: #f64e60;
}
.recomended-coin-mobile {
  position: absolute;
  font-size: 8px;
  bottom: -15px;
  left: 0px;
  font-weight: 800;
  color: #f64e60;
  width: max-content;
}

.bgtd4 {
  background-color: #038272 !important;
}
.bgtd5 {
  background-color: #5f0624 !important;
}
.bgtd6 {
  background-color: #b6a999 !important;
}
.bgtd7 {
  background-color: #504538 !important;
}
.bgtd8 {
  background-color: #062b48 !important;
}
