header {
  position: relative;
  background-color: #fff !important;
}


header:after,
header:before {
  /* content: " ";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%; */
}

header:before {
  /* background: url()
    bottom 0px center no-repeat;
  z-index: 1; */
}

header:after {
  /* z-index: 2;
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(27%, transparent),
      to(#1f232b)
    ),
    radial-gradient(farthest-corner, transparent 0, #1f232b 100%);
  background: -webkit-linear-gradient(top, transparent 27%, #1f232b),
    -webkit-radial-gradient(farthest-corner, transparent 0, #1f232b 100%);
  background: linear-gradient(180deg, transparent 27%, #1f232b),
    radial-gradient(farthest-corner, transparent 0, #1f232b 100%); */
}
header:after,
header:before {
  /* content: " ";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%; */
}
.top-bar {
  position: relative;
  height: 45px;
  line-height: 3px;
  z-index: 210 !important;
  background-color:#9462f5;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}
.topnav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.custom-nav {
  position: relative;
  height: 80px;
  z-index: 1000 !important;
  background-color:#f8f8f8;
}
.particles2js,
.particlesjs {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
html:not(.no-js) [data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
html:not(.no-js) [data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, -webkit-transform;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
}
header .header-row {
  position: relative;
  min-height: 600px !important;
  z-index: 5 !important;
  padding-top: 80px;
}
.align-items-center {
  align-items: center !important;
}
.bignumbers {
  font-size: 50px;
  font-family: "Acme", sans-serif;
  line-height: 1em;
  font-weight: 300;
  color: #fff !important;
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 944px;
  }
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.container-fluid {
  padding-right: 150px;
  padding-left: 150px;
  margin-right: auto;
  margin-left: auto;
}


@media (max-width: 576px) {
  .container-fluid {
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

}
.part {
  /* position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0; */
}
.custom-nav .custom-navbar,
.mobile-menu {
  height: 80px;
}
.custom-nav .custom-navbar a:not(.navbrand):hover:before {
  border-bottom: 3px solid #f7b126;
  width: 100%;
}
.custom-nav .custom-navbar a:not(.navbrand):before {
  content: " ";
  position: absolute;
  height: 2px;
  left: 0;
  width: 0;
  border-bottom: 3px solid #f7b126;
  bottom: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.custom-nav .custom-navbar a:not(.navbrand) {
  font-size: 16px;
  color:#000000;
  display: inline-block;
  min-width: 100px;
  font-weight: 600;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
header .header-stats {
  position: relative;
  z-index: 999 !important;
  font-family: "Montserrat", sans-serif;
  padding-top: 60px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  color: #32325d;
}
.h3,
h3 {
  font-size: 1.0625rem;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.text-white {
  color: rgb(0, 0, 0) !important;
}
.custom-nav .custom-navbar a:not(.navbrand):before {
  content: " ";
  position: absolute;
  height: 2px;
  left: 0;
  width: 0;
  border-bottom: 3px solid #fff;
  bottom: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.topnav li {
  display: inline-block;
  height: 45px;
  line-height: 45px;
  font-size: 10px;
  text-transform: uppercase;
  z-index: 1 !important;
}
.icon-menu {
  font-size: 20px;
  line-height: 45px;
}
@media (min-width: 992px) {
  .text-lg-right {
    text-align: right !important;
  }
}
.blbXGf {
  color: rgb(255, 255, 255);
  line-height: 42px;
  border: 28px solid #15181d;
  background-color: #182634;
  border-radius: 24px;
  padding: 32.5px;
  z-index: 5;
  font-size: 48px;
  text-align: right;
}
.DBTfN {
  margin-bottom: -20%;
  width: 210px;
}

.darker {
  color: #15181d;
}
.text-light {
  color: #ced4da !important;
  font-size: 18px;
}

.fs-16px {
  font-size: 16px;
}
.con-b {
  -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 0;
    border-radius: 16px;
    box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-letter-spacing: 0.03em;
    -moz-letter-spacing: 0.03em;
    -ms-letter-spacing: 0.03em;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0;
    -webkit-transition: background-color 0.2s,opacity 0.2s;
    transition: background-color 0.2s,opacity 0.2s;
    height: 32px;
    padding: 0 16px;
    background-color: rgb(255 255 255);
    color: #000000;
}
.con-c{
  webkit-align-items: center;
    align-items: center;
    border: 0;
    border-radius: 16px;
    box-shadow: 0px -1px 0px 0px rgb(14 14 44 / 40%) inset;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    -webkit-letter-spacing: 0.03em;
    -moz-letter-spacing: 0.03em;
    -ms-letter-spacing: 0.03em;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0;
    transition: background-color 0.2s,opacity 0.2s;
    height: 48px;
    padding: 0 24px;
    background-color: #EFF4F5;
    box-shadow: none;
    color: rgb(148, 98, 245);
    width: 100%;
    margin-bottom: 8px;
}
.con-b:hover {
  opacity: 0.8;
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .custom-navbar {
    display: none;
  }
}
.flex-cont {
  flex-direction: row;
}
@media (max-width: 991.98px) {
  .flex-cont {
    flex-direction: column;
  }
  .custom-nav {
    height: 60px !important;
  }
  header .header-row {
    padding-top: 10px;
  }
  .mlmobile27 {
    margin-left: 27px;
  }
}

@media (min-width: 991.98px) {
  .padding-left-60 {
    padding-left: 60px;
  }
}
